import React from 'react';

const AnswerQuestionsWithCount = ({ data }) => {
    // const data = [
    //     {
    //         text: '2020/12/12 11:11 ~ 2020/12/12 11:12',
    //         count: 21,
    //     },
    //     {
    //         text: '2020/12/12 ~ 2020/12/12',
    //         count: 0,
    //     },
    //     {
    //         text: '2020/12/12 ~ 2020/12/12',
    //         count: 21,
    //     },
    // ];

    return (
        <div className="session-detail-card mt-8 p-12">
            {data.map((d, index) => {
                if (d.count) {
                    return (
                        <div
                            key={index}
                            className={`d-flex ans-with-count align-items-center ${
                                index < data.length - 1 ? 'mb-8' : ''
                            } `}
                            style={{ width: 'unset', height: 'unset'}}
                        >
                            <span>{d.text}</span>
                            <div className="ans-counting align-items-center ml-8" style={{ minWidth: '25px'}}>{d.count}</div>
                        </div>
                    );
                }
                return (
                    <span key={index} className={`ans-with-count ${index < data.length - 1 ? 'mb-8' : ''}`}>
                        {d.text}
                    </span>
                );
            })}
        </div>
    );
};

export default AnswerQuestionsWithCount;
