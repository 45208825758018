import React from 'react';
import PropTypes from 'prop-types';
import { EditIcon } from 'components/SvgIcon';

const FieldInfo = ({ className, label, value, showEdit = true, onEdit }) => {
    let tmpClassName = 'field-info';
    if (className) {
        tmpClassName += ' ' + className;
    }
    return (
        <div className={tmpClassName}>
            <div className="field-label">
                {label}
                {showEdit && (
                    <div className="edit-icon" onClick={() => onEdit && onEdit()}>
                        <EditIcon />
                    </div>
                )}
            </div>
            <div className="field-value">{value}</div>
        </div>
    );
};

export default FieldInfo;

FieldInfo.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    showEdit: PropTypes.bool,
    onEdit: PropTypes.func,
};
