import React, { useRef, useState } from 'react';
import TdtSelect from 'components/TdtSelect';
import { Button, Select, Typography, Space, Radio, DatePicker } from 'antd';
import {
    overviewSelector,
    setDisplayPeriodType,
    setFilterByAnswerPeriodId,
    setFilterByCompany,
    setFilterByDateRange,
} from 'pages/admin/interview_sheet/detail_screen/interview_sheet_detail.slice';
import { connect } from 'react-redux';
import ResponsePeriodType from 'enums/response_period_type.enum';
import { getEnumText } from 'helpers/enum.helper';
import { useTranslation } from 'react-i18next';
import { AddIcon, CalendarIcon, RadioIcon, RadioSelectedIcon } from 'components/SvgIcon';
import { periodFullStartEnd } from 'helpers/date.helper';

const mapState = state => ({
    periodFilter: state.admin.interviewSheetDetail.periodFilter,
    ...overviewSelector(state.admin.interviewSheetDetail),
});
const mapDispatch = { setFilterByCompany, setFilterByDateRange, setDisplayPeriodType, setFilterByAnswerPeriodId };

const PeriodFilter = ({
    companies,
    periodFilter,
    setFilterByCompany,
    setFilterByDateRange,
    setDisplayPeriodType,
    setFilterByAnswerPeriodId,
    setActiveKey,
    interviewSheet,
}) => {
    const { t } = useTranslation();
    const { RangePicker } = DatePicker;
    const dateFormat = 'YYYY/MM/DD';

    const { dateRange: hasDateRange, displayPeriodType, answerPeriodId } = periodFilter;
    const isAnswerPeriodType = displayPeriodType === ResponsePeriodType.ANSWER_PERIOD.value;

    return (
        <div className="flex-1">
            <div className="d-flex mb--8 ">
                <div>
                    <Typography className="fw-700 fs-16 color-blg-100">{t('表示期間')}</Typography>
                    <div className="mt-4 d-flex">
                        <TdtSelect
                            size="large"
                            className=" mr-8 fw-400 fs-16 bg-gray-5 interview-sheet-select"
                            style={{ width: 182, height: 41 }}
                            value={displayPeriodType}
                            onChange={v => {
                                setDisplayPeriodType(v);
                            }}
                        >
                            {Object.values(ResponsePeriodType).filter(o => interviewSheet.is_radar_questionnaire || o.value === ResponsePeriodType.FREE_CHOICE_OF_PERIOD.value).map(o => (
                                <Select.Option key={o.value} value={o.value}>
                                    {t(`enum:${getEnumText(ResponsePeriodType, o.value)}`)}
                                </Select.Option>
                            ))}
                        </TdtSelect>
                        {isAnswerPeriodType ? (
                            <TdtSelect
                                defaultValue={'whole_period'}
                                className="fw-400 fs-16 bg-gray-5"
                                style={{ width: 182 }}
                                optionLabelProp="label"
                                value={answerPeriodId}
                                onChange={v => {
                                    if (v === null) return;
                                    setFilterByAnswerPeriodId(v);
                                }}
                            >
                                <Select.Option value={'whole_period'} label={'全期間'}>
                                    <span className="d-flex align-items-center pt-2 pb-2">
                                        {answerPeriodId === 'whole_period' ? (
                                            <RadioSelectedIcon size={24} className="mr-8" />
                                        ) : (
                                            <RadioIcon size={24} className="mr-8 color-blg-60" />
                                        )}
                                        {'全期間'}
                                    </span>
                                </Select.Option>
                                {interviewSheet.periods.map(item => (
                                    <Select.Option
                                        key={`period_${item.id}`}
                                        value={item.id}
                                        label={`${periodFullStartEnd(item)}`}
                                    >
                                        <span className="d-flex align-items-center pt-2 pb-2">
                                            {answerPeriodId === item.id ? (
                                                <RadioSelectedIcon size={24} className="mr-8" />
                                            ) : (
                                                <RadioIcon size={24} className="mr-8 color-blg-60" />
                                            )}
                                            {periodFullStartEnd(item)}
                                        </span>
                                    </Select.Option>
                                ))}
                                <Select.Option className="mt-8">
                                    <Space style={{ padding: '0 8px' }}>
                                        <Button
                                            className="fw-b color-blue-100 bg-transparent"
                                            type="text"
                                            style={{
                                                height: 40,
                                                borderRadius: 6,
                                            }}
                                            icon={<AddIcon />}
                                            onClick={() => {
                                                setActiveKey('response_period');
                                            }}
                                        >
                                            {'回答期間を追加'}
                                        </Button>
                                    </Space>
                                </Select.Option>
                            </TdtSelect>
                        ) : (
                            <div
                                className={`period-filter-range period-filter-range--${
                                    hasDateRange ? 'has-value' : 'has-no-value'
                                }`}
                            >
                                <RangePicker
                                    className="bg-gray-5 mr-8"
                                    style={{ width: 236, height: 41 }}
                                    format={dateFormat}
                                    showToday={true}
                                    allowClear={true}
                                    suffixIcon={<CalendarIcon className="pl-0" size={20} />}
                                    inputReadOnly={true}
                                    value={periodFilter.dateRange}
                                    onChange={dates => setFilterByDateRange(dates)}
                                />
                                <span className="f16-24 color-blg-100 whole-period">{'全期間'}</span>
                            </div>
                        )}
                    </div>
                </div>
                <div className="ml-40">
                    <Typography className="fw-700 fs-16 color-blg-100">{t('表示企業')}</Typography>
                    <TdtSelect
                        size="large"
                        className=" mt-4 fw-400 fs-16 bg-gray-5 interview-sheet-select"
                        style={{ width: 182, height: 41 }}
                        defaultValue={null}
                        value={periodFilter.companyId}
                        onChange={v => setFilterByCompany(v)}
                    >
                        <Select.Option value={null}>{'全ての企業'}</Select.Option>
                        {companies.map(company => (
                            <Select.Option key={company.id} value={company.id}>
                                {company.name}
                            </Select.Option>
                        ))}
                    </TdtSelect>
                </div>
            </div>
        </div>
    );
};

export default connect(mapState, mapDispatch)(PeriodFilter);
