import { randomId } from 'helpers/common.helper';

const validateEmail = email => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const isValidEmail = email => validateEmail(email);

export const csvDataToListEmail = data => {
    let error = {};
    let emails = [];
    const existed = {};

    // line 0 is head
    let i = 1;
    for (i; i < data.length; i++) {
        const item = data[i];
        const email = item[0];

        if (!email || email.trim().length < 1) {
            continue;
        }

        // check: incorrect email
        if (!isValidEmail(item[0])) {
            error[i] = 'incorrect';
            continue;
        }
        // check: not an email

        // check duplicate email
        if (existed[email]) {
            error[i] = 'duplicate';
        } else {
            emails.push({ email, id: randomId() });
            existed[email] = true;
        }
    }

    return { error, emails, hasError: Object.keys(error).length > 0 };
};
