import React from 'react';
import { Col, Image, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import noData from 'assets/images/noData.png';

const NoData = ({ messageNoData = 'データがありません', srcImage, className, textClassName }) => (
    <>
        <Row justify="center">
            <Col>
                <Image className={`${className} mt-98`} src={srcImage ? srcImage : noData} preview={false} />
            </Col>
        </Row>
        <Row justify="center">
            <Col span={24}>
                <Typography className={`${textClassName} description-no-data mt-32 mb-32`}>{messageNoData}</Typography>
            </Col>
        </Row>
    </>
);

export default NoData;

NoData.prototype = {
    messageNoData: PropTypes.string,
    srcImage: PropTypes.any,
    className: PropTypes.any,
    textClassName: PropTypes.any,
};
