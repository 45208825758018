import { createSelector, createSlice } from '@reduxjs/toolkit';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import { showNormal } from 'helpers/notification.helper';
import { processCounselorDetails } from 'helpers/counselor.helper';

const name = 'counselorDetail';

const counselorDetailSlice = createSlice({
    name,
    initialState: {
        counselor: null,
        registeredLoading: true,
        registered: [],
        companies: [],
        programs: {},
        companiesForFilter: {},
        filter: '',
        isStaff: false,
        orgRegistered: {}, // registered without process data
    },
    reducers: {
        setCounselor(state, action) {
            state.counselor = action.payload;
        },
        setCounselorRole(state, action) {
            state.counselor.role = action.payload;
        },
        setCounselorEmail(state, action) {
            state.counselor.email = action.payload;
        },
        setRegisteredLoading(state, action) {
            state.registeredLoading = action.payload;
        },
        setRegistered(state, action) {
            state.registered = action.payload;
        },
        setPrograms(state, action) {
            state.programs = action.payload;
        },
        setFilter(state, action) {
            state.filter = action.payload;
        },
        setIsStaff(state, action) {
            state.isStaff = action.payload;
        },
        setCompanies(state, action) {
            state.companies = action.payload;
        },
        setCompaniesForFilter(state, action) {
            state.companiesForFilter = action.payload;
        },
        initialCounselor(state) {
            state.counselor = null;
            state.registeredLoading = true;
            state.programs = {};
            state.companiesForFilter = {};
            state.registered = [];
            state.companies = [];
            state.filter = '';
            state.isStaff = false;
            state.orgRegistered = {};
        },
        setOrgRegistered(state, action) {
            state.orgRegistered = action.payload;
        },
    },
});

export const {
    setCounselor,
    setCounselorRole,
    setCounselorEmail,
    setRegisteredLoading,
    setRegistered,
    setPrograms,
    setFilter,
    setIsStaff,
    setCompanies,
    initialCounselor,
    setCompaniesForFilter,
    setOrgRegistered,
} = counselorDetailSlice.actions;

export const getCounselor = id => async dispatch => {
    dispatch(initialCounselor());
    const data = await api.get(APP_URLS.ADMIN_COUNSELORS_DETAIL.replace(':id', id));
    if (data) {
        dispatch(setCounselor(data));
        if (data.companies) {
            let companyFilter = {};
            for (const company of data.companies) {
                if (!companyFilter[company.id]) {
                    companyFilter[company.id] = company.name;
                }
            }
            dispatch(setCompaniesForFilter(companyFilter));
            dispatch(setCompanies(data.companies));
            dispatch(setIsStaff(true));
        } else {
            dispatch(getRegistered(id));
            dispatch(setIsStaff(false));
        }
    }
    dispatch(setRegisteredLoading(false));
};

export const getRegistered =
    (id = '') =>
    async dispatch => {
        const data = await api.get(APP_URLS.ADMIN_COUNSELORS_DETAIL_REGISTERED.replace(':id', id));
        dispatch(setOrgRegistered(data));
        let processedData = processCounselorDetails(data);
        if (processedData) {
            dispatch(setRegistered(processedData.registered));
            dispatch(setPrograms(processedData.programs));
        }
    };

const filterSelector = state => state.filter;
const registeredSelector = state => state.registered;
const companiesSelector = state => state.companies;

export const filterRegistered = createSelector(filterSelector, registeredSelector, (filter, registered) => {
    if (!filter) return registered;
    return registered.filter(r => r.programId.toString() === filter);
});

export const filterCompanies = createSelector(filterSelector, companiesSelector, (filter, companies) => {
    if (!filter) return companies;
    return companies.filter(r => r.id.toString() === filter);
});

export const updateRole = (id, role, message) => async dispatch => {
    const data = await api.patch(APP_URLS.ADMIN_COUNSELORS_DETAIL_ROLE.replace(':id', id), { role });
    if (data) {
        dispatch(setCounselorRole(role));
        showNormal('', message, 3);
    }
};

export const updateEmail = (id, email) => async dispatch => {
    const data = await api.patch(APP_URLS.ADMIN_COUNSELORS_DETAIL_EMAIL.replace(':id', id), { email });
    if (data) {
        dispatch(setCounselorEmail(email));
    }
};

export default counselorDetailSlice.reducer;
