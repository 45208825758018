import { createSlice } from '@reduxjs/toolkit';
import { randomId } from 'helpers/common.helper';
import { getTimeFromMinutes } from 'helpers/moment.helper';

const defaultDetail = { is_enabled: true, day: 'monday', hour_start: 0, hour_end: 0 };

const reservationDetail = createSlice({
    name: 'reservationDetail',
    initialState: {
        details: {},
        detailsOrder: [],
        ignoreTime: {},
        loading: false,
    },
    reducers: {
        addDetail(state, action) {
            const sessionTime = action.payload;
            defaultDetail.hour_start = getTimeFromMinutes(0);
            defaultDetail.hour_end = getTimeFromMinutes(sessionTime);
            // add new
            const detailId = randomId();
            state.details[detailId] = { ...defaultDetail };
            state.detailsOrder = [...state.detailsOrder, detailId];
        },
        addDetails(state, action) {
            state.loading = true;
            const details = action.payload;
            for (const timeItem of details) {
                const detailId = randomId();
                state.details[detailId] = { ...defaultDetail, ...timeItem };
                state.detailsOrder = [...state.detailsOrder, detailId];
            }
            state.loading = false;
        },
        updateDetail(state, action) {
            const { id, value } = action.payload;
            state.details = {
                ...state.details,
                [id]: {
                    ...state.details[id],
                    ...value,
                },
            };
        },
        removeDetail(state, action) {
            const { id } = action.payload;
            let newDetails = { ...state.details };
            delete newDetails[id];
            state.details = newDetails;
            state.detailsOrder = [...state.detailsOrder].filter(d => d !== id);
        },
        resetToDefault(state) {
            state.details = {};
            state.detailsOrder = [];
        },
    },
});

export default reservationDetail.reducer;
export const { addDetail, updateDetail, removeDetail, resetToDefault, addDetails } = reservationDetail.actions;
