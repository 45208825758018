import cryptoRandomString from 'crypto-random-string';
import { useRef } from 'react';
import { diffHourStartEnd } from 'helpers/moment.helper';

export const isNullOrUndefined = value => {
    return typeof value === 'undefined' || value === null;
};

export const isEmpty = value => {
    return isNullOrUndefined(value) || value === '';
};

export const randomId = () => {
    return cryptoRandomString({ length: 10 });
};
export const isEmptyAllowObject = obj => {
    // null and undefined are "empty"
    if (obj == null) return true;

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0) return false;
    if (obj.length === 0) return true;

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== 'object') return true;

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    for (var key in obj) {
        if (hasOwnProperty.call(obj, key)) return false;
    }

    return true;
};
export const scrollRef = ref => {
    //window.scrollTo(0, ref.current.offsetTop);
    ref.current.scrollTo(0, ref.current.scrollHeight, 'auto');
    // window.scrollTo({
    //     top: ref.current.scrollHeight,
    //     left: 0,
    //     behavior: 'auto',
    // });
};

export const isElementInViewport = el => {
    const rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) - 250
        //rect.left >= 0 &&
        //rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

const dayOrder = { sunday: 1, monday: 2, tuesday: 3, wednesday: 4, thursday: 5, friday: 6, saturday: 7 };
export const processOverlap = (targets, exists) => {
    const flats = [
        ...targets.map(t => ({ ...t, source: 'targets' })),
        ...exists.map(e => ({ ...e, source: 'exists' })),
    ];

    flats.sort((a, b) => {
        if (dayOrder[a.day] === dayOrder[b.day]) {
            return a.hour_start > b.hour_start ? 1 : a.hour_start < b.hour_start ? -1 : 0;
        }
        return dayOrder[a.day] > dayOrder[b.day] ? 1 : -1;
    });

    let overlapInfo = [];
    let appendNewInfo = true;
    let result = [];
    let tmp = {};
    for (let i = 0; i < flats.length; i++) {
        let curr = flats[i];
        if (!tmp.day) {
            tmp = curr;
            if (flats.length === 1) {
                result.push(curr);
            }
            continue;
        }

        if (curr.day !== tmp.day || curr.hour_start > tmp.hour_end) {
            result.push(tmp);
            tmp = curr;
            appendNewInfo = true;
        } else {
            const maxEndTime = tmp.hour_end > curr.hour_end ? tmp.hour_end : curr.hour_end;
            if (appendNewInfo) {
                overlapInfo = [...overlapInfo, [tmp, curr]];
            } else {
                overlapInfo[overlapInfo.length - 1].push(curr);
            }
            tmp = { ...tmp, hour_end: maxEndTime };
            appendNewInfo = false;
        }

        if (i === flats.length - 1) {
            result.push(tmp);
        }
    }

    return { result, overlapInfo };
};

export const boundaryAfterMerging = arrInfo => {
    let arrHour = [];
    arrInfo.map(info => {
        arrHour.push(info.hour_start, info.hour_end);
    });
    arrHour.sort();
    return {
        hour_start: arrHour[0],
        hour_end: arrHour[arrHour.length - 1],
        day: arrInfo[0].day,
    };
};

export const findMinDurationOfRanges = ranges => {
    let minDuration = diffHourStartEnd(ranges[0].hour_start, ranges[0].hour_end);
    ranges.map(r => {
        const duration = diffHourStartEnd(r.hour_start, r.hour_end);
        if (duration < minDuration) {
            minDuration = duration;
        }
    });

    return minDuration;
};

export const copyToClipboard = (text, callback) => {
    navigator.clipboard.writeText(text);
    callback && callback();
};

const iOS = () => {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 */
export const getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (iOS()) {
        return "iOS";
    }

    return "unknown";
};

const platform = getMobileOperatingSystem();
export const isAndroid = platform === "Android";
export const isIOS = platform === "iOS";