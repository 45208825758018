import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Form, Input, Row } from 'antd';
import { APP_URLS } from 'constants/url.constant';
import Routes from 'routes';
import PropTypes from 'prop-types';
import SidebarTopClient from 'layouts/SidebarTopClient';
import api from 'helpers/api.helper';
import { useTranslation } from 'react-i18next';
import history from 'helpers/history.helper';
import { setPasswordResetAccount } from 'pages/auth/auth.slice';

const mapState = state => ({
    passwordResetAccount: state.auth.passwordResetAccount,
});
const mapDispatch = { setPasswordResetAccount };

const PasswordReset = ({ setPasswordResetAccount }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const formSubmit = async values => {
        setError(false);
        setLoading(true);
        let email = values.email;
        let url = location.origin + Routes.public.PASSWORD_CHANGE.path;
        setPasswordResetAccount({ email: email, url: url });

        try {
            await api.post(
                APP_URLS.REQUEST_PASSWORD_RESET,
                {
                    email: email,
                    url: url,
                },
                {},
                {},
                true
            );
            setLoading(false);
            history.push(Routes.public.PASSWORD_RESET_SENT.path);
        } catch (e) {
            setLoading(false);
            setError(true);
        }
    };

    const validateMessages = {
        required: t('validation:Please enter ${label}'),
        types: {
            email: t('validation:Please check format of your ${label}'),
        },
    };
    return (
        <div id="forgot-password">
            <SidebarTopClient backUrl={Routes.public.LOGIN.path} />

            <div className="register-header mt-96">
                <h2 className="td-header">{t('Password reissue')}</h2>
            </div>
            <Row justify="center" align="middle">
                <Col xs={22} sm={24} md={12} lg={8} xl={6}>
                    <div id="form-wrapper">
                        <Form layout="vertical" onFinish={formSubmit} validateMessages={validateMessages}>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <div className="mb-24">
                                        {t(
                                            'A link for reissuing your password will be sent to your registered email address'
                                        )}
                                    </div>
                                    <Form.Item
                                        name="email"
                                        label={t('field:Email')}
                                        validateStatus={error && 'error'}
                                        validateTrigger={false}
                                        rules={[
                                            {
                                                required: true,
                                                type: 'email',
                                            },
                                        ]}
                                    >
                                        <Row gutter={16}>
                                            <Col span={24}>
                                                <Input placeholder="sample@example.com" />
                                                {error && (
                                                    <div className="ant-form-item-explain ant-form-item-explain-error">
                                                        <div role="alert">
                                                            {t('validation:The email does not exist')}
                                                        </div>
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row justify="center" align="middle" className="mt-32">
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" loading={loading} className="td-btn">
                                        {t('Send')}
                                    </Button>
                                </Form.Item>
                            </Row>
                        </Form>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default connect(mapState, mapDispatch)(PasswordReset);

PasswordReset.propTypes = {
    setPasswordResetAccount: PropTypes.func.isRequired,
};
