import React from 'react';

export const HMText = ({ detail }) => {
    const wrapper_style = 'font-size: 0; padding: 0 16px 24px 16px; word-break: break-word;';
    const style =
        'font-family: Noto Sans JP; font-size: 15px; line-height: 150%; font-weight: 400; text-align: left; color: #000000;';

    const raw = `<tr>
    <td align="center" style="${wrapper_style}">
        <div style="${style}">
            ${detail['content']}
        </div>
    </td>
</tr>
`;

    return <tr dangerouslySetInnerHTML={{ __html: raw }} />;
};
