import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { CloseIcon } from 'components/SvgIcon';
import DeleteBtnInModal from 'components/DeleteBtnInModal';

const ResponsePeriodDeleteModal = ({ visible, onClose, onUpdate }) => {
    return (
        <Modal
            title={'回答期間削除'}
            visible={visible}
            width={320}
            footer={null}
            closeIcon={<CloseIcon />}
            onCancel={() => onClose()}
        >
            <p>{'回答期間を削除します。この操作はやり直しできません。よろしいですか？'}</p>
            <DeleteBtnInModal onClose={onClose} onUpdate={onUpdate} />
        </Modal>
    );
};

ResponsePeriodDeleteModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

export default ResponsePeriodDeleteModal;
