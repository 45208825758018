import EmployeeContainer from 'pages/admin/company/tabs/employee/EmployeeContainer';
import ProgramContainer from 'pages/admin/company/tabs/program/ProgramContainer';
import ProgramCreate from 'pages/admin/company/tabs/program/ProgramCreate';
import ProgramUpdate from 'pages/admin/company/tabs/program/ProgramUpdate';
import StaffContainer from 'pages/admin/company/tabs/staff/StaffContainer';
import MessageContainer from 'pages/admin/company/tabs/message/MessageContainer';
import MessageCreate from 'pages/admin/company/tabs/message/MessageCreate';
import MessageDetail from 'pages/admin/company/tabs/message/MessageDetail';
import SettingContainer from 'pages/admin/company/tabs/setting/SettingContainer';
import AddCounselorContainer from 'pages/admin/company/tabs/program/AddCounselorContainer';
import SeminarContainer from 'pages/admin/company/tabs/seminar/SeminarContainer';
import SeminarCreate from 'pages/admin/company/tabs/seminar/SeminarCreate';
import SeminarDetail from 'pages/admin/company/tabs/seminar/SeminarDetail';
import SeminarUpdate from 'pages/admin/company/tabs/seminar/SeminarUpdate';
import MessageNewProgram from 'pages/admin/company/tabs/message/MessageNewProgram';
import MessageNewSession from 'pages/admin/company/tabs/message/MessageNewSession';

const CompanyDetailRoutes = {
    INDEX: { path: '/', component: EmployeeContainer },
    EMPLOYEE: { path: '/employee', component: EmployeeContainer }, // --
    PROGRAM: { path: '/program', component: ProgramContainer }, // --
    PROGRAM_CREATE: { path: '/program/create', component: ProgramCreate },
    PROGRAM_EDIT: { path: '/program/:programId/edit', component: ProgramUpdate },
    PROGRAM_ADD_COUNSELOR: { path: '/program/:programId/add-counselor', component: AddCounselorContainer },
    STAFF: { path: '/staff', component: StaffContainer }, // --
    SEMINAR: { path: '/seminar', component: SeminarContainer }, // --
    SEMINAR_CREATE: { path: '/seminar/create', component: SeminarCreate },
    SEMINAR_DETAIL: { path: '/seminar/:seminarId', component: SeminarDetail },
    SEMINAR_UPDATE: { path: '/seminar/:seminarId/update', component: SeminarUpdate },
    MESSAGE: { path: '/message', component: MessageContainer }, // --
    MESSAGE_CREATE: { path: '/message/create', component: MessageCreate },
    MESSAGE_NEW_PROGRAM: { path: '/message/new-program', component: MessageNewProgram },
    MESSAGE_NEW_SESSION: { path: '/message/new-session', component: MessageNewSession },
    MESSAGE_DETAIL: { path: '/message/:messageId', component: MessageDetail },
    SETTING: { path: '/setting', component: SettingContainer }, // --
};

export default CompanyDetailRoutes;
