import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { CloseIcon } from 'components/SvgIcon';
import { useTranslation } from 'react-i18next';
import ActionBtnInModal from 'components/ActionBtnInModal';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import { showNormal } from 'helpers/notification.helper';
import history from 'helpers/history.helper';
import Routes from 'routes';

const CloneDistributionListModal = ({ visible, onClose, record }) => {
    const { t } = useTranslation();

    const cloneDistributionList = async distributionListId => {
        const response = await api.post(
            APP_URLS.ADMIN_EMAIL_DISTRIBUTIONS_CLONE_DISTRIBUTION.replace(':id', distributionListId)
        );
        if (response) {
            history.push(Routes.private.ADMIN_EMAIL_DISTRIBUTION_UPDATE.path.replace(':id', response.id));
            showNormal('', t('message:Data has been saved'), 3);
        }
    };

    if (!record) return <></>;

    return (
        <>
            <Modal
                title={'複製'}
                visible={visible}
                width={400}
                footer={null}
                closeIcon={<CloseIcon />}
                onCancel={() => onClose()}
            >
                <div className="fw-b d-inline-flex">{`“${record.name}”`}</div>
                <p>を複製します。よろしいですか？</p>
                <ActionBtnInModal
                    onClose={onClose}
                    onUpdate={() => cloneDistributionList(record.id)}
                    confirmText={'複製'}
                    isNotDelete={true}
                />
            </Modal>
        </>
    );
};

CloneDistributionListModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default CloneDistributionListModal;
