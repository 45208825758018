import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Select, Typography } from 'antd';
import {
    getQuestionnaire,
    setQuestionnaire,
    setLoading,
} from 'pages/counselor/calendar/questionnaire/questionnaire_detail.slice';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Loader from 'components/Loader';
import NoData from 'components/NoData';
import { dateToStr, getGraphPeriods } from 'helpers/date.helper';
import DataTable from 'components/DataTable';
import AnswerListWithScore from 'pages/admin/interview_sheet/detail_screen/common/AnswerListWithScore';
import {
    answersToRadarChartData,
    getNextAnswer,
    getPrevAnswer,
    parseCategoriesWithQuestions,
    parseQuestionnaire,
    getLastAnswer,
} from 'helpers/questionnaire.helper';
import { ArrowLeftIcon, ArrowRightIcon } from 'components/SvgIcon';
import RadarChart, { TransitionGraphChart } from 'components/chart/radar/RadarChart';
import history from 'helpers/history.helper';
import TdtSelect from 'components/TdtSelect';

const mapState = state => ({
    loading: state.counselor.questionnaireDetail.loading,
    questionnaire: state.counselor.questionnaireDetail.questionnaire,
});
const mapDispatch = { getQuestionnaire, setLoading, setQuestionnaire };

const QuestionnaireDetail = ({
                                 loading,
                                 setLoading,
                                 questionnaire,
                                 getQuestionnaire,
                                 firstId,
                                 allAnswers,
                                 baseUrl,
                             }) => {
    const { id, questionnaireId } = useParams();
    const { t } = useTranslation();
    const [displayType, setDisplayType] = useState(9999);

    const selectedQuestionnaireId = questionnaireId || firstId;

    useEffect(() => {
        if (selectedQuestionnaireId) {
            questionnaire = null;
            getQuestionnaire(id, selectedQuestionnaireId);
        } else {
            setLoading(false);
        }

        return () => {
            setLoading(true);
        };
    }, [selectedQuestionnaireId]);

    if (loading) {
        return (
            <div className='mt-40 ml-24 mb-24'>
                <Loader />
            </div>
        );
    }

    if (!questionnaire || questionnaire.client_id !== Number(id)) {
        return <NoData />;
    }

    const interviewSheet = questionnaire.interview_sheet;

    const columns = [
        {
            title: 'スコア',
            dataIndex: 'score',
            width: 120,
            render: (_, r) => `${r.score_from}~${r.score_to}`,
        },
        {
            title: '判定',
            dataIndex: 'title',
        },
    ];

    const result = parseQuestionnaire({
        questions: questionnaire.interview_sheet.questions,
        listAnswers: JSON.parse(questionnaire.json_answers),
    });

    const matchInfoByScore = interviewSheet.info_by_scores.find(
        o => o.score_from <= result.totalScore && o.score_to >= result.totalScore,
    );

    const categories = parseCategoriesWithQuestions(questionnaire, result.questions);

    const otherAnswerInterviewSheet = allAnswers
        .filter(o => o.interview_sheet_id === questionnaire.interview_sheet_id && o.id !== questionnaire.id)
        .sort((a, b) => {
            return a.updated_at.localeCompare(b.updated_at);
        });

    const prev = getPrevAnswer(otherAnswerInterviewSheet, questionnaire);
    const goPrev = () => {
        if (prev) {
            history.push(`${baseUrl}/${prev.id}`);
        }
    };

    const lastAnswer = getLastAnswer(otherAnswerInterviewSheet, questionnaire);

    const next = getNextAnswer(otherAnswerInterviewSheet, questionnaire);
    const goNext = () => {
        if (next) {
            history.push(`${baseUrl}/${next.id}`);
        }
    };

    const combineResults = answersToRadarChartData([questionnaire, prev]);
    const chartData = {
        labels: combineResults[0]?.map(o => o.title),
        datasets: combineResults.map((combineResult, i) => ({
            label: `${combineResult[0].dataSetLabel} ${i === 0 ? '(今回値)' : '(前回値)'}`,
            data: combineResult.map(o => o.scorePercent),
            dataScore: combineResult.map(o => o.score),
        })),
    };

    const combineAllResults = answersToRadarChartData(
        allAnswers
            .filter(o => o.interview_sheet_id === questionnaire.interview_sheet_id)
            .sort((a, b) => {
                return a.updated_at.localeCompare(b.updated_at);
            }).slice(-displayType),
        'YYYY/MM/DD HH:mm:ss',
    );

    const graphPeriods = getGraphPeriods(questionnaire.interview_sheet?.periods);

    const resultToGraph = results => {
        const labels = [];
        const datasets = {};
        for (const result of results) {
            labels.push(result[0].dataSetLabel);
            for (const category of result) {
                if (!datasets[category.title]) {
                    datasets[category.title] = {
                        title: category.title,
                        data: [],
                        dataScore: [],
                    };
                }
                datasets[category.title].data.push(category.scorePercent);
                datasets[category.title].dataScore.push(category.score);
            }
        }

        return { labels, datasets: Object.values(datasets) };
    };
    const graphData = resultToGraph(combineAllResults);
    const chartDataGraph = {
        labels: graphData.labels,
        datasets: graphData.datasets.map(dataset => ({
            label: dataset.title,
            data: dataset.data,
            dataScore: dataset.dataScore,
        })),
    };

    return (
        <>
            <div className='message-detail mt-40 ml-32 mb-24 mr-32'>
                <div className='d-flex align-items-center'>
                    <div className='mt-24 flex-1'>
                        <Typography className='fw-700 fs-24'>{questionnaire.title}</Typography>
                        <Typography className='mt-8 fw-400 fs-14 text-gray-color'>
                            {`最新回答日時: ${dateToStr(lastAnswer.updated_at, 'YYYY/MM/DD HH:mm')}`}
                        </Typography>
                    </div>
                </div>
                {categories.length > 0 && (
                    <div className='mt-24 flex-1 text-align-center'>
                        <Typography className='fw-400 fs-14 color-blg-80'>{'回答日時'}</Typography>
                        <div className='d-flex justify-content-center'>
                            <Button
                                type='text'
                                className={`arrow-btn mr-16 ${!prev ? 'color-blg-20' : 'color-blg-80'}`}
                                icon={<ArrowLeftIcon />}
                                onClick={() => goPrev()}
                                disabled={!prev}
                            />
                            <Typography className='mt-8 fw-700 fs-18 color-blg-100'>
                                {` ${dateToStr(questionnaire.updated_at, 'YYYY/MM/DD HH:mm')}`}
                            </Typography>
                            <Button
                                type='text'
                                className={`arrow-btn color-blg-60 ml-32 mr-8 ${
                                    !next ? 'color-blg-20' : 'color-blg-80'
                                }`}
                                icon={<ArrowRightIcon />}
                                onClick={() => goNext()}
                                disabled={!next}
                            />
                        </div>
                    </div>
                )}

                <Typography className='fw-700 fs-16 mt-24'>{'結果'}</Typography>
                <div className='mt-8 session-detail-card--body bg-gray-5 text-align-center'>
                    <span className='seminar-render-page-name fs-18 fw-700'>
                        {matchInfoByScore ? matchInfoByScore.title : ''}
                    </span>
                    <Typography className='fs-48 fw-100'>
                        {result.totalScore}
                        <span className='fs-18 fw-700 ml-4'>{'点'}</span>
                    </Typography>
                    <Typography
                        className='counselor-questionnaire-render-page-description mt-8 fs-14-21 color-blg-60 mb-0'>
                        {matchInfoByScore ? matchInfoByScore.description : ''}
                    </Typography>
                </div>
                <div className='mt-16'>
                    <Typography className='fw-700 fs-14 text-gray-color'>{t('内訳')}</Typography>
                    <div className='ant-layout mt-8'>
                        <DataTable
                            className='collapse-table overflow-y h-100p'
                            columns={columns}
                            data={interviewSheet.info_by_scores}
                            renderNoData={<div className='pt-24 pb-24'>データがありません</div>}
                        />
                    </div>
                </div>

                {categories.length > 0 && (
                    <>
                        <div className='mt-32'>
                            <Typography className='fw-700 fs-16'>{t('チャート')}</Typography>
                        </div>
                        <div className='mt-32 d-flex align-items-center justify-content-space-between'>
                            <RadarChart chartData={chartData} showScore={true} />
                        </div>

                        <div
                            className='mt-32 d-flex align-items-center justify-content-space-between ant-select-arrow-interview'>
                            <Typography className='fw-700 fs-16'>{t('推移グラフ')}</Typography>
                            <TdtSelect
                                size='large'
                                className=' mt-4 fw-400 fs-16 bg-white interview-sheet-select'
                                style={{ width: 140, height: 44 }}
                                value={displayType}
                                onChange={v => setDisplayType(v)}
                            >
                                <Select.Option value={3}>{'3回分'}</Select.Option>
                                <Select.Option value={6}>{'6回分'}</Select.Option>
                                <Select.Option value={12}>{'12回分'}</Select.Option>
                                <Select.Option value={9999}>{'全部'}</Select.Option>
                            </TdtSelect>
                        </div>
                        <div className='mt-32 mb-24'>
                            <TransitionGraphChart chartData={chartDataGraph} showScore={true}
                                                  graphPeriods={graphPeriods} />
                        </div>
                    </>
                )}

                <div className='mt-40'>
                    <Typography className='fw-700 fs-16'>{t('回答一覧')}</Typography>
                </div>
                <div className='mt-24'>
                    <AnswerListWithScore questionnaire={questionnaire} resultDailyLife={true} />
                </div>
            </div>
        </>
    );
};

export default connect(mapState, mapDispatch)(QuestionnaireDetail);
