import React from 'react';

const Axis = ({
    width,
    height,
    names,
    config,
    yInfo,
    yStepHeight,
    xStepWidth,
    yLabelRender,
    xLabelRender,
    isDecimal,
    yLabelCenter = false,
}) => {
    let yTicks = [],
        yStepSkip = null;
    if (yStepHeight < config.yLabelMinHeight) {
        yStepSkip = Math.ceil(config.yLabelMinHeight / yStepHeight);
    }
    for (let i = 0; i < yInfo.count; i++) {
        const key = yInfo.min + i * yInfo.size;
        let name = isDecimal ? key.toFixed(1) : key.toFixed();
        if (yStepSkip && i % yStepSkip !== 0) {
            name = null;
        }
        yTicks.push({ key, name, x: config.yLabelWidth - 4, y: height - config.xLabelHeight - i * yStepHeight });
    }

    let xStepSkip = null;
    let xTicks = [];
    if (xStepWidth < config.xLabelMinWidth) {
        xStepSkip = Math.ceil(config.xLabelMinWidth / xStepWidth);
    }
    for (let i = 0; i < names.length; i++) {
        const x = i * xStepWidth + config.yLabelWidth;
        let name = names[i];
        if (xStepSkip && i % xStepSkip !== 0) {
            name = null;
        }
        xTicks.push({ key: names[i], name, x });
    }

    return (
        <React.Fragment>
            {yTicks.map(tick => (
                <React.Fragment key={tick.key}>
                    <line x1={tick.x} y1={tick.y} x2={width} y2={tick.y} strokeWidth={1} stroke={config.borderColor} />
                    {tick.name !== null && (
                        <text x={tick.x} y={tick.y - 4} fill={config.textColor} fontSize={12} textAnchor="end">
                            {yLabelRender ? yLabelRender(tick.name) : tick.name}
                        </text>
                    )}
                </React.Fragment>
            ))}
            {xTicks.map(tick => (
                <React.Fragment key={tick.key}>
                    <line
                        x1={tick.x}
                        y1={yStepHeight / 2}
                        x2={tick.x}
                        y2={height - 16}
                        strokeWidth={1}
                        stroke={config.borderColor}
                    />
                    {tick.name !== null && (
                        <text
                            x={tick.x + 4 + (yLabelCenter ? xStepWidth / 2 - 4 : 0)}
                            y={height}
                            fill={config.textColor}
                            fontSize={12}
                            textAnchor={yLabelCenter ? 'middle' : 'start'}
                        >
                            {xLabelRender ? xLabelRender(tick.name) : tick.name}
                        </text>
                    )}
                </React.Fragment>
            ))}
            <line
                x1={width}
                y1={yStepHeight / 2}
                x2={width}
                y2={height - 20}
                strokeWidth={1}
                stroke={config.borderColor}
            />
        </React.Fragment>
    );
};

export default React.memo(Axis);
