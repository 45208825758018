import React, { useState } from 'react';
import { connect } from 'react-redux';
import SidebarTopClient from 'layouts/SidebarTopClient';
import { APP_URLS } from 'constants/url.constant';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Button, Col, Row } from 'antd';
import { InfoIcon } from 'components/SvgIcon';
import MessageWithIcon from 'components/MessageWithIcon';
import Routes from 'routes';
import api from 'helpers/api.helper';
import { useTranslation } from 'react-i18next';
import history from 'helpers/history.helper';
import passwordResetSentImage from 'assets/images/icons/colorful/password_reset_sent.png';
import { isEmpty } from 'helpers/common.helper';
import { showNormal } from 'helpers/notification.helper';

const mapState = state => ({
    passwordResetAccount: state.auth.passwordResetAccount,
});

const mapDispatch = {};

const PasswordResetSent = ({ passwordResetAccount }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    if (isEmpty(passwordResetAccount.email)) {
        return <Redirect to={'/'} />;
    }

    const formSubmit = async () => {
        setLoading(true);

        try {
            await api.post(
                APP_URLS.REQUEST_PASSWORD_RESET,
                {
                    email: passwordResetAccount.email,
                    url: passwordResetAccount.url,
                },
                {},
                {},
                true
            );
            setLoading(false);
            showNormal('', t('message:Resent the email'), 5);
        } catch (e) {
            setLoading(false);
        }
    };

    return (
        <>
            <SidebarTopClient backUrl={Routes.public.PASSWORD_RESET.path} />
            <div className="mt-96">
                <Row justify="center" align="middle">
                    <Col xs={24} sm={24} md={12} lg={16} xl={16}>
                        <MessageWithIcon
                            imageUrl={passwordResetSentImage}
                            title={t('translation:Sent a password reissue link')}
                            // description={t(
                            //     'translation:We have sent a link to reissue your password to your registered email address'
                            // )}
                        />
                        <div className="registration-complete">
                            <div className="mr-8">
                                <InfoIcon className="td-info-icon" />
                            </div>
                            <div>
                                <span className="title-fill-interview">
                                    {t(
                                        'translation:Please click on the link has just been sent to  your registered email account to reset your password'
                                    )}
                                </span>
                                <div className="title-confirm-email title-confirm-email-message">
                                    {t(
                                        'translation:Please confirm the mailbox. If you have not received a verification email, please try again by clicking this button below'
                                    )}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row justify="center" align="middle" className="mt-40">
                    <Button
                        type="primary"
                        loading={loading}
                        onClick={() => {
                            formSubmit();
                        }}
                        className="td-btn resend-email-btn"
                    >
                        {t('translation:Click here if you have not received the email')}
                    </Button>
                </Row>
                <Row justify="center" align="middle" className="mt-32">
                    <Button
                        type="link"
                        onClick={() => {
                            history.push(Routes.public.LOGIN.path);
                        }}
                        className="td-btn-link"
                    >
                        {t('translation:Login') + t('translation:screen')}
                    </Button>
                </Row>
            </div>
        </>
    );
};

export default connect(mapState, mapDispatch)(PasswordResetSent);

PasswordResetSent.propTypes = {
    passwordResetAccount: PropTypes.any.isRequired,
};
