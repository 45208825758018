import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { CloseIcon } from 'components/SvgIcon';
import { useTranslation } from 'react-i18next';
import ActionBtnInModal from 'components/ActionBtnInModal';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import { showNormal } from 'helpers/notification.helper';
import history from 'helpers/history.helper';
import Routes from 'routes';

const CloneCommonSessionModal = ({ visible, onClose, commonSession }) => {
    const { t } = useTranslation();

    const cloneCommonSession = async commonSessionId => {
        const response = await api.post(APP_URLS.ADMIN_COMPANIES_SEMINAR_CLONE_SEMINAR.replace(':id', commonSessionId));
        if (response) {
            history.push(Routes.private.ADMIN_COMMON_SESSIONS_DETAIL.path.replace(':id', response.id));
            showNormal('', t('message:Duplicate session'), 3);
        }
    };

    return (
        <>
            <Modal
                title={t('field:Session duplication')}
                visible={visible}
                width={400}
                footer={null}
                closeIcon={<CloseIcon />}
                onCancel={() => onClose()}
            >
                <p>セッションの複製します。よろしいですか？</p>
                <ActionBtnInModal
                    onClose={onClose}
                    onUpdate={() => {
                        cloneCommonSession(commonSession.id);
                    }}
                    confirmText={'複製'}
                    isNotDelete={true}
                />
            </Modal>
        </>
    );
};

CloneCommonSessionModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default CloneCommonSessionModal;
