import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { SearchIcon } from 'components/SvgIcon';
import { Input } from 'antd';

const InputSearch = ({ className = '', onSearch, ...rest }) => {
    const ref = useRef(null);
    const handlerSearch = evt => onSearch && onSearch(evt.target.value);
    const handlerClickSearch = () => {
        onSearch && onSearch(ref.current.input.value);
    };

    return (
        <Input
            {...rest}
            ref={ref}
            prefix={<SearchIcon onClick={handlerClickSearch} />}
            // className="input-search"
            className={`input-search ${className}`}
            onPressEnter={handlerSearch}
        />
    );
};

export default InputSearch;

InputSearch.propTypes = {
    onSearch: PropTypes.func,
};
