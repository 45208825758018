import React from 'react';
import moment from 'moment';
import { enWeekDayNames } from 'helpers/date.helper';
import TdtCalendarContextMenu from 'components/calendar/TdtCalendarContextMenu';
import { checkBusySlot } from 'helpers/calendar.helper';

const times = [...Array(24).keys()].map(num => (num < 10 ? `0${num}` : num));
const startOfDay = moment('00:00', 'HH:mm');
const blockHeight = 48;

const TdtCalendarDay = ({
    startDate = moment(),
    events = {},
    disableDate,
    active = {},
    blocked = {},
    createMenu = null,
    onEventClick = null,
    busySlots,
}) => {
    const dateStr = startDate.format('YYYY-MM-DD');
    return (
        <div className="tdt-calendar-day-wrapper">
            <table className="tdt-calendar tdt-calendar-day">
                <thead>
                    <tr>
                        <th className="time" />
                        <th>
                            <TdtCalendarDayEvents
                                events={events[startDate.format('YYYY-MM-DD')]}
                                onEventClick={onEventClick}
                            />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {times.map(time => {
                        const enWeekDay = enWeekDayNames[startDate.day()];
                        const timeStr = `${time}:00`;
                        let isActive;
                        if (disableDate && disableDate(dateStr)) isActive = false;
                        else isActive = active[enWeekDay] && active[enWeekDay].indexOf(timeStr) > -1;
                        const isBlocked = blocked[dateStr] && blocked[dateStr].indexOf(timeStr) > -1;
                        let className = '';
                        if (isBlocked) className += 'blocked ';
                        if (isActive) className += 'active ';
                        else className += 'disabled ';

                        // blocked by busy slots
                        if (isActive && checkBusySlot(busySlots, dateStr, timeStr)) {
                            // not own doctor
                            if (Object.keys(blocked).length === 0) {
                                className += 'blocked ';
                                isActive = false;
                            }
                        }

                        return (
                            <tr key={time} id={time === '08' ? 'anchor-time' : undefined}>
                                <td className="time">{timeStr}</td>
                                <td className={className}>
                                    <TdtCalendarContextMenu
                                        createMenu={createMenu}
                                        date={dateStr}
                                        time={timeStr}
                                        isActive={isActive}
                                        isBlocked={isBlocked}
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

const TdtCalendarDayEvents = ({ events, onEventClick }) => {
    if (!events) return null;
    let sorted = [...events];
    sorted.sort((e1, e2) => {
        if (e1.start < e2.start) {
            return -1;
        } else if (e1.start > e2.start) {
            return 1;
        } else {
            return e1.end > e2.end ? -1 : 1;
        }
    });

    let groups = [];
    for (const orgEvent of sorted) {
        let event = { ...orgEvent };
        const start = moment(event.start, 'HH:mm');
        const end = moment(event.end, 'HH:mm');
        event.mStart = start;
        event.height = (end.diff(start, 'minute') / 30) * blockHeight;
        let hasGroup = false;
        for (const g of groups) {
            if ((start.isSame(g.start) || start.isAfter(g.start)) && start.isBefore(g.end)) {
                hasGroup = true;
                g.events.push(event);
                if (end.isAfter(g.end)) {
                    g.end = end;
                }
            }
        }
        if (!hasGroup) {
            groups.push({
                start: start,
                end: end,
                events: [event],
            });
        }
    }

    return (
        <div className="event-wrapper">
            {groups.map(group => {
                const startBlock = Math.ceil(group.start.diff(startOfDay, 'minute') / 30);
                let top = startBlock * blockHeight;
                if (startBlock % 2 !== 0) top -= 2;
                return (
                    <div className="event-row" key={group.start.format('HH:mm')} style={{ top: `${top}px`, left: 0 }}>
                        {group.events.map(event => {
                            const marginBlock = Math.ceil(event.mStart.diff(group.start, 'minute') / 30);
                            let marginTop = marginBlock * blockHeight;
                            if ((marginBlock + startBlock) % 2 !== 0) {
                                marginTop -= 6;
                            }
                            return (
                                <div
                                    key={event.id}
                                    className={`event ${event.status} ${
                                        event.deleted_by_id ? 'calendar-cell-deleted' : ''
                                    }`}
                                    onClick={e => {
                                        e.stopPropagation();
                                        onEventClick(event);
                                    }}
                                    style={{
                                        marginTop: `${marginTop}px`,
                                        height: `${event.height}px`,
                                    }}
                                >
                                    {event.subtitle && <span className="subtitle">{event.subtitle}</span>}
                                    <span className="title">{event.title}</span>
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
};

export default TdtCalendarDay;
