import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { Alert, Button, Col, Form, Input, Row } from 'antd';
import Routes from 'routes';
import { APP_URLS } from 'constants/url.constant';
import api from 'helpers/api.helper';
import history from 'helpers/history.helper';
import { rootRedirect } from 'helpers/root_redirect.helper';
import SidebarTopClient from 'layouts/SidebarTopClient';
import {
    setCountAnswer,
    setCountMessage,
    setCountReservation,
    setCountReservationToday,
    setUser,
} from 'pages/auth/auth.slice';

const mapState = state => ({
    authenticated: state.auth.isAuth,
});
const mapDispatch = { setUser, setCountAnswer, setCountMessage, setCountReservation, setCountReservationToday };
const Login = ({
    authenticated,
    setUser,
    setCountAnswer,
    setCountMessage,
    setCountReservation,
    setCountReservationToday,
}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);

    if (authenticated) {
        return <Redirect to={'/'} />;
    }

    const location = useLocation();
    const isInvalidToken = !!new URLSearchParams(location.search).get('invalidToken');

    const formSubmit = async values => {
        setLoading(true);
        try {
            const data = await api.post(
                APP_URLS.LOGIN,
                { email: values.email, password: values.password },
                {},
                {},
                true
            );
            setLoading(false);
            localStorage.setItem('access_token', data.token);
            api.accessToken = data.token;
            const { user, count } = data;
            setUser(user);
            setCountAnswer(count.answer);
            setCountMessage(count.message);
            setCountReservation(count.reservation);
            setCountReservationToday(count.reservation_today);

            // redirect to:
            const questionnaireId = new URLSearchParams(location.search).get('questionnaire');
            if (questionnaireId) {
                setLoading(true);
                const res = await api.post(APP_URLS.QUESTIONNAIRE_DETAIL.replace(':uuid', questionnaireId));
                if (res) {
                    history.push(Routes.private.CLIENT_INTERVIEW_SHEET_DETAIL.path.replace(':id', res.id));
                    return;
                }
                setLoading(false);
            }

            rootRedirect(user);
        } catch (e) {
            setLoading(false);
            setHasError(true);
        }
    };

    return (
        <div id="login">
            <SidebarTopClient />
            <div className="login-container">
                <Row justify="center" align="middle">
                    <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                        <div id="form-wrapper" className="mt-56">
                            <h2 className="td-header">{t('Login')}</h2>
                            {isInvalidToken && (
                                <Alert
                                    className="mb-24"
                                    message={t('The authentication session has expired. Please log in again.')}
                                    type="info"
                                    showIcon
                                    closable
                                />
                            )}
                            <Form layout="vertical" onFinish={formSubmit} onValuesChange={() => setHasError(false)}>
                                <Form.Item
                                    label={t('field:Email')}
                                    extra={t('Alert_Input Email address')}
                                    name="email"
                                    validateTrigger={false}
                                    validateStatus={hasError ? 'error' : undefined}
                                    rules={[
                                        {
                                            required: true,
                                            type: 'email',
                                            message: t('validation:Please enter {{field}}', {
                                                field: t('field:email'),
                                            }),
                                        },
                                    ]}
                                >
                                    <Input placeholder="sample@example.com" />
                                </Form.Item>
                                <Form.Item
                                    className="mb-16"
                                    label={t('field:Password')}
                                    extra={t('Alert_Input 8 characters')}
                                    name="password"
                                    validateTrigger={false}
                                    validateStatus={hasError ? 'error' : undefined}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('validation:Please enter {{field}}', {
                                                field: t('field:password'),
                                            }),
                                        },
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>
                                <Row className="mb-24">
                                    <span>{t('Message_Forgot password')}</span>
                                    <Button
                                        type="link"
                                        onClick={() => {
                                            history.push(Routes.public.PASSWORD_RESET.path);
                                        }}
                                        className="p-0 td-link-bolder"
                                    >
                                        {t('Message_Click here')}
                                    </Button>
                                </Row>

                                <div className="mb-40 color-red-100">
                                    {hasError ? t('The email address or password is incorrect.') : null}
                                </div>

                                <Row justify="center" align="middle">
                                    <Form.Item className="mb-0">
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={loading}
                                            className="td-btn login-btn"
                                        >
                                            {t('Login')}
                                        </Button>
                                    </Form.Item>
                                </Row>
                                <div className="mt-32" align="middle">
                                    <Button
                                        type="link"
                                        onClick={() => {
                                            history.push(Routes.public.REGISTER.path);
                                        }}
                                        className="p-0 td-link-bolder"
                                    >
                                        {t('Click here for new registration')}
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default connect(mapState, mapDispatch)(Login);

Login.propTypes = {
    authenticated: PropTypes.bool.isRequired,
    setUser: PropTypes.func.isRequired,
    setCountAnswer: PropTypes.func.isRequired,
    setCountMessage: PropTypes.func.isRequired,
};
