import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { AssignmentIcon, LineIcon } from 'components/SvgIcon';
import history from 'helpers/history.helper';
import Routes from 'routes';
import { getEnumText } from 'helpers/enum.helper';
import ReservationAnswerStatus from 'enums/reservation_answer_status.enum';
import TdtButton from 'components/TdtButton';
import { dateToStr } from 'helpers/date.helper';

const InterviewSheetRow = ({ programId, reservationAnswer, isFirstInterviewSheet }) => {
    const { t } = useTranslation();
    const isBlank = reservationAnswer.status === ReservationAnswerStatus.BLANK.value;

    const getDateForInterviewSheet = () => {
        if (isFirstInterviewSheet) {
            return '要予約';
        }

        let date = isFirstInterviewSheet ? reservationAnswer.filled_in_date : reservationAnswer.reservation.date;
        return dateToStr(date, 'YYYY年M月DD日(ddd)');
    };
    return (
        <Row className="f-primary pt-24 pb-24 border-bottom-separator" align="middle">
            <Col className="mr-16 interview-icon">
                <div>
                    {/* add .with-check if checked */}
                    <span className={`circle-icon-with-bg ${isBlank ? '' : 'with-check'}`}>
                        <AssignmentIcon />
                        <span className="circle-icon-check custom-zoom-icon">
                            <LineIcon />
                        </span>
                    </span>
                </div>
            </Col>
            <div className="boxes-col">
                <Col className="mr-8 interview-date">
                    <div className="fw-500 f16-24 interview-datetime itv-date-time">{getDateForInterviewSheet()}</div>
                    <div className={isBlank ? 'interview-status' : 'interview-status itv-reservation-status'}>
                        {t(`enum:${getEnumText(ReservationAnswerStatus, reservationAnswer.status)}`)}
                    </div>
                </Col>
                <Col className="flex-1 interview-title">
                    <div className="f20-30 itv-reservation-title">{reservationAnswer.title}</div>
                    <div className="interview-title-war">
                        {reservationAnswer.input_condition
                            ? reservationAnswer.input_condition
                            : 'オンライン相談前日にご記入ください'}
                    </div>
                </Col>
            </div>

            <Col className="ml-24 interview-btn">
                <div className="itv-btn">
                    <TdtButton
                        className="fw-b btn-w120 single-btn"
                        buttonStyle={isBlank ? 'primary' : 'outline'}
                        onClick={() => {
                            let referId = isFirstInterviewSheet ? programId : reservationAnswer.id;
                            isBlank
                                ? history.push(
                                      Routes.private.CLIENT_INTERVIEW_SHEET_DETAIL.path.replace(':id', referId) +
                                          (isFirstInterviewSheet ? '?first=true' : '')
                                  )
                                : history.push(
                                      Routes.private.CLIENT_INTERVIEW_SHEET_DISPLAY.path.replace(':id', referId) +
                                          (isFirstInterviewSheet ? '?first=true' : '')
                                  );
                        }}
                    >
                        {isBlank ? '記入' : '履歴'}
                    </TdtButton>
                </div>
            </Col>
        </Row>
    );
};

export default InterviewSheetRow;

InterviewSheetRow.propTypes = {
    programId: PropTypes.number,
    reservationAnswer: PropTypes.any,
    isFirstInterviewSheet: PropTypes.bool,
};
