import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { CloseIcon } from 'components/SvgIcon';
import { useTranslation } from 'react-i18next';
import ActionBtnInModal from 'components/ActionBtnInModal';

const DeleteCommonSessionModal = ({ visible, onClose, onUpdate }) => {
    const { t } = useTranslation();
    return (
        <>
            <Modal
                title={t('field:Delete session')}
                visible={visible}
                width={400}
                footer={null}
                closeIcon={<CloseIcon />}
                onCancel={() => onClose()}
            >
                <p>{'セッションを削除します。この操作は元に戻せません。よろしいですか？'}</p>

                <ActionBtnInModal onClose={onClose} onUpdate={onUpdate} />
            </Modal>
        </>
    );
};

DeleteCommonSessionModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

export default DeleteCommonSessionModal;
