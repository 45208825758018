import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import { CloseIcon } from 'components/SvgIcon';
import { useTranslation } from 'react-i18next';
import { InterviewSheetPropTypes } from 'constants/proptypes.constant';

const DeleteInterviewSheetModal = ({ interviewSheet, visible, onClose, onUpdate }) => {
    const { t } = useTranslation();
    if (!interviewSheet) return <></>;
    return (
        <>
            <Modal
                title={t('field:削除')}
                visible={visible}
                width={320}
                height={269}
                footer={null}
                closeIcon={<CloseIcon />}
                onCancel={() => onClose()}
            >
                <div>
                    <div className="fw-b d-inline-flex">{`“${interviewSheet.name}”`}</div>
                    を削除します。回答済みのデータも削除されます。
                    <div className="mt-24">{'この操作は元に戻せません。削除してよろしいですか？'}</div>
                </div>

                <>
                    <div className="delete-action-panel">
                        <Button
                            type=""
                            className="cancel-button fw-b disable-color"
                            onClick={() => {
                                onClose();
                            }}
                        >
                            <span className={'title-button'}>{t('Cancel')}</span>
                        </Button>
                        <Button
                            type=""
                            className={ 'delete-button fw-b'}
                            onClick={() => {
                                onUpdate && onUpdate();
                                onClose();
                            }}
                        >
                            <span className={'title-button'}>{ t('Delete')}</span>
                        </Button>
                    </div>
                </>
            </Modal>
        </>
    );
};

DeleteInterviewSheetModal.propTypes = {
    interviewSheet: InterviewSheetPropTypes,
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

export default DeleteInterviewSheetModal;
