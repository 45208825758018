import React from 'react';
import Axis from 'components/chart/Axis';
import { defaultConfig, getMinMax, getYAxisInfo } from 'helpers/chart.helper';

const LineChart = ({
    width = 1000,
    height = 320,
    data,
    config,
    yMin,
    yStepSize,
    yCount,
    circleRadius = 4,
    circleOnHoverOnly = false,
    baseLines,
    yLabelRender,
    xLabelRender,
    tooltipRender,
    className,
    isDecimal,
}) => {
    let chartConfig;
    if (config) {
        chartConfig = { ...defaultConfig, ...config };
    } else {
        chartConfig = defaultConfig;
    }
    const { min: minValue, max: maxValue } = getMinMax(data.values);

    // Y Axis
    const yInfo = getYAxisInfo(minValue, maxValue, yMin, yStepSize, yCount);
    const yStepHeight = (height - chartConfig.xLabelHeight) / yInfo.count;

    // X Axis
    const xStepWidth = (width - chartConfig.yLabelWidth - chartConfig.xPaddingRight) / data.names.length;

    // Points & lines
    let points = [];
    let lines = [];
    let dataCount = 0;
    for (let i = 0; i < data.values.length && i < data.names.length; i++) {
        const value = data.values[i];
        if (value === null || typeof value === 'undefined') {
            continue;
        }
        if (!Array.isArray(value) || value.length < data.colors.length) continue;
        dataCount++;
        const x = (i + 0.5) * xStepWidth + chartConfig.yLabelWidth;
        const name = data.names[i];
        let items = [];
        for (let j = 0; j < data.colors.length; j++) {
            const y = height - chartConfig.xLabelHeight - ((value[j] - yInfo.min) / yInfo.size) * yStepHeight;
            items.push({ x, y, value: value[j], name });
        }
        points.push(items);
    }
    for (let i = 0; i < points.length - 1; i++) {
        const p1 = points[i];
        const p2 = points[i + 1];
        let items = [];
        for (let j = 0; j < p1.length; j++) {
            items.push({
                x1: p1[j].x,
                y1: p1[j].y,
                x2: p2[j].x,
                y2: p2[j].y,
            });
        }
        lines.push(items);
    }
    if (dataCount < 2) {
        circleOnHoverOnly = false;
    }

    return (
        <svg viewBox={`0 0 ${width} ${height}`} className={className}>
            <Axis
                width={width - chartConfig.xPaddingRight}
                height={height}
                names={data.names}
                config={chartConfig}
                yInfo={yInfo}
                yStepHeight={yStepHeight}
                xStepWidth={xStepWidth}
                yLabelRender={yLabelRender}
                xLabelRender={xLabelRender}
                isDecimal={isDecimal}
            />
            {baseLines &&
                baseLines.length > 0 &&
                baseLines.map((baseLine, index) => {
                    if (baseLine <= yInfo.min || baseLine >= yInfo.max) return null;
                    const y = height - chartConfig.xLabelHeight - ((baseLine - yInfo.min) / yInfo.size) * yStepHeight;
                    return (
                        <line
                            key={index}
                            x1={chartConfig.yLabelWidth}
                            y1={y}
                            x2={width - chartConfig.xPaddingRight}
                            y2={y}
                            strokeWidth={1}
                            stroke="#E1338D"
                        />
                    );
                })}
            {lines.map((items, idx1) => {
                return (
                    <React.Fragment key={idx1}>
                        {items.map((line, idx2) => (
                            <line
                                key={idx2}
                                x1={line.x1}
                                y1={line.y1}
                                x2={line.x2}
                                y2={line.y2}
                                strokeWidth={2}
                                stroke={data.colors[idx2]}
                            />
                        ))}
                    </React.Fragment>
                );
            })}
            {!circleOnHoverOnly &&
                points.map((items, idx1) => {
                    return (
                        <React.Fragment key={idx1}>
                            {items.map((point, idx2) => (
                                <circle
                                    className="point"
                                    key={idx2}
                                    cx={point.x}
                                    cy={point.y}
                                    r={circleRadius}
                                    stroke={data.colors[idx2]}
                                    strokeWidth={2}
                                    fill="#ffffff"
                                />
                            ))}
                        </React.Fragment>
                    );
                })}
            {points.map((items, index) => {
                return (
                    <g key={index} className="g-hover">
                        <rect
                            x={items[0].x - xStepWidth / 2}
                            y={0}
                            width={xStepWidth}
                            height={height - chartConfig.xLabelHeight}
                            fillOpacity={0}
                        />
                        <line
                            className="hover-line"
                            x1={items[0].x}
                            y1={yStepHeight / 2}
                            x2={items[0].x}
                            y2={height - chartConfig.xLabelHeight}
                            strokeWidth={1}
                            stroke="#666666"
                        />
                        {items.map((point, idx2) => (
                            <circle
                                className="hover-point"
                                key={idx2}
                                cx={point.x}
                                cy={point.y}
                                r={circleRadius}
                                stroke={data.colors[idx2]}
                                strokeWidth={2}
                                fill={data.colors[idx2]}
                            />
                        ))}
                        {tooltipRender && <g className="tooltip">{tooltipRender(items)}</g>}
                    </g>
                );
            })}
        </svg>
    );
};

export default React.memo(LineChart);
