import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { CloseIcon } from 'components/SvgIcon';
import TdtButton from 'components/TdtButton';

const CantCreateModal = ({ visible, onClose, warningPrograms = [] }) => {
    const { t } = useTranslation();
    return (
        <Modal
            title={t('The reservation frame could not be created')}
            visible={visible}
            width={320}
            footer={[
                <TdtButton
                    key="do_not_save"
                    buttonSize="big"
                    buttonStyle="plain"
                    className="fw-b"
                    onClick={() => onClose()}
                >
                    {t('OK')}
                </TdtButton>,
            ]}
            closeIcon={<CloseIcon />}
            onCancel={() => onClose()}
            className="modal-footer-no-border"
        >
            <div className="body1">
                {t(
                    'The reservation slot for the next program could not be created with the specified reservation slot. Please review the available slots.'
                )}
                <div className="mt-24">
                    {warningPrograms.map((p, i) => (
                        <p className="mb-0" key={`${p.id}_${i}`}>
                            <span>{'・'}</span>
                            {p.name_mngt}
                        </p>
                    ))}
                </div>
            </div>
        </Modal>
    );
};

export default CantCreateModal;
