import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import TdtButton from 'components/TdtButton';
import { DeleteIcon, EditIcon, VcIcon } from 'components/SvgIcon';
import TdtTextAreaEditable from 'components/TdtTextAreaEditable';
import FloatBar from 'components/FloatBar';
import { dateToStr } from 'helpers/date.helper';
import DeleteReservationModal from 'pages/counselor/calendar/consultation/DeleteReservationModal';
import CancelReservationModal from 'pages/counselor/calendar/consultation/CancelReservationModal';
import ResumeReservationModal from 'pages/counselor/calendar/consultation/ResumeReservationModal';
import history from 'helpers/history.helper';
import ConsultationRoutes from 'pages/counselor/calendar/consultation/consultation_routes';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import ReservationStatus from 'enums/reservation_status.enum';
import Routes from 'routes';
import { showNormal } from 'helpers/notification.helper';
import { useTranslation } from 'react-i18next';
import NoData from 'components/NoData';
import {
    getConsultation,
    setLoading,
    setConsultation,
} from 'pages/counselor/calendar/consultation/consultation_detail.slice';
import Loader from 'components/Loader';
import TdtButtonRestrict from 'components/TdtButtonRestrict';
import moment from 'moment';
import { Button } from 'antd';

const mapState = state => ({
    loading: state.counselor.consultationDetail.loading,
    consultation: state.counselor.consultationDetail.consultation,
});
const mapDispatch = { getConsultation, setLoading, setConsultation };

const ConsultationDetail = ({
    loading,
    setLoading,
    consultation,
    getConsultation,
    baseUrl,
    getConsultations,
    firstId,
    isAdmin,
    isAdminStaff,
    setConsultation,
}) => {
    const { id, reservationId } = useParams();
    const { t } = useTranslation();

    const selectedReservationId = reservationId || firstId;

    const [isEditMedicalRecord, setIsEditMedicalRecord] = useState(false);
    const [feedback, setFeedback] = useState(consultation?.feedback || '');
    const [modalVisible, setModalVisible] = useState({
        delete: false,
        cancel: false,
        resume: false,
        id: null,
    });

    const showDeleteModal = id => setModalVisible({ delete: true, cancel: false, resume: false, id });
    const showCancelModal = id => setModalVisible({ delete: false, cancel: true, resume: false, id });
    const showResumeModal = id => setModalVisible({ delete: false, cancel: false, resume: true, id });
    const closeModal = () => setModalVisible({ delete: false, cancel: false, resume: false, id: null });

    const deleteReservation = async () => {
        const response = await api.patch(
            APP_URLS.COUNSELOR_CONSULTATIONS_DELETE.replace(':id', id).replace(':reservationId', consultation.id),
            {}
        );
        if (response) {
            getConsultations();
            //getConsultation(id, selectedReservationId);
            setConsultation(null);
            history.push(Routes.private.COUNSELOR_DETAIL_CONSULTATION.path.replace(':id', id));
            showNormal('', t('message:I deleted the reservation'), 3);
        }
    };
    const cancelReservation = async () => {
        await api.patch(
            APP_URLS.COUNSELOR_CONSULTATIONS_CANCELLATION.replace(':id', id).replace(':reservationId', consultation.id),
            {}
        );
        getConsultations();
        getConsultation(id, selectedReservationId);
    };
    const resumeReservation = async () => {
        await api.patch(
            APP_URLS.COUNSELOR_CONSULTATIONS_RESUME.replace(':id', id).replace(':reservationId', consultation.id),
            {}
        );
        getConsultations();
        getConsultation(id, selectedReservationId);
    };
    const submitFeedback = async value => {
        await api.patch(
            APP_URLS.COUNSELOR_CONSULTATIONS_FEEDBACK.replace(':id', id).replace(':reservationId', consultation.id),
            { feedback: value }
        );
        getConsultations();
        getConsultation(id, selectedReservationId);
    };

    const startReservation = async () => {
        await api.patch(
            APP_URLS.COUNSELOR_CONSULTATIONS_START.replace(':id', id).replace(':reservationId', consultation.id),
            {}
        );
        getConsultations();
        getConsultation(id, selectedReservationId);
    };

    useEffect(() => {
        if (selectedReservationId) {
            consultation = null;
            getConsultation(id, selectedReservationId).then(data => {
                setIsEditMedicalRecord(false);
                setFeedback(data?.feedback || '');
            });
        } else {
            setLoading(false);
        }

        return () => {
            setLoading(true);
        };
    }, [selectedReservationId]);

    if (loading) {
        return (
            <div className="message-detail mt-40 ml-24 mb-24">
                <Loader />
            </div>
        );
    }

    if (!consultation || consultation.client_id !== Number(id)) {
        return <NoData />;
    }

    // or current account
    const counselor = consultation.counselor || {};

    const isCompleted = consultation.status === ReservationStatus.COMPLETED.value;
    const isCancelled = consultation.status === ReservationStatus.PENDING.value;
    const isEnded = consultation.status === ReservationStatus.END.value;

    const isDisabledByCancellation =
        [
            ReservationStatus.PENDING.value,
            ReservationStatus.CANCELLED_TIMEOUT.value,
            ReservationStatus.CANCELLED_BOOKING.value,
        ].indexOf(consultation.status) > -1;

    const isDisabledFromEnd = isEnded || isCompleted || isDisabledByCancellation;

    const cancelBy = consultation.cancellation_by_counselor || {};

    const zoomClickHandle = () => {
        startReservation().then(() => {
            //const zoom = consultation.zoom || {};
            //window.open(zoom.start_url, '_blank');
        });
    };

    const renderNormal = () => {
        return (
            <>
                <div className="subtitle1 mb-24 mt-40">{'相談者からの情報'}</div>
                <TdtButton
                    buttonSize="big"
                    buttonStyle="outline"
                    className="fw-b mb-24"
                    style={{ minWidth: 144 }}
                    onClick={() =>
                        history.push(
                            `${baseUrl}${ConsultationRoutes.DISPLAY_INTERVIEW_SHEET.path.replace(
                                ':reservationId',
                                consultation.id
                            )}`
                        )
                    }
                >
                    {'質問票の表示'}
                </TdtButton>
                <div className="subtitle2 mb-8">{'予約時メッセージ'}</div>
                <div className="body2 mb-32 white-space-pre-line">{consultation.message}</div>
                <div className="border-bottom-separator mb-32" />
                <div className="subtitle1 mb-16 d-flex">
                    {'カルテ'}
                    {!isEditMedicalRecord && (
                        <TdtButton
                            buttonStyle="plain"
                            icon={<EditIcon />}
                            className="ml-4"
                            onClick={() => setIsEditMedicalRecord(true)}
                        />
                    )}
                </div>
                <div className="body2 feed-back-content mb-24">
                    {isEditMedicalRecord ? (
                        <>
                            <TdtTextAreaEditable
                                value={feedback}
                                setValue={setFeedback}
                                onSave={value => {
                                    submitFeedback(value).then(() => setIsEditMedicalRecord(false));
                                }}
                            />
                        </>
                    ) : (
                        <>{feedback}</>
                    )}
                </div>

                <div className="subtitle1 mb-8">{'フィードバック'}</div>
                <div className="mb-8">
                    {consultation.message_threads_count ? (
                        <span>
                            {'送信しました。'}
                            <span className="ml-8 caption text-gray-color">{`（${dateToStr(
                                consultation.message_threads[consultation.message_threads.length - 1].created_at,
                                'YYYY MM/DD HH:mm'
                            )} ${
                                consultation.message_threads[consultation.message_threads.length - 1].counselor
                                    .full_name
                            }）`}</span>
                        </span>
                    ) : (
                        <span className="caption text-gray-color">
                            {'相談終了後、相談者にフィードバックしてください'}
                        </span>
                    )}
                </div>
                <TdtButton
                    buttonSize="big"
                    buttonStyle="outline"
                    className="fw-b mb-24"
                    style={{ minWidth: 176 }}
                    onClick={() =>
                        history.push(
                            `${baseUrl}${ConsultationRoutes.CREATE_FEEDBACK.path.replace(
                                ':reservationId',
                                consultation.id
                            )}`
                        )
                    }
                >
                    {'メッセージの作成'}
                </TdtButton>

                <div className="subtitle1 mb-8">{'相談の中止'}</div>
            </>
        );
    };

    const renderForAdmin = () => {
        return (
            <>
                <div className="subtitle1 mb-8 mt-24">{'カウンセラー'}</div>
                <div className="h6 mb-8">{`${counselor.full_name}`}</div>
                <div className="border-bottom-separator mt-24" />

                <div className={`subtitle1 ${isAdminStaff ? 'mb-8' : 'mb-24'} mt-24`}>{'相談者からの情報'}</div>
                {isAdminStaff ? (
                    <TdtButtonRestrict className="mb-24" />
                ) : (
                    <TdtButton
                        buttonSize="big"
                        buttonStyle="outline"
                        className="fw-b mb-24"
                        style={{ minWidth: 144 }}
                        onClick={() =>
                            history.push(
                                `${baseUrl}${ConsultationRoutes.DISPLAY_INTERVIEW_SHEET.path.replace(
                                    ':reservationId',
                                    consultation.id
                                )}`
                            )
                        }
                    >
                        {'質問票の表示'}
                    </TdtButton>
                )}

                <div className="subtitle2 mb-8">{'予約時メッセージ'}</div>
                <div className="body2 mb-32 white-space-pre-line">
                    {isAdminStaff ? <TdtButtonRestrict /> : <>{consultation.message}</>}
                </div>
                <div className="border-bottom-separator mb-32" />
                <div className="subtitle1 mb-16 d-flex">
                    {'カルテ'}
                    {!isAdminStaff && !isEditMedicalRecord && (
                        <TdtButton
                            buttonStyle="plain"
                            icon={<EditIcon />}
                            className="ml-4"
                            onClick={() => setIsEditMedicalRecord(true)}
                        />
                    )}
                </div>
                <div className="body2 feed-back-content mb-24">
                    {isEditMedicalRecord ? (
                        <>
                            <TdtTextAreaEditable
                                value={feedback}
                                setValue={setFeedback}
                                onSave={value => {
                                    submitFeedback(value).then(() => setIsEditMedicalRecord(false));
                                }}
                            />
                        </>
                    ) : (
                        <>{isAdminStaff ? <TdtButtonRestrict /> : <>{feedback}</>}</>
                    )}
                </div>

                <div className="subtitle1 mb-8">{'フィードバック'}</div>
                <div className="mb-8">
                    {consultation.message_threads_count ? (
                        <span>
                            {'送信しました。'}
                            <span className="ml-8 caption text-gray-color">{`（${dateToStr(
                                consultation.message_threads[consultation.message_threads.length - 1].created_at,
                                'YYYY MM/DD HH:mm'
                            )} ${
                                consultation.message_threads[consultation.message_threads.length - 1].counselor
                                    .full_name
                            }）`}</span>
                        </span>
                    ) : (
                        <span className="caption text-gray-color">
                            {'相談終了後、相談者にフィードバックしてください'}
                        </span>
                    )}
                </div>
                {isAdminStaff ? (
                    <div className="body2 mb-24">{!consultation.message_threads_count ? t('Not created') : ''}</div>
                ) : (
                    <TdtButton
                        buttonSize="big"
                        buttonStyle="outline"
                        className="fw-b mb-24"
                        style={{ minWidth: 176 }}
                        onClick={() =>
                            history.push(
                                `${baseUrl}${ConsultationRoutes.CREATE_FEEDBACK.path.replace(
                                    ':reservationId',
                                    consultation.id
                                )}`
                            )
                        }
                    >
                        {'メッセージの作成'}
                    </TdtButton>
                )}

                <div className="subtitle1 mb-8">{'相談の中止'}</div>
            </>
        );
    };

    const isAdminLayout = isAdmin || isAdminStaff;

    let duration = null;
    if (consultation.session_end_time) {
        duration = moment(consultation.session_end_time, 'HH:mm').diff(
            moment(consultation.start_time, 'HH:mm'),
            'minutes'
        );
    }

    const superAdminCanDeleteNotCompleteReservation = () => {
        return !isAdmin || (isAdmin && consultation.status === ReservationStatus.COMPLETED.value);
    }

    return (
        <>
            <div className="consultation-detail p-24 pt-40">
                <div className={`consultation-detail-header ${isAdminLayout && 'mb-40'}`}>
                    <div className="mb-8">{`${dateToStr(consultation.date, 'YYYY年M月DD日(ddd)')} ${
                        consultation.start_time
                    }〜${consultation.session_end_time}(${duration}分)`}</div>
                    <div className="mb-12">
                        {isAdminLayout ? consultation.program.name_mngt : consultation.program.name}
                    </div>
                </div>

                {isAdminLayout ? renderForAdmin() : renderNormal()}

                <div className="caption medium-color text-gray-color mb-8">
                    {'当日に何らかの事情により相談が実施できなかった場合は中止してください'}
                </div>
                {isCancelled ? (
                    <>
                        <div className="mt-16">
                            {'相談を中止しました。'}
                            <span className="caption text-placeholder-color">
                                {`（${dateToStr(consultation.cancellation_date, 'YYYY MM/DD HH:mm')} ${
                                    cancelBy ? `${cancelBy.full_name}` : ''
                                }）`}
                            </span>
                        </div>
                        <TdtButton
                            buttonSize="big"
                            buttonStyle="plain"
                            className="fw-b primary-color mb-24 pl-0 pr-0"
                            onClick={() => showResumeModal(consultation.id)}
                        >
                            {'相談の再開'}
                        </TdtButton>
                    </>
                ) : (
                    <>
                        <TdtButton
                            disabled={isDisabledFromEnd}
                            buttonSize="big"
                            buttonStyle="plain"
                            className="fw-b pink-color mb-24 pl-0 pr-0 disabled-link-plain"
                            onClick={() => showCancelModal(consultation.id)}
                        >
                            {'相談の中止'}
                        </TdtButton>
                    </>
                )}
            </div>

            <DeleteReservationModal
                onClose={() => closeModal()}
                onUpdate={() => deleteReservation()}
                visible={modalVisible.delete}
            />

            <CancelReservationModal
                onClose={() => closeModal()}
                onUpdate={() => cancelReservation()}
                visible={modalVisible.cancel}
            />

            <ResumeReservationModal
                onClose={() => closeModal()}
                onUpdate={() => resumeReservation()}
                visible={modalVisible.resume}
            />

            <FloatBar className="counselor-floatbar left-2side">
                {!isAdminLayout && (
                    <Button
                        type="primary"
                        icon={<VcIcon />}
                        className="fw-b"
                        disabled={isCompleted || isEditMedicalRecord || isDisabledFromEnd}
                        onClick={() => zoomClickHandle()}
                        href={'' + (consultation.zoom || {}).start_url}
                        target="_blank"
                    >
                        {'相談の開始（Zoom）'}
                    </Button>
                )}

                <div className={`flex-1 ${isAdminLayout && 'text-align-right'}`}>
                    <TdtButton
                        buttonSize="big"
                        buttonStyle="outline-secondary"
                        icon={<DeleteIcon />}
                        className="fw-b mr-16"
                        onClick={() => showDeleteModal(consultation.id)}
                        disabled={(isCompleted || isEditMedicalRecord || isDisabledFromEnd) && superAdminCanDeleteNotCompleteReservation()}
                    >
                        {'予約削除'}
                    </TdtButton>
                    <TdtButton
                        buttonSize="big"
                        buttonStyle="outline"
                        icon={<EditIcon />}
                        className="fw-b"
                        onClick={() => {
                            history.push(
                                Routes.private.COUNSELOR_DETAIL_UPDATE_RESERVATION.path
                                    .replace(':id', id)
                                    .replace(':reservationId', consultation.id)
                            );
                        }}
                        disabled={isCompleted || isEditMedicalRecord || isDisabledFromEnd}
                    >
                        {'予約編集'}
                    </TdtButton>
                </div>
            </FloatBar>
        </>
    );
};

export default connect(mapState, mapDispatch)(ConsultationDetail);

ConsultationDetail.propTypes = {
    consultation: PropTypes.any,
    url: PropTypes.string,
    getConsultations: PropTypes.func,
    firstId: PropTypes.any,
};
