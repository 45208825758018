import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

const TabList = ({ tabs = [], className = '', onChange, indent, ...rest }) => {
    return (
        <Tabs className={`tab-list ${className} ${indent ? 'tab-list-indent' : ''}`} onChange={onChange} {...rest}>
            {tabs.map((tab, index) => (
                <TabPane tab={tab.title} key={tab.key || index} forceRender={tab.forceRender} disabled={tab.disabled}>
                    {tab.container}
                </TabPane>
            ))}
        </Tabs>
    );
};

export default TabList;

TabList.propTypes = {
    tabs: PropTypes.array,
    className: PropTypes.string,
    onChange: PropTypes.func,
    indent: PropTypes.bool,
};
