import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Input } from 'antd';
import MessageThreadRow from 'pages/client/message/MessageThreadRow';
import FloatBar from 'components/FloatBar';
import TdtVerticalResizable from 'components/TdtVerticalResizable';
import { setLoading, getMessageThread, createMessage } from 'pages/client/message/message_detail.slice';
import { SendIcon } from 'components/SvgIcon';
import BackBtnWithTitle from 'components/BackBtnWithTitle';
import TdtButtonSubmit from 'components/TdtButtonSubmit';
import Loader from 'components/Loader';
import { useTranslation } from 'react-i18next';
import { scrollRef } from 'helpers/common.helper';
import { decreaseCountMessage } from 'pages/auth/auth.slice';
import EmailType from 'enums/email_type.enum';

const mapState = state => ({
    messageThread: state.client.messageDetail.messageThread,
    hasNewMessage: state.auth.event.hasNewMessage,
    loading: state.client.messageDetail.loading,
    currentUser: state.auth.user,
});
const mapDispatch = { setLoading, getMessageThread, createMessage, decreaseCountMessage };

const MessageDetail = ({
    messageThread,
    loading,
    getMessageThread,
    createMessage,
    hasNewMessage,
    decreaseCountMessage,
    setLoading,
    currentUser,
}) => {
    const { id } = useParams();
    const { t } = useTranslation();

    const [heightMessage, setHeightMessage] = useState(650);

    const ref = useRef(null);

    useEffect(() => {
        setLoading(null);
        getMessageThread(id);
    }, [id]);

    useEffect(() => {
        getMessageThread(id);
        if (hasNewMessage[id] !== undefined) {
            decreaseCountMessage();
        }
    }, [hasNewMessage[id]]);

    // useEffect(() => {
    //     if (ref.current != null) {
    //         scrollRef(ref);
    //     }
    // }, [id, loading]);

    const [value, setValue] = useState('');

    const submitMessage = async () => {
        if (!value || value.length < 1) {
            return false;
        }
        await createMessage(id, value, t('message:I sent a message'));
        setValue('');
        scrollRef(ref);
    };

    const changeHeightChatBox = () => {
        // let bodyHeight = document.body.offsetHeight;
        // let backButtonHeight = document.querySelector('.back-button')?.offsetHeight || 0;
        // let chatBoxHeight = document.querySelector('.message-thread-float-bar')?.offsetHeight || 0;
        // setHeightMessage(bodyHeight - backButtonHeight - chatBoxHeight - 20);
    };

    if (loading == null) {
        return <Loader className="loader-center" />;
    }

    const isEmailType = [
        'session',
        'program',
        EmailType.HTML_EMAIL.value,
        EmailType.NEW_PROGRAM.value,
        EmailType.NEW_SESSION.value,
    ].includes(messageThread.type);

    const isBulkType = messageThread.company_id;
    const bulkMessageAsNormal = () => {
        return [{ ...messageThread, user: messageThread.counselor }];
    };
    return (
        <div className="fixed-title-msg">
            <BackBtnWithTitle title={messageThread.title} />

            <div className={`client-message-threads mt-4 ${isEmailType ? 'thread-as-email-type' : ''}`} ref={ref}>
                {(isBulkType ? bulkMessageAsNormal() : messageThread.messages).map(m => (
                    <MessageThreadRow key={m.id} message={m} messageThread={messageThread} currentUser={currentUser} />
                ))}
            </div>

            {isBulkType || isEmailType ? (
                <></>
            ) : messageThread.close_flag ? (
                <FloatBar className="message-thread-end">
                    <div>{'やりとりが終了しています'}</div>
                </FloatBar>
            ) : !messageThread.allow_reply_flag ? (
                <FloatBar className="message-thread-end">
                    <div>{'このメッセージには返信できません'}</div>
                </FloatBar>
            ) : (
                <FloatBar className="message-thread-float-bar pb-42">
                    <TdtVerticalResizable onChangeHeight={changeHeightChatBox} height={74}>
                        <Input.TextArea
                            className={'flex-1 input-message '}
                            // maxLength={400}
                            // showCount={{ formatter: ({ count, maxLength = 400 }) => `${count}/${maxLength}` }}
                            placeholder={'400文字まで入力できます'}
                            value={value}
                            onChange={v => setValue(v.target.value)}
                        />

                        <TdtButtonSubmit
                            disabled={value && value.length > 400}
                            className="fw-b ml-16 btn-message-detail csl-send-btn"
                            type="primary"
                            onClick={() => submitMessage()}
                        >
                            <span className="btn-message-detail-name">{'送信'}</span>
                            <span className="btn-message-detail-icon">
                                <SendIcon />
                            </span>
                        </TdtButtonSubmit>

                        <span className="text-area-show-count w-100p">
                            <span className={`${value && value.length > 400 ? 'pink-color' : ''}`}>
                                {value ? value.length : 0}
                            </span>
                            <span>{'/400'}</span>
                        </span>
                    </TdtVerticalResizable>
                </FloatBar>
            )}
        </div>
    );
};

export default connect(mapState, mapDispatch)(MessageDetail);

MessageDetail.propTypes = {
    messageThread: PropTypes.any,
    getMessageThread: PropTypes.func.isRequired,
    createMessage: PropTypes.func.isRequired,
    currentUser: PropTypes.any,
};
