import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Col, Row, Select } from 'antd';
import TdtSelect from 'components/TdtSelect';
import DataTable from 'components/DataTable';
import {
    getData,
    getMetaData,
    setFilters,
    setPagination,
} from 'pages/counselor/consultation-reservation/reservation.slice';
import { dateToStr } from 'helpers/date.helper';
import InputSearch from 'components/InputSearch';
import TabList from 'components/TabList';
import ReservationStatus from 'enums/reservation_status.enum';
import Routes from 'routes';
import history from 'helpers/history.helper';
import SelectPagination from 'components/SelectPagination';
import { setBackUrl } from 'core.slice';
import { emailDeletedShow } from 'helpers/string.helper';

const mapState = state => ({ state: state.counselor.consultationReservation });
const mapDispatch = { getData, getMetaData, setFilters, setPagination, setBackUrl };

const Reservation = ({ state, getData, getMetaData, setFilters, setPagination, setBackUrl }) => {
    const { t } = useTranslation();

    const columns = [
        {
            title: '予約日時',
            dataIndex: 'date',
            key: 'date',
            width: 160,
            render: (value, record) =>
                `${dateToStr(value, 'M月DD日(ddd)')} ${record.start_time}-${record.session_end_time}`,
            sorter: true,
        },
        {
            title: '氏名',
            dataIndex: 'client|first_name',
            width: 302,
            render: (_, record) => {
                const client = record.client || {};
                return (
                    <>
                        <div className="fw-b">{`${client.full_name}`}</div>
                        <span className="text-small">{emailDeletedShow(client.email)}</span>
                    </>
                );
            },
            sorter: true,
        },
        {
            title: 'カウンセラー',
            dataIndex: 'counselor|first_name',
            width: 158,
            render: (_, record) => {
                const counselor = record.counselor || {};
                return `${counselor.full_name}`;
            },
            sorter: true,
        },
        {
            title: t('Program name'),
            dataIndex: 'program_name',
            width: 158,
            render: (_, record) => (
                <>
                    <span className={`eclipse-status eclipse-status-${record.program.color} mr-10`} />
                    {record.program.name}
                </>
            ),
        },
    ];

    const getReservations = (pagination = state.pagination, filters = state.filters, sorter = state.sorter) => {
        getData({ pagination, filters, sorter });
    };
    const handleRowClick = row => {
        if (row.client.deleted_by_id) return;
        setBackUrl(Routes.private.COUNSELOR_CONSULTATION_RESERVATION.path);
        if (row) {
            history.push(
                Routes.private.COUNSELOR_DETAIL_CONSULTATION.path.replace(':id', row.client_id) + '/' + row.id
            );
        }
    };

    // tab list
    const tabList = [
        {
            key: 'all',
            title: 'すべて',
            container: <></>,
        },
        {
            key: ReservationStatus.CREATED.value,
            title: '予約',
            container: <></>,
        },
        {
            key: ReservationStatus.COMPLETED.value,
            title: '終了分',
            container: <></>,
        },
    ];
    const [tab, setTab] = useState(state.filters.status);
    const handlerTabChange = key => {
        setTab(key);

        // set filters
        const newFilters = { ...state.filters, status: key };
        const newPagination = { ...state.pagination, current: 1 };
        setFilters(newFilters);
        setPagination(newPagination);
        getReservations(newPagination, newFilters, state.sorter);
    };

    useEffect(() => {
        getMetaData();
        getReservations();
    }, []);

    const metaData = state.metaData;

    const handleFilterByCompany = key => {
        let programId = state.filters.program_id;
        if (programId && key) {
            const program = metaData.programs.find(p => p.id === programId);
            if (program && !program.companyIds.includes(key)) {
                programId = null;
            }
        }
        const newFilters = { ...state.filters, company_id: key, program_id: programId };
        const newPagination = { ...state.pagination, current: 1 };
        setFilters(newFilters);
        setPagination(newPagination);
        getReservations(newPagination, newFilters, state.sorter);
    };

    const handleFilterByProgram = key => {
        const newFilters = { ...state.filters, program_id: key };
        const newPagination = { ...state.pagination, current: 1 };
        setFilters(newFilters);
        setPagination(newPagination);
        getReservations(newPagination, newFilters, state.sorter);
    };

    const handlerFilterByKeyword = value => {
        const newFilters = { ...state.filters, keyword: value };
        const newPagination = { ...state.pagination, current: 1 };
        setFilters(newFilters);
        setPagination(newPagination);
        getReservations(newPagination, newFilters, state.sorter);
    };

    return (
        <div className="self-wrapper d-block">
            <h1 className="page-title mb-24">{t('Consultation reservation')}</h1>

            <TabList
                tabs={tabList}
                onChange={handlerTabChange}
                activeKey={tab}
                animated={false}
                indent
                className="mb-16"
            />

            <Row gutter={16}>
                <Col span={24} className="d-flex">
                    <InputSearch
                        value={state.filters.keyword}
                        onChange={e => setFilters({ ...state.filters, keyword: e.target.value })}
                        style={{ maxWidth: 240 }}
                        onSearch={handlerFilterByKeyword}
                        placeholder={'氏名・プログラムで検索'}
                    />
                    <TdtSelect
                        value={state.filters.company_id}
                        size="large"
                        className="fs-14 mr-8 ml-8"
                        onChange={handleFilterByCompany}
                    >
                        <Select.Option value={null}>{'全ての企業'}</Select.Option>
                        {Object.values(metaData.companies).map(c => (
                            <Select.Option key={c.id} value={c.id}>
                                {c.name}
                            </Select.Option>
                        ))}
                    </TdtSelect>
                    <TdtSelect
                        value={state.filters.program_id}
                        size="large"
                        className="fs-14 mr-8"
                        onChange={handleFilterByProgram}
                    >
                        <Select.Option value={null}>{'全てのプログラム'}</Select.Option>
                        {metaData.programs
                            .filter(p => {
                                if (state.filters.company_id) {
                                    return p.companyIds.includes(state.filters.company_id);
                                }
                                return true;
                            })
                            .map(p => (
                                <Select.Option key={p.id} value={p.id}>
                                    {p.name}
                                </Select.Option>
                            ))}
                    </TdtSelect>

                    <SelectPagination
                        onChange={pagination => getReservations(pagination)}
                        pageSize={state.pagination.pageSize}
                    />
                </Col>
            </Row>
            <DataTable
                className="collapse-table mt-16"
                columns={columns}
                hideColumns={state.hideColumns}
                data={state.data}
                loading={state.loading}
                pagination={state.pagination}
                filters={state.filters}
                sorter={state.sorter}
                onTableChange={(pagination, filter, sorter) => {
                    getReservations(pagination, state.filters, sorter);
                }}
                noDataText={'データがありません'}
                onRow={record => ({
                    onClick: () => {
                        handleRowClick(record);
                    },
                })}
            />
        </div>
    );
};

export default connect(mapState, mapDispatch)(Reservation);

Reservation.propTypes = {
    state: PropTypes.any,
    getData: PropTypes.func.isRequired,
    getMetaData: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
    setPagination: PropTypes.func.isRequired,
};
