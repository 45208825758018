import { createSlice } from '@reduxjs/toolkit';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';

const name = 'counselorQuestionnaireDetail';

const questionnaireDetailSlice = createSlice({
    name,
    initialState: {
        questionnaire: null,
        loading: true,
    },
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setQuestionnaire(state, action) {
            state.questionnaire = action.payload;
            state.loading = false;
        },
    },
});

export const { setLoading, setQuestionnaire } = questionnaireDetailSlice.actions;

export const getQuestionnaire = (id, questionnaireId) => async dispatch => {
    dispatch(setLoading(true));
    const data = await api.get(
        APP_URLS.COUNSELOR_QUESTIONNAIRE_DETAIL.replace(':id', id).replace(':questionnaireId', questionnaireId)
    );
    if (data) {
        dispatch(setQuestionnaire(data));
        return data;
    }
};

export default questionnaireDetailSlice.reducer;
