import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Menu } from 'antd';
import { AddIcon } from 'components/SvgIcon';
import PageElementTypeEnum from 'enums/page_element_type.enum';
import HeadingElement from 'pages/admin/common/page-elements/types/HeadingElement';
import { randomId } from 'helpers/common.helper';
import { addPageElement, reset, setPageElement, setRefs } from 'pages/admin/common/page-elements/page_elements.slice';
import TextElement from 'pages/admin/common/page-elements/types/TextElement';
import ImageElement from 'pages/admin/common/page-elements/types/ImageElement';
import PersonProfileElement from 'pages/admin/common/page-elements/types/PersonProfileElement';
import HTMLCodeElement from 'pages/admin/common/page-elements/types/HTMLCodeElement';
import ButtonElement from 'pages/admin/common/page-elements/types/ButtonElement';

const PageElementsMemo = React.memo(
    ({ byId, order, addPageElement, reset, setPageElement, operationTargetId, setRefs, refs, hasExtends = true }) => {
        const { t } = useTranslation();

        const menu = (
            <Menu
                onClick={opt => {
                    addPageElement({
                        id: randomId(),
                        pageElement: {
                            type: PageElementTypeEnum[opt.key].value,
                            is_displayed: 1,
                        },
                    });
                }}
            >
                {Object.keys(PageElementTypeEnum).filter(type => hasExtends || !PageElementTypeEnum[type].is_extend).map(type => {
                    return <Menu.Item key={type}>{t(`enum:${PageElementTypeEnum[type].text}`)}</Menu.Item>;
                })}
            </Menu>
        );

        const targetToDOM = target => {
            if (target.input) {
                return target.input;
            } else if (target.editingArea) {
                return target.editingArea;
            } else if (target.resizableTextArea) {
                return target.resizableTextArea.textArea;
            }
        };

        useEffect(() => {
            return () => {
                reset();
            };
        }, []);

        useEffect(() => {
            if (operationTargetId && refs[operationTargetId]) {
                // scroll + focus
                const target = refs[operationTargetId];
                const el = targetToDOM(target);
                if (el) {
                    setTimeout(() => {
                        const elementRect = el.getBoundingClientRect();
                        const absoluteElementTop = elementRect.top + window.pageYOffset;
                        const middle = absoluteElementTop - window.innerHeight / 2;
                        //window.scrollTo(0, middle');
                        window.scrollTo({ top: middle, left: 0, behavior: 'smooth' });
                        target.focus();
                    });
                }
            }
        }, [order, Object.keys(refs).length]);

        return (
            <>
                {order.length > 0 && (
                    <div className="mb-24">
                        {order.map(id => {
                            let Component = null;
                            switch (byId[id].type) {
                                case PageElementTypeEnum.HEADING.value:
                                    Component = HeadingElement;
                                    break;
                                case PageElementTypeEnum.TEXT.value:
                                    Component = TextElement;
                                    break;
                                case PageElementTypeEnum.IMAGE.value:
                                    Component = ImageElement;
                                    break;
                                case PageElementTypeEnum.PERSON_PROFILE.value:
                                    Component = PersonProfileElement;
                                    break;
                                case PageElementTypeEnum.HTML_CODE.value:
                                    Component = HTMLCodeElement;
                                    break;
                                case PageElementTypeEnum.BUTTON.value:
                                    Component = ButtonElement;
                                    break;
                            }

                            if (Component) {
                                return (
                                    <Component
                                        key={id}
                                        id={id}
                                        pageElement={byId[id]}
                                        setPageElement={setPageElement}
                                        setRefs={setRefs}
                                    />
                                );
                            }

                            return <div key={id} className="page-element-block" />;
                        })}
                    </div>
                )}
                <Dropdown overlay={menu} trigger={['click']}>
                    <Button icon={<AddIcon />} className="fw-b pl-20 pr-24">
                        {t('Add page element')}
                    </Button>
                </Dropdown>
            </>
        );
    }
);

const mapState = state => ({
    byId: state.admin.pageElements.elements.byId,
    order: state.admin.pageElements.elements.order,
    operationTargetId: state.admin.pageElements.operationTargetId,
    refs: state.admin.pageElements.refs,
});

const mapDispatch = { addPageElement, reset, setPageElement, setRefs };

const PageElements = React.memo(({ byId, order, addPageElement, reset, setPageElement, operationTargetId, setRefs, refs, hasExtends }) => {
    return (
        <PageElementsMemo
            byId={byId}
            order={order}
            addPageElement={addPageElement}
            reset={reset}
            setPageElement={setPageElement}
            operationTargetId={operationTargetId}
            setRefs={setRefs}
            refs={refs}
            hasExtends={hasExtends}
        />
    );
});

export default connect(mapState, mapDispatch)(PageElements);

PageElements.propTypes = {
    byId: PropTypes.object,
    order: PropTypes.array,
    addPageElement: PropTypes.func,
    reset: PropTypes.func,
    setPageElement: PropTypes.func,
    operationTargetId: PropTypes.any,
    setRefs: PropTypes.func,
    refs: PropTypes.any,
    hasExtends: PropTypes.bool,
};

PageElementsMemo.propTypes = {
    byId: PropTypes.object,
    order: PropTypes.array,
    addPageElement: PropTypes.func,
    reset: PropTypes.func,
    setPageElement: PropTypes.func,
    operationTargetId: PropTypes.any,
    setRefs: PropTypes.func,
    refs: PropTypes.any,
    hasExtends: PropTypes.bool,
};
