import React, { useState } from 'react';
import PropTypes from 'prop-types';
import QuestionTypeEnum from 'enums/question_type.enum';
import { useTranslation } from 'react-i18next';
import { Checkbox, Input } from 'antd';
import QuestionCommon from 'components/questions/QuestionCommon';

const MultipleSelectQuestion = ({ info, optionsOrder, options, ...rest }) => {
    const { t } = useTranslation();

    // const other = { id: rest.id, value: rest.value, onChange: rest.onChange };
    const other = { id: rest.id };

    const customValue = {
        ids: rest.value ? rest.value.ids : [],
        others: rest.value ? rest.value.others : {},
    };

    const [others, setOthers] = useState(customValue.others);
    const handleOrderInput = (optId, value) => {
        setOthers({ ...others, [optId]: value });
        rest.onChange && rest.onChange({ ids: customValue.ids, others: { ...customValue.others, [optId]: value } });
    };

    const checkSelectedLimit = targetId => customValue.ids.length >= 5 && !customValue.ids.includes(targetId);

    return (
        <div className="question multiple-select">
            <QuestionCommon
                title={info.value}
                addition={info.addition}
                type={t(`enum:${QuestionTypeEnum.MULTIPLE_SELECTION.text}`)}
                isRequired={info.isRequired || info.is_required}
            />
            <div className="vertical-group mt-16">
                <Checkbox.Group
                    {...other}
                    value={customValue.ids}
                    onChange={v => {
                        let newOthers = {};
                        if (others) {
                            Object.keys(others).map(k => {
                                if (v.indexOf(Number(k)) > -1) {
                                    newOthers[k] = others[k];
                                }
                            });
                        }
                        setOthers(newOthers);
                        rest.onChange && rest.onChange({ ids: v, others: newOthers });
                    }}
                >
                    {optionsOrder != null &&
                        optionsOrder.map(optId => {
                            return (
                                <React.Fragment key={optId}>
                                    <Checkbox className="question-checkbox-inner " value={optId}>
                                        {options[optId].value}
                                    </Checkbox>
                                    {options[optId].type === 'other' && customValue.ids.includes(optId) && (
                                        <Input
                                            value={others[optId] || ''}
                                            style={{ minWidth: 480 }}
                                            onChange={evt => handleOrderInput(optId, evt.target.value)}
                                            className="mb-16"
                                        />
                                    )}
                                </React.Fragment>
                            );
                        })}
                </Checkbox.Group>
            </div>
        </div>
    );
};

export default MultipleSelectQuestion;

MultipleSelectQuestion.propTypes = {
    info: PropTypes.shape({
        value: PropTypes.string,
        isRequired: PropTypes.bool,
    }),
    optionsOrder: PropTypes.array,
    options: PropTypes.object,
};
