import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { UserPropTypes } from 'constants/proptypes.constant';
import BackBtn from 'components/BackBtn';
import { showNormal } from 'helpers/notification.helper';
import Loader from 'components/Loader';
import { Button, Checkbox, Col, Form, Row, Select } from 'antd';
import TdtSelect from 'components/TdtSelect';
import FloatBar from 'components/FloatBar';
import { getCounselor, setFilter, updateEmail, updateRole } from 'pages/admin/counselor/counselor_detail.slice';
import {
    getAllCompanies,
    getAllProgramsByCompany,
} from 'pages/admin/counselor/company_program/counselor_company_program.slice';
import { InfoIcon } from 'components/SvgIcon';
import ProgramBookingOption from 'pages/admin/common/program/ProgramBookingOption';
import ReservationFrameList from 'pages/admin/counselor/company_program/ReservationFrameList';
import { resetToDefault } from 'pages/admin/counselor/company_program/reservable_frame_programs.slice';
import CantCreateModal from 'pages/admin/counselor/company_program/CantCreateModal';
import api from 'helpers/api.helper';
import history from 'helpers/history.helper';
import { APP_URLS } from 'constants/url.constant';
import { findMinDurationOfRanges } from 'helpers/common.helper';
import { sessionTimeToPoint } from 'helpers/session_time_calculation.helper';

const mapState = state => ({
    counselor: state.admin.counselorDetail.counselor,
    counselorCompanyProgram: state.admin.counselorCompanyProgram,
    detailsOrder: state.admin.reservableFramePrograms.detailsOrder,
    details: state.admin.reservableFramePrograms.details,
});
const mapDispatch = {
    getAllCompanies,
    getAllProgramsByCompany,
    getCounselor,
    updateRole,
    updateEmail,
    setFilter,
    resetToDefault,
};
const CounselorAddCompanyProgram = ({
    counselor,
    getCounselor,
    getAllCompanies,
    getAllProgramsByCompany,
    counselorCompanyProgram,
    resetToDefault,
    detailsOrder,
    details,
}) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const [form] = Form.useForm();
    const [companySelectedValue, setCompanySelectedValue] = useState(-1);
    const { allCompanies, companyProgramsLoading, companyPrograms } = counselorCompanyProgram;
    const [checkedProgramIds, setCheckedProgramIds] = useState([]);
    const [isValid, setIsValid] = useState(false);
    const [modalVisible, setModalVisible] = useState({
        cantCreate: false,
        warningPrograms: [],
    });
    const showCantCreateModal = data => setModalVisible({ cantCreate: true, warningPrograms: data });
    const closeModal = () => setModalVisible({ cantCreate: false, warningPrograms: [] });
    const [isLoading, setIsLoading] = useState(false);

    const handleCompanySelected = companyId => {
        setCompanySelectedValue(companyId);
        if (companyId > 0) {
            getAllProgramsByCompany(companyId, counselor.id);
        }
        setCheckedProgramIds([]);
    };

    useEffect(() => {
        counselor = null;
        getCounselor(id);
        getAllCompanies();

        return () => {
            setIsLoading(false);
            resetToDefault();
        };
    }, [id]);

    useEffect(() => {
        if (counselor && counselor.is_disabled) {
            showNormal('', t('Has been deleted'), 3);
        }
    }, [counselor && counselor.is_disabled]);

    useEffect(() => {
        setIsValid(detailsOrder.length > 0 && checkedProgramIds.length > 0 && !!companySelectedValue);
    }, [detailsOrder, checkedProgramIds, companySelectedValue]);

    if (!counselor) {
        return <Loader className="loader-center" />;
    }

    const programHasBookingOption = program => {
        const { allow_same_day_booking, auto_allocate_counselor } = program;
        return allow_same_day_booking || auto_allocate_counselor;
    };

    let renderCompanyProgram;
    if (companyProgramsLoading) {
        renderCompanyProgram = <Loader className={'mt-16 ml-16'} />;
    } else if (companySelectedValue < 0) {
        renderCompanyProgram = (
            <div className="mt-8 body2">
                <InfoIcon className="mr-8 color-blg-60" size={16} />
                {t('If you select the company in charge, you will be able to select the program in charge')}
            </div>
        );
    } else {
        renderCompanyProgram = (
            <div className="vertical-group baseline-align-checkboxes">
                <Checkbox.Group value={checkedProgramIds} onChange={v => setCheckedProgramIds(v)}>
                    {companyPrograms &&
                        companyPrograms.map(p => {
                            return (
                                <React.Fragment key={p.id}>
                                    <Checkbox value={p.id}>
                                        {p.name_mngt}
                                        {programHasBookingOption(p) ? <span className="pink-color">{'*'}</span> : <></>}
                                    </Checkbox>
                                    {checkedProgramIds.indexOf(p.id) > -1 ? (
                                        <ProgramBookingOption program={p} className="mb-16" />
                                    ) : (
                                        <></>
                                    )}
                                </React.Fragment>
                            );
                        })}
                </Checkbox.Group>
            </div>
        );
    }

    const renderReservableFrames = () => {
        return (
            <div className="mt-8 body2">
                <InfoIcon className="mr-8 color-blg-60" size={16} />
                {t(
                    'Please specify the day and time when the counselor can operate. Within that range, the reservation limit for the program in charge will be determined.'
                )}
            </div>
        );
    };

    const submit = async values => {
        const response = await api.post(
            APP_URLS.ADMIN_COUNSELORS_DETAIL_ADD_COMPANY_PROGRAM.replace(':counselorId', id),
            values
        );
        if (response) {
            history.goBack();
            showNormal('', t('message:Added the company / program in charge'), 3);
        }
    };

    const checkSessionLength = details => {
        const programs = companyPrograms;
        const minSessionLength = findMinDurationOfRanges(details);

        let warningPrograms = [];
        checkedProgramIds.map(id => {
            const program = programs.find(p => p.id === Number(id));
            if (program && sessionTimeToPoint(program.program_companies[0]) > minSessionLength) {
                warningPrograms.push(program);
            }
        });

        return warningPrograms;
    };

    const processOnSubmit = values => {
        values.program_ids = checkedProgramIds;

        // map details (reservable_frames)
        values.details = [];
        detailsOrder.map(orderKey => values.details.push(details[orderKey]));

        const warningPrograms = checkSessionLength(values.details);
        if (warningPrograms.length > 0) {
            showCantCreateModal(warningPrograms);
        } else {
            setIsLoading(true);
            submit(values).then(() => {});
        }
    };

    return (
        <div className="px-48 pb-96">
            <BackBtn label={`${t('Addition of responsible company/program')}`} />
            {/*<FieldInfo*/}
            {/*    className="mt-32 mb-40"*/}
            {/*    label={t('field:カウンセラー')}*/}
            {/*    value={t(counselor.full_name)}*/}
            {/*    showEdit={false}*/}
            {/*/>*/}

            <Form
                form={form}
                layout="vertical"
                initialValues={{}}
                requiredMark={false}
                className="mt-32"
                onFinish={v => processOnSubmit(v)}
            >
                <Row gutter={16}>
                    <Col span={24} className="mb-8">
                        <Form.Item name="company_id" label={t('Company in charge')} validateTrigger={false}>
                            <TdtSelect
                                style={{ width: 320 }}
                                size="large"
                                className="fs-14 select-gray"
                                onChange={handleCompanySelected}
                            >
                                <Select.Option key="-1" value="-1">
                                    {' '}
                                </Select.Option>
                                {allCompanies.map(c => (
                                    <Select.Option key={c.id} value={c.id}>{`${c.name}`}</Select.Option>
                                ))}
                            </TdtSelect>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24} className="mb-8">
                        <Form.Item label={t('Program in charge')}>{renderCompanyProgram}</Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24} className="mb-8">
                        <Form.Item label={t('Reservable frame')} validateTrigger={false} className="mb-16">
                            {renderReservableFrames()}
                        </Form.Item>
                        <div className="mt-8">
                            <ReservationFrameList sessionTime={60} />
                        </div>
                    </Col>
                </Row>
                <FloatBar>
                    <Button type="primary" htmlType="submit" className="fw-b" disabled={!isValid} loading={isLoading}>
                        {'追加'}
                    </Button>
                </FloatBar>
            </Form>
            <CantCreateModal
                visible={modalVisible.cantCreate}
                warningPrograms={modalVisible.warningPrograms}
                onClose={() => {
                    closeModal();
                    setIsLoading(false);
                }}
            />
        </div>
    );
};

export default connect(mapState, mapDispatch)(CounselorAddCompanyProgram);

CounselorAddCompanyProgram.propTypes = {
    counselor: UserPropTypes,
    counselorCompanyProgram: PropTypes.any,
};
