import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Modal, Select } from 'antd';
import { CloseIcon } from 'components/SvgIcon';
import RandomCompanyCodeEnum from 'enums/random_company_code.emum';
import { showNormal } from 'helpers/notification.helper';
import TdtSelect from 'components/TdtSelect';

const NewIssueModal = ({ visible, onClose, onUpdate }) => {
    const { t } = useTranslation();
    const [value, setValue] = useState(RandomCompanyCodeEnum.SIZE_5.value);

    return (
        <Modal
            title={'企業コードの発行'}
            visible={visible}
            width={400}
            footer={null}
            closeIcon={<CloseIcon />}
            onCancel={() => onClose()}
        >
            <p>{'一度発行した企業コードを再発行すると、前の企業コードが無効になりますのでご注意ください。'}</p>
            <TdtSelect size="large" className="fs-14 mr-8" value={value} onChange={v => setValue(v)}>
                {Object.values(RandomCompanyCodeEnum).map(v => (
                    <Select.Option key={v.value} value={v.value}>
                        {v.text}
                    </Select.Option>
                ))}
            </TdtSelect>

            <span>{'のランダムな英数字'}</span>
            <Button
                type="primary"
                block
                className="mt-16"
                onClick={() => {
                    onUpdate && onUpdate(value);
                    showNormal('', t('message:Issued company code'), 5);
                    onClose();
                }}
            >
                {'発行'}
            </Button>
        </Modal>
    );
};

NewIssueModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

export default NewIssueModal;
