import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Upload } from 'antd';
import { AddIcon, CloseButtonImageIcon } from 'components/SvgIcon';

const AddImageButton = ({ className = '', fileList, setFileList, multiple = false, onChange }) => {
    const { t } = useTranslation();

    const props = {
        onRemove: file => {
            setFileList(fileList.filter(f => f !== file));
        },
        beforeUpload: (file, fileLists) => {
            file.url = URL.createObjectURL(file);
            setFileList([...fileList, ...fileLists]);
            return false;
        },
        //fileList,
    };

    useEffect(() => {
        onChange && onChange(fileList);
    }, [fileList]);

    return (
        <Upload
            {...props}
            accept={['.jpg', '.webp', '.png', '.heic']}
            listType="picture-card"
            fileList={fileList}
            multiple={multiple}
            className={`add-image-button ${className}`}
            showUploadList={{ removeIcon: <CloseButtonImageIcon size={10} /> }}
        >
            {(multiple || fileList.length < 1) && (
                <Button icon={<AddIcon />} className="fw-b pl-20 pr-24">
                    {t('Add photo')}
                </Button>
            )}
        </Upload>
    );
};

export default AddImageButton;
