import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ButtonSizes = ['small', 'medium', 'big'];

const ButtonTypes = {
    BUTTON: 'button',
    LINK: 'link',
};

const ButtonStyles = ['default', 'primary', 'secondary', 'outline', 'outline-secondary', 'dark', 'dashed', 'plain'];

const TdtButton = ({
    children,
    icon,
    suffixIcon,
    className,
    style,
    buttonSize = 'medium',
    buttonType = ButtonTypes.BUTTON,
    buttonStyle = 'primary',
    disabled = false,
    disabled2 = false,
    linkTo = '',
    isSubmitButton = false,
    width = null,
    centered = false,
    ...rest
}) => {
    const TagName = buttonType === ButtonTypes.BUTTON ? 'button' : Link;
    let props = { style };
    if (buttonType === ButtonTypes.BUTTON) {
        if (isSubmitButton) {
            props.type = 'submit';
        } else {
            props.type = 'button';
        }
    } else if (buttonType === ButtonTypes.LINK) {
        props.to = linkTo;
    }
    let buttonClass = 'tdt-button ';
    if (disabled || disabled2) {
        props.disabled = true;
        buttonClass += disabled2 ? 'tdt-button-disabled-2 ' : 'tdt-button-disabled ';
    } else {
        if (ButtonStyles.indexOf(buttonStyle) > -1) {
            buttonClass += `tdt-button-${buttonStyle} `;
        }
    }
    if (ButtonSizes.indexOf(buttonSize) > -1) {
        buttonClass += `tdt-button-${buttonSize} `;
    }
    if (centered) {
        buttonClass += 'tdt-button-center ';
    }
    if (className) {
        buttonClass += className;
    }
    if (width) {
        props.style = Object.assign({}, props.style, { width });
    }

    let childrenClassName = '';
    if (icon) {
        childrenClassName += 'ml-4 ';
    }
    if (suffixIcon) {
        childrenClassName += 'mr-4 ';
    }

    return (
        <TagName {...rest} className={buttonClass} {...props}>
            {icon}
            <div className={childrenClassName}>{children}</div>
            {suffixIcon}
        </TagName>
    );
};

export default TdtButton;

TdtButton.propTypes = {
    buttonSize: PropTypes.oneOf(ButtonSizes),
    buttonStyle: PropTypes.oneOf(ButtonStyles),
    buttonType: PropTypes.oneOf(Object.values(ButtonTypes)),
    children: PropTypes.any,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    disabled2: PropTypes.bool,
    icon: PropTypes.any,
    isSubmitButton: PropTypes.bool,
    linkTo: PropTypes.string,
    style: PropTypes.object,
    suffixIcon: PropTypes.any,
    width: PropTypes.any,
    centered: PropTypes.bool,
};
