import Reservation from 'pages/client/reservation/Reservation';
import HealthData from 'pages/client/health_data/HealthData';
import Message from 'pages/client/message/Message';
import ClientInterviewSheet from 'pages/client/interview_sheet/InterviewSheet';
import ClientInterviewSheetHistory from 'pages/client/interview_sheet/InterviewSheetHistory';
import ClientInterviewSheetDetail from 'pages/client/interview_sheet/InterviewSheetDetail';
import ClientInterviewSheetDisplay from 'pages/client/interview_sheet/InterviewSheetDisplay';
import Calendar from 'pages/admin/calendar/Calendar';
import AdminCalendarCreateReservation from 'pages/admin/calendar/ReservationCreate';
import CommonSession from './pages/admin/feature/CommonSession';
import CommonSessionCreate from './pages/admin/feature/CommonSessionCreate';
import CommonSessionUpdate from './pages/admin/feature/CommonSessionUpdate';
import CommonSessionDetail from './pages/admin/feature/CommonSessionDetail';
import Company from 'pages/admin/company/Company';
import CompanyCreate from 'pages/admin/company/CompanyCreate';
import CompanyUpdate from 'pages/admin/company/CompanyUpdate';
import CompanyDetail from 'pages/admin/company/CompanyDetail';
import Counselor from 'pages/admin/counselor/Counselor';
import CounselorCreate from 'pages/admin/counselor/CounselorCreate';
import CounselorDetail from 'pages/admin/counselor/CounselorDetail';
import CounselorAddCompanyProgram from 'pages/admin/counselor/company_program/CounselorAddCompanyProgram';
import CounselorAddIndividualProgramCompany from 'pages/admin/counselor/company_program/CounselorAddIndividualProgramCompany';
import Profile from 'pages/admin/profile/Profile';
import ProfileUpdateName from 'pages/admin/profile/ProfileUpdateName';
import Program from 'pages/admin/program/Program';
import ProgramCreate from 'pages/admin/program/ProgramCreate';
import ProgramUpdate from 'pages/admin/program/ProgramUpdate';
import ProgramDisplayOrderSetting from 'pages/admin/program/ProgramDisplayOrderSetting';
import ProfileUpdatePassword from 'pages/admin/profile/ProfileUpdatePassword';
import AdminInterviewSheet from 'pages/admin/interview_sheet/InterviewSheet';
import AdminInterviewSheetCreate from 'pages/admin/interview_sheet/InterviewSheetCreate';
import AdminInterviewSheetDetail from 'pages/admin/interview_sheet/detail_screen/InterviewSheetDetail';
import AdminInterviewSheetUpdate from 'pages/admin/interview_sheet/InterviewSheetUpdate';
import AdminInterviewSheetPreview from 'pages/admin/interview_sheet/InterviewSheetPreview';
import ResponsePeriodUpdate from 'pages/admin/interview_sheet/detail_screen/tabs/response_period/ResponsePeriodUpdate';
import ResponsePeriodCreate from 'pages/admin/interview_sheet/detail_screen/tabs/response_period/ResponsePeriodCreate';
import MessageDetail from 'pages/client/message/MessageDetail';
import ReservationCreate from 'pages/client/reservation/ReservationCreate';
import ReservationDetail from 'pages/client/reservation/ReservationDetail';
import ReservationUpdate from 'pages/client/reservation/ReservationUpdate';
import Account from 'pages/client/account/Account';
import UpdateAccount from 'pages/client/account/UpdateAccount';
import UpdatePasswordAccount from 'pages/client/account/UpdatePasswordAccount';
import UpdateEmailAccount from 'pages/client/account/UpdateEmailAccount';
import CounselorDashboard from 'pages/counselor/dashboard/Dashboard';
import CounselorCalendar from 'pages/counselor/calendar/Calendar';
import CounselorCalendarCreateReservation from 'pages/counselor/calendar/reservation-create/ReservationCreate';
import CounselorConsultation from 'pages/counselor/calendar/consultation/Consultation';
import CounselorConsultationCreateReservation from 'pages/counselor/calendar/consultation/ReservationCreate';
import CounselorConsultationUpdateReservation from 'pages/counselor/calendar/consultation/ReservationUpdate';
import CounselorHealthData from 'pages/counselor/calendar/health_data/HealthData';
import CounselorHealthDataWeightChart from 'pages/counselor/calendar/health_data/WeightChart';
import CounselorHealthDataHeartRateChart from 'pages/counselor/calendar/health_data/HeartRateChart';
import CounselorHealthDataPressureChart from 'pages/counselor/calendar/health_data/PressureChart';
import CounselorHealthDataPhotoTabList from 'pages/counselor/calendar/health_data/PhotoTabList';
import CounselorMessage from 'pages/counselor/calendar/message/Message';
import CounselorMessageCreateMessageThread from 'pages/counselor/calendar/message/MessageThreadCreate';
import PhotoTabList from 'pages/client/health_data/PhotoTabList';
import WeightChart from 'pages/client/health_data/charts/WeightChart';
import HearRateChart from 'pages/client/health_data/charts/HeartRateChart';
import PressureChart from 'pages/client/health_data/charts/PressureChart';
import CounselorAccount from 'pages/counselor/account/Account';
import CounselorUpdateName from 'pages/counselor/account/UpdateNameAccount';
import CounselorUpdatePasswordAccount from 'pages/counselor/account/UpdatePasswordAccount';
import CounselorUpdateEmailAccount from 'pages/counselor/account/UpdateEmailAccount';
import CounselorMessageContainer from 'pages/counselor/message/MessageContainer';
import CounselorConsultationReservation from 'pages/counselor/consultation-reservation/Reservation';
import CounselorConsultantList from 'pages/counselor/consultant-list/Consultant';
import Log from 'pages/admin/log/Log';
import UserInformation from 'pages/counselor/calendar/user_information/UserInformation';
import ProfileUpdateEmail from 'pages/admin/profile/ProfileUpdateEmail';
import Seminar from 'pages/client/seminar/Seminar';
import ClientSeminarDetail from 'pages/client/seminar/ClientSeminarDetail';
import Inquiry from 'pages/client/inquiry/Inquiry';
import ProgramDetail from 'pages/client/reservation/ProgramDetail';
import PastReservation from 'pages/client/reservation/PastReservation';
import AccountDeletion from 'pages/client/account/AccountDeletion';
import Questionnaire from './pages/counselor/calendar/questionnaire/Questionnaire';
import Email from 'pages/admin/email/Email';
import DistributionList from 'pages/admin/email/distribution/DistributionList';
import EmailCreate from 'pages/admin/email/EmailCreate';
import EmailUpdate from 'pages/admin/email/EmailUpdate';
import EmailDetail from 'pages/admin/email/detail_screen/EmailDetail';
import DistributionCreate from 'pages/admin/email/distribution/DistributionCreate';
import DistributionUpdate from 'pages/admin/email/distribution/DistributionUpdate';

const Routes = {
    public: {
        LOGIN: {
            path: '/login',
            component: null,
        },
        REGISTER: {
            path: '/register',
            component: null,
        },
        REGISTER_STEP_2: {
            path: '/register/step2',
            component: null,
        },
        REGISTER_COMPLETE: {
            path: '/register/complete',
            component: null,
        },
        PASSWORD_RESET: {
            path: '/password-reset',
            component: null,
        },
        PASSWORD_RESET_SENT: {
            path: '/password-reset/complete',
            component: null,
        },
        PASSWORD_CHANGE: {
            path: '/password-reset/change',
            component: null,
        },
        ACCOUNT_DELETED: {
            path: '/account-deleted',
            component: null,
        },
        NOT_FOUND: {
            path: '/404',
            component: null,
        },
    },
    private: {
        ADMIN_CALENDAR: {
            path: '/admin/calendar',
            component: Calendar,
        },
        ADMIN_CALENDAR_RESERVATION_CREATE: {
            path: '/admin/calendar/create-reservation',
            component: AdminCalendarCreateReservation,
        },
        ADMIN_COMPANY: {
            path: '/admin/company',
            component: Company,
        },
        ADMIN_COMPANIES_CREATE: {
            path: '/admin/company/create',
            component: CompanyCreate,
        },
        ADMIN_COMPANY_PROGRAM__ADD_COUNSELOR: {
            path: '/admin/company/:company_id/program/:company_program_id/add-counselor?counselor_id=:counselor_id',
            component: null,
        },
        ADMIN_COMPANIES_UPDATE: {
            path: '/admin/company/update/:id',
            component: CompanyUpdate,
        },
        ADMIN_COMPANIES_DETAIL: {
            path: '/admin/company/:id',
            component: CompanyDetail,
            exact: false,
        },
        ADMIN_COMPANIES_DETAIL_SETTING: {
            path: '/admin/company/:id/setting',
            component: null,
        },
        ADMIN_COMMON_SESSION: {
            path: '/admin/common-session',
            component: CommonSession,
        },
        ADMIN_COMMON_SESSIONS_CREATE: {
            path: '/admin/common-session/create',
            component: CommonSessionCreate,
        },
        ADMIN_COMMON_SESSIONS_DETAIL: {
            path: '/admin/common-session/:id',
            component: CommonSessionDetail,
        },
        ADMIN_COMMON_SESSIONS_UPDATE: {
            path: '/admin/common-session/:id/update',
            component: CommonSessionUpdate,
        },
        ADMIN_COUNSELOR: {
            path: '/admin/counselors',
            component: Counselor,
        },
        ADMIN_COUNSELORS_CREATE: {
            path: '/admin/counselors/create',
            component: CounselorCreate,
        },
        ADMIN_COUNSELORS_DETAIL: {
            path: '/admin/counselors/:id',
            component: CounselorDetail,
        },
        ADMIN_COUNSELORS_ADD_COMPANY_PROGRAM: {
            path: '/admin/counselors/:id/add-company-program',
            component: CounselorAddCompanyProgram,
        },
        ADMIN_COUNSELORS_ADD_COMPANY_PROGRAM_INDIVIDUAL: {
            path: '/admin/counselors/:id/add-individual-company-program',
            component: CounselorAddIndividualProgramCompany,
        },
        ADMIN_PROGRAM: {
            path: '/admin/program',
            component: Program,
        },
        ADMIN_PROGRAM_CREATE: {
            path: '/admin/program/create',
            component: ProgramCreate,
        },
        ADMIN_PROGRAM_DISPLAY_ORDER_SETTING: {
            path: '/admin/program/display-order-setting',
            component: ProgramDisplayOrderSetting,
        },
        ADMIN_PROGRAM_DETAIL: {
            path: '/admin/program/:id',
            component: null,
        },
        ADMIN_PROGRAM_UPDATE: {
            path: '/admin/program/:id/update',
            component: ProgramUpdate,
        },
        ADMIN_INTERVIEW_SHEET: {
            path: '/admin/interview-sheets',
            component: AdminInterviewSheet,
        },
        ADMIN_INTERVIEW_SHEETS_CREATE: {
            path: '/admin/interview-sheets/create',
            component: AdminInterviewSheetCreate,
        },
        ADMIN_INTERVIEW_SHEETS_DETAIL: {
            path: '/admin/interview-sheets/:id',
            component: AdminInterviewSheetDetail,
        },
        ADMIN_INTERVIEW_SHEETS_UPDATE: {
            path: '/admin/interview-sheets/:id/update',
            component: AdminInterviewSheetUpdate,
        },
        ADMIN_INTERVIEW_SHEETS_PREVIEW: {
            path: '/admin/interview-sheets/preview',
            component: AdminInterviewSheetPreview,
        },
        ADMIN_INTERVIEW_SHEETS_UPDATE_PREVIEW: {
            path: '/admin/interview-sheets/:id/preview',
            component: AdminInterviewSheetPreview,
        },
        ADMIN_INTERVIEW_SHEETS_RESPONSE_PERIOD_CREATE: {
            path: '/admin/interview-sheets/:id/period',
            component: ResponsePeriodCreate,
        },
        ADMIN_INTERVIEW_SHEETS_RESPONSE_PERIOD_UPDATE: {
            path: '/admin/interview-sheets/:id/period/:periodId/update',
            component: ResponsePeriodUpdate,
        },

        ADMIN_LOG: {
            path: '/admin/logs',
            component: Log,
        },
        ADMIN_PROFILE: {
            path: '/admin/profile',
            component: Profile,
        },
        ADMIN_PROFILE_UPDATE_NAME: {
            path: '/admin/profile/update-name',
            component: ProfileUpdateName,
        },

        ADMIN_PROFILE_UPDATE_PASSWORD: {
            path: '/admin/profile/change-password',
            component: ProfileUpdatePassword,
        },

        ADMIN_PROFILE_UPDATE_EMAIL: {
            path: '/admin/profile/update-email',
            component: ProfileUpdateEmail,
        },

        ADMIN_EMAIL: {
            path: '/admin/email',
            component: Email,
            exact: true,
        },
        ADMIN_EMAIL_CREATE: {
            path: '/admin/email/create',
            component: EmailCreate,
        },
        ADMIN_EMAIL_UPDATE: {
            path: '/admin/email/:id/update',
            component: EmailUpdate,
        },
        ADMIN_EMAIL_DISTRIBUTION: {
            path: '/admin/email/distribution',
            component: DistributionList,
            exact: true,
        },
        ADMIN_EMAIL_DISTRIBUTION_CREATE: {
            path: '/admin/email/distribution/create',
            component: DistributionCreate,
        },
        ADMIN_EMAIL_DISTRIBUTION_UPDATE: {
            path: '/admin/email/distribution/:id/update',
            component: DistributionUpdate,
        },
        ADMIN_EMAIL_DETAIL: {
            path: '/admin/email/:id',
            component: EmailDetail,
        },

        COUNSELOR_DASHBOARD: {
            path: '/counselor/dashboard',
            component: CounselorDashboard,
        },
        COUNSELOR_CALENDAR: {
            path: '/counselor/calendar',
            component: CounselorCalendar,
        },
        COUNSELOR_CALENDAR_RESERVATION_CREATE: {
            path: '/counselor/calendar/create-reservation',
            component: CounselorCalendarCreateReservation,
        },
        COUNSELOR_DETAIL_CREATE_RESERVATION: {
            path: '/counselor/calendar/:id/consultation/create-reservation',
            component: CounselorConsultationCreateReservation,
        },
        COUNSELOR_DETAIL_UPDATE_RESERVATION: {
            path: '/counselor/calendar/:id/consultation/:reservationId/update-reservation',
            component: CounselorConsultationUpdateReservation,
        },
        COUNSELOR_DETAIL_CONSULTATION: {
            path: '/counselor/calendar/:id/consultation',
            component: CounselorConsultation,
            exact: false,
        },
        COUNSELOR_DETAIL_HEALTH_DATA: {
            path: '/counselor/calendar/:id/health-data',
            component: CounselorHealthData,
        },
        COUNSELOR_DETAIL_HEALTH_DATA_WEIGHT_CHART: {
            path: '/counselor/calendar/:id/health-data/weight-chart',
            component: CounselorHealthDataWeightChart,
        },
        COUNSELOR_DETAIL_HEALTH_DATA_HEART_BEAT_CHART: {
            path: '/counselor/calendar/:id/health-data/heart-beat-chart',
            component: CounselorHealthDataHeartRateChart,
        },
        COUNSELOR_DETAIL_HEALTH_DATA_PRESSURE_CHART: {
            path: '/counselor/calendar/:id/health-data/pressure-chart',
            component: CounselorHealthDataPressureChart,
        },
        COUNSELOR_DETAIL_HEALTH_DATA_PHOTO_LIST: {
            path: '/counselor/calendar/:id/health-data/photo-list',
            component: CounselorHealthDataPhotoTabList,
        },
        // COUNSELOR_DETAIL_CREATE_MESSAGE: {
        //     path: '/counselor/calendar/:id/messages/create-message',
        //     component: CounselorMessageCreateMessageThread,
        // },
        COUNSELOR_DETAIL_QUESTIONNAIRE: {
            path: '/counselor/calendar/:id/questionnaire',
            component: Questionnaire,
            exact: false,
        },
        COUNSELOR_DETAIL_MESSAGE: {
            path: '/counselor/calendar/:id/messages',
            component: CounselorMessage,
            exact: false,
        },
        COUNSELOR_DETAIL_USER_INFORMATION: {
            path: '/counselor/calendar/:id/user-information',
            component: UserInformation,
        },
        COUNSELOR_CONSULTATION_RESERVATION: {
            path: '/counselor/consultation-reservation',
            component: CounselorConsultationReservation,
        },
        COUNSELOR_MESSAGE: {
            path: '/counselor/message',
            component: CounselorMessageContainer,
        },
        COUNSELOR_CONSULTANT_LIST: {
            path: '/counselor/consultant-list',
            component: CounselorConsultantList,
        },
        COUNSELOR_ACCOUNT: {
            path: '/counselor/account',
            component: CounselorAccount,
        },
        COUNSELOR_ACCOUNT_UPDATE_NAME: {
            path: '/counselor/account/update-name',
            component: CounselorUpdateName,
        },
        COUNSELOR_ACCOUNT_UPDATE_PASSWORD: {
            path: '/counselor/account/change-password',
            component: CounselorUpdatePasswordAccount,
        },
        COUNSELOR_ACCOUNT_UPDATE_EMAIL: {
            path: '/counselor/account/update-email',
            component: CounselorUpdateEmailAccount,
        },

        CLIENT_RESERVATION: {
            path: '/client/reservation',
            component: Reservation,
        },
        CLIENT_PAST_RESERVATION: {
            path: '/client/past-reservation',
            component: PastReservation,
        },
        CLIENT_RESERVATION_CREATE: {
            path: '/client/reservation/create',
            component: ReservationCreate,
        },
        CLIENT_PROGRAM_DETAIL: {
            path: '/client/reservation/program',
            component: ProgramDetail,
            exact: true,
        },
        CLIENT_RESERVATION_DETAIL: {
            path: '/client/reservation/:id',
            component: ReservationDetail,
        },
        CLIENT_RESERVATION_DETAIL_UPDATE: {
            path: '/client/reservation/:id/update',
            component: ReservationUpdate,
        },
        CLIENT_HEALTH_DATA: {
            path: '/client/health-data',
            component: HealthData,
        },
        CLIENT_INTERVIEW_SHEET: {
            path: '/client/interview-sheet',
            component: ClientInterviewSheet,
        },
        CLIENT_INTERVIEW_SHEET_HISTORY: {
            path: '/client/interview-sheet-history',
            component: ClientInterviewSheetHistory,
        },
        PHOTO_TAB_LIST: {
            path: '/client/health-data/photo-list',
            component: PhotoTabList,
        },
        WEIGHT_CHART: {
            path: '/client/health-data/weight-chart',
            component: WeightChart,
        },
        HEART_BEAT_CHART: {
            path: '/client/health-data/heart-beat-chart',
            component: HearRateChart,
        },
        PRESSURE_CHART: {
            path: '/client/health-data/pressure-chart',
            component: PressureChart,
        },
        CLIENT_INTERVIEW_SHEET_DISPLAY: {
            path: '/client/interview-sheet/:id/display',
            component: ClientInterviewSheetDisplay,
        },
        CLIENT_INTERVIEW_SHEET_DETAIL: {
            path: '/client/interview-sheet/:id',
            component: ClientInterviewSheetDetail,
        },
        CLIENT_MESSAGE: {
            path: '/client/message',
            component: Message,
        },
        CLIENT_MESSAGE_DETAIL: {
            path: '/client/message/:id',
            component: MessageDetail,
        },
        CLIENT_ACCOUNT: {
            path: '/client/account',
            component: Account,
        },
        CLIENT_ACCOUNT_UPDATE: {
            path: '/client/account/edit',
            component: UpdateAccount,
        },
        CLIENT_ACCOUNT_UPDATE_PASSWORD: {
            path: '/client/account/change-password',
            component: UpdatePasswordAccount,
        },
        CLIENT_ACCOUNT_UPDATE_EMAIL: {
            path: '/client/account/update-email',
            component: UpdateEmailAccount,
        },
        CLIENT_ACCOUNT_DELETION: {
            path: '/client/account/deletion',
            component: AccountDeletion,
        },
        CLIENT_SEMINAR: {
            path: '/client/seminar',
            component: Seminar,
        },
        CLIENT_SEMINARS_DETAIL: {
            path: '/client/seminar/:id',
            component: ClientSeminarDetail,
        },
        CLIENT_INQUIRY: {
            path: '/client/inquiry',
            component: Inquiry,
        },
    },
};

export default Routes;
