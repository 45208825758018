const DayOfWeekShortEnum = Object.freeze({
    SUNDAY: { value: 'sunday', text: '日' },
    MONDAY: { value: 'monday', text: '月' },
    TUESDAY: { value: 'tuesday', text: '火' },
    WEDNESDAY: { value: 'wednesday', text: '水' },
    THURSDAY: { value: 'thursday', text: '木' },
    FRIDAY: { value: 'friday', text: '金' },
    SATURDAY: { value: 'saturday', text: '土' },
});

export default DayOfWeekShortEnum;
