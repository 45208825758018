import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import { CloseIcon } from 'components/SvgIcon';
import DeleteBtnInModal from 'components/DeleteBtnInModal';
import { useTranslation } from 'react-i18next';

const DeleteCompanyModal = ({ visible, onClose, onUpdate, existCreatedReservation = false }) => {
    const { t } = useTranslation();
    const titleContent = existCreatedReservation ? (
        <span className={'not-delete-title'}>この企業は削除できません</span>
    ) : (
        <>企業の削除</>
    );

    const descriptionContent = existCreatedReservation ? (
        <span>
            {t('There is a consultation reservation that has not been implemented yet so it cannot be deleted')}
        </span>
    ) : (
        <p>
            {t('Delete the company message')}
            <br />
            {t('This operation is irreversible. Are you sure you want to delete?')}
        </p>
    );

    return (
        <Modal
            title={titleContent}
            visible={visible}
            width={400}
            footer={null}
            closeIcon={<CloseIcon />}
            onCancel={() => onClose()}
        >
            {descriptionContent}
            {existCreatedReservation ? (
                <div className="delete-action-panel">
                    <Button
                        type=""
                        className="cancel-button fw-b"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        <span className={'title-button'}>{'OK'}</span>
                    </Button>
                </div>
            ) : (
                <DeleteBtnInModal onClose={onClose} onUpdate={onUpdate} />
            )}
        </Modal>
    );
};

DeleteCompanyModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

export default DeleteCompanyModal;
