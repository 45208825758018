import React from 'react';

export const HMHeading = ({ detail }) => {
    const wrapper_style = 'font-size: 0; padding: 0 16px; word-break: break-word;';
    const style = 'font-family: Noto Sans JP; font-weight: 700; color: #000000;';
    const l_size = 'font-size: 24px; line-height: 36px; padding-bottom: 24px; text-align: center;';
    const m_size = 'font-size: 18px; line-height: 27px; padding-bottom: 8px; text-align: left;';
    const s_size = 'font-size: 16px; line-height: 24px; padding-bottom: 8px; text-align: left;';
    const size = detail.heading_size === 'm' ? m_size : detail.heading_size === 's' ? s_size : l_size;

    const raw = `<tr>
    <td align="center" style="${wrapper_style}">
        <div style="${style} ${size}">${detail['content']}</div>
    </td>
</tr>
`;

    return <tr dangerouslySetInnerHTML={{ __html: raw }} />;
};
