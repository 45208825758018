import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';
import moment from 'moment';
import { Button } from 'antd';
import history from 'helpers/history.helper';
import Routes from 'routes';
import BackBtnWithTitle from 'components/BackBtnWithTitle';
import completeImage from 'assets/images/icons/colorful/complete.png';
import MessageWithIcon from 'components/MessageWithIcon';
import FloatBar from 'components/FloatBar';
import { DeleteIcon, EditIcon, InfoIcon } from 'components/SvgIcon';
import FieldInfo from 'components/FieldInfo';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import { showNormal } from 'helpers/notification.helper';
import ReservationDeleteModal from 'pages/client/reservation/ReservationDeleteModal';
import { addToGoogleCalendar } from 'helpers/google_calendar.helper';
import ReservationStatus from 'enums/reservation_status.enum';
import Loader from 'components/Loader';
import { isMobileActive } from 'helpers/mobile.helper';

const ReservationDetail = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const location = useLocation();
    const isCreated = new URLSearchParams(location.search).get('created');
    const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);

    const cancelReservation = async () => {
        const data = await api.delete(APP_URLS.CLIENT_RESERVATIONS_DETAIL.replace(':id', id));
        if (data) {
            history.replace(Routes.private.CLIENT_RESERVATION.path);
        }
    };

    const [reservation, setReservation] = useState(null);
    useEffect(() => {
        let isCancelled = false;
        const getReservation = async () => {
            const data = await api.get(APP_URLS.CLIENT_RESERVATIONS_DETAIL.replace(':id', id));
            if (data && !isCancelled) {
                setReservation(data);
            }
        };
        getReservation();
        return () => {
            isCancelled = true;
        };
    }, [id]);

    if (!reservation) {
        return (
            <div>
                <BackBtnWithTitle
                    title={t('Consultation reservation detail')}
                    onClick={() => history.replace(Routes.private.CLIENT_RESERVATION.path)}
                />
                <Loader className="loader-center" />
            </div>
        );
    }

    let duration = null;
    if (reservation.session_end_time) {
        duration = moment(reservation.session_end_time, 'HH:mm').diff(
            moment(reservation.start_time, 'HH:mm'),
            'minutes'
        );
    }

    const disableAction =
        reservation.status !== ReservationStatus.CREATED.value && reservation.status !== ReservationStatus.FILLED.value;

    const hasInterviewSheet = reservation.program.first_interview_sheet_id || reservation.program.interview_sheet_id;

    return (
        <div>
            <BackBtnWithTitle
                title={isCreated ? '' : t('Consultation reservation detail')}
                onClick={() => history.goBack()}
            />
            <div className="reservation-container">
                {isCreated && (
                    <MessageWithIcon
                        className="mb-72"
                        imageUrl={completeImage}
                        title={t('{{entity}} has been created', { entity: t('entity:Reservation') })}
                        description={t(
                            'When the reservation date and time arrives, please start the consultation from the consultation reservation screen'
                        )}
                    />
                )}
                <div className="reservation-detail">
                    <p className="fs-20 mb-8">{reservation.program.name}</p>
                    <p className="fs-20 mb-8 reservation-detail-date-time">
                        <span className="mr-16">{moment(reservation.date).format('YYYY年M月D日(dd)')}</span>
                        <span>
                            {reservation.start_time}
                            {reservation.session_end_time ? `〜${reservation.session_end_time}(${duration}分)` : ''}
                        </span>
                    </p>
                    <Button
                        type="link"
                        className="fs-16 mb-16"
                        href={addToGoogleCalendar(reservation)}
                        target={isMobileActive() ? '_top' : '_blank'}
                    >
                        {t('Add to Google Calendar')}
                    </Button>
                    <p className="reservation-detail-typo">
                        {
                            '予約前日の12時・開始30分前になったら、登録されたメールアドレス宛にリマインドメールが送信されます。当日は、5分前までに通信環境の確認をして、待機していてください。'
                        }
                    </p>
                    {hasInterviewSheet && (
                        <p className="info">
                            <InfoIcon className={'mr-10'} />
                            予約日時までに質問表のご記入をお願いいたします
                        </p>
                    )}

                    <FieldInfo
                        className="mt-40"
                        label={t('entity:Counselor')}
                        value={`${
                            reservation.program.auto_allocate_counselor
                                ? t('Td counselor')
                                : reservation.counselor.full_name
                        }`}
                        showEdit={false}
                    />
                    <div className="reservation-detail-message message mt-40">
                        <FieldInfo
                            label={t('What you want to talk about on the day')}
                            value={reservation.message}
                            showEdit={false}
                        />
                    </div>
                </div>
            </div>
            <FloatBar style={{ justifyContent: 'space-between' }}>
                <Button
                    icon={!isCreated && <EditIcon />}
                    className="fw-b"
                    disabled={reservation.company_is_stage_stopped_end || disableAction}
                    onClick={() => {
                        isCreated
                            ? history.push(Routes.private.CLIENT_RESERVATION.path)
                            : history.push(Routes.private.CLIENT_RESERVATION_DETAIL_UPDATE.path.replace(':id', id), {
                                  reservation,
                              });
                    }}
                >
                    {isCreated ? t('To consultation reservation list') : t('Reservation edit')}
                </Button>
                {!isCreated && (
                    <Button
                        className="fw-b btn-delete"
                        icon={<DeleteIcon />}
                        disabled={reservation.company_is_stage_stopped_end || disableAction}
                        onClick={() => {
                            setVisibleDeleteModal(true);
                        }}
                    >
                        {t('Delete reservation')}
                    </Button>
                )}
                <ReservationDeleteModal
                    visible={visibleDeleteModal}
                    onClose={() => setVisibleDeleteModal(false)}
                    onUpdate={() => {
                        cancelReservation().then(() => {
                            showNormal('', t('message:Cancellation of reservation has been completed'));
                        });
                    }}
                />
            </FloatBar>
        </div>
    );
};

export default ReservationDetail;
