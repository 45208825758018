import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import EmailForm from 'pages/admin/email/form/EmailForm';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import DeliveryStatus from 'enums/delivery_status.enum';
import history from 'helpers/history.helper';
import Routes from 'routes';

const EmailUpdate = () => {
    const { id } = useParams();
    const [email, setEmail] = useState({});
    const [loading, setLoading] = useState(true);

    const getEmail = async id => {
        const response = await api.get(APP_URLS.ADMIN_EMAILS_DETAIL.replace(':id', id));
        if (response) {
            setEmail(response);
            if (response.status === DeliveryStatus.DELIVERED.value) {
                history.push(Routes.private.ADMIN_EMAIL_DETAIL.path.replace(':id', response.id));
            } else {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        getEmail(id).then(() => {});

        return () => {
            setEmail({});
            setLoading(true);
        };
    }, [id]);

    if (loading) return <></>;

    return <EmailForm isUpdate={true} initialValues={email} />;
};

export default EmailUpdate;
