import { createSlice } from '@reduxjs/toolkit';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';

const name = 'masterData';
const initialState = {
    companyEmployeesData: {
        fetched: false,
        data: [],
    },
};

const masterDataSlice = createSlice({
    name,
    initialState: initialState,
    reducers: {
        setMasterCompanyEmployeesData(state, action) {
            state.companyEmployeesData.fetched = true;
            state.companyEmployeesData.data = action.payload;
        },
    },
});

export const { setMasterCompanyEmployeesData } = masterDataSlice.actions;
export default masterDataSlice.reducer;

export const getMasterCompanyEmployees = () => async dispatch => {
    const data = await api.get(APP_URLS.ADMIN_MASTER_COMPANY_EMPLOYEES);
    if (data) {
        dispatch(setMasterCompanyEmployeesData(data));
    }
};
