import React from 'react';
import { Col, Row, Image, Button, Pagination, Typography } from 'antd';
import { CalendarIcon, PauseIcon } from 'components/SvgIcon';
import { truncateStr } from 'helpers/string.helper';
import { APP_URLS } from 'constants/url.constant';
import { dateWithForwardSlash } from 'helpers/date.helper';
import Routes from 'routes';
import history from 'helpers/history.helper';
import { useTranslation } from 'react-i18next';
import { getEndSeminars } from 'pages/client/seminar/seminar.slice';
import { connect } from 'react-redux';
import noImage from 'assets/images/noImage.png';
import { imageUrlAdapter } from 'helpers/image_url.helper';

const mapDispatch = { getEndSeminars };
const SeminarBlock = ({ seminarState, type, showPagination, getEndSeminars }) => {
    const { t } = useTranslation();
    const data = seminarState.data;
    const pagination = seminarState.pagination;
    const handlePagination = pageNum => {
        getEndSeminars({
            pagination: { ...pagination, current: pageNum },
            filters: { ...seminarState.filters, anchor_time: 'before' },
        });
    };

    return (
        <>
            {type === 'releaseType' ? (
                <Row gutter={[16, { xs: 8, md: 16 }]} className="mb-40">
                    {data.map(s => (
                        <Col xs={24} md={12} key={s.id} className="upcoming-seminar-col">
                            <a
                                className="seminar-block-link"
                                onClick={() =>
                                    history.push(Routes.private.CLIENT_SEMINARS_DETAIL.path.replace(':id', s.id))
                                }
                            >
                                <div className="seminar-block-container">
                                    {s.cover_img ? (
                                        <Image
                                            className={'seminar-block-image'}
                                            src={imageUrlAdapter(`${APP_URLS.STATIC_BASE_URL}/${s.cover_img}`, 480)}
                                            preview={false}
                                            height={160}
                                        />
                                    ) : (
                                        <div className="no-image-background block">
                                            <span className="content">{'No Image'}</span>
                                        </div>
                                    )}

                                    <div className="seminar-block-content">
                                        <div className="date-time-content align-items-center">
                                            <CalendarIcon size={16} />
                                            <span className="pl-6 date-text">{dateWithForwardSlash(s.date)}</span>
                                            &nbsp;
                                            <span className="date-text">{s.start_time}</span>
                                            <span className="color-blg-100">{'~'}</span>
                                            <span className="date-text">{s.end_time}</span>
                                            {s.is_running ? (
                                                <span className="held-this-week">{t('Session is running')}</span>
                                            ) : s.held_this_week ? (
                                                <span className="held-this-week">{t('Coming soon!')}</span>
                                            ) : null}
                                            {/*{s.held_this_week && (*/}
                                            {/*    <span className="held-this-week">{t('Coming soon!')}</span>*/}
                                            {/*)}*/}
                                        </div>
                                        <span className="fw-b fs-16 seminar-block-content-name">{s.name}</span>
                                        <span className="fs-14 seminar-block-content-description">{s.description}</span>
                                    </div>
                                </div>
                            </a>
                        </Col>
                    ))}
                </Row>
            ) : (
                <>
                    {data.map(s => (
                        <a
                            className="seminar-block-link past"
                            onClick={() => {
                                history.push(Routes.private.CLIENT_SEMINARS_DETAIL.path.replace(':id', s.id));
                            }}
                            key={s.id}
                        >
                            <Row className="past-seminar-row" align="middle" key={s.id} wrap={false}>
                                <div>
                                    {s.cover_img ? (
                                        <div
                                            className={`seminar-block-image have-passed`}
                                            style={{
                                                backgroundImage: `url(${imageUrlAdapter(`${APP_URLS.STATIC_BASE_URL}/${s.cover_img}`, 480)})`,
                                            }}
                                        >
                                            {!!(s.archived_at && s.is_video_public) && (
                                                <div className='mini-archive-overlay'>
                                                    <PauseIcon size={32} />
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <div className="no-image-background past-seminar-image seminar-block-image have-passed">
                                            <span className="content">{'No Image'}</span>
                                            {!!(s.archived_at && s.is_video_public) && (
                                                <div className='mini-archive-overlay'>
                                                    <PauseIcon size={32} />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div className="detail-action-panel">
                                    <Col xs={24} md={18}>
                                        <div className='info'>
                                            <div className='seminar-date-panel d-flex align-items-center flex-wrap'>
                                                <CalendarIcon size={16} />
                                                <span className="seminar-date">{dateWithForwardSlash(s.date)}</span>
                                                &nbsp;
                                                <span className="seminar-time">{s.start_time}</span>
                                                <span className="color-blg-100">{'~'}</span>
                                                <span className='seminar-time mr-10'>{s.end_time}</span>
                                                <div>
                                                    {!!(s.archived_at && s.is_video_public) && (
                                                        <div className='small-note-seminar-archive'>
                                                            <Typography className='text-small-seminar-archive'>
                                                                {t('Archive')}
                                                            </Typography>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="seminar-name">{truncateStr(s.name, 30)}</div>
                                            {/*<span className="seminar-badge">{t('Reception closed')}</span>*/}
                                        </div>
                                    </Col>
                                    {/*<Col className="btn-outline send-button past-seminar-btn" xs={24} md={3}>*/}
                                    {/*    <Button*/}
                                    {/*        className="fw-b btn-outline-detail btn-browsing"*/}
                                    {/*        type="outline"*/}
                                    {/*        onClick={event => {*/}
                                    {/*            history.push(*/}
                                    {/*                Routes.private.CLIENT_SEMINARS_DETAIL.path.replace(':id', s.id)*/}
                                    {/*            );*/}
                                    {/*            event.stopPropagation();*/}
                                    {/*        }}*/}
                                    {/*    >*/}
                                    {/*        {t('Browsing')}*/}
                                    {/*    </Button>*/}
                                    {/*</Col>*/}
                                </div>
                            </Row>
                        </a>
                    ))}
                    {!!showPagination && (
                        <Pagination
                            {...pagination}
                            onChange={handlePagination}
                            hideOnSinglePage={true}
                            showSizeChanger={false}
                            className="ant-pagination mini ant-table-pagination ant-table-pagination-center mt-24"
                        />
                    )}
                </>
            )}
        </>
    );
};

export default connect(null, mapDispatch)(SeminarBlock);
