import history from 'helpers/history.helper';
import Routes from 'routes';
import RoleEnum from 'enums/role.enum';

const hasRole = (roles = [], inputRole) => roles.find(r => r.name === inputRole);

export const rootRedirect = user => {
    if (hasRole(user.roles, RoleEnum.ADMIN.value) || hasRole(user.roles, RoleEnum.ADMIN_STAFF.value)) {
        history.push(Routes.private.ADMIN_COMPANY.path);
    } else if (hasRole(user.roles, RoleEnum.USER.value)) {
        history.push(Routes.private.CLIENT_SEMINAR.path);
    } else if (hasRole(user.roles, RoleEnum.STAFF.value) || hasRole(user.roles, RoleEnum.DOCTOR.value)) {
        history.push(Routes.private.COUNSELOR_CALENDAR.path);
    } else {
        history.push('/');
    }
};
