import { createSelector, createSlice } from '@reduxjs/toolkit';
import { APP_URLS } from 'constants/url.constant';
import HealthTypeEnum from 'enums/health_type.enum';
import api from 'helpers/api.helper';
import { baseChartInitState, baseChartReducer } from 'helpers/slice.helper';

const name = 'healthDataHeartRate';

const LINE_COLOR = '#4C18ED';

const healthDataHeartRateSlice = createSlice({
    name,
    initialState: {
        ...baseChartInitState,
        heartRates: [],
    },
    reducers: {
        ...baseChartReducer,
        setHeartRates(state, action) {
            state.heartRates = action.payload;
        },
    },
});

export const { setStartDate, setEndDate, goPrev, goNext, goToday, setDisplayType, setMonthFilter, setHeartRates } =
    healthDataHeartRateSlice.actions;

export const getHeartRates = (startDate, endDate) => async (dispatch, getState) => {
    if (!startDate || !endDate) {
        const state = getState().client.healthDataHeartRate;
        startDate = state.startDate;
        endDate = state.endDate;
    }
    const data = await api.get(APP_URLS.CLIENT_HEALTH_DATA_BY_DAY.replace(':typeId', HealthTypeEnum.HEART_BEAT.value), {
        'created_at:gte': startDate.format('YYYY-MM-DD'),
        'created_at:lte': endDate.format('YYYY-MM-DD'),
    });
    dispatch(setHeartRates(data));
};

const startDateSelector = state => state.startDate;
const endDateSelector = state => state.endDate;
const displayTypeSelector = state => state.displayType;
const monthFilterSelector = state => state.monthFilter;
const heartRatesSelector = state => state.heartRates;

export const heartRatesFilterSelector = createSelector(
    displayTypeSelector,
    monthFilterSelector,
    heartRatesSelector,
    (displayType, monthFilter, heartRates) => {
        if (displayType !== 'year') return heartRates;
        const dateStr = monthFilter.format('YYYY-MM');
        return heartRates.filter(heartRate => heartRate.created_at.substr(0, 7) === dateStr);
    }
);

export const chartDataSelector = createSelector(
    startDateSelector,
    endDateSelector,
    displayTypeSelector,
    heartRatesSelector,
    (startDate, endDate, displayType, heartRates) => {
        let diff,
            duration,
            names = [],
            tmpObj = {};

        if (displayType === 'year') {
            duration = 'months';
        } else {
            duration = 'days';
        }
        diff = endDate.diff(startDate, duration);
        for (let i = 0; i <= diff; i++) {
            const tmpDate = startDate.clone().add(i, duration).format('YYYY-MM-DD');
            names.push(tmpDate);
            tmpObj[tmpDate] = null;
        }
        for (const heartRate of heartRates) {
            let date;
            const value = parseFloat(heartRate.value);
            if (displayType === 'year') {
                date = `${heartRate.created_at.substr(0, 7)}-01`;
            } else {
                date = heartRate.created_at.substr(0, 10);
            }
            if (!tmpObj[date]) {
                tmpObj[date] = [value, value];
            } else {
                if (tmpObj[date][0] > value) tmpObj[date][0] = value;
                if (tmpObj[date][1] < value) tmpObj[date][1] = value;
            }
        }
        return { names, values: Object.values(tmpObj), colors: [LINE_COLOR] };
    }
);

export default healthDataHeartRateSlice.reducer;
