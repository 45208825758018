import { createSelector, createSlice } from '@reduxjs/toolkit';
import { APP_URLS } from 'constants/url.constant';
import HealthTypeEnum from 'enums/health_type.enum';
import api from 'helpers/api.helper';
import { baseChartInitState, baseChartReducer } from 'helpers/slice.helper';

const name = 'healthDataPressure';

const LINE_COLOR_HIGH = '#4C18ED';
const LINE_COLOR_LOW = '#13CBCB';

const healthDataPressureSlice = createSlice({
    name,
    initialState: {
        ...baseChartInitState,
        pressures: [],
    },
    reducers: {
        ...baseChartReducer,
        setPressures(state, action) {
            state.pressures = action.payload;
        },
    },
});

export const { setStartDate, setEndDate, goPrev, goNext, goToday, setDisplayType, setMonthFilter, setPressures } =
    healthDataPressureSlice.actions;

export const getPressures = (id, startDate, endDate) => async (dispatch, getState) => {
    if (!startDate || !endDate) {
        const state = getState().client.healthDataPressure;
        startDate = state.startDate;
        endDate = state.endDate;
    }
    const data = await api.get(
        APP_URLS.COUNSELOR_HEALTH_DATA_BY_DAY.replace(':typeId', HealthTypeEnum.PRESSURE.value),
        {
            'user_id': id,
            'created_at:gte': startDate.format('YYYY-MM-DD'),
            'created_at:lte': endDate.format('YYYY-MM-DD'),
        }
    );
    dispatch(setPressures(data));
};

const startDateSelector = state => state.startDate;
const endDateSelector = state => state.endDate;
const displayTypeSelector = state => state.displayType;
const monthFilterSelector = state => state.monthFilter;
const pressuresSelector = state => state.pressures;

export const pressuresFilterSelector = createSelector(
    displayTypeSelector,
    monthFilterSelector,
    pressuresSelector,
    (displayType, monthFilter, pressures) => {
        if (displayType !== 'year') return pressures;
        const dateStr = monthFilter.format('YYYY-MM');
        return pressures.filter(pressure => pressure.created_at.substr(0, 7) === dateStr);
    }
);

export const chartDataSelector = createSelector(
    startDateSelector,
    endDateSelector,
    displayTypeSelector,
    pressuresSelector,
    (startDate, endDate, displayType, pressures) => {
        let diff,
            duration,
            names = [],
            tmpObj = {},
            avgObj = {};

        if (displayType === 'year') {
            duration = 'months';
        } else {
            duration = 'days';
        }
        diff = endDate.diff(startDate, duration);
        for (let i = 0; i <= diff; i++) {
            const tmpDate = startDate.clone().add(i, duration).format('YYYY-MM-DD');
            names.push(tmpDate);
            tmpObj[tmpDate] = null;
        }

        let datesHasAverage = [];

        for (const pressure of pressures) {
            let date;
            const value = parseFloat(pressure.value);
            const extValue = parseFloat(pressure.ext_value);

            displayType === 'year'
                ? (date = `${pressure.created_at.substr(0, 7)}-01`)
                : (date = pressure.created_at.substr(0, 10));

            if (!avgObj[date]) {
                avgObj[date] = { totalLow: extValue, totalHigh: value, countLow: 1, countHigh: 1 };
            } else {
                avgObj[date].totalLow += extValue;
                avgObj[date].totalHigh += value;
                avgObj[date].countLow++;
                avgObj[date].countHigh++;
            }
        }

        for (const date of Object.keys(avgObj)) {
            tmpObj[date] = [
                avgObj[date].totalLow / avgObj[date].countLow,
                avgObj[date].totalHigh / avgObj[date].countHigh,
            ];

            if (avgObj[date].countLow > 1) {
                datesHasAverage.push(date);
            }
        }

        return { names, values: Object.values(tmpObj), colors: [LINE_COLOR_LOW, LINE_COLOR_HIGH], datesHasAverage };
    }
);

export default healthDataPressureSlice.reducer;
