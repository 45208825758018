import React from 'react';

export const HMPersonProfile = ({ paddingBottom = '32px', detail, isProgram }) => {
    const style_label =
        'font-family:Noto Sans JP;font-size:24px;font-weight:700;line-height:36px;text-align:center;color:#000000;';
    const style_paragraph =
        'font-family:Noto Sans JP;font-size:15px;font-weight:400;line-height:150%;text-align:left;color:#000000;margin-bottom:0;';
    const style_name =
        'font-family:Noto Sans JP;font-size:16px;font-weight:700;line-height:21px;text-align:center;color:#000000;';

    const raw = `
        <tr>
    <td style="padding: 0 16px ${paddingBottom};">
        <div>
            <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
                <tbody>
                <tr>
                    <td style="background-color:rgba(0, 0, 0, 0.1);border-radius:4px;vertical-align:top;padding:1px;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="background-color:#F2F7FC;border-radius:3px;" width="100%">
                            <tbody>
                            <tr>
                                <td align="center" style="font-size:0px;padding:16px;word-break:break-word;">
                                    <div style="${style_label}">${isProgram ? 'カウンセラー' : 'プロフィール'}</div>
                                </td>
                            </tr>
                            <tr>
                                <td style="padding: 0 8px 16px 8px;">
                                    <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style="font-size: 14px; text-align: center;">
                                        <tr>
                                            <td style="line-height: 1; font-size: 0">
                                                <img src="${
                                                    detail['media_url']
                                                }" width="120" height="120" border="0" alt="alt_text" style="border-radius: 100%;width: 120px; height: 120px; background: #C4C4C4; object-fit: cover;" class="center-on-narrow">
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td align="center" style="font-size:0px;padding:0 16px 16px;word-break:break-word;">
                                    <div style="${style_name}">${detail['profile_name'] || ''}</div>
                                </td>
                            </tr>
                            <tr>
                                <td align="center" style="font-size:0px;padding:0 16px 16px;word-break:break-word;">
                                    <div style="${style_paragraph}">
                                        ${detail['content'] || ''}
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </td>
</tr>
    `;

    return <tr dangerouslySetInnerHTML={{ __html: raw }} />;
};
