import Routes from 'routes';
import {
    AccessibilityIcon,
    AssignmentIcon,
    AssignmentIndIcon,
    DashboardIcon,
    DateRangeIcon,
    DomainIcon,
    FaceIcon,
    GroupIcon,
    ListIcon,
    MailIcon,
    ReservationIcon,
    SaveAltIcon,
    SessionIcon,
    SendIcon,
    UserAccountIcon,
} from 'components/SvgIcon';

export default [
    // Admin
    {
        key: 'ADMIN_CALENDAR',
        path: Routes.private.ADMIN_CALENDAR.path,
        text: 'Calendar',
        icon: DateRangeIcon,
        module: 'admin',
    },
    {
        key: 'ADMIN_COMPANY',
        path: Routes.private.ADMIN_COMPANY.path,
        text: 'Company',
        icon: DomainIcon,
        module: 'admin',
    },
    {
        key: 'ADMIN_COUNSELOR',
        path: Routes.private.ADMIN_COUNSELOR.path,
        text: 'Member',
        icon: FaceIcon,
        module: 'admin',
    },
    {
        key: 'ADMIN_PROGRAM',
        path: Routes.private.ADMIN_PROGRAM.path,
        text: 'Program',
        icon: AssignmentIndIcon,
        module: 'admin',
    },
    {
        key: 'ADMIN_COMMON_SESSION',
        path: Routes.private.ADMIN_COMMON_SESSION.path,
        text: 'Common_Session',
        icon: SessionIcon,
        module: 'admin',
    },
    {
        key: 'ADMIN_EMAIL',
        path: Routes.private.ADMIN_EMAIL.path,
        text: 'Email',
        icon: MailIcon,
        module: 'admin',
    },
    {
        key: 'ADMIN_INTERVIEW_SHEET',
        path: Routes.private.ADMIN_INTERVIEW_SHEET.path,
        text: 'Interview sheet',
        icon: AssignmentIcon,
        module: 'admin',
    },
    {
        key: 'ADMIN_LOG',
        path: Routes.private.ADMIN_LOG.path,
        text: 'ログ',
        icon: SaveAltIcon,
        module: 'admin',
    },

    // Counselor
    {
        key: 'COUNSELOR_DASHBOARD',
        path: Routes.private.COUNSELOR_DASHBOARD.path,
        text: 'Dashboard',
        icon: DashboardIcon,
        module: 'counselor',
    },
    {
        key: 'COUNSELOR_CALENDAR',
        path: Routes.private.COUNSELOR_CALENDAR.path,
        text: 'Calendar',
        icon: ReservationIcon,
        module: 'counselor',
    },
    // Counselor -> Consultant screen
    {
        key: 'COUNSELOR_DETAIL_CONSULTATION',
        path: Routes.private.COUNSELOR_DETAIL_CONSULTATION.path,
        text: 'Consultation',
        icon: DateRangeIcon,
        module: 'counselor',
    },
    {
        key: 'COUNSELOR_DETAIL_HEALTH_DATA',
        path: Routes.private.COUNSELOR_DETAIL_HEALTH_DATA.path,
        text: 'Health data',
        icon: AccessibilityIcon,
        module: 'counselor',
    },
    {
        key: 'COUNSELOR_DETAIL_MESSAGE',
        path: Routes.private.COUNSELOR_DETAIL_MESSAGE.path,
        text: 'Message',
        icon: MailIcon,
        module: 'counselor',
    },
    {
        key: 'COUNSELOR_DETAIL_QUESTIONNAIRE',
        path: Routes.private.COUNSELOR_DETAIL_QUESTIONNAIRE.path,
        text: 'Interview sheet',
        icon: AssignmentIcon,
        module: 'counselor',
    },
    {
        key: 'COUNSELOR_DETAIL_USER_INFORMATION',
        path: Routes.private.COUNSELOR_DETAIL_USER_INFORMATION.path,
        text: 'User information',
        icon: UserAccountIcon,
        module: 'counselor',
    },
    // End Consultant screen
    {
        key: 'COUNSELOR_CONSULTATION_RESERVATION',
        path: Routes.private.COUNSELOR_CONSULTATION_RESERVATION.path,
        text: 'Consultation reservation',
        icon: GroupIcon,
        module: 'counselor',
    },
    {
        key: 'COUNSELOR_MESSAGE',
        path: Routes.private.COUNSELOR_MESSAGE.path,
        text: 'Message',
        icon: MailIcon,
        module: 'counselor',
    },
    {
        key: 'COUNSELOR_CONSULTANT_LIST',
        path: Routes.private.COUNSELOR_CONSULTANT_LIST.path,
        text: 'Consultant list',
        icon: ListIcon,
        module: 'counselor',
    },

    // Client
    {
        key: 'SEMINAR',
        path: Routes.private.CLIENT_SEMINAR.path,
        text: 'Session',
        icon: SessionIcon,
        module: 'client',
    },
    {
        key: 'RESERVATION',
        path: Routes.private.CLIENT_RESERVATION.path,
        text: 'Consultation reservation',
        icon: DateRangeIcon,
        module: 'client',
    },
    {
        key: 'MESSAGE',
        path: Routes.private.CLIENT_MESSAGE.path,
        text: 'Message',
        icon: MailIcon,
        module: 'client',
    },
    {
        key: 'INTERVIEW_SHEET',
        path: Routes.private.CLIENT_INTERVIEW_SHEET.path,
        text: 'Interview sheet',
        icon: AssignmentIcon,
        module: 'client',
    },
    {
        key: 'HEALTH_DATA',
        path: Routes.private.CLIENT_HEALTH_DATA.path,
        text: 'Health data',
        icon: AccessibilityIcon,
        module: 'client',
    },
    {
        key: 'INQUIRY',
        path: Routes.private.CLIENT_INQUIRY.path,
        text: 'Inquiry',
        icon: SendIcon,
        module: 'client',
    },
];
