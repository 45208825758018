import { Avatar } from 'antd';
import React from 'react';

const TdtAvatar = ({
    avatarIcon,
    avatarSize,
    avatarColor = '#FFFFFF',
    avatarBgColor = '#13CBCB',
    badgeIcon,
    badgeSize = 20,
    badgeColor = '#FFFFFF',
    badgeBgColor = '#13CBCB',
}) => {
    const avatar = (
        <Avatar icon={avatarIcon} size={avatarSize} style={{ backgroundColor: avatarBgColor, color: avatarColor }} />
    );
    if (!badgeIcon) {
        return avatar;
    }
    return (
        <div className="tdt-avatar">
            {avatar}
            <div
                className="tdt-avatar-badge"
                style={{
                    width: `${badgeSize}px`,
                    height: `${badgeSize}px`,
                    backgroundColor: badgeBgColor,
                    color: badgeColor,
                }}
            >
                {badgeIcon}
            </div>
        </div>
    );
};

export default TdtAvatar;
