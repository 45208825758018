import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { baseExtraReducers, baseGetData, baseInitialState, baseReducers } from 'helpers/slice.helper';
import { APP_URLS } from 'constants/url.constant';

const name = 'health_data';

export const getData = createAsyncThunk(
    `${name}/getData`,
    async props => await baseGetData({ url: APP_URLS.CLIENT_HEALTH_DATA, ...props })
);

export const defaultFilters = {};

const healthDataSlice = createSlice({
    name,
    initialState: {
        ...baseInitialState,
        filters: defaultFilters,
        sorter: {
            field: 'created_at',
            order: 'ascend',
        },
    },
    reducers: baseReducers,
    extraReducers: baseExtraReducers(getData),
});

export const { setLoading, setData, setPagination, setFilters, setSorter, setHideColumns, setSelection } =
    healthDataSlice.actions;

export default healthDataSlice.reducer;
