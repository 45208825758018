import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Route, Switch, useRouteMatch, useParams } from 'react-router-dom';
import { AddIcon, LockIcon } from 'components/SvgIcon';
import TdtButton from 'components/TdtButton';
import DataTable from 'components/DataTable';
import { getData, setFilters } from 'pages/counselor/calendar/message/message.slice';
import { dateToStr } from 'helpers/date.helper';
import history from 'helpers/history.helper';
import MessageRoutes from 'pages/counselor/calendar/message/message_routes';
import TdtFilter from 'components/TdtFilter';
import { truncateStr } from 'helpers/string.helper';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import { hasRole, isAdminStaff } from 'helpers/role.helper';
import RoleEnum from 'enums/role.enum';

const mapState = state => ({
    state: state.counselor.message,
    messageThread: state.counselor.messageDetail.messageThread,
    user: state.auth.user,
});
const mapDispatch = { getData, setFilters };

const Message = ({ state, getData, setFilters, messageThread, user }) => {
    const { t } = useTranslation();
    const { id } = useParams();
    let { path, url } = useRouteMatch();
    const isAdmin = hasRole(user, RoleEnum.ADMIN.value);

    const columns = [
        {
            title: '日付',
            dataIndex: 'created_at',
            sorter: true,
            render: (_, record) => {
                return (
                    <>
                        {record.unread_message > 0 && <span className="dot-unread-message abs-left" />}
                        <div className="mb-4">{`${dateToStr(record.created_at, 'M月DD日(ddd) HH:mm')}`}</div>
                        {!isAdminStaff(user) ? (
                            <div>{truncateStr(record.title)}</div>
                        ) : (
                            <div className="color-blg-50 d-flex mt-8">
                                <LockIcon size={18} className="mr-4" />
                                {t('You do not have permission to view')}
                            </div>
                        )}
                    </>
                );
            },
        },
        {
            title: 'ステータス',
            dataIndex: 'status',
            width: 92,
            className: 'text-align-right',
            render: (_, record) => (record.close_flag ? '完了' : 'やりとり中'),
        },
    ];

    const getMessageThreads = (
        pagination = state.pagination,
        filters = state.filters,
        sorter = state.sorter,
        callback
    ) => {
        getData({ pagination, filters: { ...filters, module: 'counselor', user_id: id }, sorter }).then(o => {
            const data = o.payload ? o.payload.data : [];
            return callback && callback(data);
        });
    };

    const [metaData, setMetaData] = useState({});
    const getMetaDataMessageThreads = async () => {
        const response = await api.get(APP_URLS.MESSAGE_THREADS_META_DATA.replace(':id', id), { user_id: id });
        if (response) {
            setMetaData(response);
        }
    };

    useEffect(() => {
        getMetaDataMessageThreads().then(() => {});
    }, []);

    // filters
    const filterCond = [
        {
            key: 'status',
            label: 'ステータス',
            options: [
                { key: `status_open`, text: 'やりとり中', value: 'open_flag' },
                { key: `status_close`, text: '完了', value: 'close_flag' },
            ],
        },
        {
            key: 'program',
            label: 'プログラム',
            options: metaData.programs
                ? metaData.programs.map(p => ({
                      key: `program_${p.id}`,
                      text: isAdmin ? p.name_mngt : p.name,
                      value: p.id,
                  }))
                : [],
        },
    ];

    // merge helper
    const mergeFilter = (filters, keyStartWith) => {
        let tmp = [];
        filters.map(f => {
            if (f.key.indexOf(keyStartWith) === 0) {
                tmp.push(f.value);
            }
        });
        return tmp.join(',');
    };

    const getMessagesWithFilter = filters => {
        const mergeFilters = {
            ...state.filters,
            status: mergeFilter(filters, 'status_'),
            programs: mergeFilter(filters, 'program_'),
        };
        setFilters(mergeFilters);
        getMessageThreads(state.pagination, mergeFilters, state.sorter);
    };

    return (
        <>
            <div className="counselor-aside">
                <div className="consultation-aside ant-layout h-100p">
                    <div className="p-16 pb-0">
                        <div className="h7 mt-16 mb-24">{'メッセージ'}</div>
                        {isAdminStaff(user) ? (
                            <></>
                        ) : (
                            <TdtButton
                                icon={<AddIcon />}
                                className="w-100p f-14-700"
                                onClick={() => history.push(`${url}${MessageRoutes.CREATE.path}`)}
                            >
                                {'新規メッセージ作成'}
                            </TdtButton>
                        )}

                        <div className="text-align-right mt-16">
                            <TdtFilter rows={filterCond} applyFilters={getMessagesWithFilter} />
                        </div>
                    </div>

                    <div className="ant-layout">
                        <DataTable
                            className="collapse-table aside-table overflow-y h-100p counselor-for-message-table"
                            columns={columns}
                            data={state.data}
                            loading={state.loading}
                            pagination={state.pagination}
                            filters={state.filters}
                            sorter={state.sorter}
                            onTableChange={(pagination, filter, sorter) => {
                                getMessageThreads(pagination, state.filters, sorter);
                            }}
                            renderNoData={<div className="pt-24 pb-24">データがありません</div>}
                            onRow={record => {
                                return {
                                    onClick: () => {
                                        history.push(
                                            `${url}${MessageRoutes.DETAIL.path.replace(':messageThreadId', record.id)}`
                                        );
                                    },
                                };
                            }}
                            rowClassName={record => (record.id === messageThread?.id ? 'tdt-row-selected' : '')}
                        />
                    </div>
                </div>
            </div>
            <div className="counselor-main">
                <Switch>
                    {Object.keys(MessageRoutes).map(k => {
                        const r = MessageRoutes[k];
                        return (
                            <Route
                                key={`consultation_message_${r.path}`}
                                path={`${path}${r.path}`}
                                exact
                                render={props => (
                                    <r.component
                                        {...props}
                                        baseUrl={url}
                                        getMessageThreads={() => getMessageThreads()}
                                        firstId={state.data.length > 0 ? state.data[0].id : undefined}
                                    />
                                )}
                            />
                        );
                    })}
                </Switch>
            </div>
        </>
    );
};

export default connect(mapState, mapDispatch)(Message);

Message.propTypes = {
    state: PropTypes.any,
    getData: PropTypes.func.isRequired,
};
