import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DatePicker, Row, Col, Typography, Avatar, Modal, Image, Button } from 'antd';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import { dateToStr, fullDateFormat } from 'helpers/date.helper';
import { AddIcon, ArrowNextPrimaryIcon, ArrowPreviousPrimaryIcon, CalendarIcon, DeleteIcon } from 'components/SvgIcon';
import { useTranslation } from 'react-i18next';
import DeleteDataModal from 'pages/client/health_data/DeleteDataModal';
import FloatBar from 'components/FloatBar';
import CreateImageModal from 'pages/client/health_data/CreateImageModal';
import moment from 'moment';
import { showNormal } from 'helpers/notification.helper';
import Loader from 'components/Loader';
import NoData from 'components/NoData';
import { connect } from 'react-redux';
import ReservationStatus from 'enums/reservation_status.enum';

const mapState = state => ({ user: state.auth.user });
const PhotoTabContainer = ({ category, tab, setTab, user }) => {
    const { t } = useTranslation();
    const { RangePicker } = DatePicker;
    const dateFormat = 'YYYY/MM/DD';
    const isStateEnd = user.employee_company[0].state === ReservationStatus.END.value;

    const [listPhoto, setListPhoto] = useState([]);
    const [loading, setLoading] = useState(true);
    const [date, setDate] = useState({});
    const [value, setValue] = useState({ created_at: moment.utc() });
    const [modalVisible, setModalVisible] = useState({
        image: false,
        deleteImage: false,
        createImage: false,
        data: {},
        id: null,
    });
    const [modalCreateVisible, setModalCreateVisible] = useState({
        image: false,
    });

    const closeCreateModal = () => setModalCreateVisible({ image: false });
    const showCreateModal = () => setModalCreateVisible({ image: true });

    const showImageModal = data => setModalVisible({ image: true, deleteImage: false, data: data });
    const showDeleteModal = id =>
        setModalVisible({ deleteImage: true, image: true, createImage: false, data: modalVisible.data, id });

    const closeModal = () =>
        setModalVisible({ image: false, deleteImage: false, createImage: false, data: {}, id: null });
    const closeDeleteModal = forceClose =>
        setModalVisible({
            deleteImage: false,
            image: !forceClose,
            createImage: false,
            data: modalVisible.data,
            id: null,
        });

    const deleteImage = async () => {
        const response = await api.delete(APP_URLS.CLIENT_HEALTH_DATA_IMAGE_DELETE.replace(':id', modalVisible.id));
        if (response) {
            await getListPhoto(category);
            closeModal();
            showNormal('', t('message:Data has been deleted'), 3);
        } else {
            showNormal('', t('message:Cannot delete data'), 3);
        }
    };

    const getListPhoto = async category => {
        const response = await api.get(APP_URLS.CLIENT_HEALTH_DATA_IMAGE_BY_CATEGORY.replace(':category', category), {
            ...date,
        });
        if (response) {
            setListPhoto(response);
            setLoading(false);
        }
    };

    useEffect(() => {
        getListPhoto(category);
    }, [category, date, tab]);

    const groupImagesByDate = listImages => {
        let result = {};
        listImages.map(image => {
            const date = dateToStr(image.created_at, 'YYYY年M月DD日');
            result[date] = result[date] || [];
            result[date].push(image);
        });
        return result;
    };

    const imageGrouping = groupImagesByDate(listPhoto);

    const createdAtChangeHandler = value => {
        setDate({
            'created_at:gte': value[0] + ' 00:00:00',
            'created_at:lte': value[1] + ' 23:59:59',
        });
    };

    const handleSwipePhoto = isNext => {
        const data = modalVisible.data;
        const date = dateToStr(data.created_at, 'YYYY年M月DD日');
        const listPhotoOfDate = imageGrouping[date];
        const index = listPhotoOfDate.findIndex(d => d.id === data.id);

        const dateKeys = Object.keys(imageGrouping);
        const dateIndex = dateKeys.findIndex(k => k === date);
        const isLastDateIndex = dateIndex === dateKeys.length - 1;

        if (isNext) {
            if (index === listPhotoOfDate.length - 1) {
                if (!isLastDateIndex) {
                    setModalVisible({ ...modalVisible, data: imageGrouping[dateKeys[dateIndex + 1]][0] });
                }
            } else {
                setModalVisible({ ...modalVisible, data: listPhotoOfDate[index + 1] });
            }
        } else {
            if (index === 0) {
                if (dateIndex !== 0) {
                    const prevImageGrouping = imageGrouping[dateKeys[dateIndex - 1]];
                    setModalVisible({ ...modalVisible, data: prevImageGrouping[prevImageGrouping.length - 1] });
                }
            } else {
                setModalVisible({ ...modalVisible, data: listPhotoOfDate[index - 1] });
            }
        }
    };

    return (
        <>
            <div className="photo-tab-container">
                <Row>
                    <Col span={24}>
                        <div className="photo-range-picker-wrapper">
                            <RangePicker
                                className="photo-range-picker"
                                defaultValue={[moment.utc().startOf('month'), moment.utc()]}
                                format={dateFormat}
                                showToday={true}
                                allowClear={false}
                                suffixIcon={<CalendarIcon className="pl-8" size={20} />}
                                onChange={(mm, value) => {
                                    createdAtChangeHandler(value);
                                }}
                                inputReadOnly={true}
                            />
                        </div>
                    </Col>
                </Row>
                {loading ? (
                    <>
                        <Row justify={'center'} align={'middle'}>
                            <Col>
                                <Loader />
                            </Col>
                        </Row>
                    </>
                ) : (
                    <>
                        {listPhoto.length === 0 && <NoData />}
                        <Row className="mt-32">
                            <Col span={24}>
                                {Object.keys(imageGrouping).map(k => {
                                    return (
                                        <>
                                            <Typography key={k} className="mt-24">
                                                {k}
                                            </Typography>
                                            {imageGrouping[k].map(image => {
                                                return (
                                                    <>
                                                        <a onClick={() => showImageModal(image)}>
                                                            <Avatar
                                                                className="mr-16 mt-20"
                                                                shape="square"
                                                                size={64}
                                                                key={k}
                                                                src={`${APP_URLS.STATIC_BASE_URL}/${image.url}`}
                                                            />
                                                        </a>
                                                    </>
                                                );
                                            })}
                                        </>
                                    );
                                })}

                                <Modal
                                    closable={true}
                                    onCancel={closeModal}
                                    cancelButtonProps={false}
                                    visible={modalVisible.image}
                                    width={600}
                                    bodyStyle={{ paddingTop: '64px' }}
                                    footer={
                                        <>
                                            <div className="footer-photo-modal">
                                                <Button
                                                    className="btn-slide-photo none-pc-display"
                                                    type="outline"
                                                    icon={
                                                        <ArrowPreviousPrimaryIcon className="primary-color" size={12} />
                                                    }
                                                    onClick={() => handleSwipePhoto()}
                                                />
                                                <Typography className="photo-tab-modal fs-12-none-pc none-pc-display">
                                                    {fullDateFormat(modalVisible.data && modalVisible.data.created_at)}
                                                </Typography>
                                                <Button
                                                    className="btn-slide-photo none-pc-display"
                                                    type="outline"
                                                    icon={<ArrowNextPrimaryIcon className="primary-color" size={12} />}
                                                    onClick={() => handleSwipePhoto(true)}
                                                />
                                                {!isStateEnd && (
                                                    <Button
                                                        icon={<DeleteIcon />}
                                                        className="modal-delete-btn fw-b btn-pink"
                                                        onClick={() => showDeleteModal(modalVisible.data.id)}
                                                    >
                                                        <span className="health-data-delete-span">
                                                            {t('Data deletion')}
                                                        </span>
                                                    </Button>
                                                )}
                                            </div>
                                        </>
                                    }
                                >
                                    <div className="photo-tab-modal center">
                                        <Image
                                            shape="square"
                                            preview={false}
                                            size={64}
                                            style={{ maxHeight: '550px' }}
                                            src={`${APP_URLS.STATIC_BASE_URL}/${modalVisible.data.url}`}
                                        />
                                        <Row justify="center" align="middle" className="pt-16">
                                            <Col>
                                                <Button
                                                    className="btn-slide-photo pc-display"
                                                    type="outline"
                                                    icon={
                                                        <ArrowPreviousPrimaryIcon className="primary-color" size={12} />
                                                    }
                                                    onClick={() => handleSwipePhoto()}
                                                />
                                            </Col>
                                            <Col>
                                                <Typography className="photo-tab-modal pc-display">
                                                    {fullDateFormat(modalVisible.data && modalVisible.data.created_at)}
                                                </Typography>
                                            </Col>
                                            <Col>
                                                <Button
                                                    className="btn-slide-photo pc-display"
                                                    type="outline"
                                                    icon={<ArrowNextPrimaryIcon className="primary-color" size={12} />}
                                                    onClick={() => handleSwipePhoto(true)}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </Modal>
                                <DeleteDataModal
                                    visible={modalVisible.deleteImage}
                                    onClose={() => closeDeleteModal()}
                                    initialValue={{}}
                                    onUpdate={() => {
                                        deleteImage();
                                        closeDeleteModal(true);
                                    }}
                                />
                            </Col>
                        </Row>
                    </>
                )}
                <FloatBar>
                    {!isStateEnd && (
                        <Button
                            icon={<AddIcon />}
                            className="fw-b"
                            type="primary"
                            onClick={() => {
                                showCreateModal();
                            }}
                        >
                            {t('Data entry')}
                        </Button>
                    )}
                    <CreateImageModal
                        visible={modalCreateVisible.image}
                        onClose={() => {
                            closeCreateModal();
                        }}
                        isTabList={true}
                        getListPhoto={getListPhoto}
                        value={value}
                        setValue={setValue}
                        tab={tab}
                        setTab={setTab}
                        setLoading={setLoading}
                    />
                </FloatBar>
            </div>
        </>
    );
};

export default connect(mapState, null)(PhotoTabContainer);

PhotoTabContainer.propTypes = {
    category: PropTypes.any,
    setTab: PropTypes.func,
    tab: PropTypes.any,
};
