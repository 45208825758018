import React from 'react';
import TdtButton from 'components/TdtButton';
import { ArrowDownIcon, ArrowUpIcon, CopyIcon, DeleteIcon } from 'components/SvgIcon';
import { connect } from 'react-redux';
import { duplicateQuestion, moveQuestionDown, moveQuestionUp, removeQuestion } from '../interview_sheet_create.slice';
import { Tooltip } from 'antd';

const mapState = state => ({
    categories: state.admin.interviewSheetCreate.categories,
});

const mapDispatch = { removeQuestion, duplicateQuestion, moveQuestionUp, moveQuestionDown };
const CategoryFloatBar = ({
    categoryId,
    questionId,
    id,
    removeQuestion,
    duplicateQuestion,
    moveQuestionUp,
    moveQuestionDown,
    className = '',
    categories,
}) => {
    const checkMoveStatus = () => {
        const category = categories.byId[categoryId];
        // float bar question
        if (questionId) {
            const indexOfQuestion = category.children.indexOf(questionId);
            return {
                canMoveUp: indexOfQuestion > 0,
                canMoveDown: indexOfQuestion < category.children.length - 1,
            };

            // float bar category
        } else {
            const indexOfCategory = categories.order.indexOf(categoryId);
            return {
                canMoveUp: indexOfCategory > 0,
                canMoveDown: indexOfCategory < categories.order.length - 1,
            };
        }
    };

    const moveStatus = checkMoveStatus();

    const cantDeleteCategory = !questionId && categories.order.length < 4;

    return (
        <>
            <div className={`d-flex align-items-center justify-content-flex-end category-float-bar ${className}`}>
                <TdtButton
                    key="up"
                    disabled={!moveStatus.canMoveUp}
                    buttonStyle="plain"
                    icon={<ArrowUpIcon />}
                    className="text-gray-color"
                    onClick={() => moveQuestionUp({ id, isRadar: true, categoryId, questionId })}
                />
                <TdtButton
                    key="down"
                    disabled={!moveStatus.canMoveDown}
                    buttonStyle="plain"
                    icon={<ArrowDownIcon />}
                    className="ml-6 text-gray-color"
                    onClick={() => moveQuestionDown({ id, isRadar: true, categoryId, questionId })}
                />
                <TdtButton
                    key="duplicate"
                    buttonStyle="plain"
                    icon={<CopyIcon />}
                    className="ml-6 text-gray-color"
                    onClick={() => duplicateQuestion({ id, isRadar: true, categoryId, questionId })}
                />
                <TdtButton
                    key="delete"
                    disabled={cantDeleteCategory}
                    buttonStyle="plain"
                    icon={<DeleteIcon />}
                    className="ml-6 text-gray-color bb"
                    onClick={() => removeQuestion({ id, isRadar: true, categoryId, questionId })}
                />
            </div>
        </>
    );
};

export default connect(mapState, mapDispatch)(CategoryFloatBar);
