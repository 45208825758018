import React from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Routes from 'routes';

const HealthDataSidebar = ({ activeKey = 'body_weight' }) => {
    const { t } = useTranslation();
    const { id } = useParams();

    const routes = [
        {
            key: 'body_weight',
            path: Routes.private.COUNSELOR_DETAIL_HEALTH_DATA_WEIGHT_CHART.path,
            text: t('Body weight'),
        },
        {
            key: 'heart_beat',
            path: Routes.private.COUNSELOR_DETAIL_HEALTH_DATA_HEART_BEAT_CHART.path,
            text: t('Heart beat'),
        },
        {
            key: 'blood_pressure',
            path: Routes.private.COUNSELOR_DETAIL_HEALTH_DATA_PRESSURE_CHART.path,
            text: t('Blood Pressure'),
        },
        {
            key: 'photo',
            path: Routes.private.COUNSELOR_DETAIL_HEALTH_DATA_PHOTO_LIST.path,
            text: t('Photo'),
        },
    ];

    return (
        <div className="counselor-health-data-sidebar">
            {routes.map(r => (
                <Link
                    key={r.key}
                    to={r.path.replace(':id', id)}
                    className={`body1 ${activeKey === r.key ? 'active fw-b' : ''}`}
                >
                    {r.text}
                </Link>
            ))}
        </div>
    );
};

export default HealthDataSidebar;

HealthDataSidebar.propTypes = {
    activeKey: PropTypes.string,
};
