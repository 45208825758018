import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Select, DatePicker, Row, Col } from 'antd';
import { BaseStatePropShape, UserPropTypes } from 'constants/proptypes.constant';
import RoleEnum from 'enums/role.enum';
import ActivityLogEnum from 'enums/activity_log.enum';
import { getEnumText } from 'helpers/enum.helper';
import { getActionName, getCauserName, getCauserEmail, getCompanyName } from 'helpers/custom_action_name_log.helper';
import DataTable from 'components/DataTable';
import moment from 'moment';
import { callLogApi, getData, setFilters, setPagination } from 'pages/admin/log/log.slice';
import { timeFormatCreatedAt, dateFormatCreatedAt } from 'helpers/date.helper';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import TdtButtonSubmit from 'components/TdtButtonSubmit';
import { CSVLink } from 'react-csv';
import TdtSelect from 'components/TdtSelect';
import SelectPagination from 'components/SelectPagination';
import { isAdminStaff } from 'helpers/role.helper';

const mapState = state => ({ logState: state.admin.log, user: state.auth.user });
const mapDispatch = { getData, setFilters, setPagination };
const formatUrl = function ($url, $role) {
    if ($url && $url.indexOf('counselor/') > -1) {
        return $url;
    }

    if ($url && ($url.indexOf('/consultation') > -1 || $url.indexOf('/messages/') > -1)) {
        return '/counselor' + $url;
    }

    if ($role === RoleEnum.ADMIN.value || $role === RoleEnum.ADMIN_STAFF.value || ($url && ($url.indexOf('/interview-sheets/') > -1 || $url.indexOf('/messages/') > -1))) {
        return '/admin' + $url;
    } else if ($role === RoleEnum.DOCTOR.value || $role === RoleEnum.STAFF.value) {
        return '/counselor' + $url;
    } else {
        return $url;
    }
};

const Log = ({ logState, getData, setFilters, setPagination, user }) => {
    const [dataLog, setDataLog] = useState([]);
    const [fileName, setFileName] = useState();
    const [endTime, setEndTime] = useState();
    const csvLink = useRef();

    const getFullLogs = async $param => {
        const data = await api.get(APP_URLS.ADMIN_LOG, $param);
        if (data) {
            return data;
        }
    };
    const { t } = useTranslation();

    const columns = [
        {
            title: '日付',
            dataIndex: 'created_at',
            width: 94,
            render: (_, r) => `${dateFormatCreatedAt(r.created_at)}`,
        },
        {
            title: '時間',
            dataIndex: 'created_at',
            width: 74,
            render: (_, r) => `${timeFormatCreatedAt(r.created_at)}`,
        },
        {
            title: 'ユーザー名',
            dataIndex: 'causer',
            width: 140,
            render: (_, r) => `${getCauserName(r.properties)}`,
        },
        {
            title: 'ユーザータイプ',
            dataIndex: 'causer_role',
            className: 'white-space-nowrap',
            width: 114,
            render: (_, r) => `${getEnumText(ActivityLogEnum, r.causer_role) || ''}`,
        },
        {
            title: 'メールアドレス',
            dataIndex: 'causer',
            width: 180,
            render: (_, r) => isAdminStaff(user) && r.causer_role === RoleEnum.USER.value ? '' : `${getCauserEmail(r.properties)}`,
        },
        {
            title: t('Company'),
            dataIndex: 'company',
            className: 'white-space-nowrap',
            width: 180,
            render: (_, r) => `${getCompanyName(r.properties)}`,
        },
        {
            title: 'URL',
            dataIndex: 'url',
            width: 160,
            render: (_, r) =>
                (r.causer_role === 'admin' || (r.url && (r.url.indexOf('/interview-sheets/') > -1 || r.url.indexOf('/messages/') > -1))) ? (
                    <a href={formatUrl(r.url, r.causer_role)} target="_blank" rel="noreferrer">
                        {r.url}
                    </a>
                ) : (
                    r.url
                ),
        },
        {
            title: '説明',
            dataIndex: 'description',
            width: 220,
            render: (_, r) => `${getActionName(r.description, r.properties)}`,
        },
    ];

    const getLogs = (pagination = logState.pagination, filters = logState.filters, sorter = logState.sorter) => {
        setEndTime(moment(filters['created_at:lte']).add('-1', 'day'));
        getData({ pagination, filters, sorter });
    };
    useEffect(() => {
        getLogs();
    }, []);

    useEffect(() => {
        let fromTime = moment(logState.filters['created_at:gte']).format('YYYYMMDD');
        let toTime = moment(logState.filters['created_at:lte']).add('-1', 'day').format('YYYYMMDD');
        setFileName('LOGS_' + fromTime + '-' + toTime + '.csv');
    }, [endTime]);

    const convertDatToCSV = () => {
        let csvData = [];
        const $param = {
            'type': 'all-record',
            'order_by': '-id',
            'created_at:gte': moment(logState.filters['created_at:gte']).format('YYYY-MM-DD'),
            'created_at:lte': moment(logState.filters['created_at:lte']).format('YYYY-MM-DD'),
            'causer_role': logState.filters.causer_role,
        };
        getFullLogs($param).then(data => {
            if (data) {
                csvData.push(['日付', '時間', '原因者名', 'ユーザータイプ', '原因者名', '企業', 'URL', '説明']);
                data.forEach(data => {
                    csvData.push([
                        dateFormatCreatedAt(data.created_at),
                        timeFormatCreatedAt(data.created_at),
                        getCauserName(data.properties),
                        getEnumText(ActivityLogEnum, data.causer_role) || '',
                        getCauserEmail(data.properties),
                        getCompanyName(data.properties),
                        data.url,
                        getActionName(data.description, data.properties),
                    ]);
                });
                setDataLog(csvData);
                csvLink.current.link.click();
                callLogApi('csv_log_download');
            }
        });
    };

    const createdAtChangeHandler = value => {
        const newFilters = Object.assign({}, logState.filters, {
            'created_at:gte': value[0] + ' 00:00:00',
            'created_at:lte': moment(value[1]).add('1', 'day').format('YYYY/MM/DD (ddd)') + ' 23:59:59',
        });
        setFilters(newFilters);
        setEndTime(moment(value[1]).format('YYYY/MM/DD (ddd)'));
        getLogs(logState.pagination, newFilters, logState.sorter);
    };

    return (
        <div className="self-wrapper d-block">
            <h1 className="page-title mb-32">{t('Log')}</h1>
            <div className="mb-8">
                <label className="label-title">{'データ種別'}</label>
            </div>
            <TdtSelect
                style={{ width: 160 }}
                size="large"
                className="fs-14 mb-32 select-gray logs"
                placeholder="すべて"
                value={logState.filters.causer_role || ''}
                onChange={value => {
                    let newFilters = '';
                    const newPagination = { ...logState.pagination, current: 1 };
                    if (value === ActivityLogEnum.ROLE_DOCTOR_STAFF) {
                        newFilters = { ...logState.filters, causer_role: ActivityLogEnum.ROLE_DOCTOR_STAFF.value };
                    } else {
                        newFilters = { ...logState.filters, causer_role: value };
                    }
                    setFilters(newFilters);
                    setPagination(newPagination);
                    getLogs(newPagination, newFilters, logState.sorter);
                }}
            >
                <Select.Option value="">{'すべて'}</Select.Option>
                <Select.Option
                    value={ActivityLogEnum.ROLE_DOCTOR_STAFF.value}
                >{`${ActivityLogEnum.ROLE_DOCTOR_STAFF.text}`}</Select.Option>
                <Select.Option
                    value={ActivityLogEnum.ROLE_ADMIN.value}
                >{`${ActivityLogEnum.ROLE_ADMIN.text}`}</Select.Option>
                <Select.Option
                    value={ActivityLogEnum.ROLE_ADMIN_STAFF.value}
                >{`${ActivityLogEnum.ROLE_ADMIN_STAFF.text}`}</Select.Option>
                <Select.Option
                    value={ActivityLogEnum.ROLE_USER.value}
                >{`${ActivityLogEnum.ROLE_USER.text}`}</Select.Option>
            </TdtSelect>
            <div className="mb-8">
                <label className="label-title">{'期間'}</label>
            </div>
            <div className="mb-32 logs">
                <DatePicker.RangePicker
                    className="logs-date-picker"
                    format="YYYY/MM/DD (ddd)"
                    onChange={(mm, value) => createdAtChangeHandler(value)}
                    value={[moment(logState.filters['created_at:gte']), endTime]}
                    allowClear={false}
                    inputReadOnly={true}
                />
            </div>
            <Row gutter={16}>
                <Col span={24} className="d-flex">
                    <div>
                        <TdtButtonSubmit
                            type="primary"
                            htmlType="submit"
                            className="fw-b"
                            onClick={() => {
                                convertDatToCSV();
                            }}
                        >
                            {'CSVファイルをダウンロード'}
                        </TdtButtonSubmit>
                        <CSVLink data={dataLog} filename={fileName} target="_blank" ref={csvLink}></CSVLink>
                    </div>

                    <div className="page-size-logs">
                        <SelectPagination
                            onChange={pagination => getLogs(pagination)}
                            pageSizeOptions={[50, 100, 200]}
                            pageSize={logState.pagination.pageSize}
                        />
                    </div>
                </Col>
            </Row>

            <div className="logs-table">
                <DataTable
                    className="collapse-table mt-24"
                    columns={columns}
                    noDataText={'データがありません'}
                    hideColumns={logState.hideColumns}
                    data={logState.data}
                    loading={logState.loading}
                    pagination={logState.pagination}
                    filters={logState.filters}
                    sorter={logState.sorter}
                    onTableChange={pagination => {
                        getLogs(pagination, logState.filters, logState.sorter);
                    }}
                />
            </div>
        </div>
    );
};

export default connect(mapState, mapDispatch)(Log);

Log.propTypes = {
    logState: PropTypes.shape(
        Object.assign({}, BaseStatePropShape, {
            data: PropTypes.arrayOf(UserPropTypes).isRequired,
        })
    ),
    getData: PropTypes.func.isRequired,
};
