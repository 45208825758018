import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { CloseIcon } from 'components/SvgIcon';
import { useTranslation } from 'react-i18next';
import DataTable from 'components/DataTable';

const ProvideCompaniesModal = ({ visible, onClose, record }) => {
    const { t } = useTranslation();

    if (!record) {
        return <></>;
    }

    const columns = [
        {
            title: t('Company name'),
            dataIndex: 'name',
            width: 288,
            sorter: (a, b) => {
                return a.name.localeCompare(b.name);
            },
        },
        {
            title: t('Number of employees'),
            dataIndex: 'number_of_employees',
            className: 'text-align-right',
            render: total => total || 0,
            width: 160,
            sorter: (a, b) => {
                let a0 = a.number_of_employees || 0;
                let b0 = b.number_of_employees || 0;
                return a0 > b0 ? 1 : a0 < b0 ? -1 : 0;
            },
        },
    ];

    return (
        <>
            <Modal
                title={t('Provide companies')}
                visible={visible}
                width={480}
                footer={
                    <div className="color-blg-60 d-flex align-items-center pt-6 pb-6 fs-14-21">
                        <span>{'合計：'}</span>
                        <span className="fw-b f20-30 color-blg-100 mr-4">{record.companies.length}</span>
                        <span>{'社'}</span>
                    </div>
                }
                closeIcon={<CloseIcon />}
                onCancel={() => onClose()}
            >
                <DataTable
                    className="collapse-table table-border-lighter"
                    columns={columns}
                    data={record.companies}
                    noDataText={' '}
                    //yScroll={{ y: 260 }}
                    onTableChange={() => {}}
                />
            </Modal>
        </>
    );
};

export default ProvideCompaniesModal;

ProvideCompaniesModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};
