import { createSlice } from '@reduxjs/toolkit';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';

const name = 'companyDetailMessageDetail';

const messageDetailSlice = createSlice({
    name,
    initialState: {
        message: null,
    },
    reducers: {
        setMessage(state, action) {
            state.message = action.payload;
        },
    },
});

export const { setMessage } = messageDetailSlice.actions;

export const getMessage = id => async dispatch => {
    const data = await api.get(APP_URLS.MESSAGE_THREADS_DETAIL.replace(':id', id));
    if (data) {
        dispatch(setMessage(data));
    }
};

export default messageDetailSlice.reducer;
