import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import { CloseIcon } from 'components/SvgIcon';

const CodeVerifyErrorModal = ({ visible, onClose }) => {
    return (
        <Modal visible={visible} width={400} footer={null} closeIcon={<CloseIcon />} onCancel={() => onClose()}>
            <p className="fw-b mb-16 title-account">{'利用できない認証コードです'}</p>
            <p>
                {
                    '認証コードが間違っているか、利用期間を過ぎている可能性があります。受け取ったメールを確認のうえ、やり直してください。'
                }
            </p>
            <div className="btn-code">
                <Button type="" className="btn-verify-code fw-b" onClick={() => onClose()}>
                    {'OK'}
                </Button>
            </div>
        </Modal>
    );
};

CodeVerifyErrorModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default CodeVerifyErrorModal;
