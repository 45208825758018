import React from 'react';
import { dateToStr } from 'helpers/date.helper';

const HMSessionInformation = ({ info }) => {

    const raw = `<tr>
    <td style="direction:ltr;font-size:0px;padding:0 16px 32px;text-align:center;">
        <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:568px;" ><![endif]-->
        <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
            <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
                <tbody>
                <tr>
                    <td class="dark--no-border" style="background-color:rgba(0, 0, 0, 0.1);border-radius:4px;vertical-align:top;padding:1px;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" class="dark--bg-light-box" style="background-color:#F2F7FC;border-radius:3px;" width="100%">
                            <tbody>
                            <tr>
                                <td align="center" style="font-size:0px;padding:16px;word-break:break-word;">
                                    <div style="font-family:Noto Sans JP;font-size:24px;font-weight:700;line-height:36px;text-align:center;color:#000000;">セッション情報</div>
                                </td>
                            </tr>
                            <tr>
                                <td align="left" style="font-size:0px;padding:0 16px;word-break:break-word;">
                                    <table cellpadding="4px" cellspacing="0" width="100%" border="0" style="color:#000000;font-family:Noto Sans JP;font-size:16px;line-height:24px;table-layout:auto;width:100%;border:none;">
                                        <tr>
                                            <td width="130" align="right" style="vertical-align:top;">セッション名：</td>
                                            <td>『${info.name}』</td>
                                        </tr>
                                        <tr>
                                            <td width="130" align="right" style="vertical-align:top;">日時：</td>
                                            <td style="font-weight: bold;">${dateToStr(info.date, 'YYYY年M月DD日(ddd)')} ${info.start_time}-${info.end_time}</td>
                                        </tr>
                                        <tr>
                                            <td width="130" align="right" style="vertical-align:top;">Zoom：</td>
                                            <td>
                                                <a href="${info.seminar_url}" target="_blank" style="font-family:Noto Sans JP;font-size:16px;font-weight:400;line-height:24px;text-align:center;text-decoration:underline;color:#E63E55;">${info.seminar_url ? info.seminar_url : ''}</a>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            
                            <tr>
                                <td style="padding: 12px 16px 24px;">
                                    <table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" style="border-collapse:separate;width:100%;line-height:100%;margin: auto;">
                                        <tr>
                                            <td align="center" class="button-td button-td-primary" style="border-radius: 70px; background: #E63E55;">
                                                <a class="button-a button-a-primary dark--light-text" href="#" style="border-radius: 70px; background: #E63E55; font-family: Noto Sans JP; font-size: 16px; line-height: 24px; font-weight: 700; text-decoration: none; padding: 10px 25px; color: #ffffff; display: block;">詳細はこちら</a>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>

                            </tbody>
                        </table>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <!--[if mso | IE]></td></tr></table><![endif]-->
    </td>
</tr>
`;

    return <tr dangerouslySetInnerHTML={{ __html: raw }} />;
}

export default HMSessionInformation