import React from 'react';
import DailyLifeEventEnum from 'enums/daily_life_event.enum';
import { getEnumText } from 'helpers/enum.helper';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';

const times = [...Array(25).keys()];

const ResultDailyLife = ({ value: slots }) => {
    const { t } = useTranslation();

    const renderEventsName = () => {
        return (
            <div className="d-flex align-items-center">
                {Object.values(DailyLifeEventEnum).map(o => (
                    <span key={o.value} className="d-flex align-items-center mr-16">
                        <span className={`mr-4 dle-color dle-color-${o.value}`} />
                        {t(`enum:${getEnumText(DailyLifeEventEnum, o.value)}`)}
                    </span>
                ))}
            </div>
        );
    };

    const renderTimes = () => {
        return (
            <div>
                {times.map((num, index) => {
                    return (
                        <div key={`${num}_${index}`} className="rdl-time">
                            <span>{`${num}:00`}</span>
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderPlaceholderRange = () => {
        return (
            <div>
                {times.map((num, index) => {
                    return (
                        <div key={`${num}_${index}`} className="rdl-placeholder">
                            <span />
                        </div>
                    );
                })}
            </div>
        );
    };

    const timeToPx = time => {
        if (!time) {
            return 0;
        }

        const arr = time.split(':');
        const m = Number(arr[0]) * 60 + Number(arr[1]);
        return (m * 24) / 60;
    };

    const renderSlots = () => {
        return (
            <>
                {slots.map((slot, index) => {
                    return (
                        <Tooltip
                            key={index}
                            placement="right"
                            title={
                                <span className="fs-14-21">
                                    <div className="d-flex align-items-center">
                                        <span className={`mr-4 dle-color dle-color-${slot.type}`} />
                                        {t(`enum:${getEnumText(DailyLifeEventEnum, slot.type)}`)}
                                    </div>
                                    <div>{`${slot.start} ~ ${slot.end}`}</div>
                                </span>
                            }
                            color="rgba(34, 33, 38, 0.9)"
                        >
                            <div
                                className={`rdl-slot dle-color-${slot.type}`}
                                style={{ top: timeToPx(slot.start), height: timeToPx(slot.end) - timeToPx(slot.start) }}
                            />
                        </Tooltip>
                    );
                })}
            </>
        );
    };

    return (
        <div className="result-daily-life">
            {renderEventsName()}
            <div className="result-daily-life-wrapper">
                <div className="result-daily-life-times">{renderTimes()}</div>
                <div className="result-daily-life-slots">
                    {renderPlaceholderRange()}
                    {renderSlots()}
                </div>
            </div>
        </div>
    );
};

export default ResultDailyLife;
