import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { baseExtraReducers, baseGetData, baseInitialState, baseReducers } from 'helpers/slice.helper';
import { APP_URLS } from 'constants/url.constant';

const name = 'interviewSheet';

export const getData = createAsyncThunk(
    `${name}/getData`,
    async props => await baseGetData({ url: APP_URLS.ADMIN_INTERVIEW_SHEETS, ...props, filters: {...props.filters, programs_count: true } })
);

export const defaultFilters = {
    programs_count: true
};

const counselorSlice = createSlice({
    name,
    initialState: {
        ...baseInitialState,
        filters: defaultFilters,
        sorter: {
            field: 'id',
            order: 'descend',
        },
    },
    reducers: baseReducers,
    extraReducers: baseExtraReducers(getData),
});

export const { setLoading, setData, setPagination, setFilters, setSorter, setHideColumns, setSelection } =
    counselorSlice.actions;

export default counselorSlice.reducer;
