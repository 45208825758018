const ReservationStatus = Object.freeze({
    CREATED: { value: 'created', text: 'Reservation Created' },
    FILLED: { value: 'filled', text: 'Reservation Filled' }, // interview sheet filled
    START: { value: 'start', text: 'Reservation Start' }, // under consultation
    END: { value: 'end', text: 'Reservation End' }, // with remaining tasks
    COMPLETED: { value: 'completed', text: 'Reservation Completed' },
    PENDING: { value: 'pending', text: 'Reservation Pending' }, // by counselor
    CANCELLED_TIMEOUT: { value: 'cancelled_timeout', text: 'Reservation Cancelled timeout' },
    CANCELLED_BOOKING: { value: 'cancelled_booking', text: 'Reservation Cancelled booking' }, // by client
});

export default ReservationStatus;
