import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Col, DatePicker, Form, InputNumber, Row, Typography } from 'antd';
import FloatBar from 'components/FloatBar';
import TdtButtonSubmit from 'components/TdtButtonSubmit';
import { DateRangeIcon, DeleteIcon } from 'components/SvgIcon';
import moment from 'moment';
import BackBtn from 'components/BackBtn';
import { periodToDateTime } from 'helpers/date.helper';
import RouterPrompt from 'components/RouterPrompt';
import { showNormal } from 'helpers/notification.helper';
import ResponsePeriodDeleteModal from 'pages/admin/interview_sheet/detail_screen/tabs/response_period/ResponsePeriodDeleteModal';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import history from 'helpers/history.helper';
import Routes from 'routes';

const ResponsePeriodForm = ({ isEdit = false, initialValues = {}, periods, onFinish }) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const routerPromptRef = useRef();
    const [isValueChange] = useState(false);

    const [modalVisible, setModalVisible] = useState({
        delete: false,
    });

    initialValues.start_date && (initialValues.start_date = moment(initialValues.start_date, 'YYYY-MM-DD'));
    initialValues.end_date && (initialValues.end_date = moment(initialValues.end_date, 'YYYY-MM-DD'));

    const [status, setStatus] = useState({
        initFull: true,
        isEndBeforeStart: false, // text?
        isOverlapStart: false,
        isOverlapEnd: false,
    });
    const isInvalid = status.initFull || status.isEndBeforeStart || status.isOverlapStart || status.isOverlapEnd;
    const hasAnswers = initialValues.answered_count > 0;

    const handleFormValuesChange = (changedValues, allValues) => {
        const {
            start_date: startDate,
            start_hour: startHour,
            start_minute: startMinute,
            end_date: endDate,
            end_hour: endHour,
            end_minute: endMinute,
        } = allValues;

        if (
            startDate === undefined ||
            startHour === undefined ||
            startMinute === undefined ||
            endDate === undefined ||
            endHour === undefined ||
            endMinute === undefined
        ) {
            return;
        }
        const newStatus = { ...status, initFull: false };

        const startDateTime = periodToDateTime(startDate, startHour, startMinute);
        const endDateTime = periodToDateTime(endDate, endHour, endMinute);

        // check end before start
        newStatus.isEndBeforeStart = endDateTime.isSameOrBefore(startDateTime);

        // end time before now check, split with isEndBeforeStart if need show message
        if (!newStatus.isEndBeforeStart) {
            newStatus.isEndBeforeStart = endDateTime.isBefore(moment());
        }

        // check overlap
        newStatus.isOverlapStart = periods
            .filter(p => (isEdit ? p.id !== initialValues.id : true))
            .some(period => {
                const existedPeriodStart = periodToDateTime(period.start_date, period.start_hour, period.start_minute);
                const existedPeriodEnd = periodToDateTime(period.end_date, period.end_hour, period.end_minute);

                return startDateTime.isBetween(existedPeriodStart, existedPeriodEnd);
            });
        newStatus.isOverlapEnd = periods
            .filter(p => (isEdit ? p.id !== initialValues.id : true))
            .some(period => {
                const existedPeriodStart = periodToDateTime(period.start_date, period.start_hour, period.start_minute);
                const existedPeriodEnd = periodToDateTime(period.end_date, period.end_hour, period.end_minute);

                return endDateTime.isBetween(existedPeriodStart, existedPeriodEnd);
            });

        // check new period bound others
        const hasBound = periods
            .filter(p => (isEdit ? p.id !== initialValues.id : true))
            .some(period => {
                const existedPeriodStart = periodToDateTime(period.start_date, period.start_hour, period.start_minute);
                const existedPeriodEnd = periodToDateTime(period.end_date, period.end_hour, period.end_minute);

                return (
                    existedPeriodStart.isBetween(startDateTime, endDateTime) ||
                    existedPeriodEnd.isBetween(startDateTime, endDateTime)
                );
            });
        if (hasBound) {
            newStatus.isOverlapStart = true;
            newStatus.isOverlapEnd = true;
        }

        setStatus(newStatus);
    };
    const showDeleteModal = () => setModalVisible({ ...modalVisible, delete: true });
    const closeModal = () => setModalVisible({ delete: false });
    const deleteResponsePeriod = async () => {
        const response = await api.delete(
            APP_URLS.ADMIN_INTERVIEW_SHEETS_DETAIL_PERIOD_UPDATE.replace(':id', initialValues.interview_sheet_id).replace(':periodId', initialValues.id),
        );
        if (response) {
            history.push(Routes.private.ADMIN_INTERVIEW_SHEETS_DETAIL.path.replace(':id', initialValues.interview_sheet_id));
            showNormal('', t('message:Data has been deleted'), 3);
        }
    };

    return (
        <div className="self-wrapper d-block px-48">
            <BackBtn className="" label={isEdit ? '回答期間編集' : '回答期間登録'} />
            <RouterPrompt
                childRef={ref => (routerPromptRef.current = ref)}
                handleSave={() => {}}
                isValueChange={isValueChange}
                title={'確認'}
                body={'記入された内容は破棄されますがよろしいですか？'}
                leaveMode={true}
                discontinuationText="OK"
            />
            <Form
                form={form}
                layout="vertical"
                initialValues={initialValues}
                onFinish={async values => {
                    await onFinish(values);
                }}
                requiredMark={false}
                className="mt-32"
                onValuesChange={handleFormValuesChange}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <div className={`${status.isOverlapStart && 'period-has-error-item'}`}>
                            <Typography className="fs-16 fw-700">{t('Start date')}</Typography>
                            <div className="d-flex align-items-center mt-8 ">
                                <Form.Item name="start_date" noStyle>
                                    <DatePicker
                                        placeholder={'開始日'}
                                        className=" bg-gray tdt-datepicker reservation-date-picker"
                                        dropdownClassName="tdt-datepicker-panel"
                                        // disabledDate={date => {
                                        //     if (!date.isAfter(moment().startOf('day'))) {
                                        //         return true;
                                        //     }
                                        // }}
                                        style={{ maxWidth: 142 }}
                                        suffixIcon={<DateRangeIcon />}
                                        format="YYYY/MM/DD"
                                        allowClear={false}
                                        showToday={false}
                                        inputReadOnly={true}
                                        disabled={hasAnswers}
                                    />
                                </Form.Item>
                                <div className="d-flex ml-16">
                                    <Form.Item name="start_hour" noStyle>
                                        <InputNumber
                                            className="input-number-middle bg-gray-5"
                                            type="text"
                                            min={0}
                                            max={23}
                                            placeholder="00"
                                            style={{ maxWidth: 42, height: 40 }}
                                            disabled={hasAnswers}
                                        />
                                    </Form.Item>
                                    <Typography className=" ml-4 mt-8 fw-400 fs-16 color-blg-100">{'時'}</Typography>
                                </div>
                                <div className="d-flex ml-16">
                                    <Form.Item name="start_minute" noStyle>
                                        <InputNumber
                                            className="input-number-middle bg-gray-5"
                                            type="text"
                                            min={0}
                                            max={59}
                                            placeholder="00"
                                            style={{ maxWidth: 42, height: 40 }}
                                            disabled={hasAnswers}
                                        />
                                    </Form.Item>
                                    <Typography className=" ml-4 mt-8 fw-400 fs-16 color-blg-100">{'分'}</Typography>
                                </div>
                            </div>
                            {status.isOverlapStart && (
                                <Typography className="mt-4 fw-400 fs-14 color-red-100">
                                    {'前回の回答期間と日時が重複しています。'}
                                </Typography>
                            )}
                        </div>

                        {hasAnswers && (
                            <Typography className=" mt-8 fw-400 fs-14 color-blg-60">
                                {'※回答済のデータがあるため、変更できません'}
                            </Typography>
                        )}

                        <div
                            className={`mt-32 ${
                                (status.isEndBeforeStart || status.isOverlapEnd) && 'period-has-error-item'
                            }`}
                        >
                            <Typography className="fs-16 fw-700">{t('End date')}</Typography>
                            <div className="d-flex align-items-center mt-8">
                                <Form.Item name="end_date" noStyle>
                                    <DatePicker
                                        placeholder={'終了日'}
                                        className=" bg-gray tdt-datepicker reservation-date-picker"
                                        dropdownClassName="tdt-datepicker-panel"
                                        disabledDate={date => {
                                            // if (!date.isAfter(moment().startOf('day'))) {
                                            //     return true;
                                            // }
                                            if (date.isBefore(moment().startOf('day'))) {
                                                return true;
                                            }
                                            if (form.getFieldValue('start_date')?.isAfter(date)) {
                                                return true;
                                            }
                                        }}
                                        style={{ maxWidth: 142 }}
                                        suffixIcon={<DateRangeIcon />}
                                        format="YYYY/MM/DD"
                                        allowClear={false}
                                        showToday={false}
                                        value={form.getFieldValue('date')}
                                        onChange={date => {
                                            form.setFieldsValue({ date: date });
                                        }}
                                        inputReadOnly={true}
                                    />
                                </Form.Item>
                                <div className="d-flex ml-16">
                                    <Form.Item name="end_hour" noStyle>
                                        <InputNumber
                                            className="input-number-middle bg-gray-5"
                                            type="text"
                                            min={0}
                                            max={23}
                                            placeholder="00"
                                            style={{ maxWidth: 42, height: 40 }}
                                        />
                                    </Form.Item>
                                    <Typography className=" ml-4 mt-8 fw-400 fs-16 color-blg-100">{'時'}</Typography>
                                </div>
                                <div className="d-flex ml-16">
                                    <Form.Item name="end_minute" noStyle>
                                        <InputNumber
                                            className="input-number-middle bg-gray-5"
                                            type="text"
                                            min={0}
                                            max={59}
                                            placeholder="00"
                                            style={{ maxWidth: 42, height: 40 }}
                                        />
                                    </Form.Item>
                                    <Typography className=" ml-4 mt-8 fw-400 fs-16 color-blg-100">{'分'}</Typography>
                                </div>
                            </div>
                            {status.isOverlapEnd && (
                                <Typography className="mt-4 fw-400 fs-14 color-red-100">
                                    {'前回の回答期間と日時が重複しています。'}
                                </Typography>
                            )}
                            {status.isEndBeforeStart && (
                                <Typography className="mt-4 fw-400 fs-14 color-red-100">
                                    {'終了日時は開始日時と現行の日時より未来の値を設定してください。'}
                                </Typography>
                            )}
                        </div>
                    </Col>
                </Row>

                <Form.Item shouldUpdate>
                    {() => (
                        <FloatBar>
                            <TdtButtonSubmit
                                type="primary"
                                disabled={isInvalid}
                                htmlType="submit"
                                className="ml-16 fw-b disable-gray-btn"
                                onClick={() => {
                                    showNormal('', t('message:Registered response period'), 3);
                                }}
                            >
                                {isEdit ? t('Save') : t('Register')}
                            </TdtButtonSubmit>
                            {isEdit && (
                                <>
                                    <Button
                                        disabled={hasAnswers}
                                        icon={<DeleteIcon />}
                                        className={`fw-b ${hasAnswers ? 'disable-gray-btn' : 'btn-pink'}`}
                                        onClick={() => showDeleteModal()}
                                    >
                                        {'回答期間の削除'}
                                    </Button>
                                    <ResponsePeriodDeleteModal
                                        visible={modalVisible.delete}
                                        onClose={() => closeModal()}
                                        onUpdate={() => {
                                            deleteResponsePeriod().then(() => {});
                                        }}
                                    />
                                </>
                            )}
                        </FloatBar>
                    )}
                </Form.Item>
            </Form>
        </div>
    );
};

export default ResponsePeriodForm;

ResponsePeriodForm.propTypes = {
    isEdit: PropTypes.bool,
    initialValues: PropTypes.object,
    onFinish: PropTypes.func,
};
