import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import { CloseIcon } from 'components/SvgIcon';
import { useTranslation } from 'react-i18next';

const StopTransmissionModal = ({ visible, onClose, onUpdate }) => {
    const { t } = useTranslation();
    const titleContent = <span>{t('Stop automatic notification transmission')}</span>;

    const descriptionContent = (
        <>
            <span className="d-block mb-16">{t('Stops automatic notification transmission. Is it OK?')}</span>
        </>
    );

    return (
        <Modal
            title={titleContent}
            visible={visible}
            width={450}
            footer={null}
            closeIcon={<CloseIcon />}
            onCancel={() => onClose()}
        >
            {descriptionContent}
            {
                <>
                    <div className="delete-action-panel">
                        <Button
                            type=""
                            className="cancel-button fw-b disable-color"
                            onClick={() => {
                                onClose();
                            }}
                        >
                            <span className={'title-button'}>{t('Cancel')}</span>
                        </Button>
                        <Button
                            type=""
                            className={`delete-button fw-b bg-transparent`}
                            onClick={() => {
                                onUpdate && onUpdate();
                                onClose();
                            }}
                        >
                            <span className={'title-button'}>{t('Stop')}</span>
                        </Button>
                    </div>
                </>
            }
        </Modal>
    );
};

StopTransmissionModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

export default StopTransmissionModal;
