import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { reset } from 'pages/admin/program/program_create.slice';
import { useLocation } from 'react-router';
import ProgramForm from './ProgramForm';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import { showNormal } from 'helpers/notification.helper';
import { useTranslation } from 'react-i18next';
import history from 'helpers/history.helper';
import Routes from 'routes';
import PropTypes from 'prop-types';
import ProgramUpdate from 'pages/admin/program/ProgramUpdate';

const mapDispatch = { reset };

const ProgramCreate = ({ reset, canDelete = false }) => {
    const { t } = useTranslation();

    const createProgram = async values => {
        if (values['first_interview_sheet_id'] == '') {
            delete values['first_interview_sheet_id'];
        }
        const response = await api.post(APP_URLS.ADMIN_PROGRAM_CREATE, values);
        if (response) {
            showNormal('', t('message:I registered the program'), 5);
            history.push(Routes.private.ADMIN_PROGRAM.path);
        }
    };
    const location = useLocation();
    const isReset = new URLSearchParams(location.search).get('new');
    useEffect(() => {
        if (isReset) reset();
    }, []);

    return <ProgramForm mode="create" isUpdate={false} canDelete={canDelete} onFinish={createProgram} />;
};

export default connect(null, mapDispatch)(ProgramCreate);

ProgramUpdate.propTypes = {
    canDelete: PropTypes.any,
};
