import { createSlice } from '@reduxjs/toolkit';

const programTemp = createSlice({
    name: 'programTemp',
    initialState: {
        link: undefined,
        data: undefined,
    },
    reducers: {
        setLink(state, action) {
            state.link = action.payload;
        },
        setProgramTempData(state, action) {
            state.data = action.payload;
        },
        setDefaultData(state) {
            state.data = undefined;
            state.link = undefined;
        },
    },
});

export default programTemp.reducer;
export const { setLink, setProgramTempData, setDefaultData } = programTemp.actions;
