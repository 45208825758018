import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';
import moment from 'moment';
import { APP_URLS } from 'constants/url.constant';
import Routes from 'routes';
import history from 'helpers/history.helper';
import api from 'helpers/api.helper';
import BackBtnWithTitle from 'components/BackBtnWithTitle';
import ReservationForm from 'pages/client/reservation/ReservationForm';
import { showNormal } from 'helpers/notification.helper';

const ReservationUpdate = () => {
    const { t } = useTranslation();

    const { id } = useParams();
    const location = useLocation();
    const [reservation, setReservation] = useState(null);

    useEffect(() => {
        if (location.state && location.state.reservation) {
            setReservation(location.state.reservation);
            return;
        }
        let isCancelled = false;
        const getReservation = async () => {
            const data = await api.get(APP_URLS.CLIENT_RESERVATIONS_DETAIL.replace(':id', id));
            if (data && !isCancelled) {
                setReservation(data);
            }
        };
        getReservation();
        return () => {
            isCancelled = true;
        };
    }, [location.state]);

    const updateReservation = async params => {
        const data = await api.patch(APP_URLS.CLIENT_RESERVATIONS_DETAIL.replace(':id', id), params);
        if (data) {
            showNormal('', t('message:Reservation editing is complete'), 5);
            history.replace(Routes.private.CLIENT_RESERVATION.path);
        }
    };

    if (!reservation) {
        return (
            <div>
                <BackBtnWithTitle title={t('Consultation reservation creation')} onClick={() => history.goBack()} />
                Loading
            </div>
        );
    }

    let initialValues = { ...reservation };
    initialValues.date = moment(reservation.date);
    initialValues.time = `${reservation.start_time}〜${reservation.end_time}`;

    return (
        <div>
            <BackBtnWithTitle
                title={t('Counseling editing')}
                onClick={() => history.replace(Routes.private.CLIENT_RESERVATION.path)}
            />
            <ReservationForm
                isUpdate={true}
                onSubmit={values => updateReservation(values)}
                program={reservation.program}
                companyProgramId={reservation.company_program_id}
                initialValues={initialValues}
                hiddenSubmit={true}
            />
        </div>
    );
};

export default ReservationUpdate;
