import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Col, DatePicker, Form, Row, Select } from 'antd';
import TdtSelect from 'components/TdtSelect';
import { DateRangeIcon } from 'components/SvgIcon';
import FloatBar from 'components/FloatBar';
import { ProgramPropTypes, UserPropTypes } from 'constants/proptypes.constant';
import {
    getReservablePrograms,
    setForm,
    timeSelector,
    getReservableCounselors,
    counselorSelector,
    checkBlockPickDate,
    autoMatchTime,
    setPrograms,
} from 'pages/counselor/calendar/reservation-create/reservation_form.slice';
import moment from 'moment';
import SearchClientModal from 'pages/counselor/calendar/reservation-create/SearchClientModal';
import { hasAnyRole, hasRole } from 'helpers/role.helper';
import RoleEnum from 'enums/role.enum';

const mapState = state => ({
    programs: Object.values(state.counselor.calendarReservationForm.programs),
    form: state.counselor.calendarReservationForm.form,
    reserved: state.counselor.calendarReservationForm.reserved,
    registered: state.counselor.calendarReservationForm.registered,
    clientRegisteredTimes: state.counselor.calendarReservationForm.clientRegisteredTimes,
    counselors: state.counselor.calendarReservationForm.counselors,
    counselorsFiltered: counselorSelector(state.counselor.calendarReservationForm),
    sessionLength: state.counselor.calendarReservationForm.sessionLength,
    realSessionLength: state.counselor.calendarReservationForm.realSessionLength,
    time: timeSelector(state.counselor.calendarReservationForm),
    user: state.auth.user,
});
const mapDispatch = { getReservablePrograms, setForm, getReservableCounselors, setPrograms };

const ReservationForm = ({
    isUpdate = false,
    initialValues = {},
    programs,
    getReservablePrograms,
    form,
    reserved,
    registered,
    clientRegisteredTimes,
    counselors,
    counselorsFiltered,
    sessionLength,
    realSessionLength,
    time,
    getReservableCounselors,
    setForm,
    user,
    onSubmit,
    createFromBlock,
    setPrograms,
}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [validation, setValidation] = useState({ valid: true, errors: {} });

    const [modalVisible, setModalVisible] = useState({ client: false });
    const showSearchClientModal = () => setModalVisible({ client: true });
    const closeModal = () => setModalVisible({ client: false });
    const [client, setClient] = useState({});

    useEffect(() => {
        setForm(initialValues);
        setPrograms({});
    }, []);

    const formValidation = () => {
        let errors = {};
        if (!form.date) errors.date = t('validation:Please select {{field}}', { field: t('field:reservation date') });
        if (!form.time) errors.time = t('validation:Please select {{field}}', { field: t('field:reservation time') });
        if (!form.counselor_id && isStaff)
            errors.counselor_id = t('validation:Please select {{field}}', { field: t('field:counselor') });
        if (Object.keys(errors).length > 0) {
            setValidation({ valid: false, errors });
            return false;
        }
        setValidation({ valid: true, errors: {} });
        return true;
    };

    const submitForm = async values => {
        const result = formValidation();
        if (!result) {
            return;
        }

        setLoading(true);
        let params = { ...form, ...values };
        params.company_program_id =
            (selectedReservableProgram && selectedReservableProgram.id) || initialValues.company_program_id;

        if (!isStaff && !params.counselor_id) {
            params.counselor_id = user.id;
        }

        params.client_id = client.id;

        params.date = params.date.format('YYYY-MM-DD');
        const tmp = params.time.split('〜');
        params.start_time = tmp[0];
        if (tmp.length > 1) {
            params.end_time = tmp[1];
            params.session_end_time = moment(tmp[0], 'HH:mm').add(realSessionLength, 'minutes').format('HH:mm');
        }
        delete params.time;
        await onSubmit(params);
        setLoading(false);
    };

    const [selectedReservableProgram, setSelectedReservableProgram] = useState({});
    const onReservableProgramChange = companyProgramId => {
        // clear
        if (!createFromBlock) {
            setForm({ ...form, date: null, time: null, counselor_id: null });
        }

        setSelectedReservableProgram(programs.find(rs => rs.id === companyProgramId));
        getReservableCounselors(isStaff ? null : user.id, companyProgramId, initialValues.id || null, client.id).then(data => {
            if (createFromBlock) {
                let tmpForm = { ...form };
                if (!form.date || checkBlockPickDate(data, form.date)) {
                    tmpForm.date = null;
                    tmpForm.time = null;
                } else {
                    tmpForm.time = autoMatchTime(data, tmpForm, tmpForm.timeStrValue);
                }
                setForm({ ...tmpForm, counselor_id: null });
            }
        });
    };

    // isAdmin
    const isAdmin = hasAnyRole(user, ['admin', RoleEnum.ADMIN_STAFF.value]);

    const isStaff = !!user.roles.find(r => r.name === 'staff') || isAdmin;

    useEffect(() => {
        if (client.id) {
            getReservablePrograms(isStaff ? null : user.id, client.id);
            // update
            if (isUpdate) {
                const companyProgramId = initialValues.company_program_id;
                setSelectedReservableProgram(programs.find(rs => rs.id === companyProgramId));
                getReservableCounselors(isStaff ? null : user.id, companyProgramId, initialValues.id || null, client.id);
            }
        }
    }, [client.id]);

    const handleClientSelect = newClient => {
        setClient(newClient);
        // reset program, date, time if not createFromBlock, or !first pick
        if (!createFromBlock || (createFromBlock && client.id)) {
            setForm({ ...form, date: null, time: null, counselor_id: null });
            formControl.setFieldsValue({ company_program_id: null });
        }
    };

    // for control
    const [formControl] = Form.useForm();

    return (
        <>
            <Form
                layout="vertical"
                className="reservation-form"
                hideRequiredMark={true}
                onFinish={values => submitForm(values)}
                initialValues={initialValues}
                form={formControl}
            >
                <Row gutter={16}>
                    <Col className="mt-32 mb-32">
                        <div className="subtitle2 mb-8">{'相談者'}</div>
                        <Button className="fw-b" onClick={() => showSearchClientModal()}>
                            {'相談者の選択'}
                        </Button>
                        {client && client.id && (
                            <>
                                <div className="h6 mb-8 mt-8">{client.full_name}</div>
                                <div className="caption">{client.employee_company[0].name}</div>
                                <div className="caption">{client.email}</div>
                            </>
                        )}
                    </Col>

                    <Col span={24} className="mb-8">
                        <Form.Item
                            name="company_program_id"
                            label={'プログラム'}
                            validateTrigger={false}
                            rules={[
                                {
                                    required: true,
                                    message: t('validation:Please enter {{field}}', {
                                        field: t('field:program name'),
                                    }),
                                },
                            ]}
                        >
                            <TdtSelect
                                style={{ width: 384 }}
                                size="large"
                                className="fs-14 select-gray"
                                onChange={v => onReservableProgramChange(v)}
                            >
                                {(programs || []).map(rp => (
                                    <Select.Option key={rp.id} value={rp.id}>
                                        {isAdmin ? rp.program.name_mngt : rp.program.name}
                                    </Select.Option>
                                ))}
                            </TdtSelect>
                        </Form.Item>
                    </Col>

                    <Col>
                        <div className="fs-16 fw-b mb-8">{t('Reservation date')}</div>
                    </Col>
                    <Col span={24} className="date-time-picker mb-32">
                        <div className={validation.errors.date ? 'has-error' : ''}>
                            <DatePicker
                                disabled={
                                    !isUpdate &&
                                    (!selectedReservableProgram || !selectedReservableProgram.id) &&
                                    !createFromBlock
                                }
                                className="bg-gray tdt-datepicker reservation-date-picker mr-8"
                                dropdownClassName="tdt-datepicker-panel"
                                disabledDate={date => {
                                    if (!date.isAfter(moment().startOf('day'))) {
                                        return true;
                                    }
                                    const weekDay = date.locale('en').format('dddd').toLowerCase();
                                    if (!registered.all[weekDay]) return true;
                                    const dateFormatted = date.format('YYYY-MM-DD');
                                    if (!reserved[dateFormatted]) {
                                        // No reserved on that day
                                        return false;
                                    }
                                    for (const id of Object.keys(counselors)) {
                                        if (
                                            (!reserved[dateFormatted][id] || !reserved[dateFormatted][id].is_full) &&
                                            registered[id][weekDay]
                                        ) {
                                            // Has at least 1 counselor registered & also has free time
                                            if (!clientRegisteredTimes[dateFormatted]) {
                                                // User is free
                                                return false;
                                            } else {
                                                for (const possibleStartTime of registered[id][weekDay]) {
                                                    const possibleEndTime = moment(possibleStartTime, 'HH:mm')
                                                        .add(sessionLength, 'minutes')
                                                        .format('HH:mm');
                                                    for (const r of clientRegisteredTimes[dateFormatted]) {
                                                        if (
                                                            possibleStartTime >= r.end_time ||
                                                            possibleEndTime <= r.start_time
                                                        ) {
                                                            return false;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    return true;
                                }}
                                style={{ minWidth: 240 }}
                                suffixIcon={<DateRangeIcon />}
                                format="YYYY年M月D日(dd)"
                                value={form.date}
                                allowClear={false}
                                showToday={false}
                                onChange={date => {
                                    setForm({ ...form, date, time: null, counselor_id: null });
                                }}
                                inputReadOnly={true}
                            />
                            <div className="error-message">{validation.errors.date}</div>
                        </div>

                        <div className={`reservation-select-time ${validation.errors.time ? 'has-error' : ''}`}>
                            <TdtSelect
                                style={{ width: 136 }}
                                size="large"
                                className="fs-14 select-gray reservation-select"
                                value={form.time}
                                onChange={value => {
                                    let newForm = { ...form, time: value };
                                    let tmp = value.split('〜');
                                    if (
                                        time[tmp[0]] &&
                                        form.counselor_id &&
                                        time[tmp[0]].indexOf(form.counselor_id) === -1
                                    ) {
                                        newForm.counselor_id = null;
                                    }
                                    setForm(newForm);
                                }}
                            >
                                {Object.keys(time)
                                    .sort()
                                    .map(e => {
                                        const start = e;
                                        const end = moment(e, 'HH:mm').add(sessionLength, 'minutes').format('HH:mm');
                                        const realEnd = moment(e, 'HH:mm')
                                            .add(realSessionLength, 'minutes')
                                            .format('HH:mm');
                                        return (
                                            <Select.Option
                                                key={e}
                                                value={`${start}〜${end}`}
                                            >{`${start}〜${realEnd}`}</Select.Option>
                                        );
                                    })}
                            </TdtSelect>
                            <div className="error-message">{validation.errors.time}</div>
                        </div>
                    </Col>
                    {isStaff && (
                        <>
                            <Col>
                                <div className="fs-16 fw-b mb-8">{'担当カウンセラー'}</div>
                            </Col>
                            <Col span={24}>
                                <div className={validation.errors.counselor_id ? 'has-error' : ''}>
                                    <TdtSelect
                                        style={{ width: 240 }}
                                        size="large"
                                        className="fs-14 select-gray reservation-select"
                                        value={form.counselor_id}
                                        onChange={value => {
                                            setForm({ ...form, counselor_id: value });
                                        }}
                                    >
                                        {counselorsFiltered.map(counselor => (
                                            <Select.Option key={counselor.id} value={counselor.id}>
                                                {counselor.first_name}
                                                {counselor.last_name}
                                            </Select.Option>
                                        ))}
                                    </TdtSelect>
                                    <div className="error-message">{validation.errors.counselor_id}</div>
                                </div>
                            </Col>
                        </>
                    )}
                </Row>
                <SearchClientModal
                    visible={modalVisible.client}
                    onClose={() => closeModal()}
                    onSelect={client => handleClientSelect(client)}
                />
                <FloatBar>
                    <Button type="primary" loading={loading} htmlType="submit" className="fw-b">
                        {isUpdate ? t('Save') : t('Create')}
                    </Button>
                </FloatBar>
            </Form>
        </>
    );
};

export default connect(mapState, mapDispatch)(ReservationForm);

ReservationForm.propTypes = {
    isUpdate: PropTypes.bool,
    initialValues: PropTypes.object,
    programs: PropTypes.arrayOf(ProgramPropTypes),
    getReservablePrograms: PropTypes.func.isRequired,
    form: PropTypes.shape({
        date: PropTypes.any,
        time: PropTypes.string,
    }),
    reserved: PropTypes.any,
    registered: PropTypes.object,
    counselors: PropTypes.object,
    counselorsFiltered: PropTypes.array,
    sessionLength: PropTypes.number,
    time: PropTypes.object,
    getReservableCounselors: PropTypes.func.isRequired,
    setForm: PropTypes.func.isRequired,
    user: UserPropTypes,
    onSubmit: PropTypes.func.isRequired,
    createFromBlock: PropTypes.bool,
};
