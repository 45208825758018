import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import BackBtn from 'components/BackBtn';
import { InfoIcon } from 'components/SvgIcon';
import { Button, Col, Form, Input, Row } from 'antd';
import TargetProgramTable from 'pages/admin/company/tabs/message/TargetProgramTable';
import FloatBar from 'components/FloatBar';
import MessageNewPreviewModal from 'pages/admin/company/tabs/message/MessageNewPreviewModal';
import api from 'helpers/api.helper';
import history from 'helpers/history.helper';
import { showNormal } from 'helpers/notification.helper';
import { APP_URLS } from 'constants/url.constant';
import CompanyDetailRoutes from 'pages/admin/company/company_detail_routes';

const MessageNewProgram = ({ company, baseUrl }) => {
    const { t } = useTranslation();

    const [note, setNote] = useState('');
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [modalVisible, setModalVisible] = useState({
        preview: false,
    });
    const showPreviewModal = () => setModalVisible({ ...modalVisible, preview: true });
    const closeModal = () => setModalVisible({ preview: false });

    const submitMessageNewProgram = async () => {
        const res = await api.post(
            APP_URLS.ADMIN_COMPANIES_PROGRAM_SEND_MESSAGE_NEW.replace(':id', company.id).replace(
                ':programId',
                selectedRecord.id
            ),
            { note }
        );
        if (res) {
            history.push(`${baseUrl}${CompanyDetailRoutes.MESSAGE.path}`);
            showNormal('', t('message:I sent a notice'), 3);
        }
    };

    return (
        <div className="px-24">
            <BackBtn className="mb-32" label={t('Manually send notifications')} />

            <div className="mt-24 mb-32 fw-b f20-36">{t('Notice (new program)')}</div>

            <p className="fw-b mt-32 mb-8">{t('field:Company name')}</p>
            <div className="mb-8 h7">{company.name}</div>
            <div className="mb-32 body2 d-flex">
                <span className="medium-color">
                    <InfoIcon size={20} className="mr-4" />
                </span>
                <span>
                    <p className="mb-0">
                        {'・送信者はTeam Doctorsカスタマーサポートで、社員から返信はできない形式で送信されます。'}
                    </p>
                    <p className="mb-0">
                        {'・プログラムのニュースはプログラムが適用されていない社員には送信されません。'}
                    </p>
                </span>
            </div>

            <Form layout="vertical" requiredMark={false}>
                <Row gutter={16}>
                    <Col span={24} className="mb-8">
                        <Form.Item label={t('Additional notes')} validateTrigger={false}>
                            <Input.TextArea
                                style={{ maxWidth: 480 }}
                                autoSize={{ minRows: 2, maxRows: 6 }}
                                value={note}
                                onChange={v => setNote(v.target.value)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} className="mb-8">
                        <Form.Item label={t('Target program')} validateTrigger={false}>
                            <p className="body2">{'プログラムを１つ選択してください(必須)'}</p>
                            <TargetProgramTable
                                companyId={company.id}
                                selectedRecord={selectedRecord}
                                setSelectedRecord={setSelectedRecord}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <MessageNewPreviewModal
                    record={selectedRecord}
                    visible={modalVisible.preview}
                    onClose={() => closeModal()}
                    isProgram={true}
                    onUpdate={() => {
                        submitMessageNewProgram();
                        closeModal();
                    }}
                    note={note}
                />

                <FloatBar>
                    <div className="float-bar-company">
                        <Button className="fw-b" onClick={() => showPreviewModal()} disabled={!selectedRecord}>
                            {t('Preview and submit')}
                        </Button>
                    </div>
                </FloatBar>
            </Form>
        </div>
    );
};

export default MessageNewProgram;

MessageNewProgram.propTypes = {
    initialValues: PropTypes.object,
    company: PropTypes.object,
};
