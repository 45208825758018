import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { DateRangeIcon, InfoIcon } from 'components/SvgIcon';
import TdtSelect from 'components/TdtSelect';
import FloatBar from 'components/FloatBar';
import {
    allTimeSelector,
    counselorSelector,
    getReservableCounselors,
    setForm,
    timeSelector,
} from 'pages/client/reservation/reservation.slice';
import RouterPrompt from 'components/RouterPrompt';
import { handleScrollOnFocus } from 'helpers/form_event.helper';
import { showNormal } from 'helpers/notification.helper';

const mapState = state => ({
    form: state.client.reservation.form,
    reserved: state.client.reservation.reserved,
    registered: state.client.reservation.registered,
    clientRegisteredTimes: state.client.reservation.clientRegisteredTimes,
    counselors: state.client.reservation.counselors,
    interviewSheet: state.client.reservation.interviewSheet,
    firstInterviewSheet: state.client.reservation.firstInterviewSheet,
    isFirstTime: state.client.reservation.isFirstTime,
    counselorsFiltered: counselorSelector(state.client.reservation),
    sessionLength: state.client.reservation.sessionLength,
    realSessionLength: state.client.reservation.realSessionLength,
    time: timeSelector(state.client.reservation),
    allTime: allTimeSelector(state.client.reservation),
    user: state.auth.user,
});
const mapDispatch = { getReservableCounselors, setForm };
const ReservationForm = ({
    isUpdate = false,
    form,
    reserved,
    registered,
    clientRegisteredTimes,
    counselors,
    interviewSheet,
    firstInterviewSheet,
    counselorsFiltered,
    sessionLength,
    realSessionLength,
    getReservableCounselors,
    setForm,
    onSubmit,
    program = {},
    companyProgramId,
    initialValues = {},
    time,
    allTime,
    user,
    hiddenSubmit,
}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [validation, setValidation] = useState({ valid: true, errors: {} });
    const [isInvalidMessage, setIsInvalidMessage] = useState(false);
    const [statusBtnSubmit, setStatusBtnSubmit] = useState(true);

    const routerPromptRef = useRef();
    const [isValueChange, setIsValueChange] = useState(false);

    useEffect(() => {
        setForm(initialValues);

        return () => {
            setForm({});
        };
    }, []);

    useEffect(() => {
        getReservableCounselors(companyProgramId, initialValues.id, user.id);
    }, [program.id]);

    // auto select if has only one counselor match
    useEffect(() => {
        if (autoAllocateCounselor && !isUpdate) {
            if (counselorsFiltered.length > 0) {
                const minReservationsThisMonthCount = Math.min(
                    ...counselorsFiltered.map(c => c.count_reservations_this_month)
                );
                const candidates = counselorsFiltered.filter(
                    c => c.count_reservations_this_month === minReservationsThisMonthCount
                );
                const randomIndex = Math.floor(Math.random() * candidates.length);
                setForm({
                    ...form,
                    counselor_id: candidates[randomIndex].id,
                    counselor: candidates[randomIndex],
                });
            }
        } else {
            if (counselorsFiltered.length === 1) {
                setForm({
                    ...form,
                    counselor_id: counselorsFiltered[0].id,
                    counselor: counselorsFiltered[0],
                });
            }
        }
    }, [counselorsFiltered.length]);

    const formValidation = () => {
        let errors = {};
        if (!form.date) errors.date = t('validation:Please select {{field}}', { field: t('field:reservation date') });
        if (!form.time) errors.time = t('validation:Please select {{field}}', { field: t('field:reservation time') });
        if (!form.counselor_id)
            errors.counselor_id = t('validation:Please select {{field}}', { field: t('field:counselor') });
        if (Object.keys(errors).length > 0) {
            setValidation({ valid: false, errors });
            return false;
        }
        setValidation({ valid: true, errors: {} });
        return true;
    };

    const submitForm = async () => {
        const result = formValidation();
        if (!result) {
            return;
        }

        setLoading(true);
        let params = { ...form };
        params.company_program_id = companyProgramId;
        params.date = params.date.format('YYYY-MM-DD');
        const tmp = params.time.split('〜');
        params.start_time = tmp[0];
        if (tmp.length > 1) {
            params.end_time = tmp[1];
            params.session_end_time = moment(tmp[0], 'HH:mm').add(realSessionLength, 'minutes').format('HH:mm');
        }
        delete params.time;
        setIsValueChange(false);

        try {
            await onSubmit(params);
        } catch (e) {
            showNormal(
                '',
                t(
                    'message:The specified reservation slot was already filled. Sorry to trouble you, but please recreate the reservation.'
                ),
                5,
                null,
                'custom-notification white-space-pre-line'
            );
            setForm(initialValues);
            getReservableCounselors(companyProgramId, initialValues.id, user.id);
        }

        setLoading(false);
    };

    const checkSubmit = () => {
        return !!(hiddenSubmit && statusBtnSubmit);
    };
    const allowBookingToday = program.allow_same_day_booking;
    const autoAllocateCounselor = program.auto_allocate_counselor;

    const getInterviewSheetName = interviewSheet => {
        if (!interviewSheet) {
            return '';
        }
        return interviewSheet.name || interviewSheet.title || '';
    };

    const checkFirstInterviewSheet =
        firstInterviewSheet && firstInterviewSheet.id
            ? `「${getInterviewSheetName(firstInterviewSheet)}」(初回のみ)${
                  interviewSheet && interviewSheet.id ? '、' : ''
              } `
            : '';

    const checkInterviewSheet =
        interviewSheet && interviewSheet.id ? `「${getInterviewSheetName(interviewSheet)}」` : '';

    const checkInterviewSheetName =
        !interviewSheet && !firstInterviewSheet
            ? '当日は、5分前までに通信環境の確認をして、待機していてください'
            : // is first interview sheet
              `予約前日までに質問票${checkFirstInterviewSheet}${checkInterviewSheet}に回答してください。当日は、5分前までに通信環境の確認をして、待機していてください`;

    return (
        <div className="reservation-container">
            <p className="fs-20">{program.name}</p>
            <p className="mb-32 fs-14">{checkInterviewSheetName}</p>

            <RouterPrompt
                childRef={ref => (routerPromptRef.current = ref)}
                handleSave={() => {}}
                isValueChange={isValueChange}
                title={t('Cancel reservation')}
                body={t('Cancel the reservation creation. Is it OK?')}
                leaveMode={true}
            />

            <div className="reservation-form">
                <div className="fs-16 fw-b mb-8">{t('Reservation date')}</div>
                <Row gutter={16} className="mb-24" align="top">
                    <Col span={24} className="date-time-picker">
                        <div className={validation.errors.date ? 'has-error' : ''}>
                            <DatePicker
                                className="bg-gray tdt-datepicker reservation-date-picker"
                                dropdownClassName="tdt-datepicker-panel"
                                disabledDate={date => {
                                    if (
                                        allowBookingToday
                                            ? !date.isAfter(moment().startOf('day'))
                                            : !date.isAfter(moment().endOf('day'))
                                    ) {
                                        return true;
                                    }
                                    const weekDay = date.locale('en').format('dddd').toLowerCase();
                                    if (!registered.all[weekDay]) return true;
                                    const dateFormatted = date.format('YYYY-MM-DD');

                                    if (!reserved[dateFormatted]) {
                                        // No reserved on that day
                                        return false;
                                    }
                                    for (const id of Object.keys(counselors)) {
                                        if (
                                            (!reserved[dateFormatted][id] || !reserved[dateFormatted][id].is_full) &&
                                            registered[id][weekDay]
                                        ) {
                                            // Has at least 1 counselor registered & also has free time
                                            if (!clientRegisteredTimes[dateFormatted]) {
                                                // User is free
                                                return false;
                                            } else {
                                                for (const possibleStartTime of registered[id][weekDay]) {
                                                    const possibleEndTime = moment(possibleStartTime, 'HH:mm')
                                                        .add(sessionLength, 'minutes')
                                                        .format('HH:mm');
                                                    for (const r of clientRegisteredTimes[dateFormatted]) {
                                                        if (
                                                            possibleStartTime >= r.end_time ||
                                                            possibleEndTime <= r.start_time
                                                        ) {
                                                            return false;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    return true;
                                }}
                                style={{ minWidth: 240 }}
                                suffixIcon={<DateRangeIcon />}
                                format="YYYY年M月D日(dd)"
                                value={form.date}
                                allowClear={false}
                                showToday={false}
                                onChange={date => {
                                    setForm({ ...form, date, time: null, counselor_id: null, counselor: null });
                                    setIsValueChange(true);
                                    setStatusBtnSubmit(false);
                                }}
                                inputReadOnly={true}
                            />
                            <div className="error-message">{validation.errors.date}</div>
                        </div>

                        <div className={`ml-16 reservation-select-time ${validation.errors.time ? 'has-error' : ''}`}>
                            <TdtSelect
                                style={{ width: 240 }}
                                size="large"
                                className="fs-14 select-gray reservation-select"
                                value={form.time}
                                onChange={value => {
                                    let newForm = { ...form, time: value };
                                    let tmp = value.split('〜');
                                    if (
                                        time[tmp[0]] &&
                                        form.counselor_id &&
                                        time[tmp[0]].indexOf(form.counselor_id) === -1
                                    ) {
                                        newForm.counselor_id = null;
                                    }
                                    setForm(newForm);
                                    setStatusBtnSubmit(false);
                                }}
                                optionLabelProp="label"
                            >
                                {Object.keys(allTime)
                                    .sort()
                                    .map(e => {
                                        const start = e;
                                        const end = moment(e, 'HH:mm').add(sessionLength, 'minutes').format('HH:mm');
                                        const realEnd = moment(e, 'HH:mm')
                                            .add(realSessionLength, 'minutes')
                                            .format('HH:mm');
                                        let isDisabled = !time[e];

                                        // today: past times
                                        const now = moment().format('HH:mm');
                                        const isToday =
                                            moment().format('YYYY-MM-DD') === moment(form.date).format('YYYY-MM-DD');
                                        if (allowBookingToday && isToday && start < now) {
                                            //return null;
                                            isDisabled = true;
                                        }

                                        return (
                                            <Select.Option
                                                key={e}
                                                value={`${start}〜${end}`}
                                                label={`${start}〜${realEnd}`}
                                                disabled={isDisabled}
                                            >
                                                <span className="mr-8">{!isDisabled ? '○' : '×'}</span>
                                                {`${start}〜${realEnd}`}
                                            </Select.Option>
                                        );
                                    })}
                            </TdtSelect>
                            <div className="error-message">{validation.errors.time}</div>
                        </div>
                    </Col>
                </Row>
                <div className="fs-16 fw-b mb-8">{t('Counselor')}</div>
                <Row gutter={16} className="mb-24">
                    <Col span={24}>
                        <div className={validation.errors.counselor_id ? 'has-error' : ''}>
                            {autoAllocateCounselor && !isUpdate ? (
                                <>
                                    <div className="mb-24 body2 d-flex mt-8">
                                        <span className="color-blg-60">
                                            <InfoIcon size={16} className="mr-8" />
                                        </span>
                                        {t('The on-duty counselor on the reserved day will be in charge')}
                                    </div>
                                </>
                            ) : (
                                <TdtSelect
                                    style={{ width: 240 }}
                                    size="large"
                                    className="fs-14 select-gray reservation-select"
                                    value={isUpdate ? form.counselor && form.counselor.id : form.counselor_id}
                                    onChange={value => {
                                        setForm({
                                            ...form,
                                            counselor_id: value,
                                            counselor: counselorsFiltered.find(c => c.id === value),
                                        });
                                        setStatusBtnSubmit(false);
                                    }}
                                >
                                    {counselorsFiltered.length < 1 && form.counselor && (
                                        <Select.Option key={form.counselor.id} value={form.counselor.id}>
                                            {form.counselor.full_name}
                                        </Select.Option>
                                    )}
                                    {counselorsFiltered.map(counselor => (
                                        <Select.Option key={counselor.id} value={counselor.id}>
                                            {counselor.full_name}
                                        </Select.Option>
                                    ))}
                                </TdtSelect>
                            )}
                            <div className="error-message">{validation.errors.counselor_id}</div>
                        </div>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form onFocus={handleScrollOnFocus} onChange={handleScrollOnFocus}>
                            <div className="message reservation-form-message">
                                <div className="pb-8">
                                    <div className="fs-16 fw-b mb-8">{t('What you want to talk about on the day')}</div>
                                    <span className="text-area-description">
                                        {t(
                                            'If you would like to consult with a counselor on the day, please feel free to write'
                                        )}
                                    </span>
                                </div>

                                <Input.TextArea
                                    className={isInvalidMessage && 'has-error'}
                                    style={{ maxWidth: 480 }}
                                    //showCount={{ formatter: ({ count, maxLength = 400 }) => `${count}/${maxLength}` }}
                                    //maxLength={400}
                                    value={form.message}
                                    onChange={e => {
                                        const value = e.target.value;
                                        setForm({ ...form, message: value });
                                        setIsInvalidMessage(value && value.length > 400);
                                        setStatusBtnSubmit(value && value.length > 400);
                                    }}
                                />
                                <span className="text-area-show-count">
                                    <span
                                        className={`${form.message && form.message.length > 400 ? 'pink-color' : ''}`}
                                    >
                                        {form.message ? form.message.length : 0}
                                    </span>
                                    <span>{'/400'}</span>
                                </span>
                            </div>
                        </Form>
                    </Col>
                </Row>
                <FloatBar>
                    <Button
                        disabled={isInvalidMessage || checkSubmit()}
                        type="primary"
                        loading={loading}
                        htmlType="submit"
                        className="fw-b"
                        onClick={() => submitForm()}
                    >
                        {isUpdate ? t('Save') : t('Reservation')}
                    </Button>
                </FloatBar>
            </div>
        </div>
    );
};

export default connect(mapState, mapDispatch)(ReservationForm);

ReservationForm.propTypes = {
    isUpdate: PropTypes.bool,
    form: PropTypes.shape({
        date: PropTypes.any,
        time: PropTypes.string,
    }),
    reserved: PropTypes.any,
    registered: PropTypes.object,
    counselors: PropTypes.object,
    counselorsFiltered: PropTypes.array,
    sessionLength: PropTypes.number,
    realSessionLength: PropTypes.number,
    time: PropTypes.object,
    program: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        description: PropTypes.string,
    }),
    companyProgramId: PropTypes.number,
    getReservableCounselors: PropTypes.func.isRequired,
    setForm: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    user: PropTypes.any,
};
