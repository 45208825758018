const DailyLifeEventEnum = Object.freeze({
    SLEEP: { value: 'sleep', text: 'Sleep' },
    WORK: { value: 'work', text: 'Work' },
    TEACHING_RESEARCH: { value: 'teaching_research', text: 'Teaching research' },
    STUDY: { value: 'study', text: 'Study' },
    MEAL: { value: 'meal', text: 'Meal' },
    MOTION: { value: 'motion', text: 'Motion' },
    DOMESTIC_AFFAIRS: { value: 'domestic_affairs', text: 'Domestic affairs' },
    REST: { value: 'rest', text: 'Rest' },
    LEISURE: { value: 'leisure', text: 'Leisure' },
});

export default DailyLifeEventEnum;