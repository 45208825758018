import React, { useState } from 'react';
import { Button, Col, Row } from 'antd';
import history from 'helpers/history.helper';
import Routes from 'routes';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout, setUser } from 'pages/auth/auth.slice';
import UpdateEmailModal from 'pages/client/account/UpdateEmailModal';
import { useTranslation } from 'react-i18next';
import { showNormal } from 'helpers/notification.helper';
import LogOutModal from 'pages/client/account/LogOutModal';
import { DeleteIcon } from 'components/SvgIcon';

const mapState = state => ({
    account: state.auth.user,
});

const mapDispatch = { logout, setUser };
const Account = ({ account, logout, setUser }) => {
    const { t } = useTranslation();
    const [modalVisible, setModalVisible] = useState({
        emailModal: false,
        logOut: false,
    });

    const showEmailModal = () => {
        setModalVisible({ emailModal: true, logOut: false });
    };
    const showLogOutModal = () => {
        setModalVisible({ logOut: true, emailModal: false });
    };
    const closeModal = () => {
        setModalVisible({ emailModal: false, logOut: false });
    };

    return (
        <div className="account-settings">
            <h1 className="page-title mb-32">{t('Account')}</h1>

            <Row className="account" justify="space-between" align="middle">
                <Col className=" mr-16 title-name-setting">
                    <p className="mb-8 main-content">
                        <span>{account.full_name}</span>
                    </p>
                    <p className="mb-0 sub-content">
                        {account.first_name_kana && account.first_name_kana}{' '}
                        {account.last_name_kana && account.last_name_kana}
                    </p>
                </Col>

                <Col className="btn-setting">
                    <Button
                        className="fw-b btn-setting-responsive text-align-right"
                        type="primary"
                        onClick={() => history.push(Routes.private.CLIENT_ACCOUNT_UPDATE.path)}
                    >
                        <span>{'編集'}</span>
                    </Button>
                </Col>
            </Row>
            <Row className="account" justify="space-between" align="middle">
                <Col className=" mr-16 title-name-setting">
                    <p className="mb-6 main-content fw-b fs-16">{'メールアドレス'}</p>
                    <p className="mb-0 main-content">{`${account.email}`}</p>
                </Col>

                <Col className="btn-setting">
                    <Button className="fw-b btn-setting-responsive" type="primary" onClick={() => showEmailModal()}>
                        {'編集'}
                    </Button>
                </Col>
                <UpdateEmailModal
                    visible={modalVisible.emailModal}
                    onClose={() => closeModal()}
                    onUpdate={value => {
                        setModalVisible({ emailModal: false });
                        showNormal('', t('message:We sent an email enclosed authentication code'), 5);
                        setUser({ ...account, tmpEmail: value });
                        history.push(Routes.private.CLIENT_ACCOUNT_UPDATE_EMAIL.path);
                        closeModal();
                    }}
                />
            </Row>
            <Row className="account" justify="space-between" align="middle">
                <Col className=" mr-16 title-name-setting">
                    <p className="mb-6 main-content fw-b fs-16">{'パスワード'}</p>
                    <p className="mb-0 sub-content">{'●●●●●●'}</p>
                </Col>

                <Col className="btn-setting">
                    <Button
                        className="fw-b btn-setting-responsive"
                        type="primary"
                        onClick={() => history.push(Routes.private.CLIENT_ACCOUNT_UPDATE_PASSWORD.path)}
                    >
                        {'編集'}
                    </Button>
                </Col>
            </Row>
            <div className="account-with-btn-right">
                <Button
                    onClick={() => {
                        showLogOutModal();
                    }}
                    className="mt-60 fw-b btn-account"
                >
                    {'ログアウト'}
                </Button>
            </div>
            <div className="account-with-btn-right">
                <Button
                    className="mt-80 fw-b pink-color btn-account-delete-btn"
                    icon={<DeleteIcon />}
                    onClick={() => history.push(Routes.private.CLIENT_ACCOUNT_DELETION.path)}
                >
                    {t('Account deletion')}
                </Button>
            </div>
            <LogOutModal onClose={closeModal} onUpdate={logout} visible={modalVisible.logOut} />
        </div>
    );
};

export default connect(mapState, mapDispatch)(Account);

Account.propTypes = {
    account: PropTypes.any,
    logout: PropTypes.func,
    setUser: PropTypes.func,
};
