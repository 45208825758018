import React from 'react';
import { CloseIcon } from 'components/SvgIcon';
import { Modal } from 'antd';
import QuestionnairePreview from 'pages/admin/interview_sheet/preview/QuestionnairePreview';
import { useTranslation } from 'react-i18next';

const InterviewSheetPreviewModal = ({ visible, onClose, data }) => {
    const { t } = useTranslation();

    return (
        <Modal
            title={t('Preview')}
            visible={visible}
            width={823}
            footer={null}
            closeIcon={<CloseIcon />}
            onCancel={() => onClose()}
            bodyStyle={{ padding: 0 }}
        >
            <div className="render-page-container pt-24 pl-32 pb-24 pr-32 white-space-pre-line">
                <QuestionnairePreview data={data} />
            </div>
        </Modal>
    );
};

export default InterviewSheetPreviewModal;
