import React from 'react';
import { Button, Modal } from 'antd';
import { CloseIcon } from 'components/SvgIcon';
import { useTranslation } from 'react-i18next';

const DeleteEmailModal = ({ visible, onClose, onUpdate, record }) => {
    const { t } = useTranslation();
    if (!record) return <></>;

    return (
        <>
            <Modal
                title={t('Delete')}
                visible={visible}
                width={320}
                height={269}
                footer={null}
                closeIcon={<CloseIcon />}
                onCancel={() => onClose()}
            >
                <div>
                    <div className="fw-b d-inline-flex">{`“${record.title || ''}”`}</div>
                    <div className="mt-24">{'この操作は元に戻せません。削除してよろしいですか？'}</div>
                </div>

                <>
                    <div className="delete-action-panel">
                        <Button
                            type=""
                            className={'delete-button fw-b'}
                            onClick={() => {
                                onUpdate && onUpdate();
                                onClose();
                            }}
                        >
                            <span className={'title-button'}>{t('Delete')}</span>
                        </Button>
                        <Button
                            type=""
                            className="cancel-button fw-b disable-color"
                            onClick={() => {
                                onClose();
                            }}
                        >
                            <span className={'title-button'}>{t('Cancel')}</span>
                        </Button>
                    </div>
                </>
            </Modal>
        </>
    );
};

export default DeleteEmailModal;
