import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Menu } from 'antd';
import {
    duplicatePageElement,
    movePageElementDown,
    movePageElementUp,
    removePageElement,
} from 'pages/admin/common/page-elements/page_elements.slice';

const mapDispatch = { movePageElementUp, movePageElementDown, duplicatePageElement, removePageElement };

const ElementActions = ({ id, movePageElementUp, movePageElementDown, duplicatePageElement, removePageElement }) => {
    const { t } = useTranslation();

    const menu = (
        <Menu
            onClick={({ key }) => {
                switch (key) {
                    case 'up':
                        movePageElementUp({ id });
                        break;
                    case 'down':
                        movePageElementDown({ id });
                        break;
                    case 'duplicate':
                        duplicatePageElement({ id });
                        break;
                    case 'delete':
                        removePageElement({ id });
                        break;
                }
            }}
        >
            <Menu.Item key="up">{t('Move up')}</Menu.Item>
            <Menu.Item key="down">{t('Move down')}</Menu.Item>
            <Menu.Item key="duplicate">{t('Duplicate')}</Menu.Item>
            <Menu.Item key="delete">{t('Delete')}</Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <Button className="fw-b pl-24 pr-24 element-action-btn">{t('Action')}</Button>
        </Dropdown>
    );
};

export default connect(null, mapDispatch)(ElementActions);

ElementActions.propTypes = {
    id: PropTypes.any.isRequired,
    movePageElementUp: PropTypes.func.isRequired,
    movePageElementDown: PropTypes.func.isRequired,
    duplicatePageElement: PropTypes.func.isRequired,
    removePageElement: PropTypes.func.isRequired,
};
