import React from 'react';
import { useTranslation } from 'react-i18next';
import history from 'helpers/history.helper';
import TabList from 'components/TabList';
import Routes from 'routes';

const EmailTabList = ({ tab }) => {
    const { t } = useTranslation();

    const tabList = [
        {
            key: 'delivery',
            title: t('Delivery list'),
        },
        {
            key: 'distribution',
            title: t('Distribution list'),
        },
    ];

    const handlerTabChange = key => {
        history.push(
            key === 'distribution' ? Routes.private.ADMIN_EMAIL_DISTRIBUTION.path : Routes.private.ADMIN_EMAIL.path
        );
    };

    return (
        <TabList
            tabs={tabList}
            onChange={handlerTabChange}
            activeKey={tab}
            animated={false}
            className="interview-sheet-tab-list tab-with-indent bg-white"
        />
    );
};

export default EmailTabList;
