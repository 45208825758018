import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import { CloseIcon } from 'components/SvgIcon';
import DeleteBtnInModal from 'components/DeleteBtnInModal';
import { useTranslation } from 'react-i18next';

const TermsOfUseModal = ({ visible, onClose, onUpdate, form, setAgreeTerm }) => {
    const { t } = useTranslation();
    return (
        <>
            <div className="terms-of-use-modal">
                <Modal
                    title={'利用規約'}
                    visible={visible}
                    width={900}
                    bodyStyle={{ maxHeight: '600px' }}
                    className="terms-of-use-wrapper"
                    footer={null}
                    closeIcon={<CloseIcon />}
                    onCancel={() => onClose()}
                >
                    <div className="terms-of-use-content">
                        <p className="fw-b text-align-center">{'TEAM DOCTORS利用条件'}</p>
                        <p>
                            {
                                '株式会社Bynds（以下「当社」といいます。）が定める以下の条件を承諾の上、当社が提供するTEAM DOCTORSのWebサイト、アプリ及び各種プログラム（以下「本サービス」と総称します。）をご利用下さい。 '
                            }
                        </p>
                        <ol>
                            <li>
                                <span className="fw-b">{'本サービスの利用条件等'}</span>
                                <p>
                                    {
                                        '（１）ご利用者（以下「ユーザー」といいます。）は、ユーザーごとのID・パスワードをご自身で管理するものとし、第三者に貸与、譲渡等することはできません。 '
                                    }
                                    <br />
                                    {
                                        '（２）本サービスを利用できる期間は、ユーザーが所属する企業等（以下「所属企業等」といいます。）と当社との契約期間中です。 '
                                    }
                                    <br />
                                    {
                                        '（３）本サービスにおける相談等のプログラム（以下「各種プログラム」といいます。）は、当社と提携するカウンセラー、アドバイザー、医師等の専門家により提供されます。 '
                                    }
                                    <br />
                                    {
                                        '（４）各種プログラムにおいては、原則として、治療その他の医療行為は行いません。ただし、医師が提供するプログラムにおいては、受診勧奨等を目的とした診断をすることがあります。 '
                                    }
                                    <br />
                                    {
                                        '（５）ユーザーは、各種プログラムの録画・録音をすることはできません。なお、当社は、本サービスの改善等のために、各種プログラムを録画・録音することがあります。'
                                    }
                                    <br />
                                    {
                                        '（６）ユーザーは、各種プログラムにおいて提供された情報の不特定もしくは特定多数の第三者への開示、または、有償・無償の事業等への転用をすることはできません。'
                                    }
                                    <br />
                                    {
                                        '（７）ユーザーは、本サービスの運営に支障をきたすおそれのある行為、当社・所属企業等・他のユーザー・その他の第三者の権利を侵害するおそれのある行為、法令・公序良俗に反する行為を行わないものとします。'
                                    }
                                    <br />
                                    {
                                        '（８）本サービスに関する知的財産権は、当社または当社が指定する第三者に帰属します。'
                                    }
                                </p>
                            </li>
                            <li>
                                <span className="fw-b">{'個人情報の取扱 '}</span>
                                <p>
                                    {
                                        '（１）当社は、本サービスの提供に伴い取得するユーザーの個人情報を、個人情報の保護に関する法律等の関連法令及び当社のプライバシーポリシーにしたがって、適切に取り扱います。 '
                                    }
                                    <br />
                                    {'（２）当社は、ユーザーの個人情報を次の目的のために利用します。 '}
                                    <ol type="a">
                                        <li>{'各種プログラムの提供 '}</li>
                                        <li>{'本サービスに関する問い合わせ対応 '}</li>
                                        <li>{'ユーザーの情報の分析 '}</li>
                                        <li>
                                            {
                                                'その他本サービス（当社及び関連会社の関連サービスを含みます。）の開発、運営、改善、情報提供 '
                                            }
                                        </li>
                                        <li>{'上記の他、プライバシーポリシーに定める目的 '}</li>
                                    </ol>
                                    {
                                        '（３）当社は、各種プログラムの運営のために、ユーザーの個人情報を、提携する専門家に提供します。 '
                                    }
                                    <br />
                                    {
                                        '（４）当社は、ユーザーの明示的な同意がない限り、本サービスの提供に伴いユーザーから取得した個人情報を所属先企業等に提供しません。ただし、当社は、所属先企業等に対して、セミナー等の複数のユーザーを対象とした各種プログラムへの参加履歴を報告する場合、または、ユーザーが上記1(6)に該当する行為を行った場合、その他本利用条件に違反した場合において、対応を求めるときには、所属先企業等に当該個人情報を提供することがあります。 '
                                    }
                                    <br />
                                    {
                                        '（５）当社は、ユーザーの個人情報を基に、特定のユーザーを識別できない形式に加工した上で、所属先企業、その他の第三者に対し、提供することがあります。 '
                                    }
                                </p>
                            </li>
                            <li>
                                <span className="fw-b">{'提供条件の変更等 '}</span>
                                <p>
                                    {
                                        '（１）本サービスの提供条件は、社会・経済情勢、技術環境や経営環境の変化等に伴う本サービスの合理化、改善、その他の技術上・運用上の事情により、必要に応じて、ユーザーに通知の上、変更されることがあります。 '
                                    }
                                    <br />
                                    {
                                        '（２）本サービスの提供条件の変更後も本サービスの利用を継続する場合は、当該変更を承諾したものとします。 '
                                    }
                                    <br />
                                    {
                                        '（３）当社からユーザーへの通知は、TEAM DOCTORSのWebサイトもしくはアプリへの掲載、電子メール等の送信により行い、当該掲載または送信の時点で通知が完了したものとします。 '
                                    }
                                    <br />
                                    {
                                        '（４）本サービスの提供は、メンテナンス、その他運営上の事情により、一時的に中断し、または、終了することがあります。 '
                                    }
                                    <br />
                                    {
                                        '（５）当社は、本サービスの利用結果、プログラムにおいて提供される情報の正確性、本サービスの提供の中断・終了・条件変更等について、ユーザーに対する責任を負いません。 '
                                    }
                                    <br />
                                    {
                                        '（６）ユーザーが本条件に違反した場合、本サービスの利用をお断りすることがあります。 '
                                    }
                                </p>
                            </li>
                        </ol>
                    </div>
                    <div className="text-align-center pb-32 pt-12">
                        <Button
                            type=""
                            className="delete-button primary-color fw-b"
                            onClick={() => {
                                onUpdate && onUpdate();
                                onClose();
                            }}
                        >
                            <span className="title-button">{t('Agree and use')}</span>
                        </Button>
                        <Button
                            type=""
                            className="cancel-button fw-b"
                            onClick={() => {
                                form.setFieldsValue({ agree_term: false });
                                setAgreeTerm(false);
                                onClose();
                            }}
                        >
                            <span className="title-button disable-color">{t('Disagree')}</span>
                        </Button>
                    </div>
                </Modal>
            </div>
        </>
    );
};

TermsOfUseModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

export default TermsOfUseModal;
