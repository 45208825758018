import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { enWeekDayNames, fullDateFormatExceptHour } from 'helpers/date.helper';
import TdtCalendarContextMenu from 'components/calendar/TdtCalendarContextMenu';
import { CloseIcon } from 'components/SvgIcon';
import { Modal } from 'antd';

const weekDayNames = moment.weekdaysMin();

const checkDateBlocked = (blockedTimes, activeTimes) => {
    if (!activeTimes) return false;
    if (!blockedTimes) return false;
    if (blockedTimes.length === 24) return true;
    for (const time of activeTimes) {
        if (blockedTimes.indexOf(time) === -1) return false;
    }
    return true;
};

const TdtCalendarMonth = ({
    startDate = moment(),
    events = {},
    active = {},
    blocked = {},
    disableDate,
    createMenu = null,
    onEventClick = null,
}) => {
    const { t } = useTranslation();
    const startMonth = startDate.clone().startOf('month');
    const weekStartDate = startMonth.clone().startOf('week');
    const endMonth = startDate.clone().endOf('month');
    const weekEndDate = endMonth.clone().endOf('week');
    const dateCount = weekEndDate.diff(weekStartDate, 'day') + 1;
    const today = moment();

    const [modalVisible, setModalVisible] = useState(false);
    const [modalData, setModalData] = useState({ date: null, events: null });

    let weeks = [];
    let week;

    for (let i = 0; i < dateCount; i++) {
        const date = weekStartDate.clone().add(i, 'day');
        if (i % 7 === 0) {
            week = {
                no: date.week(),
                dates: [],
            };
            weeks.push(week);
        }

        week.dates.push({
            full: date.format('YYYY-MM-DD'),
            date: date.format('D'),
            isBlank: date.isBefore(startMonth) || date.isAfter(endMonth),
        });
    }

    return (
        <>
            <table className="tdt-calendar tdt-calendar-month">
                <thead>
                    <tr>
                        <th>{weekDayNames[0]}</th>
                        <th>{weekDayNames[1]}</th>
                        <th>{weekDayNames[2]}</th>
                        <th>{weekDayNames[3]}</th>
                        <th>{weekDayNames[4]}</th>
                        <th>{weekDayNames[5]}</th>
                        <th>{weekDayNames[6]}</th>
                    </tr>
                </thead>
                <tbody>
                    {weeks.map(week => {
                        return (
                            <tr key={week.no}>
                                {week.dates.map((date, index) => {
                                    if (date.isBlank) {
                                        return <td className="blank" key={date.full} />;
                                    }
                                    const enWeekDay = enWeekDayNames[index];
                                    let isActive;
                                    if (disableDate && disableDate(date.full)) isActive = false;
                                    else isActive = !!active[enWeekDay];
                                    const isCurrent = today.isSame(date.full, 'day');
                                    const isBlocked = checkDateBlocked(blocked[date.full], active[enWeekDay]);
                                    let className = '';
                                    if (isBlocked) className += 'blocked ';
                                    if (isCurrent) className += 'current ';
                                    if (isActive) className += 'active ';
                                    else className += 'disabled ';
                                    let content = (
                                        <>
                                            <div className="date">{date.date}</div>
                                            <DateEvent
                                                dateEvents={events[date.full]}
                                                onEventClick={onEventClick}
                                                onMoreClick={() => {
                                                    setModalData({ date: date.full, events: events[date.full] });
                                                    setModalVisible(true);
                                                }}
                                            />
                                        </>
                                    );
                                    let menu;
                                    if (createMenu && isActive) {
                                        menu = (
                                            <TdtCalendarContextMenu
                                                createMenu={createMenu}
                                                date={date.full}
                                                time={null}
                                                isActive={isActive}
                                                isBlocked={isBlocked}
                                                body={<div className="dropdown-clickable">{content}</div>}
                                            />
                                        );
                                    }

                                    return (
                                        <td className={className} key={date.full}>
                                            {menu ? menu : content}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <MoreEventModal
                visible={modalVisible}
                setVisible={setModalVisible}
                date={modalData.date}
                events={modalData.events}
                onEventClick={onEventClick}
            />
        </>
    );
};

const DateEvent = ({ dateEvents, onEventClick, onMoreClick }) => {
    const { t } = useTranslation();
    if (!dateEvents || dateEvents.length === 0) return null;
    return (
        <>
            <div className="events">
                {dateEvents.slice(0, 2).map(event => {
                    return (
                        <div
                            key={event.id}
                            className={`event ${event.status} ${event.deleted_by_id ? 'calendar-cell-deleted' : ''}`}
                            onClick={e => {
                                e.stopPropagation();
                                onEventClick(event);
                            }}
                        >
                            <span className="time">{event.start}</span>
                            <span className="title">{event.title}</span>
                        </div>
                    );
                })}
            </div>
            {dateEvents.length > 2 && (
                <div
                    className="show-more"
                    onClick={e => {
                        e.stopPropagation();
                        onMoreClick();
                    }}
                >
                    {t('{{num}} more items', { num: dateEvents.length - 2 })}
                </div>
            )}
        </>
    );
};

const MoreEventModal = ({ visible, setVisible, date, events, onEventClick }) => {
    if (!events || !date) return null;
    return (
        <Modal
            className="calendar-more-modal"
            title={fullDateFormatExceptHour(date)}
            visible={visible}
            width={320}
            footer={null}
            closeIcon={<CloseIcon />}
            onCancel={() => setVisible(false)}
        >
            {events.map(event => (
                <div key={event.id} className="event" onClick={() => onEventClick(event)}>
                    <span className={`eclipse-status eclipse-status-${event.colorCode} mr-10`} />
                    <span className="event-title">{event.title}</span>
                    <span className="event-subtitle ml-8">{event.subtitle}</span>
                </div>
            ))}
        </Modal>
    );
};

export default TdtCalendarMonth;
