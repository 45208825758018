import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'antd';
import DataTable from 'components/DataTable';
import BackBtn from 'components/BackBtn';
import history from 'helpers/history.helper';
import CompanyDetailRoutes from 'pages/admin/company/company_detail_routes';
import FloatBar from 'components/FloatBar';
import { getData, setFilters, setSorter } from 'pages/admin/company/tabs/message/message.slice';
import { dateToStr } from 'helpers/date.helper';
import Routes from 'routes';
import { truncateStr } from 'helpers/string.helper';
import CompanyStateEnum from 'enums/company_state.enum';
import CustomDropdown from 'components/CustomDropdown';
import { useTranslation } from 'react-i18next';

const mapState = state => ({
    state: state.admin.companyDetailMessage,
});
const mapDispatch = { getData, setSorter, setFilters };

const MessageContainer = ({ company, renderTabList, baseUrl, state, getData, setSorter }) => {
    const { t } = useTranslation();

    const [sttSendMsg, setSttSendMsg] = useState(false);
    const columns = [
        {
            title: '送信日時',
            dataIndex: 'created_at',
            width: 120,
            render: d => <span className="text-small high-color">{dateToStr(d)}</span>,
            sorter: (a, b) => {
                return a.created_at.localeCompare(b.created_at);
            },
        },
        {
            title: '内容',
            dataIndex: 'content',
            //width: 658,
            render: (_, r) => {
                return (
                    <div
                        onClick={() =>
                            history.push(
                                `${baseUrl}${CompanyDetailRoutes.MESSAGE_DETAIL.path.replace(':messageId', r.id)}`
                            )
                        }
                    >
                        <div className="fw-b high-color">{r.title}</div>
                        <div className="text-small">{truncateStr(r.content, 70)}</div>
                    </div>
                );
            },
            sorter: (a, b) => {
                return a.content.localeCompare(b.content);
            },
        },
        {
            title: '種類',
            dataIndex: 'type',
            width: 120,
            sorter: true,
            render: type =>
                type === 'session' ? 'セッション' : type === 'program' ? '新着プログラム' : 'フリーテキスト',
        },
    ];

    const getMessages = (pagination = state.pagination, filters = state.filters, sorter = state.sorter) => {
        if (!sorter || !sorter.field) {
            sorter = state.sorter;
        }
        setSorter(sorter);
        getData({ pagination, filters: { ...filters, company_id: company.id }, sorter });
    };

    useEffect(() => {
        getMessages();
    }, [company.id]);

    useEffect(() => {
        if (company.total_employee > 0 && company.state !== CompanyStateEnum.END.value) {
            setSttSendMsg(true);
        }
    }, [company.total_employee, company.state]);

    return (
        <>
            <div className="px-24 pb-12">
                <BackBtn label={company.name} action={() => history.push(Routes.private.ADMIN_COMPANY.path)} />
            </div>
            {renderTabList('message')}

            <div className="p-0">
                <p>
                    {/*{company.name}*/}
                    {'登録されている全社員に一括でメッセージを送信します。'}
                </p>

                <div className="mt-16">
                    <DataTable
                        className="collapse-table"
                        columns={columns}
                        hideColumns={state.hideColumns}
                        data={state.data}
                        loading={state.loading}
                        pagination={state.pagination}
                        filters={state.filters}
                        sorter={state.sorter}
                        onTableChange={(pagination, filter, sorter) => {
                            getMessages(pagination, state.filters, sorter);
                        }}
                        noDataText={'登録されているメッセージはいません'}
                    />

                    <FloatBar>
                        <div className="float-bar-company">
                            <div className="title-total-employee">{'該当人数'}</div>
                            <div className="number-total-employee">{`${company.total_employee} 人`}</div>
                            {/*<Button*/}
                            {/*    className="fw-b"*/}
                            {/*    disabled={!sttSendMsg}*/}
                            {/*    onClick={() => history.push(`${baseUrl}${CompanyDetailRoutes.MESSAGE_CREATE.path}`)}*/}
                            {/*>*/}
                            {/*    {'一括送信メッセージを作成'}*/}
                            {/*</Button>*/}
                            <CustomDropdown
                                trigger={['click']}
                                options={[
                                    {
                                        key: 'free_text',
                                        text: 'フリーテキストを送信',
                                    },
                                    {
                                        key: 'new_program',
                                        text: '新着プログラムを送信',
                                    },
                                    {
                                        key: 'new_session',
                                        text: 'セッションを送信',
                                    },
                                ]}
                                onSelect={opt => {
                                    if (opt.key === 'new_program') {
                                        history.push(`${baseUrl}${CompanyDetailRoutes.MESSAGE_NEW_PROGRAM.path}`);
                                    } else if (opt.key === 'new_session') {
                                        history.push(`${baseUrl}${CompanyDetailRoutes.MESSAGE_NEW_SESSION.path}`);
                                    } else {
                                        history.push(`${baseUrl}${CompanyDetailRoutes.MESSAGE_CREATE.path}`);
                                    }
                                }}
                                separator
                                placement="topCenter"
                                getPopupContainer={trigger => trigger.parentNode}
                            >
                                <Button className="fw-b" onClick={() => {}} disabled={!sttSendMsg}>
                                    {t('Manually send notifications')}
                                </Button>
                            </CustomDropdown>
                        </div>
                    </FloatBar>
                </div>
            </div>
        </>
    );
};

export default connect(mapState, mapDispatch)(MessageContainer);

MessageContainer.propTypes = {
    company: PropTypes.any,
    renderTabList: PropTypes.func,
    baseUrl: PropTypes.string,
    state: PropTypes.any,
    getData: PropTypes.func.isRequired,
    setSorter: PropTypes.func.isRequired,
};
