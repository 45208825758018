import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Input } from 'antd';
import { CloseIcon } from 'components/SvgIcon';
import { useTranslation } from 'react-i18next';
import ActionBtnInModal from 'components/ActionBtnInModal';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import { showNormal } from 'helpers/notification.helper';
import history from 'helpers/history.helper';
import Routes from 'routes';

const CloneInterviewSheetModal = ({ visible, onClose, interviewSheet }) => {
    const { t } = useTranslation();
    const [inputValue, setInputValue] = useState(`${interviewSheet.name}_コピー`);
    const cloneInterviewSheet = async interviewSheetId => {
        const response = await api.post(APP_URLS.ADMIN_INTERVIEW_SHEETS_CLONE.replace(':id', interviewSheetId), { name: inputValue });

        if (response) {
            history.push(Routes.private.ADMIN_INTERVIEW_SHEETS_DETAIL.path.replace(':id', response.id));
            showNormal('', t('message:Duplicated interview sheet'), 3);
        }
    };

    if (!interviewSheet) { return <></> }

    return (
        <>
            <Modal
                title={t('field:複製')}
                visible={visible}
                width={320}
                footer={null}
                closeIcon={<CloseIcon />}
                onCancel={() => onClose()}
            >
                <div className='mb-8'>
                    <div className='fw-b d-inline-flex'>{`“${interviewSheet.name}”`}</div>
                    を複製します。よろしいですか？
                    <div className='mt-16 fw-700 fs-14 text-gray-color'>{'名称'}</div>
                    <Input
                        className='mt-4'
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        style={{ borderRadius: 4, borderColor: '#D1D0D6', backgroundColor: '#FFFFFF' }}
                    />
                </div>

                <ActionBtnInModal
                    onClose={onClose}
                    onUpdate={() => {
                        cloneInterviewSheet(interviewSheet.id);
                    }}
                    confirmText={'複製'}
                    isNotDelete={true}
                />
            </Modal>
        </>
    );
};

CloneInterviewSheetModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default CloneInterviewSheetModal;
