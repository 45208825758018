import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const SidebarItem = ({ isActive = false, icon: Icon, text, badge, onClick, isTranslate = true, className = '' }) => {
    const { t } = useTranslation();
    return (
        <li className={`navigation-item ${className} ${isActive ? 'active' : ''}`} onClick={onClick}>
            <Icon size={24} className="mr-8" />
            {isTranslate ? t(text) : text}
            {badge !== null && typeof badge !== 'undefined' && badge.toString() !== '0' && (
                <div className="badge">{badge}</div>
            )}
        </li>
    );
};

export default SidebarItem;

SidebarItem.propTypes = {
    isActive: PropTypes.bool,
    icon: PropTypes.any.isRequired,
    text: PropTypes.string.isRequired,
    badge: PropTypes.any,
    onClick: PropTypes.func,
    className: PropTypes.string,
};
