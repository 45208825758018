import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { baseExtraReducers, baseGetData, baseInitialState, baseReducers } from 'helpers/slice.helper';
import { APP_URLS } from 'constants/url.constant';

const name = 'program';

export const getData = createAsyncThunk(
    `${name}/getData`,
    async props => await baseGetData({ url: APP_URLS.ADMIN_PROGRAM, ...props })
);

export const defaultFilters = {};

const programSlice = createSlice({
    name,
    initialState: {
        ...baseInitialState,
        filters: defaultFilters,
        sorter: {
            field: 'display_order',
            order: 'descend',
        },
    },
    reducers: baseReducers,
    extraReducers: baseExtraReducers(getData),
});

export const { setLoading, setData, setPagination, setFilters, setSorter, setHideColumns, setSelection } =
    programSlice.actions;

export default programSlice.reducer;
