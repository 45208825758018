import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'antd';
import { CloseIcon } from 'components/SvgIcon';
import DeleteBtnInModal from 'components/DeleteBtnInModal';

const DeleteReservationModal = ({ visible, onClose, onUpdate }) => {
    const { t } = useTranslation();

    return (
        <Modal
            title={'予約削除'}
            visible={visible}
            width={400}
            footer={null}
            closeIcon={<CloseIcon />}
            onCancel={() => onClose()}
        >
            <p>{'予約を削除します。この操作はやり直しできません。よろしいですか？'}</p>
            <DeleteBtnInModal onClose={onClose} onUpdate={onUpdate} />
        </Modal>
    );
};

DeleteReservationModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

export default DeleteReservationModal;
