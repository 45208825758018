import React from 'react';
import moment from 'moment';
import { Dropdown } from 'antd';
import { enWeekDayNames } from 'helpers/date.helper';
import TdtCalendarContextMenu from 'components/calendar/TdtCalendarContextMenu';
import { checkBusySlot } from 'helpers/calendar.helper';

const times = [...Array(24).keys()].map(num => (num < 10 ? `0${num}` : num));
const weekDayNames = moment.weekdaysMin();
const startOfDay = moment('00:00', 'HH:mm');
const blockHeight = 48;

const TdtCalendarWeek = ({
    startDate = moment(),
    events = {},
    blocked = {},
    active = {},
    disableDate,
    createMenu = null,
    onEventClick = null,
    busySlots,
}) => {
    const startWeek = startDate.clone().startOf('week');
    const today = moment();
    let days = [];
    for (let i = 0; i < 7; i++) {
        const tmpDate = startWeek.clone().add(i, 'day');
        days.push({
            full: tmpDate.format('YYYY-MM-DD'),
            date: tmpDate.format('D'),
            isCurrent: tmpDate.isSame(today, 'day'),
        });
    }

    return (
        <>
            <div className="calendar-week-header">
                <div className="time" />
                {days.map((day, index) => {
                    return (
                        <div key={day.full} className="column">
                            <div className="week-day">{weekDayNames[index]}</div>
                            <div className={`date ${day.isCurrent ? 'current' : ''}`}>{day.date}</div>
                        </div>
                    );
                })}
            </div>
            <div className="tdt-calendar-week-wrapper">
                <table className="tdt-calendar tdt-calendar-week">
                    <thead>
                        <tr>
                            <th className="time" />
                            {days.map(day => {
                                return (
                                    <th key={day.full}>
                                        {events[day.full] && (
                                            <TdtCalendarWeekEvents
                                                events={events[day.full]}
                                                onEventClick={onEventClick}
                                            />
                                        )}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {times.map(time => {
                            return (
                                <tr key={time} id={time === '08' ? 'anchor-time' : undefined}>
                                    <td className="time">{parseInt(time)}:00</td>
                                    {days.map((day, index) => {
                                        const enWeekDay = enWeekDayNames[index];
                                        const timeStr = `${time}:00`;
                                        let isActive;
                                        if (disableDate && disableDate(day.full)) isActive = false;
                                        else isActive = active[enWeekDay] && active[enWeekDay].indexOf(timeStr) > -1;
                                        const isBlocked = blocked[day.full] && blocked[day.full].indexOf(timeStr) > -1;

                                        let className = '';
                                        if (isBlocked) className += 'blocked ';
                                        if (isActive) className += 'active ';
                                        else className += 'disabled ';

                                        // blocked by busy slots
                                        if (isActive && checkBusySlot(busySlots, day.full, timeStr)) {
                                            // not own doctor
                                            if (Object.keys(blocked).length === 0) {
                                                className += 'blocked ';
                                                isActive = false;
                                            }
                                        }

                                        return (
                                            <td key={day.full} className={className}>
                                                <TdtCalendarContextMenu
                                                    createMenu={createMenu}
                                                    date={day.full}
                                                    time={timeStr}
                                                    isActive={isActive}
                                                    isBlocked={isBlocked}
                                                />
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
};

const TdtCalendarWeekEvents = ({ events, onEventClick }) => {
    let sorted = [...events];
    sorted.sort((e1, e2) => {
        if (e1.start < e2.start) {
            return -1;
        } else if (e1.start > e2.start) {
            return 1;
        } else {
            return e1.end > e2.end ? -1 : 1;
        }
    });
    let offset = {};
    let newEvents = [];
    for (const orgEvent of sorted) {
        let event = { ...orgEvent };
        const start = moment(event.start, 'HH:mm');
        const end = moment(event.end, 'HH:mm');
        event.startBlock = Math.ceil(start.diff(startOfDay, 'minute') / 30);
        if (event.startBlock < 0) continue;
        event.lengthBlock = end.diff(start, 'minute') / 30;
        event.height = event.lengthBlock * blockHeight;
        event.top = event.startBlock * blockHeight;
        if (event.startBlock % 2 === 0) {
            event.top += 4;
            if (event.lengthBlock % 2 === 0) {
                event.height -= 8;
            } else {
                event.height -= 6;
            }
        } else {
            event.top += 2;
            if (event.lengthBlock % 2 === 0) {
                event.height -= 4;
            } else {
                event.height -= 6;
            }
        }
        event.offset = offset[event.startBlock + 1] || 0;
        for (let i = 1; i <= event.lengthBlock; i++) {
            const index = event.startBlock + i;
            if (typeof offset[index] === 'undefined') {
                offset[index] = 1;
            } else {
                offset[index]++;
            }
        }
        newEvents.push(event);
    }
    return (
        <div className="event-wrapper">
            {newEvents.map(event => {
                return (
                    <div
                        key={event.id}
                        className={`event ${event.status} ${event.deleted_by_id ? 'calendar-cell-deleted' : ''}`}
                        onClick={e => {
                            e.stopPropagation();
                            onEventClick && onEventClick(event);
                        }}
                        style={{
                            top: `${event.top}px`,
                            height: `${event.height}px`,
                            left: `${event.offset * 16 + 4}px`,
                        }}
                    >
                        {event.subtitle && <span className="subtitle">{event.subtitle}</span>}
                        <span className="title">{event.title}</span>
                    </div>
                );
            })}
        </div>
    );
};

export default TdtCalendarWeek;
