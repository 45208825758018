import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import i18n from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/ja';
import history from 'helpers/history.helper';
import ja from 'locales/ja';
import Login from 'pages/auth/Login';
import MainLayout from 'layouts/MainLayout';
import Register from 'pages/auth/register/Register';
import RegisterComplete from 'pages/auth/register/RegisterComplete';
import RegisterStep2 from 'pages/auth/register/RegisterStep2';
import RegisterEmailVerify from 'pages/auth/register/RegisterEmailVerify';
import PasswordReset from 'pages/auth/password_reset/PasswordReset';
import PasswordResetSent from 'pages/auth/password_reset/PasswordResetSent';
import PasswordResetChange from 'pages/auth/password_reset/PasswordResetChange';
import { checkAuth } from 'pages/auth/auth.slice';
import api from 'helpers/api.helper';
import Loader from 'components/Loader';
import GaTracker from 'GaTracker';
import AccountDeleted from 'pages/auth/AccountDeleted';
import Questionnaire from 'pages/questionnaire/Questionnaire';

moment.locale('ja');

i18n.use(initReactI18next).init({
    resources: { ja },
    ns: ['translation', 'entity', 'enum', 'validation', 'field', 'message'],
    defaultNS: 'translation',
    lng: 'ja',
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    },
    nsSeparator: ':',
    keySeparator: '#',
});

const mapState = state => ({
    loading: state.core.loading,
});
const mapDispatch = { checkAuth };
const App = ({ loading, checkAuth }) => {
    const { t } = useTranslation();

    useEffect(() => {
        checkAuth();
        api.message = {
            error: t('Error'),
            serverError: t('Server error'),
            applicationError: t('Application error'),
            message: t('Error occurred please contact administrator'),
        };
    }, []);

    if (loading) {
        return <Loader className="loader-center" />;
    }

    return (
        <Router history={history}>
            <GaTracker />
            <Switch>
                <Route path="/login">
                    <Login />
                </Route>
                <Route path="/register/complete">
                    <RegisterComplete />
                </Route>
                <Route path="/register/step2">
                    <RegisterStep2 />
                </Route>
                <Route path="/register">
                    <Register />
                </Route>
                <Route path="/email/verify">
                    <RegisterEmailVerify />
                </Route>
                <Route path="/password-reset/change">
                    <PasswordResetChange />
                </Route>
                <Route path="/password-reset/complete">
                    <PasswordResetSent />
                </Route>
                <Route path="/password-reset">
                    <PasswordReset />
                </Route>
                <Route path="/counselors/setting-password">
                    <PasswordResetChange isCounselor={true} />
                </Route>
                <Route path="/account-deleted">
                    <AccountDeleted />
                </Route>
                <Route path="/questionnaire/:uuid">
                    <Questionnaire />
                </Route>
                <Route path="*">
                    <MainLayout />
                </Route>
            </Switch>
        </Router>
    );
};

export default connect(mapState, mapDispatch)(App);

App.propTypes = {
    loading: PropTypes.bool.isRequired,
    checkAuth: PropTypes.func.isRequired,
};
