import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'antd';
import DataTable from 'components/DataTable';
import { AddIcon, DeleteIcon, EditIcon } from 'components/SvgIcon';
import BackBtn from 'components/BackBtn';
import FloatBar from 'components/FloatBar';
import { getData, setFilters, setSorter } from 'pages/admin/company/tabs/staff/staff.slice';
import CustomDropdown from 'components/CustomDropdown';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import history from 'helpers/history.helper';
import Routes from 'routes';
import { useTranslation } from 'react-i18next';
import DeleteStaffModal from 'pages/admin/company/tabs/staff/DeleteStaffModal';
import { showNormal } from 'helpers/notification.helper';

const mapState = state => ({
    state: state.admin.companyDetailStaff,
});
const mapDispatch = { getData, setSorter, setFilters };

const StaffContainer = ({ company, renderTabList, state, getData, setSorter }) => {
    const { t } = useTranslation();
    const [modalVisible, setModalVisible] = useState({
        delete: false,
        id: null,
    });
    const showDeleteModal = id => setModalVisible({ delete: true, id });
    const closeModal = () => setModalVisible({ delete: false, id: null });

    const columns = [
        {
            title: '名称',
            dataIndex: 'name',
            width: 518,
            render: (_, r) => <span>{`${r.full_name}`}</span>,
            sorter: (a, b) => {
                return a.first_name.localeCompare(b.first_name);
            },
        },
        {
            title: '担当企業数',
            dataIndex: 'total', // number_of_companies_in_charge
            className: 'text-align-right pr-24',
            width: 120,
            render: (_, r) => <span>{r.total}</span>,
        },
        {
            title: t('Edit'),
            dataIndex: 'EDIT',
            // className: 'text-align-center',
            width: 60,
            render: (_, r) => {
                return (
                    <CustomDropdown
                        trigger={['click']}
                        options={[
                            {
                                key: 'delete',
                                text: (
                                    <>
                                        <DeleteIcon /> <span>{t('Delete staff')}</span>
                                    </>
                                ),
                                className: 'pink-color fw-b',
                            },
                        ]}
                        onSelect={opt => {
                            // const pc = r.program_companies[0];
                            if (opt.key === 'delete') {
                                showDeleteModal(r.id);
                            }
                        }}
                        separator
                    >
                        <EditIcon className="primary-color custom-icon c-pointer" />
                    </CustomDropdown>
                );
            },
        },
    ];

    const getStaffs = (pagination = state.pagination, filters = state.filters, sorter = state.sorter) => {
        setSorter(sorter);
        getData({ id: company.id, pagination, filters, sorter });
    };

    const [counselors, setCounselors] = useState([]);
    const getCounselors = async () => {
        const res = await api.get(APP_URLS.ADMIN_COMPANIES_DETAIL_STAFFS_COUNSELORS.replace(':id', company.id));
        if (res && res.data && res.data.length > 0) {
            setCounselors(res.data);
        }
    };
    const addCounselorCompany = async counselorId => {
        const res = await api.post(APP_URLS.ADMIN_COMPANIES_DETAIL_STAFFS_ADD_COUNSELOR.replace(':id', company.id), {
            counselor_id: counselorId,
        });
        if (res) {
            setCounselors([...counselors].filter(c => c.id !== Number(counselorId)));
            getStaffs();
        }
    };
    const deleteStaff = async staffId => {
        const res = await api.delete(
            APP_URLS.ADMIN_COMPANIES_DETAIL_STAFFS_DELETE.replace(':id', company.id).replace(':staffsId', staffId)
        );
        if (res) {
            getStaffs();
            getCounselors();
            showNormal('', t('message:Staff has been deleted'), 3);
        }
    };

    // filter or setState
    const filterCounselors = () => {
        return counselors;
    };

    useEffect(() => {
        getStaffs();
        getCounselors().then(() => {});
    }, [company.id]);

    return (
        <>
            <BackBtn label={company.name} action={() => history.push(Routes.private.ADMIN_COMPANY.path)} />

            {renderTabList('staff')}

            <div className="p-0">
                <div className="mt-16">
                    <DataTable
                        className="collapse-table"
                        columns={columns}
                        hideColumns={state.hideColumns}
                        data={state.data}
                        loading={state.loading}
                        pagination={state.pagination}
                        filters={state.filters}
                        sorter={state.sorter}
                        onTableChange={(pagination, filter, sorter) => {
                            getStaffs(pagination, state.filters, sorter);
                        }}
                        noDataText={'登録されているスタッフはいません'}
                    />

                    <DeleteStaffModal
                        visible={modalVisible.delete}
                        onClose={() => closeModal()}
                        initialValue={{}}
                        data={state.data.find(d => d.id === modalVisible.id)}
                        onUpdate={() => deleteStaff(modalVisible.id)}
                    />

                    <FloatBar>
                        <CustomDropdown
                            trigger={['click']}
                            options={[...filterCounselors().map(c => ({ key: c.id, text: `${c.full_name}` }))]}
                            onSelect={opt => addCounselorCompany(opt.key)}
                            separator
                            placement="topCenter"
                        >
                            <Button icon={<AddIcon />} className="fw-b" onClick={() => {}}>
                                {'スタッフ追加'}
                            </Button>
                        </CustomDropdown>
                    </FloatBar>
                </div>
            </div>
        </>
    );
};

export default connect(mapState, mapDispatch)(StaffContainer);

StaffContainer.propTypes = {
    company: PropTypes.any,
    renderTabList: PropTypes.func,
    state: PropTypes.any,
    getData: PropTypes.func.isRequired,
    setSorter: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
};
