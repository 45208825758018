import { createSelector, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';

const name = 'counselor_reservation_form';

const initialState = {
    programs: {},
    counselors: {},
    reserved: {},
    registered: { all: {} },
    form: {},
    sessionLength: 50,
    realSessionLength: 50,
    clientRegisteredTimes: {},
};

const reservationSlice = createSlice({
    name,
    initialState: initialState,
    reducers: {
        setPrograms(state, action) {
            state.programs = action.payload;
        },
        setCounselors(state, action) {
            state.counselors = action.payload;
        },
        setSchedule(state, action) {
            state.counselors = action.payload.counselors;
            state.reserved = action.payload.reserved;
            state.registered = action.payload.registered;
            state.sessionLength = action.payload.sessionLength;
            state.realSessionLength = action.payload.realSessionLength;
            state.clientRegisteredTimes = action.payload.clientRegisteredTimes;
        },
        setForm(state, action) {
            state.form = action.payload;
        },
        setSessionLength(state, action) {
            state.sessionLength = action.payload;
        },
    },
});

export const { setPrograms, setCounselors, setSchedule, setForm } = reservationSlice.actions;

export default reservationSlice.reducer;

export const getReservablePrograms = (counselorId, id) => async dispatch => {
    const data = await api.get(APP_URLS.COUNSELOR_PROGRAMS_RESERVABLE.replace(':id', id), {
        counselor_id: counselorId,
    });
    if (data) {
        let programs = {};
        for (const program of data) {
            programs[program.id] = program;
        }
        dispatch(setPrograms(programs));
    }
};

export const getReservableCounselors = (counselorId, companyProgramId, reservationId, clientId) => async dispatch => {
    const data = await api.get(APP_URLS.COUNSELOR_RESERVATIONS_SCHEDULES, {
        company_program_id: companyProgramId,
        reservation_id: reservationId,
        counselor_id: counselorId,
        client_id: clientId,
    });
    if (data) {
        dispatch(setSchedule(data));
    }
};

const reservedSelector = state => state.reserved;
const registeredSelector = state => state.registered;
const counselorsSelector = state => state.counselors;
const formSelector = state => state.form;
const sessionLengthSelector = state => state.sessionLength;
const clientRegisteredTimesSelector = state => state.clientRegisteredTimes;

export const timeSelector = createSelector(
    formSelector,
    reservedSelector,
    registeredSelector,
    sessionLengthSelector,
    clientRegisteredTimesSelector,
    (form, reserved, registered, sessionLength, clientRegisteredTimes) => {
        if (!form.date) {
            return {};
        }
        const weekDay = form.date.locale('en').format('dddd').toLowerCase();
        if (!registered.all || !registered.all[weekDay]) {
            return {};
        }
        const dateStr = form.date.format('YYYY-MM-DD');

        if (reserved[dateStr]) {
            let times = {};
            for (const regTime of Object.keys(registered.all[weekDay])) {
                for (const id of registered.all[weekDay][regTime]) {
                    let isFree = false;
                    if (!reserved[dateStr][id]) {
                        // No reserve for this counselor
                        isFree = true;
                    } else {
                        const tmpEndTime = moment(regTime, 'HH:mm')
                            .add(sessionLength - 30, 'minutes')
                            .format('HH:mm');
                        if (
                            reserved[dateStr][id].data.indexOf(regTime) === -1 &&
                            reserved[dateStr][id].data.indexOf(tmpEndTime) === -1
                        ) {
                            isFree = true;
                        }
                    }

                    if (isFree && isFreeTimeOfClient(clientRegisteredTimes, dateStr, regTime, sessionLength)) {
                        if (!times[regTime]) {
                            times[regTime] = [];
                        }
                        times[regTime].push(id);
                    }
                }
            }
            return times;
        } else {
            let times = {};
            const registeredAllWeekDay = registered.all[weekDay];
            Object.keys(registeredAllWeekDay).map(k => {
                if (isFreeTimeOfClient(clientRegisteredTimes, dateStr, k, sessionLength)) {
                    times[k] = registeredAllWeekDay[k];
                }
            });
            return { ...times };
        }
    }
);

const isFreeTimeOfClient = (clientRegisteredTimes, date, regTime, sessionLength) => {
    const tmpEndTime = moment(regTime, 'HH:mm').add(sessionLength, 'minutes').format('HH:mm');
    const registered = clientRegisteredTimes[date];
    if (registered && registered.length > 0) {
        return !registered.find(r => !(r.start_time >= tmpEndTime || r.end_time <= regTime));
    }
    return true;
};

export const counselorSelector = createSelector(
    formSelector,
    counselorsSelector,
    timeSelector,
    (form, counselors, times) => {
        if (!form.date || !form.time || !times) {
            return [];
        }
        const tmp = form.time.split('〜');
        if (!times[tmp[0]]) return [];
        let result = [];
        for (const id of times[tmp[0]]) {
            result.push(counselors[id]);
        }
        return result;
    }
);
