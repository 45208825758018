export const COLORS = [
    '#13CBCB',
    '#0088FF',
    '#7046F1',
    '#8DB600',
    '#FFBF00',
    '#E32636',
    '#FF9966',
    '#98777B',
    '#BF94E4',
    '#007BA7',
    '#6495ED',
    '#03C03C',
];

const dotIndexColors = ['#F66155', '#7E0CE8', '#F4BE26', '#3D9FF5', '#0CDF32'];
export const getColorStyleByDotIndex = i => {
    if (i < 0 || i >= dotIndexColors.length) {
        i = 0;
    }

    return {
        backgroundColor: dotIndexColors[i],
    };
};
