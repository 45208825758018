import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { NotificationTypes } from 'constants/notification_types.constant';
import { showNormal } from 'helpers/notification.helper';
import { Button } from 'antd';
import { hasRole } from 'helpers/role.helper';
import RoleEnum from 'enums/role.enum';
import Routes from 'routes';
import history from 'helpers/history.helper';
import { ServerEvents } from 'constants/server_events.constant';
import { useTranslation } from 'react-i18next';
import { PUSHER_CONFIG } from 'constants/pusher.constant';
import { APP_URLS } from 'constants/url.constant';
import api from 'helpers/api.helper';
import Echo from 'laravel-echo';
import { increaseCountMessage, setCountAnswer, setCountMessage, setHasNewMessage } from 'pages/auth/auth.slice';
import { dateToStr } from 'helpers/date.helper';
import { isMobileActive } from 'helpers/mobile.helper';

const mapState = state => ({
    user: state.auth.user,
});
const mapDispatch = { setCountMessage, increaseCountMessage, setCountAnswer, setHasNewMessage };

const PusherHandler = ({ user, setCountMessage, increaseCountMessage, setCountAnswer, setHasNewMessage }) => {
    const { t } = useTranslation();
    useEffect(() => {
        const options = {
            broadcaster: 'pusher',
            key: PUSHER_CONFIG.APP_KEY,
            cluster: PUSHER_CONFIG.CLUSTER,
            wsHost: PUSHER_CONFIG.WS_HOST,
            wsPort: PUSHER_CONFIG.WS_PORT,
            forceTLS: PUSHER_CONFIG.FORCE_SSL,
            authEndpoint: `${APP_URLS.BASE_URL}/broadcasting/auth`,
            auth: {
                headers: {
                    Authorization: `Bearer ${api.accessToken}`,
                    Accept: 'application/json',
                },
            },
        };
        const echo = new Echo(options);

        echo.private('global').listen(ServerEvents.HAS_APP_UPDATE, data => {
            const currentVersion = localStorage.getItem('appVersion');
            if (currentVersion !== data.version) {
                localStorage.setItem('appVersion', data.version);
                window.location.reload();
            }
        });

        const userChannel = `user-${user.id}`;
        echo.private(userChannel).notification(data => {
            switch (data.type) {
                case NotificationTypes.UPDATE_MESSAGE_COUNT:
                    setCountMessage(data.count);
                    break;
                case NotificationTypes.UPDATE_INTERVIEW_SHEET_COUNT:
                    setCountAnswer(data.count);
                    break;
                case NotificationTypes.HAS_NEW_MESSAGE:
                    increaseCountMessage();
                    if (data.content) {
                        if (!isMobileActive()) {
                            showNormal(t('New message arrived'), data.content);
                        }
                    }
                    setHasNewMessage(data.thread_id);
                    break;
                case NotificationTypes.RESERVATION_ABOUT_TO_START:
                    if (isMobileActive()) {
                        break;
                    }
                    showNormal(
                        t('message:Reservation time for online consultation.'),
                        <>
                            <div>
                                {data.start_time}〜 {data.name} {data.program}
                            </div>
                            <Button
                                className="mt-16 btn-notification-action"
                                htmlType="submit"
                                onClick={() => {
                                    let path;
                                    if (
                                        hasRole(user, RoleEnum.DOCTOR.value) ||
                                        hasRole(user, RoleEnum.STAFF.value) ||
                                        hasRole(user, RoleEnum.ADMIN.value)
                                    ) {
                                        path =
                                            Routes.private.COUNSELOR_DETAIL_CONSULTATION.path.replace(
                                                ':id',
                                                data.user_id
                                            ) + `/${data.reservation_id}`;
                                    } else {
                                        path = Routes.private.CLIENT_RESERVATION_DETAIL.path.replace(
                                            ':id',
                                            data.reservation_id
                                        );
                                    }
                                    history.push(path);
                                }}
                            >
                                {t('To consultation reservation screen')}
                            </Button>
                        </>
                    );
                    break;

                case NotificationTypes.RESERVATION_INTERVIEW_SHEET:
                    if (isMobileActive()) {
                        break;
                    }
                    showNormal(
                        <>
                            <div>{`${dateToStr(data.date, 'M月DD日（ddd）')}${data.start_time}までに${
                                data.reservation_answer_title
                            }に回答してください`}</div>
                            <Button
                                className="mt-16 btn-notification-action"
                                htmlType="submit"
                                onClick={() => {
                                    let path;
                                    if (
                                        hasRole(user, RoleEnum.DOCTOR.value) ||
                                        hasRole(user, RoleEnum.STAFF.value) ||
                                        hasRole(user, RoleEnum.ADMIN.value)
                                    ) {
                                        path =
                                            Routes.private.COUNSELOR_DETAIL_CONSULTATION.path.replace(
                                                ':id',
                                                data.user_id
                                            ) +
                                            `/${data.reservation_id}` +
                                            '/interview-sheet';
                                    } else {
                                        path = Routes.private.CLIENT_INTERVIEW_SHEET_DETAIL.path.replace(
                                            ':id',
                                            data.reservation_answer_id
                                        );
                                    }
                                    history.push(path);
                                }}
                            >
                                {'記入'}
                            </Button>
                        </>
                    );
                    break;
            }
        });

        let companyChannel;
        if (user.employee_company && user.employee_company.length > 0) {
            companyChannel = `company-${user.employee_company[0].id}`;
        }

        if (companyChannel) {
            echo.private(companyChannel).listen(ServerEvents.NEW_COMPANY_MESSAGE, data => {
                increaseCountMessage();
                if (data.content && !isMobileActive()) showNormal(t('New message arrived'), data.content);
            });
        }

        return () => {
            echo.leaveChannel('global');
            echo.leaveChannel(userChannel);
            if (companyChannel) {
                echo.leaveChannel(companyChannel);
            }
        };
    }, [user.id]);

    return <></>;
};

export default connect(mapState, mapDispatch)(PusherHandler);
