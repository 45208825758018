import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import BackBtn from 'components/BackBtn';
import FloatBar from 'components/FloatBar';
import TdtSelect from 'components/TdtSelect';
import ReservationFrameList from 'pages/admin/company/tabs/program/ReservationFrameList';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import history from 'helpers/history.helper';
import { addDetails, resetToDefault } from 'pages/admin/company/tabs/program/reservation_detail.slice';
import { showNormal } from 'helpers/notification.helper';
import FieldInfo from 'components/FieldInfo';
import TdtButtonSubmit from 'components/TdtButtonSubmit';
import Loader from 'components/Loader';
import ProgramBookingOption from 'pages/admin/common/program/ProgramBookingOption';
import { DeleteIcon, DuplicateIcon } from 'components/SvgIcon';
import Routes from '../../../../../routes';
import CounselorDeleteProgramModal from '../../../counselor/CounselorDeleteProgramModal';
import { getCounselor } from 'pages/admin/counselor/counselor_detail.slice';
import { setCloneFromId } from 'pages/admin/counselor/company_program/reservable_frame_programs.slice';
import { UserPropTypes } from 'constants/proptypes.constant';

const mapState = state => ({
    details: state.admin.companyDetailProgramReservation.details,
    detailsOrder: state.admin.companyDetailProgramReservation.detailsOrder,
});
const mapDispatch = { getCounselor, resetToDefault, addDetails, setCloneFromId };

const AddCounselorContainer = ({ details, detailsOrder, mode, resetToDefault, addDetails, setCloneFromId }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { id, programId } = useParams();
    const chosenCounselorId = new URLSearchParams(location.search).get('counselor_id');
    const [counselors, setCounselors] = useState([]);
    const [countSessionTime, setCountSessionTime] = useState(0);

    const title = mode === 'edit' ? 'カウンセラーの編集4444' : 'カウンセラーの追加';

    const [modalVisible, setModalVisible] = useState({
        deleteProgram: false,
        programId: null,
        id: null,
    });

    const closeModal = () => setModalVisible({ deleteProgram: false });

    const showDeleteProgramModal = (programId, id) => setModalVisible({ deleteProgram: true, programId, id });

    const deleteCounselorProgram = async (companyProgramId, counselorId) => {
        let deleteAPIPath = APP_URLS.ADMIN_COUNSELORS_PROGRAM_DELETE.replace(
            ':programCompanyId',
            companyProgramId,
        ).replace(':id', counselorId);
        const res = await api.delete(deleteAPIPath);
        if (res) {
            showNormal('', t('message:Deleted the responsible company / program'), 5);
            history.push(Routes.private.ADMIN_COUNSELORS_DETAIL.path.replace(':id', counselorId));
        }
    };

    const cloneCounselorProgram = async companyProgramId => {
        setCloneFromId(companyProgramId);
        history.push(
            Routes.private.ADMIN_COUNSELORS_ADD_COMPANY_PROGRAM_INDIVIDUAL.path.replace(':id', chosenCounselorId),
        );
    };

    const submitCounselor = async values => {
        const response = await api.post(
            APP_URLS.ADMIN_COMPANIES_DETAIL_ADD_COUNSELOR.replace(':id', id).replace(':programId', programId),
            values,
        );
        if (response) {
            history.goBack();
            chosenCounselorId
                ? showNormal('', t('message:I changed the reservation frame'), 3)
                : showNormal('', t('message:Counselor has been added'), 3);
        }
    };
    const [companyProgram, setCompanyProgram] = useState({});
    const [curCompany, setCurCompany] = useState({});
    const getCounselorsOfProgram = async programId => {
        // programId means companyProgramId
        const response = await api.get(
            APP_URLS.ADMIN_COMPANIES_DETAIL_COUNSELORS_OF_PROGRAM.replace(':id', id).replace(':programId', programId),
        );
        if (response) {
            let tempCompanyProgram = response.programCompany;
            setCounselors(response.counselors);
            setCompanyProgram(tempCompanyProgram);
            setCurCompany(tempCompanyProgram.company);

            // Process session time
            let totalTime, totalInteger, totalFloat;
            totalTime =
                tempCompanyProgram.session_length_hour * 60 +
                tempCompanyProgram.session_length_minute +
                tempCompanyProgram.buffer_time;
            totalFloat = totalTime / 30;
            totalInteger = parseInt(totalFloat);
            if (totalFloat > totalInteger) {
                totalInteger++;
            }
            setCountSessionTime(totalInteger * 30);
            let isExistCounselorId = null;
            if (chosenCounselorId != null) {
                isExistCounselorId = false;
                resetToDefault();
                let timeDetails = [];
                tempCompanyProgram.details.map(detailItem => {
                    if (detailItem.doctor_id === parseInt(chosenCounselorId)) {
                        isExistCounselorId = true;
                        timeDetails.push(detailItem);
                    }
                });
                addDetails(timeDetails);
            }

            if (isExistCounselorId !== null && !isExistCounselorId) {
                history.goBack();
            }
        } else {
            history.goBack();
        }
    };

    useEffect(() => {
        getCounselorsOfProgram(programId).then(() => {
        });
    }, [programId]);

    // processOnSubmit
    const processOnSubmit = values => {
        values.details = [];

        // map details
        detailsOrder.map(orderKey => values.details.push(details[orderKey]));

        submitCounselor(values).then(() => {});
    };

    useEffect(() => {
        resetToDefault();
        // getCounselor(id);
    }, [id, programId]);

    if (!companyProgram || !companyProgram.program) {
        return <Loader className='loader-center' />;
    }

    const onChangedCounselor = value => {
        resetToDefault();
        let timeDetails = [];
        companyProgram.details.map(detailItem => {
            if (detailItem.doctor_id === value) {
                timeDetails.push(detailItem);
            }
        });
        addDetails(timeDetails);
    };

    return (
        <div className='px-24'>
            <div className={'page-title mb-32'}>
                <BackBtn
                    label={chosenCounselorId ? 'カウンセラーの編集' : 'カウンセラーの追加'}
                    className={history && history.length < 2 ? 'opacity-0 pointer-events-none cursor-default' : ''}
                />
            </div>
            <FieldInfo className='mb-32' label={t('field:Company name')} value={curCompany.name} showEdit={false} />

            <FieldInfo
                className='mb-8'
                label={t('Program')}
                value={companyProgram.program.name_mngt}
                showEdit={false}
            />

            <ProgramBookingOption program={companyProgram.program} className='mb-32' />

            <Form
                form={form}
                layout='vertical'
                initialValues={{}}
                onFinish={values => processOnSubmit(values)}
                requiredMark={false}
                className='mt-32'
            >
                <Row gutter={16}>
                    <Col span={24} className='mb-8'>
                        <Form.Item
                            name='counselor_id'
                            initialValue={chosenCounselorId !== null && parseInt(chosenCounselorId)}
                            label={'カウンセラー'}
                            validateTrigger={false}
                            rules={[
                                {
                                    required: true,
                                    message: t('validation:Please enter {{field}}', {
                                        field: t('field:counselor'),
                                    }),
                                },
                            ]}
                        >
                            <TdtSelect
                                style={{ width: 320 }}
                                size='large'
                                className='fs-14 select-gray'
                                onChange={value => {
                                    onChangedCounselor(value);
                                }}
                            >
                                {counselors.map(c => (
                                    <Select.Option key={c.id} value={c.id}>{`${c.full_name}`}</Select.Option>
                                ))}
                            </TdtSelect>
                        </Form.Item>
                    </Col>
                </Row>
                <FloatBar className='d-flex justify-content-space-between align-items-center'>
                    <TdtButtonSubmit
                        type='primary'
                        htmlType='submit'
                        className='fw-b'
                        disabled={detailsOrder.length <= 0}
                    >
                        {chosenCounselorId ? '保存' : '追加'}
                    </TdtButtonSubmit>

                    {!!chosenCounselorId && (
                        <div>
                            <Button
                                className='fw-b pink-color'
                                icon={<DeleteIcon />}
                                onClick={() => {
                                    showDeleteProgramModal(programId, chosenCounselorId);
                                }}
                            >
                                {'削除'}
                            </Button>
                            <Button
                                className='ml-20 fw-b'
                                icon={<DuplicateIcon />}
                                onClick={() => cloneCounselorProgram(programId)}
                            >
                                {'複製'}
                            </Button>
                        </div>
                    )}
                </FloatBar>
                <CounselorDeleteProgramModal
                    onClose={() => closeModal()}
                    onUpdate={() => deleteCounselorProgram(modalVisible.programId, modalVisible.id)}
                    visible={modalVisible.deleteProgram}
                />
            </Form>
            <Row gutter={16}>
                <Col span={24}>
                    <ReservationFrameList sessionTime={countSessionTime} />
                </Col>
            </Row>
        </div>
    );
};

export default connect(mapState, mapDispatch)(AddCounselorContainer);

AddCounselorContainer.propTypes = {
    details: PropTypes.any,
    detailsOrder: PropTypes.array.isRequired,
    resetToDefault: PropTypes.func.isRequired,
    addDetails: PropTypes.func,
    counselor: UserPropTypes,
    getCounselor: PropTypes.func,
};
