import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Button } from 'antd';
import Routes from 'routes';
import { APP_URLS } from 'constants/url.constant';
import QuestionTypeEnum from 'enums/question_type.enum';
import history from 'helpers/history.helper';
import api from 'helpers/api.helper';
import BackBtnWithTitle from 'components/BackBtnWithTitle';
import FloatBar from 'components/FloatBar';
import {
    addOption,
    addQuestion,
    setQuestionAddition,
    setQuestionRequired,
    setQuestionText,
    updateOption,
} from 'pages/admin/interview_sheet/interview_sheet_create.slice';
import SingleSelectQuestion from 'components/questions/SingleSelectQuestion';
import MultipleSelectQuestion from 'components/questions/MultipleSelectQuestion';
import FreeInputQuestion from 'components/questions/FreeInputQuestion';
import DateTimeQuestion from 'components/questions/DateTimeQuestion';
import { showNormal } from 'helpers/notification.helper';
import DailyLifeQuestion from 'components/questions/DailyLifeQuestion';

const mapState = state => ({
    name: state.admin.interviewSheetCreate.name,
    inputCondition: state.admin.interviewSheetCreate.inputCondition,
    description: state.admin.interviewSheetCreate.description,
    byId: state.admin.interviewSheetCreate.questions.byId,
    order: state.admin.interviewSheetCreate.questions.order,
    options: state.admin.interviewSheetCreate.options.byId,
    questionOptions: state.admin.interviewSheetCreate.questionOptions,
    tempProgram: state.admin.programTemp,
});
const mapDispatch = {
    addQuestion,
    setQuestionText,
    setQuestionAddition,
    setQuestionRequired,
    addOption,
    updateOption,
};
const InterviewSheetPreview = ({
    name,
    inputCondition,
    description,
    byId,
    order,
    options,
    questionOptions,
    tempProgram,
}) => {
    const { t } = useTranslation();
    const { id: sheetId } = useParams();

    const submit = async () => {
        let data = [];
        let questionOrder = 0;
        for (const id of order) {
            let question = { ...byId[id] };
            question.id = id;
            question.is_required = question.isRequired;
            question.order = questionOrder++;
            delete question.isRequired;
            if (questionOptions[id]) {
                question.options = [];
                let optionOrder = 0;
                for (const optId of questionOptions[id]) {
                    let option = { ...options[optId] };
                    option.id = optId;
                    option.is_range = option.isRange;
                    option.order = optionOrder++;
                    delete option.isRange;
                    question.options.push(option);
                }
            }
            data.push(question);
        }
        const requestData = {
            name,
            input_condition: inputCondition,
            description,
            questions: data,
        };
        let updateResponse;
        let CreateResponse;
        if (sheetId) {
            updateResponse = await api.patch(
                APP_URLS.ADMIN_INTERVIEW_SHEETS_DETAIL.replace(':id', sheetId),
                requestData
            );
        } else {
            CreateResponse = await api.post(APP_URLS.ADMIN_INTERVIEW_SHEETS, requestData);
        }
        if (updateResponse || CreateResponse) {
            if (tempProgram && tempProgram.link) {
                return history.push(tempProgram.link);
            }
            history.replace(Routes.private.ADMIN_INTERVIEW_SHEET.path);
            CreateResponse
                ? showNormal('', t('message:I registered the interview sheet'), 3)
                : showNormal('', t('message:Saved changes to the interview sheet'), 3);
        }
    };

    return (
        <div className="self-wrapper d-block px-48">
            <BackBtnWithTitle
                title={t('Creating a questionnaire')}
                onClick={() => {
                    if (sheetId) {
                        history.goBack();
                    } else {
                        history.replace(Routes.private.ADMIN_INTERVIEW_SHEETS_CREATE.path);
                    }
                }}
            />
            <p className="mb-56">
                {t(
                    'This is a preview of the questionnaire to be created. If there is no problem, please press the save button'
                )}
            </p>
            <div className="interview-sheet-preview">
                <h2 className="mb-8 mt-16">{name}</h2>
                <p className="mb-24">{inputCondition}</p>
                <p className="description mb-16">{description}</p>
                <p className="note mb-0">*必須項目</p>

                {order.map(id => {
                    const info = byId[id];
                    if (!info) return null;
                    let Component = null;
                    switch (info.type) {
                        case QuestionTypeEnum.FREE_INPUT.value:
                        case QuestionTypeEnum.FREE_INPUT_PARAGRAPH.value:
                        case QuestionTypeEnum.FREE_INPUT_NUMBER.value:
                            Component = FreeInputQuestion;
                            break;
                        case QuestionTypeEnum.SINGLE_SELECTION.value:
                            Component = SingleSelectQuestion;
                            break;
                        case QuestionTypeEnum.MULTIPLE_SELECTION.value:
                            Component = MultipleSelectQuestion;
                            break;
                        case QuestionTypeEnum.DATE_TIME.value:
                            Component = DateTimeQuestion;
                            break;
                        case QuestionTypeEnum.DAILY_LIFE.value:
                            Component = DailyLifeQuestion;
                            break;
                    }
                    if (Component) {
                        return <Component key={id} info={info} optionsOrder={questionOptions[id]} options={options} />;
                    }
                    return <div key={id}>{id}</div>;
                })}
            </div>

            <FloatBar>
                <Button type="primary" className="mr-8 fw-b" onClick={() => submit()}>
                    {t('Save')}
                </Button>
            </FloatBar>
        </div>
    );
};

export default connect(mapState, mapDispatch)(InterviewSheetPreview);

InterviewSheetPreview.propTypes = {
    name: PropTypes.string,
    inputCondition: PropTypes.string,
    description: PropTypes.string,
    byId: PropTypes.object,
    order: PropTypes.array,
    options: PropTypes.object,
    questionOptions: PropTypes.object,
};
