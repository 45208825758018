export const numberWithCommas = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

export const numberToFixed = (x, place = 2) => x.toFixed(place);

export const floor = (x, decimals = 2) => {
    const pow = Math.pow(10, decimals);
    return Math.floor(x * pow) / pow;
};

export const percentToFixed = (a, b, place = 2) => ((a / b) * 100).toFixed(place);

export const formatNumberTwoDigit = str => {
    str = str + '';
    str.replaceAll(',', '.');

    try {
        let num = Number(str);
        return Math.round((num + Number.EPSILON) * 10) / 10;
    } catch (e) {
        return str;
    }
}