import { isEmpty } from 'helpers/common.helper';
import { getEnum } from 'helpers/enum.helper';
import RoleEnum from 'enums/role.enum';

export const getFirstRoleEnum = (roles = []) => {
    if (isEmpty(roles)) {
        return '';
    }

    let curRole = roles[0];
    return getEnum(RoleEnum, curRole.name);
};

export const hasRole = (user, roleName) => {
    if (!user.roles) return false;
    for (const role of user.roles) {
        if (role.name === roleName) return true;
    }
    return false;
};

export const hasAnyRole = (user, roleNames = []) => {
    if (!user.roles) return false;
    for (const role of user.roles) {
        if (roleNames.indexOf(role.name) > -1) return true;
    }
    return false;
};

export const allowedClientPage = user => {
    if (hasRole(user, RoleEnum.USER.value)) {
        return true;
    }
    return false;
};

export const allowedCounselorPage = user => {
    if (
        hasRole(user, RoleEnum.DOCTOR.value) ||
        hasRole(user, RoleEnum.STAFF.value) ||
        hasRole(user, RoleEnum.ADMIN.value) ||
        hasRole(user, RoleEnum.ADMIN_STAFF.value)
    ) {
        return true;
    }
    return false;
};

export const allowedAdminPage = user => {
    if (hasRole(user, RoleEnum.ADMIN.value) || hasRole(user, RoleEnum.ADMIN_STAFF.value)) {
        return true;
    }
    return false;
};

export const isAdminStaff = user => {
    return hasRole(user, RoleEnum.ADMIN_STAFF.value);
};
