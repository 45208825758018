import React, { useEffect, useState } from 'react';
import TabList from 'components/TabList';
import DeliveryResultContainer from 'pages/admin/email/detail_screen/tabs/delivery_result/DeliveryResultContainer';
import DeliveryContentContainer from 'pages/admin/email/detail_screen/tabs/delivery_content/DeliveryContentContainer';
import { Col, Row } from 'antd';
import BackBtn from 'components/BackBtn';
import history from 'helpers/history.helper';
import Routes from 'routes';
import { DeleteIcon, DotsHorizontalRoundedIcon, DuplicateIcon } from 'components/SvgIcon';
import CustomDropdown from 'components/CustomDropdown';
import { useTranslation } from 'react-i18next';
import { getEmailHtml, setEmailHtml, resetFilter } from 'pages/admin/email/detail_screen/email_detail.slice';
import { useParams } from 'react-router-dom';
import Loader from 'components/Loader';
import { getEnumText } from 'helpers/enum.helper';
import { connect } from 'react-redux';
import DeliveryStatus from 'enums/delivery_status.enum';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import { showNormal } from 'helpers/notification.helper';
import DeleteEmailModal from 'pages/admin/email/modal/DeleteEmailModal';
import CloneEmailModal from 'pages/admin/email/modal/CloneEmailModal';

const mapState = state => ({
    emailHtml: state.admin.emailDetail.emailHtml,
})
const mapDispatch = { getEmailHtml, setEmailHtml, resetFilter }
const EmailDetail = ({ emailHtml, getEmailHtml, setEmailHtml, resetFilter }) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const [modalVisible, setModalVisible] = useState({
        delete: false,
        clone: false,
    });
    const showDeleteModal = r => setModalVisible({ ...modalVisible, delete: true, record: r });
    const showCloneModal = r => setModalVisible({ ...modalVisible, clone: true, record: r });
    const closeModal = () => setModalVisible({ clone: false, delete: false });

    const deleteEmail = async emailId => {
        const response = await api.delete(APP_URLS.ADMIN_EMAILS_DELETE.replace(':id', emailId));
        if (response) {
            history.push(Routes.private.ADMIN_EMAIL.path);
            showNormal('', t('message:Data has been deleted'), 3);
        }
    }

    useEffect(() => {
        getEmailHtml(id);

        return () => {
            setEmailHtml(null);
            resetFilter();
        };
    }, [id])

    if (!emailHtml) {
        return <Loader className="loader-center" />;
    }

    const tabList = [
        {
            key: 'delivery_result',
            title: '配信結果',
            container: <DeliveryResultContainer emailHtml={emailHtml} />,
        },
        {
            key: 'delivery_content',
            title: '配信内容',
            container: <DeliveryContentContainer emailHtml={emailHtml} />,
        },
    ];

    const renderTabList = () => {
        return (
            <TabList
                tabs={tabList}
                animated={false}
                className="interview-sheet-tab-list bg-white pt-24"
            />
        );
    };
    return (
        <div className="self-wrapper px-0 pb-0 bg-gray-5">
            <div className="px-48 bg-white">
                <Row>
                    <Col span={24}>
                        <div className="d-flex justify-content-space-between align-items-center">
                            <BackBtn
                                label={emailHtml.title}
                                action={() => history.push(Routes.private.ADMIN_EMAIL.path)}
                            />
                            <div className="pt-48 change-state-container">
                                <div className="mr-16 white-space-nowrap ml-12 fw-400 fs-16">
                                    <span>{'ステータス：'}</span>
                                    <span className=" fw-700 fs-16">
                                        {t(`enum:${getEnumText(DeliveryStatus, emailHtml.status)}`)}
                                    </span>
                                </div>

                                <CustomDropdown
                                    className="mt-10"
                                    onClick={e => {
                                        e.stopPropagation();
                                    }}
                                    trigger={['click']}
                                    options={[
                                        {
                                            key: 'duplicate',
                                            text: (
                                                <>
                                                    <DuplicateIcon /> <span>{'複製'}</span>
                                                </>
                                            ),
                                            className: 'primary-color fw-b',
                                        },
                                        {
                                            key: 'delete',
                                            text: (
                                                <>
                                                    <DeleteIcon /> <span>{'削除'}</span>
                                                </>
                                            ),
                                            className: 'pink-color fw-b',
                                        },
                                    ].filter(o => o.key !== 'duplicate' || !emailHtml.ref_seminar_id)}
                                    onSelect={opt => {
                                        opt.domEvent.stopPropagation();
                                        if (opt.key === 'delete') {
                                            showDeleteModal(emailHtml);
                                        } else {
                                            showCloneModal(emailHtml);
                                        }
                                    }}
                                    separator
                                >
                                    <DotsHorizontalRoundedIcon className=" primary-color c-pointer" />
                                </CustomDropdown>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

            {renderTabList()}

            <DeleteEmailModal
                onClose={() => closeModal()}
                onUpdate={() => deleteEmail(modalVisible.record.id)}
                visible={modalVisible.delete}
                record={modalVisible.record}
            />
            <CloneEmailModal
                onClose={() => closeModal()}
                visible={modalVisible.clone}
                record={emailHtml}
            />
        </div>
    );
};

export default connect(mapState, mapDispatch)(EmailDetail);
