import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import FloatBar from 'components/FloatBar';
import moment from 'moment';
import { Button, Col, DatePicker, Row, Typography } from 'antd';
import { APP_URLS } from 'constants/url.constant';
import HealthTypeEnum from 'enums/health_type.enum';
import api from 'helpers/api.helper';
import { fullDateFormat } from 'helpers/date.helper';
import { showNormal } from 'helpers/notification.helper';
import { AddIcon, CyanCircleIcon, DeleteIcon, EditIcon, PurpleCircleIcon } from 'components/SvgIcon';
import BackBtnWithTitle from 'components/BackBtnWithTitle';
import LineChart from 'components/chart/LineChart';
import DeleteDataModal from 'pages/client/health_data/DeleteDataModal';
import CreatePressureModal from 'pages/client/health_data/CreatePressureModal';
import DateChangeComponent from 'pages/client/health_data/tabs/date_time/DateChangeComponent';
import {
    chartDataSelector,
    getPressures,
    goNext,
    goPrev,
    goToday,
    pressuresFilterSelector,
    setDisplayType,
    setMonthFilter,
} from 'pages/client/health_data/charts/pressure.slice';
import NoData from 'components/NoData';
import ReservationStatus from 'enums/reservation_status.enum';

const mapState = state => ({
    startDate: state.client.healthDataPressure.startDate,
    endDate: state.client.healthDataPressure.endDate,
    displayType: state.client.healthDataPressure.displayType,
    monthFilter: state.client.healthDataPressure.monthFilter,
    pressures: pressuresFilterSelector(state.client.healthDataPressure),
    chartData: chartDataSelector(state.client.healthDataPressure),
    user: state.auth.user,
});

const mapDispatch = { goPrev, goNext, goToday, setDisplayType, setMonthFilter, getPressures };

const xLabelRenderer = date => moment(date).format('MM/DD');

const PressureChart = ({
    startDate,
    endDate,
    displayType,
    monthFilter,
    pressures,
    chartData,
    goPrev,
    goNext,
    goToday,
    setDisplayType,
    setMonthFilter,
    getPressures,
    user,
}) => {
    const { t } = useTranslation();
    const weekFormat = 'YYYY年MM月';
    const isStateEnd = user.employee_company[0].state === ReservationStatus.END.value;
    const [value, setValue] = useState({});
    const [modalVisible, setModalVisible] = useState({
        pressure: false,
        deletePressure: false,
        id: null,
    });
    const [modalCreateVisible, setModalCreateVisible] = useState({
        createPressure: false,
    });

    const closeCreateModal = () => setModalCreateVisible({ createPressure: false });
    const showCreateModal = () => setModalCreateVisible({ createPressure: true });
    const showUpdatePressureModal = id => setModalVisible({ pressure: true, deletePressure: false, id });
    const showDeleteModal = id => setModalVisible({ deletePressure: true, pressure: false, id });
    const closeModal = () => setModalVisible({ pressure: false, deletePressure: false, id: null });

    const updatePressureData = async value => {
        const response = await api.put(APP_URLS.CLIENT_HEALTH_DATA_UPDATE.replace(':id', modalVisible.id), {
            created_at: value.created_at,
            value: value.pressure,
            ext_value: value.ext_pressure,
        });
        if (response) {
            showNormal('', t('message:Data has been saved'), 3);
            getPressures();
        } else {
            showNormal('', t('message:Invalid data'), 3);
        }
    };

    const deletePressureData = async () => {
        const response = await api.delete(APP_URLS.CLIENT_HEALTH_DATA_DELETE.replace(':id', modalVisible.id));
        if (response) {
            showNormal('', t('message:Data has been deleted'), 3);
            getPressures();
        } else {
            showNormal('', t('message:Cannot delete data'), 3);
        }
    };

    useEffect(() => {
        getPressures();
    }, [startDate, endDate]);

    const creatEntryData = async (values, type) => {
        const response = await api.post(APP_URLS.CLIENT_HEALTH_DATA_CREATE, {
            value: values.pressure,
            ext_value: values.ext_pressure,
            created_at: values.created_at,
            health_images: [{ images: values.images, category: values.category }],
            // category: values.category,
            type,
        });
        if (response) {
            showNormal('', t('message:Data has been saved'), 3);
            getPressures();
        } else {
            showNormal('', t('message:Invalid data'), 3);
        }
    };

    const tooltipRenderer = useCallback(
        points => {
            const width = 150;
            const x = points[0].x - width / 2;
            const y = points[0].y;
            return (
                <>
                    <rect x={x} y={y + 8} width={width} height={52} rx={4} ry={4} fill="#ffffff" stroke="#D0D0D0" />
                    <text x={x + 8} y={y + 32} fill="#222126" fontSize={12} alignmentBaseline="top">
                        {chartData.datesHasAverage.includes(points[0].name) && <tspan>{t('Average')}</tspan>}
                        <tspan fontSize={16} fontWeight={500} dx={4}>
                            {points[1].value.toFixed(0)}/{points[0].value.toFixed(0)} mmHg
                        </tspan>
                    </text>
                    <text x={x + 8} y={y + 50} fill="#222126" fontSize={12} alignmentBaseline="top">
                        {moment(points[0].name).format(displayType === 'year' ? 'M月' : 'M月D日(dd)')}
                    </text>
                </>
            );
        },
        [t, displayType, chartData.datesHasAverage]
    );

    return (
        <>
            <div className="mb-62">
                <BackBtnWithTitle title="血圧" />
                <DateChangeComponent
                    startDate={startDate}
                    endDate={endDate}
                    displayType={displayType}
                    goPrev={goPrev}
                    goNext={goNext}
                    goToday={goToday}
                    setDisplayType={setDisplayType}
                />
                <div className="mt-16">
                    <span>
                        <PurpleCircleIcon className="pr-8" size={11} />
                        {t('field:Maximum blood pressure')}
                    </span>
                    <span className="pl-14">
                        <CyanCircleIcon className="pr-8" size={11} />
                        {t('field:Minimum blood pressure')}
                    </span>
                </div>
                <div className="chart-wrapper">
                    <LineChart
                        className="health-data-chart"
                        data={chartData}
                        width={1000}
                        height={320}
                        yStepSize={20}
                        yMin={50}
                        yCount={6}
                        baseLines={[80, 130]}
                        xLabelRender={xLabelRenderer}
                        circleOnHoverOnly={displayType !== 'week'}
                        tooltipRender={tooltipRenderer}
                    />
                </div>

                <Row justify="space-between" align="bottom">
                    <Col className="mt-48">
                        <Typography className="fw-b data-title">{t('Data details')}</Typography>
                    </Col>
                    <Col>
                        {displayType === 'year' && (
                            <DatePicker
                                className="chart-month-picker"
                                value={monthFilter}
                                picker="month"
                                format={weekFormat}
                                allowClear={false}
                                onChange={value => {
                                    setMonthFilter(value);
                                }}
                                inputReadOnly={true}
                            />
                        )}
                    </Col>
                </Row>
                {pressures.length === 0 ? (
                    <NoData />
                ) : (
                    pressures.map(p => {
                        return (
                            <Row
                                key={p.id}
                                className="health-data-row data-details"
                                justify="space-between"
                                align="middle"
                            >
                                <Col className="health-data-col-center" xs={12} xl={4}>
                                    <Typography className="data-date-text">{fullDateFormat(p.created_at)}</Typography>
                                    <Typography className="health-data-value-none-pc">
                                        {p.value || '0'}/{p.ext_value || '0'}
                                    </Typography>
                                </Col>
                                <Col xs={4} xl={4} className="flex">
                                    <Typography className="health-data-value-pc">
                                        {p.value || '0'}/{p.ext_value || '0'}
                                    </Typography>
                                    {!isStateEnd && (
                                        <Button
                                            icon={<EditIcon />}
                                            type="link"
                                            className="edit-btn-pc pl-6"
                                            onClick={() => {
                                                setValue({
                                                    pressure: p.value,
                                                    ext_pressure: p.ext_value,
                                                    created_at: moment.utc(p.created_at),
                                                });
                                                showUpdatePressureModal(p.id);
                                            }}
                                        />
                                    )}
                                </Col>
                                <Col className="health-data-col-flex-end" xs={4} xl={14}>
                                    {!isStateEnd && (
                                        <Button
                                            icon={<DeleteIcon size={30} />}
                                            type="link"
                                            className="btn-pink btn-delete-health-data"
                                            onClick={() => showDeleteModal(p.id)}
                                        />
                                    )}
                                    {!isStateEnd && (
                                        <Button
                                            icon={<EditIcon />}
                                            type="link"
                                            className="edit-btn-mb pr-12"
                                            onClick={() => {
                                                setValue({
                                                    pressure: p.value,
                                                    ext_pressure: p.ext_value,
                                                    created_at: moment.utc(p.created_at),
                                                });
                                                showUpdatePressureModal(p.id);
                                            }}
                                        />
                                    )}
                                </Col>
                            </Row>
                        );
                    })
                )}

                <DeleteDataModal
                    visible={modalVisible.deletePressure}
                    onClose={() => closeModal()}
                    initialValue={{}}
                    onUpdate={async () => {
                        await deletePressureData();
                        closeModal();
                    }}
                />

                <CreatePressureModal
                    visible={modalVisible.pressure}
                    onClose={() => closeModal()}
                    value={value}
                    setValue={setValue}
                    onUpdate={async value => {
                        await updatePressureData(value);
                    }}
                />
            </div>
            <FloatBar>
                {!isStateEnd && (
                    <Button
                        icon={<AddIcon />}
                        className="fw-b"
                        type="primary"
                        onClick={() => {
                            setValue({ created_at: moment.utc() });
                            showCreateModal();
                        }}
                    >
                        {t('Data entry')}
                    </Button>
                )}
                <CreatePressureModal
                    visible={modalCreateVisible.createPressure}
                    onClose={() => {
                        closeCreateModal();
                    }}
                    onUpdate={values => {
                        creatEntryData(values, HealthTypeEnum.PRESSURE.value).then(() => {});
                    }}
                    value={value}
                    setValue={setValue}
                />
            </FloatBar>
        </>
    );
};

export default connect(mapState, mapDispatch)(PressureChart);

PressureChart.propTypes = {
    tab: PropTypes.string,
    url: PropTypes.string,
};
