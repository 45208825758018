import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import api from 'helpers/api.helper';
import history from 'helpers/history.helper';
import { APP_URLS } from 'constants/url.constant';
import { setUser } from 'pages/auth/auth.slice';
import Routes from 'routes';
import FloatBarAccount from 'pages/client/account/FloatBarAccount';
import CodeVerifyErrorModal from 'pages/client/account/CodeVerifyErrorModal';
import { isEmpty } from 'helpers/common.helper';
import { showNormal } from 'helpers/notification.helper';
import Loader from 'components/Loader';
import { handleScrollOnFocus } from 'helpers/form_event.helper';

const mapState = state => ({
    account: state.auth.user,
});
const mapDispatch = { setUser };
const UpdateEmail = ({ account, setUser }) => {
    const inputRef = React.useRef(null);
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [loadingResend, setLoadingResend] = useState(false);

    const location = useLocation();
    const urlSearchParams = new URLSearchParams(location.search);
    const searchParamEmailSpace = urlSearchParams.get('email');
    const searchParamEmail = searchParamEmailSpace ? searchParamEmailSpace.replaceAll(' ', '+') : searchParamEmailSpace;
    const searchParamCode = urlSearchParams.get('code');

    useEffect(() => {
        if (isEmpty(account.tmpEmail) && isEmpty(searchParamEmail)) {
            history.push('/');
        }
    }, []);

    const [modalVisible, setModalVisible] = useState({
        verifyCode: false,
    });

    const sendEmail = async () => {
        setLoadingResend(true);
        await api.patch(
            APP_URLS.CLIENT_CHANGE_EMAIL_VERIFY_CODE.replace(':new_mail', account.tmpEmail || searchParamEmail),
            {
                email: account.tmpEmail || searchParamEmail,
            }
        );
    };

    const showErrorVerifyCodeModal = () => {
        setLoading(false);
        setModalVisible({ verifyCode: true });
    };
    const closeModal = () => {
        setModalVisible({ verifyCode: false });
        inputRef.current.focus({
            cursor: 'all',
        });
    };

    const updateEmail = async values => {
        if (!account.tmpEmail && !searchParamEmail) {
            return;
        }
        setLoading(true);
        values.email = account.tmpEmail || searchParamEmail;
        try {
            const response = await api.patch(APP_URLS.CLIENT_UPDATE_EMAIL, values, {}, {}, true);
            if (response) {
                setLoading(false);
                showNormal('', t('message:Saved your changes'), 5);
                setUser(response);
                history.push(Routes.private.CLIENT_ACCOUNT.path);
            } else {
                showErrorVerifyCodeModal();
            }
        } catch (e) {
            showErrorVerifyCodeModal();
        }
    };

    let initialValues = Object.assign({}, { ...account, verify_code: searchParamCode });

    const sharedProps = {
        ref: inputRef,
    };

    return (
        <>
            <h1 className="page-title mb-32">{'認証コードの入力'}</h1>

            <Form
                layout="vertical"
                form={form}
                initialValues={initialValues}
                onFinish={async values => {
                    await updateEmail(values);
                }}
                requiredMark={false}
                onFocus={handleScrollOnFocus}
                onChange={handleScrollOnFocus}
            >
                <label className="fs-20">{account.tmpEmail || searchParamEmail}</label>
                <p className="mb-32 mt-42">
                    認証コードが上記のメールアドレスに送信されました。
                    <br />
                    メールに記載されている認証コードを入力し、「変更」を押すと変更が完了します。
                </p>

                <Row className="" align="middle">
                    <Col className="mr-16 setting-email">
                        <Form.Item
                            name="verify_code"
                            label={t('field:Authentication Code')}
                            validateTrigger={false}
                            rules={[
                                {
                                    required: true,
                                    message: t('validation:Please enter {{field}}', {
                                        field: t('field:Authentication Code'),
                                    }),
                                },
                            ]}
                        >
                            <Input autoFocus className="input-update-email" {...sharedProps} />
                        </Form.Item>
                    </Col>
                </Row>

                <p className="mt-48">
                    認証コードを再送したい場合は
                    <Button
                        type="link"
                        disabled={loadingResend}
                        onClick={() => {
                            sendEmail().then(() => {
                                setLoadingResend(false);
                                showNormal('', t('message:We sent an email enclosed authentication code'), 5);
                                inputRef.current.focus({
                                    cursor: 'all',
                                });
                            });
                        }}
                        className="p-0 td-link-bolder"
                    >
                        {'こちら'}
                        {loadingResend ? <Loader className={'resend-verify-code-spin'} /> : <></>}
                    </Button>
                </p>
                <Row>
                    <FloatBarAccount>
                        <Col>
                            <Button
                                type=""
                                className="fw-b btn-float-bar"
                                xs={12}
                                onClick={() => history.push(Routes.private.CLIENT_ACCOUNT.path)}
                            >
                                {'キャンセル'}
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                type="primary"
                                xs={12}
                                loading={loading}
                                className="fw-b btn-float-bar"
                                htmlType="submit"
                            >
                                {'変更'}
                            </Button>
                            <CodeVerifyErrorModal visible={modalVisible.verifyCode} onClose={() => closeModal()} />
                        </Col>
                    </FloatBarAccount>
                </Row>
            </Form>
        </>
    );
};

export default connect(mapState, mapDispatch)(UpdateEmail);

UpdateEmail.propTypes = {
    account: PropTypes.any,
    setUser: PropTypes.func,
};
