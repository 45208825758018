import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { addDetail } from 'pages/admin/counselor/company_program/reservable_frame_programs.slice';
import ReservationFrame from 'pages/admin/counselor/company_program/ReservationFrame';
import { AddIcon } from 'components/SvgIcon';
import Loader from 'components/Loader';

const mapState = state => ({
    detailsOrder: state.admin.reservableFramePrograms.detailsOrder,
    loading: state.admin.reservableFramePrograms.loading,
});
const mapDispatch = { addDetail };

const ReservationFrameList = React.memo(({ detailsOrder, addDetail, sessionTime, loading }) => {
    const { t } = useTranslation();

    return (
        <div className="pt-8">
            {loading ? (
                <Loader />
            ) : (
                detailsOrder.map(id => <ReservationFrame key={id} id={id} sessionTime={sessionTime} />)
            )}
            <div className="mt-4">
                <Button icon={<AddIcon />} className="fw-b pr-24" onClick={() => addDetail(sessionTime)}>
                    {t('Reservable frame added')}
                </Button>
            </div>
        </div>
    );
});

export default connect(mapState, mapDispatch)(ReservationFrameList);

ReservationFrameList.propTypes = {
    detailsOrder: PropTypes.array.isRequired,
    addDetail: PropTypes.func,
    sessionTime: PropTypes.number,
    loading: PropTypes.bool,
};
