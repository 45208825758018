import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ArrowRightIcon } from 'components/SvgIcon';
import PropTypes from 'prop-types';
import { Button, Col, Form, Input, Row } from 'antd';
import Routes from 'routes';
import SidebarTopClient from 'layouts/SidebarTopClient';
import { useTranslation } from 'react-i18next';
import { APP_URLS } from 'constants/url.constant';
import api from 'helpers/api.helper';
import { setRegisterAccount } from 'pages/auth/auth.slice';
import history from 'helpers/history.helper';
import ErrorMessageEnum from 'enums/error_message.enum';
import { getEnum } from 'helpers/enum.helper';
import { isEmpty } from 'helpers/common.helper';

const mapState = state => ({
    registerAccount: state.auth.registerAccount,
});
const mapDispatch = { setRegisterAccount };
const RegisterStep2 = ({ registerAccount, setRegisterAccount }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    if (registerAccount.email == null) {
        return <Redirect to={'/'} />;
    }

    const formSubmit = async values => {
        setError(false);
        setLoading(true);
        setErrorMessage('');
        setRegisterAccount({ ...registerAccount, company_code: values.company_code });
        try {
            const userId = await api.post(
                APP_URLS.REGISTER,
                {
                    first_name: registerAccount.first_name,
                    last_name: registerAccount.last_name,
                    first_name_kana: registerAccount.first_name_kana,
                    last_name_kana: registerAccount.last_name_kana,
                    email: registerAccount.email,
                    password: registerAccount.password,
                    company_code: values.company_code,
                    device_id: localStorage.getItem('device_id'),
                    device_token_mobile: localStorage.getItem('device_token'),
                    is_iphone: localStorage.getItem('is_iphone'),
                },
                {},
                {},
                true
            );
            localStorage.setItem('user_id', userId);
            setLoading(false);
            history.push(Routes.public.REGISTER_COMPLETE.path);
        } catch (e) {
            setLoading(false);
            let data = e.response.data;
            let errorCode = data.error;
            if (!data.success && !isEmpty(errorCode)) {
                let errorEnum = getEnum(ErrorMessageEnum, errorCode);
                let isError = false;
                switch (errorEnum) {
                    case ErrorMessageEnum.COMPANY_CODE_NOT_EXISTS:
                        isError = true;
                        setErrorMessage(t('validation:Company code does not exist'));
                        break;
                    case ErrorMessageEnum.THE_COMPANY_IS_REJECTED:
                        isError = true;
                        setErrorMessage(t('validation:The company is rejected'));
                        break;
                }
                setError(isError);
            }
        }
    };

    return (
        <div id="register-step-2">
            <SidebarTopClient backUrl={Routes.public.REGISTER.path} />

            <div className="register-header mt-56">
                <h2 className="td-header">{t('Register new')}</h2>
                <div className="register-step">
                    <div className="register-step__normal">
                        <div className="mr-4">STEP1</div>
                        <div>アカウント情報入力</div>
                    </div>
                    <ArrowRightIcon className="arrow-right" />
                    <div className="register-step__normal register-step__active">
                        <div className="mr-4">STEP2</div>
                        <div>企業コード入力</div>
                    </div>
                </div>
            </div>
            <Row justify="center" align="middle">
                <Col xs={22} sm={24} md={12} lg={8} xl={6}>
                    <div id="form-wrapper" className="mt-32">
                        <Form layout="vertical" onFinish={formSubmit}>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <div className="mb-8">
                                        {t('Please enter the code provided in the invitation email')}
                                    </div>
                                    <Form.Item
                                        name="company_code"
                                        validateStatus={error && 'error'}
                                        validateTrigger={false}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('validation:Please enter {{field}}', {
                                                    field: t('field:company code'),
                                                }),
                                            },
                                        ]}
                                    >
                                        <Row gutter={16}>
                                            <Col span={24}>
                                                <Input />
                                                {error && (
                                                    <div className="ant-form-item-explain ant-form-item-explain-error">
                                                        <div role="alert">{errorMessage}</div>
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row justify="center" align="middle" className="mt-32">
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" loading={loading} className="td-btn">
                                        {t('Register')}
                                    </Button>
                                </Form.Item>
                            </Row>
                        </Form>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default connect(mapState, mapDispatch)(RegisterStep2);

RegisterStep2.propTypes = {
    registerAccount: PropTypes.any.isRequired,
    setRegisterAccount: PropTypes.func.isRequired,
};
