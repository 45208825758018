import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PageElementTypeEnum from 'enums/page_element_type.enum';
import { Avatar, Col, Image, Row, Typography } from 'antd';
import { APP_URLS } from 'constants/url.constant';
import { imageUrlAdapter } from 'helpers/image_url.helper';
import VideoJS from 'components/VideoJS';

const RenderPageElements = ({ info, details = [], isClient, withArchive = false }) => {
    const { t } = useTranslation();

    const isSamedayBooking = info.allow_same_day_booking;

    const renderInfo = () => {
        return (
            <>
                {withArchive && info.video_src ? (
                    <div className="mb-42">
                        <div className="video-player">
                            <VideoJS
                                options={{
                                    autoplay: false,
                                    controls: true,
                                    responsive: true,
                                    fluid: true,
                                    sources: [
                                        {
                                            src: `${info.video_src}/assets/${info.video_id}/HLS/${info.zoom_id}.m3u8`,
                                            type: 'application/x-mpegURL',
                                        },
                                        {
                                            src: `${info.video_src}/assets/${info.video_id}/MP4/${info.zoom_id}.mp4`,
                                            type: 'video/mp4',
                                        }
                                    ],
                                    //poster: info.cover_img ? `${APP_URLS.STATIC_BASE_URL}/${info.cover_img}` : `${info.video_src}/assets/${info.video_id}/Thumbnails/${info.zoom_id}.0000000.jpg`
                                }}
                                onReady={() => {}}
                            />
                        </div>
                    </div>
                ) : (
                    <div className="cover-img-container mb-42">
                        {info.cover_img ? (
                            <Image
                                className="seminar-render-page-cover-img"
                                preview={false}
                                src={imageUrlAdapter(
                                    info.cover_img && info.cover_img.includes('http')
                                        ? info.cover_img
                                        : `${APP_URLS.STATIC_BASE_URL}/${info.cover_img}`
                                )}
                            />
                        ) : (
                            <div className="no-image-background no-cover-in-modal">
                                <span className="content">{'COVER IMAGE'}</span>
                            </div>
                        )}
                    </div>
                )}
                {isSamedayBooking && (
                    <p className="msg-badge msg-badge-secondary same-day-badge-detail">
                        {t('Same-day reservation is possible')}
                    </p>
                )}
                <span className="seminar-render-page-name">{info.name}</span>
                <Typography className="seminar-render-page-description mt-8">{info.description}</Typography>
            </>
        );
    };

    const renderDetail = detail => {
        switch (detail.type) {
            case PageElementTypeEnum.HEADING.value:
                return <>{renderHeading(detail)}</>;
            case PageElementTypeEnum.TEXT.value:
                return <>{renderText(detail)}</>;
            case PageElementTypeEnum.IMAGE.value:
                return <>{renderImage(detail)}</>;
            case PageElementTypeEnum.PERSON_PROFILE.value:
                return <>{renderPersonProfile(detail)}</>;
            case PageElementTypeEnum.HTML_CODE.value:
                return <>{renderHTMLCode(detail)}</>;
        }
    };

    const renderHeading = detail => {
        return (
            <>
                <Typography
                    className={
                        detail.heading_size === 's'
                            ? 'seminar-render-page-heading fs-14'
                            : detail.heading_size === 'm'
                            ? 'seminar-render-page-heading fs-16'
                            : 'seminar-render-page-heading'
                    }
                >
                    {detail.content}
                </Typography>
            </>
        );
    };

    const renderText = detail => {
        return (
            <>
                <Typography className="seminar-render-page-text" dangerouslySetInnerHTML={{ __html: detail.content }} />
            </>
        );
    };

    const renderImage = detail => {
        const mediaUrl = detail.media_url
            ? `${APP_URLS.STATIC_BASE_URL}/${detail.media_url}`
            : detail.image_media_url
            ? detail.image_media_url.url
            : null;
        return (
            <>
                <div className="cover-img-container">
                    {mediaUrl && <Image className="mt-16 mb-8" preview={false} src={imageUrlAdapter(mediaUrl)} />}
                </div>
                <Typography className="seminar-render-page-img img-description">{detail.content}</Typography>
            </>
        );
    };

    const renderPersonProfile = detail => {
        const mediaUrl = detail.media_url
            ? `${APP_URLS.STATIC_BASE_URL}/${detail.media_url}`
            : detail.image_media_url
            ? detail.image_media_url.url
            : null;
        return (
            <>
                <Row
                    className={isClient ? ' person-profile-container client-detail' : 'person-profile-container'}
                    align="middle"
                    gutter={[{ xs: 0, md: 32 }, 0]}
                >
                    <Col md={isClient ? 3 : 5} sm={3} xs={6}>
                        {mediaUrl && (
                            <Avatar
                                className={isClient ? 'person-profile-avatar client-detail' : 'person-profile-avatar'}
                                shape="circle"
                                src={imageUrlAdapter(mediaUrl, 480)}
                            />
                        )}
                    </Col>
                    <Col md={isClient ? 21 : 19} sm={21} xs={18} className={isClient && 'person-profile-description'}>
                        <Typography className="fw-b fs-16 mb-12">{detail.profile_name}</Typography>
                        <Typography className="fs-14 pre-wrap" dangerouslySetInnerHTML={{ __html: detail.content }} />
                    </Col>
                </Row>
            </>
        );
    };

    const renderHTMLCode = detail => {
        return (
            <>
                <div className="mt-32" dangerouslySetInnerHTML={{ __html: detail.content }} />
            </>
        );
    };

    return (
        <>
            {renderInfo()}
            {details.map(detail => (
                <div key={detail.id}>{renderDetail(detail)}</div>
            ))}
        </>
    );
};

export default RenderPageElements;

RenderPageElements.propTypes = {
    info: PropTypes.object,
    details: PropTypes.array,
};
