import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ArrowRightIcon } from 'components/SvgIcon';
import PropTypes from 'prop-types';
import SidebarTopClient from 'layouts/SidebarTopClient';
import { Button, Checkbox, Col, Form, Input, Row } from 'antd';
import { APP_URLS } from 'constants/url.constant';
import api from 'helpers/api.helper';
import { useTranslation } from 'react-i18next';
import history from 'helpers/history.helper';
import { setRegisterAccount } from 'pages/auth/auth.slice';
import Routes from 'routes';
import TermsOfUseModal from 'pages/auth/register/TermsOfUseModal';

const mapState = state => ({
    authenticated: state.auth.isAuth,
    registerAccount: state.auth.registerAccount,
});
const mapDispatch = { setRegisterAccount };
const Register = ({ authenticated, setRegisterAccount, registerAccount }) => {
    const { t } = useTranslation();
    const [agreeTerm, setAgreeTerm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errUniqueEmail, setErrUniqueEmail] = useState(false);
    const [errPasswordLength, setErrPasswordLength] = useState(false);

    const [form] = Form.useForm();
    const [isValid, setIsValid] = useState(false);

    const [modalVisible, setModalVisible] = useState({
        terms: false,
    });
    const showTermsModal = () => setModalVisible({ terms: true });
    const closeModal = () => setModalVisible({ terms: false });

    if (authenticated) {
        return <Redirect to={'/'} />;
    }

    const formSubmit = async values => {
        setLoading(true);
        try {
            setRegisterAccount({
                first_name: values.first_name,
                last_name: values.last_name,
                first_name_kana: values.first_name_kana,
                last_name_kana: values.last_name_kana,
                email: values.email,
                password: values.password,
            });
            setLoading(false);
            history.push(Routes.public.REGISTER_STEP_2.path);
        } catch (e) {
            setLoading(false);
        }
    };

    const validateMessages = {
        required: t('validation:Please enter ${label}'),
        types: {
            email: t('validation:Please check format of your ${label}'),
        },
    };

    const execValidateFields = () => {
        form.validateFields()
            .then(() => {
                setIsValid(true);
                setErrPasswordLength(false);
            })
            .catch(info => {
                if (form.getFieldError('password').length > 0) {
                    setErrPasswordLength(true);
                } else {
                    setErrPasswordLength(false);
                }
                setIsValid(info.errorFields.length < 1);
            });
    };

    const agreeTermOnChange = e => {
        execValidateFields();
        setAgreeTerm(e.target.checked);
    };

    const initialValues = Object.assign({
        first_name: registerAccount.first_name,
        last_name: registerAccount.last_name,
        first_name_kana: registerAccount.first_name_kana,
        last_name_kana: registerAccount.last_name_kana,
        email: registerAccount.email,
        password: registerAccount.password,
    });

    const checkUniqueEmail = async (rule, value) => {
        if (value) {
            try {
                const data = await api.post(APP_URLS.CHECK_UNIQUE_EMAIL, { email: value });
                if (data && data.is_existed) {
                    setErrUniqueEmail(true);
                    return Promise.reject(t('validation:Email has already been taken'));
                }
            } catch (e) {
                setErrUniqueEmail(false);
                return Promise.resolve();
            }
        }

        setErrUniqueEmail(false);
        return Promise.resolve();
    };

    return (
        <div id="register">
            <SidebarTopClient />
            <div className="register-header mt-56">
                <h2 className="td-header">{t('Register new')}</h2>
                <div className="register-step">
                    <div className="register-step__normal register-step__active">
                        <div className="mr-4">STEP1</div>
                        <div>アカウント情報入力</div>
                    </div>
                    <ArrowRightIcon className="arrow-right" />
                    <div className="register-step__normal">
                        <div className="mr-4">STEP2</div>
                        <div>企業コード入力</div>
                    </div>
                </div>
            </div>

            <Row justify="center" align="middle">
                <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                    <div id="form-wrapper" className="mt-32">
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={formSubmit}
                            validateMessages={validateMessages}
                            initialValues={initialValues}
                            onValuesChange={() => {
                                if (agreeTerm) {
                                    execValidateFields();
                                }
                            }}
                        >
                            <div id="form-step-1" className="m-16">
                                <Row gutter={16}>
                                    <Col xs={24} sm={24} md={12}>
                                        <Form.Item
                                            name="first_name"
                                            label={t('field:First name')}
                                            validateTrigger={['onBlur']}
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Input placeholder={t('field:First name (Placeholder)')} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12}>
                                        <Form.Item
                                            name="last_name"
                                            label={t('field:Last name')}
                                            field={t('field:Last name')}
                                            validateTrigger={['onBlur']}
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Input placeholder={t('field:Last name (Placeholder)')} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col xs={24} sm={24} md={12}>
                                        <Form.Item
                                            name="first_name_kana"
                                            label={t('field:First name kana')}
                                            validateTrigger={['onBlur']}
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                                () => ({
                                                    validator(_, value) {
                                                        if (!value || /^[ぁ-ん]+$/.test(value)) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(
                                                            new Error(t('validation:Please enter in hiragana'))
                                                        );
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input placeholder={t('field:First name kana (Placeholder)')} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12}>
                                        <Form.Item
                                            name="last_name_kana"
                                            label={t('field:Last name kana')}
                                            validateTrigger={['onBlur']}
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                                () => ({
                                                    validator(_, value) {
                                                        if (!value || /^[ぁ-ん]+$/.test(value)) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(
                                                            new Error(t('validation:Please enter in hiragana'))
                                                        );
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input placeholder={t('field:Last name kana (Placeholder)')} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item
                                            name="email"
                                            label={t('field:Email')}
                                            extra={t('Alert_Input Email address')}
                                            validateTrigger={['onBlur']}
                                            rules={[
                                                {
                                                    required: true,
                                                    type: 'email',
                                                },
                                                () => ({
                                                    validator(_, value) {
                                                        return checkUniqueEmail(_, value);
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input placeholder="sample@example.com" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item
                                            className="mb-16"
                                            label={t('field:Password')}
                                            extra={
                                                !errPasswordLength && (
                                                    <div role="alert">{t('Alert_Input 8 characters')}</div>
                                                )
                                            }
                                            name="password"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                                { min: 8, message: t('Alert_Input 8 characters') },
                                            ]}
                                        >
                                            <Input.Password min={8} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Form.Item name="agree_term" valuePropName="checked">
                                    <Checkbox className="program-checkbox" onChange={agreeTermOnChange}>
                                        <Button
                                            type="link"
                                            className="p-0 td-link-bolder"
                                            onClick={() => {
                                                showTermsModal();
                                            }}
                                        >
                                            {t('Terms of use')}
                                        </Button>
                                        {t('I agree with')}
                                    </Checkbox>
                                </Form.Item>
                                <Row justify="center" align="middle">
                                    <Form.Item className="mb-0">
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            disabled={!agreeTerm || errUniqueEmail || !isValid}
                                            loading={loading}
                                            className="td-btn disable-gray-btn"
                                        >
                                            {t('Enter company code for button')}
                                        </Button>
                                    </Form.Item>
                                </Row>
                                <div className="mt-32" align="middle">
                                    <Button
                                        type="link"
                                        className="p-0 td-link-bolder"
                                        onClick={() => {
                                            history.push(Routes.public.LOGIN.path);
                                        }}
                                    >
                                        {t('Click here if you registered')}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Col>
            </Row>
            <TermsOfUseModal
                onClose={() => {
                    closeModal();
                }}
                onUpdate={() => {
                    execValidateFields();
                    setAgreeTerm(true);
                    form.setFieldsValue({ agree_term: true });
                }}
                visible={modalVisible.terms}
                form={form}
                setAgreeTerm={setAgreeTerm}
            />
        </div>
    );
};

export default connect(mapState, mapDispatch)(Register);

Register.propTypes = {
    authenticated: PropTypes.bool.isRequired,
    setRegisterAccount: PropTypes.func.isRequired,
    registerAccount: PropTypes.any.isRequired,
};
