import React, { useState } from 'react';
import PropTypes from 'prop-types';
import QuestionTypeEnum from 'enums/question_type.enum';
import { useTranslation } from 'react-i18next';
import { Input, Radio } from 'antd';
import QuestionCommon from 'components/questions/QuestionCommon';

const SingleSelectQuestion = ({ info, optionsOrder, options, ...rest }) => {
    const { t } = useTranslation();

    // const other = { id: rest.id, value: rest.value, onChange: rest.onChange };
    const other = { id: rest.id };
    const customValue = {
        ids: rest.value ? rest.value.ids : [],
        others: rest.value ? rest.value.others : {},
    };

    const [others, setOthers] = useState(customValue.others);
    const handleOrderInput = (optId, value) => {
        setOthers({ ...others, [optId]: value });
        rest.onChange && rest.onChange({ ids: customValue.ids, others: { ...customValue.others, [optId]: value } });
    };

    return (
        <div className="question single-select">
            <QuestionCommon
                title={info.value}
                addition={info.addition}
                type={t(`enum:${QuestionTypeEnum.SINGLE_SELECTION.text}`)}
                isRequired={info.isRequired || info.is_required}
            />
            <Radio.Group
                {...other}
                className="vertical-group mt-16"
                value={customValue.ids ? customValue.ids[0] : customValue.ids}
                onChange={e => {
                    const v = e.target.value;
                    let newOthers = {};
                    if (others) {
                        Object.keys(others).map(k => {
                            if ([v].indexOf(Number(k)) > -1) {
                                newOthers[k] = others[k];
                            }
                        });
                    }
                    setOthers(newOthers);
                    rest.onChange && rest.onChange({ ids: [v], others: newOthers });
                }}
            >
                {optionsOrder != null &&
                    optionsOrder.map(optId => {
                        return (
                            <React.Fragment key={optId}>
                                <Radio value={optId}>{options[optId].value}</Radio>
                                {options[optId].type === 'other' && customValue.ids.includes(optId) && (
                                    <Input
                                        value={others[optId] || ''}
                                        style={{ minWidth: 480 }}
                                        onChange={evt => handleOrderInput(optId, evt.target.value)}
                                        className="mb-16"
                                    />
                                )}
                            </React.Fragment>
                        );
                    })}
            </Radio.Group>
        </div>
    );
};

export default SingleSelectQuestion;

SingleSelectQuestion.propTypes = {
    info: PropTypes.shape({
        value: PropTypes.string,
        isRequired: PropTypes.bool,
    }),
    optionsOrder: PropTypes.array,
    options: PropTypes.object,
};
