import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Typography } from 'antd';
import { CloseIcon, ReportProblemIcon } from 'components/SvgIcon';
import { useTranslation } from 'react-i18next';
import DataTable from 'components/DataTable';
import ActionBtnInDistributionModal from 'components/ActionBtnInDistributionModal';

const ErrorCSVUploadModal = ({ visible, onClose, csvData }) => {
    const { t } = useTranslation();
    const { error = {}, emails = [] } = csvData;

    const errorToData = error => Object.keys(error).map(k => ({ id: `${k}_${error[k]}`, line: k, type: error[k] }));

    const columns = [
        {
            title: '行数',
            dataIndex: 'line',
            width: 160,
            sorter: (a, b) => {
                return a.line.localeCompare(b.line);
            },
            render: line => `${Number(line) + 1}行目`,
        },
        {
            title: 'エラーメッセージ',
            dataIndex: 'type',
            render: type =>
                type === 'duplicate' ? 'メールアドレスが重複しています' : 'メールアドレスが間違っております',
        },
    ];
    return (
        <>
            <Modal
                title={t('field:Target registration')}
                visible={visible}
                width={640}
                footer={
                    <div className=" pt-6 pb-6 fw-400 fs-16 space">
                        <ActionBtnInDistributionModal
                            onClose={onClose}
                            onUpdate={() => {}}
                            confirmText={'OK'}
                            // okButtonDisabled={true}
                        />
                    </div>
                }
                closeIcon={<CloseIcon />}
                onCancel={() => onClose()}
            >
                <>
                    <div className="mt-8 d-flex bg-lavender-blush" style={{ width: 608, height: 98, borderRadius: 4 }}>
                        <ReportProblemIcon className="mt-14 ml-16 color-red-100" />
                        <div>
                            <Typography className=" mt-12 ml-10 fw-400 fs-16">
                                {'アップロード中にエラーが発生しました。入力内容をご確認の上、もう一度お試しください。'}
                            </Typography>
                            <Typography className=" mt-8 ml-10 fw-400 fs-14 color-blg-80">
                                {'※半角/全角入力による間違いが多いため、お気をつけください。'}
                            </Typography>
                        </div>
                    </div>
                    <DataTable
                        className="mt-16 collapse-table table-border-lighter"
                        columns={columns}
                        data={errorToData(error) || []}
                        noDataText={' '}
                        //yScroll={{ y: 260 }}
                        onTableChange={() => {}}
                    />
                </>
            </Modal>
        </>
    );
};

ErrorCSVUploadModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

export default ErrorCSVUploadModal;
