import React from 'react';

import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
} from 'chart.js';
import { Radar, Line } from 'react-chartjs-2';
import { dateToGraphAxis, formatPointValue, getMatchGraphPeriod } from 'helpers/date.helper';
import { formatNumberTwoDigit } from 'helpers/number_format.helper';

ChartJS.register(CategoryScale, LinearScale, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const radarStyles = [
    {
        backgroundColor: 'rgba(225, 51, 141, 0.16)',
        borderColor: '#E1338D',
    },
    {
        backgroundColor: 'rgba(0, 136, 255, 0.16)',
        borderColor: '#0088FF',
        borderDash: [3],
    },
];

const graphStyles = [
    {
        backgroundColor: '#F66155',
        borderColor: '#F66155',
    },
    {
        backgroundColor: '#7E0CE8',
        borderColor: '#7E0CE8',
    },
    {
        backgroundColor: '#F4BE26',
        borderColor: '#F4BE26',
    },
    {
        backgroundColor: '#3D9FF5',
        borderColor: '#3D9FF5',
    },
    {
        backgroundColor: '#0CDF32',
        borderColor: '#0CDF32',
    },
    {
        backgroundColor: '#009688',
        borderColor: '#009688',
    },
    {
        backgroundColor: '#795548',
        borderColor: '#795548',
    },
    {
        backgroundColor: '#607d8b',
        borderColor: '#607d8b',
    },
];

const getStyleByIndex = (styles, i) => {
    i = Math.min(i, styles.length - 1);
    return styles[i];
};

const RadarChart = ({ chartData, hideLegend = false, showScore = false }) => {
    const { labels, datasets } = chartData;
    const data = {
        labels: labels,
        datasets: datasets.map((o, i) => ({
            ...o,
            ...getStyleByIndex(radarStyles, i),
            borderWidth: 1,
            pointRadius: 0,
        })),
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: !hideLegend,
                labels: {
                    usePointStyle: true,
                    boxWidth: 10,
                    boxHeight: 10,
                    padding: 24,
                    font: {
                        size: '14px',
                        lineHeight: '21px',
                        family: 'Noto Sans JP',
                    },
                },
                position: 'bottom',
            },
            tooltip: {
                // displayColors: false,
                usePointStyle: 'circle',
                pointRadius: 5,
                backgroundColor: 'white',
                titleColor: 'black',
                bodyColor: 'black',
                cornerRadius: 4,
                borderWidth: 1,
                borderColor: '#D1D0D6',
                padding: 8,
                callbacks: {
                    title: tooltipItem => {
                        return tooltipItem[0].label;
                    },
                    label: tooltipItem => {
                        const { dataset, dataIndex } = tooltipItem;
                        const dataScore = dataset.dataScore;

                        // format
                        let formattedValue = formatNumberTwoDigit(tooltipItem.formattedValue);
                        if (showScore && dataScore && dataScore.length > dataIndex) {
                            return ' ' + formattedValue + ' (' + formatNumberTwoDigit(dataScore[dataIndex]) + '点)';
                        }
                        return ' ' + formattedValue;
                    },
                },
            },
        },
        scales: {
            r: {
                min: 0,
                max: 100,
                beginAtZero: true,
                ticks: {
                    min: 0,
                    maxTicksLimit: 6,
                    stepSize: 20,
                    callback: value => value,
                },
                pointLabels: {
                    color: '#222126',
                    font: {
                        family: 'Noto Sans JP',
                        size: 12,
                        weight: 700,
                        lineHeight: '18px',
                    },
                },
            },
        },
        elements: {
            point: {
                radius: 0,
                hitRadius: 16,
                hoverRadius: 6,

            },
        },
    };

    return (
        <div style={{ maxWidth: '500px', width: '100%' }} className="d-flex mb-40 ml-auto mr-auto">
            <Radar type={'radar'} data={data} options={options} />
        </div>
    );
};

export default RadarChart;

/** Initialize array with radius of n and queue values ahead of it */
const pointRadiusLast = (radius, length, initialArray) => {
    let result = initialArray || [radius];
    while (result.length < length) result.unshift(0);
    return result;
};

export const TransitionGraphChart = ({ chartData, showScore = false, graphPeriods = [] }) => {
    const { labels, datasets } = chartData;

    let lastForDrawPlot = datasets[0]?.data?.length + 1;
    if (datasets && datasets.length > 0) {
        for (let i = datasets[0]['data'].length - 1; i >= 0; i--) {
            if (datasets[0]['data'][i] !== null) {
                lastForDrawPlot = i + 1;
                break;
            }
        }
    }

    const data = {
        labels: labels,
        datasets: datasets.map((o, i) => ({
            ...getStyleByIndex(graphStyles, i),
            ...o,
            borderWidth: 2,
            pointRadius: pointRadiusLast(4, lastForDrawPlot),
        })),
    };

    return (
        <div style={{ maxWidth: '860px', width: '100%', minHeight: 400 }} className="d-flex mb-40 ml-auto mr-auto graph-h-300">
            <Line
                type={'line'}
                data={data}
                options={{
                    spanGaps: true,
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        y: {
                            position: 'right',
                            suggestedMin: 0,
                            min: 0,
                            max: 100,
                            beginAtZero: true,
                            grid: {
                                borderColor: 'transparent',
                            },
                            ticks: {
                                // maxTicksLimit: 5, // max x lines
                                stepSize: 20,
                                callback: value => value,
                            },
                        },
                        x: {
                            grid: {
                                display: false,
                            },
                            offset: 64, // padding left - right,
                            ticks: {
                                callback: function (value) {
                                    const label = this.getLabelForValue(value);
                                    return dateToGraphAxis(label);
                                },
                            },
                        },
                    },
                    elements: {
                        point: {
                            hitRadius: 10,
                            hoverRadius: 5,
                        },
                    },
                    interaction: {
                        intersect: false,
                        mode: 'index',
                    },
                    plugins: {
                        legend: {
                            // display: false,
                            labels: {
                                usePointStyle: true,
                                boxWidth: 6,
                                boxHeight: 6,
                                padding: 24,
                                font: {
                                    size: '14px',
                                    lineHeight: '18px',
                                    weight: 400,
                                    family: 'Noto Sans JP',
                                },
                            },
                            position: 'bottom',
                        },
                        tooltip: {
                            // displayColors: false,
                            usePointStyle: 'circle',
                            pointRadius: 5,
                            backgroundColor: 'white',
                            titleColor: 'black',
                            bodyColor: 'black',
                            cornerRadius: 4,
                            borderWidth: 1,
                            borderColor: '#D1D0D6',
                            padding: 12,
                            callbacks: {
                                title: tooltipItem => {
                                    let label = tooltipItem[0].label;

                                    const matchGraphPeriod = getMatchGraphPeriod(graphPeriods, label);
                                    if (matchGraphPeriod) {
                                        label = [
                                            matchGraphPeriod.displayPeriodStart + '-',
                                            matchGraphPeriod.displayPeriodEnd,
                                        ];
                                    }

                                    return label;
                                },
                                label: tooltipItem => {
                                    const { dataset, dataIndex } = tooltipItem;
                                    const { dataScore, label } = dataset;

                                    let formattedValue = formatNumberTwoDigit(tooltipItem.formattedValue);

                                    let result = label + ' ' + formattedValue;

                                    if (showScore && dataScore && dataScore.length > dataIndex) {
                                        result += ' (' + formatNumberTwoDigit(dataScore[dataIndex]) + '点)';
                                    }
                                    return result;
                                },
                            },
                        },
                    },
                }}
                plugins={[
                    {
                        beforeDraw(chart) {
                            if (chart.tooltip?._active?.length) {
                                let x = chart.tooltip._active[0].element.x;
                                let yAxis = chart.scales.y;
                                let ctx = chart.ctx;
                                ctx.save();
                                ctx.beginPath();
                                ctx.moveTo(x, yAxis.top - 5);
                                ctx.lineTo(x, yAxis.bottom + 5);
                                ctx.lineWidth = 1;
                                ctx.strokeStyle = '#767384';
                                ctx.stroke();
                                ctx.restore();
                            }

                            // vertical line at last
                            let xAxis;
                            try {
                                xAxis = chart.scales.x.getPixelForTick(lastForDrawPlot - 1);
                            } catch (e) {
                                xAxis = chart.scales.x.getPixelForTick(chart.config.data.labels.length - 1);
                            }

                            let yAxis = chart.scales.y;
                            let ctx = chart.ctx;
                            ctx.save();
                            ctx.beginPath();
                            ctx.moveTo(xAxis, yAxis.top - 5);
                            ctx.strokeStyle = '#767384';
                            ctx.lineTo(xAxis, yAxis.bottom + 5);
                            ctx.stroke();
                            ctx.restore();
                        },
                    },
                ]}
            />
        </div>
    );
};
