import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { CompanyPropTypes } from 'constants/proptypes.constant';
import { getCompany, setCompany } from 'pages/admin/company/company_detail.slice';
import { resetData as resetEmployeeData } from 'pages/admin/company/tabs/employee/employee.slice';
import CompanyTabList from 'pages/admin/company/CompanyTabList';
import CompanyDetailRoutes from 'pages/admin/company/company_detail_routes';
import Loader from 'components/Loader';

const mapState = state => ({
    company: state.admin.companyDetail.company,
    user: state.auth.user,
});
const mapDispatch = { getCompany, resetEmployeeData, setCompany };

const CompanyDetail = ({ company, getCompany, resetEmployeeData, setCompany, user }) => {
    const { id } = useParams();
    let { path, url } = useRouteMatch();

    useEffect(() => {
        resetEmployeeData();
        getCompany(id);

        return () => {
            setCompany(null);
        };
    }, [id]);

    if (!company) {
        return <Loader className="loader-center" />;
    }

    const renderTabList = tab => {
        return <CompanyTabList tab={tab} url={url} company={company} user={user} />;
    };

    return (
        <div className="self-wrapper">
            <Switch>
                {Object.keys(CompanyDetailRoutes).map(k => {
                    const r = CompanyDetailRoutes[k];
                    return (
                        <Route
                            key={`company_detail_${r.path}`}
                            path={`${path}${r.path}`}
                            exact
                            render={props => (
                                <r.component {...props} company={company} renderTabList={renderTabList} baseUrl={url} />
                            )}
                        />
                    );
                })}
            </Switch>
        </div>
    );
};

export default connect(mapState, mapDispatch)(CompanyDetail);

CompanyDetail.propTypes = {
    company: CompanyPropTypes,
    getCompany: PropTypes.func.isRequired,
    resetEmployeeData: PropTypes.func,
    setCompany: PropTypes.func,
};
