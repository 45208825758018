import React from 'react';
import { useTranslation } from 'react-i18next';
import WeightChart from 'pages/counselor/calendar/health_data/WeightChart';

const HealthData = () => {
    const { t } = useTranslation();

    return (
        <>
            <WeightChart />
        </>
    );
};

export default HealthData;
