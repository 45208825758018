import { createSlice } from '@reduxjs/toolkit';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import { showNormal } from 'helpers/notification.helper';

const name = 'counselorMessageDetail';

const counselorMessageDetailSlice = createSlice({
    name,
    initialState: {
        messageThread: null,
        loading: true,
    },
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setMessageThread(state, action) {
            state.messageThread = action.payload;
            state.loading = false;
        },
        setMessages(state, action) {
            state.messageThread.messages = [...state.messageThread.messages, action.payload];
        },
    },
});

export const { setLoading, setMessageThread, setMessages } = counselorMessageDetailSlice.actions;

export const getMessageThread = messageThreadId => async dispatch => {
    dispatch(setLoading(true));
    const data = await api.get(APP_URLS.MESSAGE_THREADS_DETAIL.replace(':id', messageThreadId), {
        module: 'counselor',
    });
    if (data) {
        dispatch(setMessageThread(data));
    }
};

export const createMessage = (messageThreadId, content, completeMessage) => async dispatch => {
    const data = await api.post(APP_URLS.MESSAGE_THREADS_CREATE, {
        content,
        message_thread_id: messageThreadId,
        module: 'counselor_message',
    });
    if (data) {
        dispatch(setMessages(data));
        showNormal('', completeMessage, 3);
    }
};

export default counselorMessageDetailSlice.reducer;
