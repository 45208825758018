import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import BackBtn from 'components/BackBtn';
import ReservationForm from 'pages/counselor/calendar/reservation-create/ReservationForm';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import history from 'helpers/history.helper';
import Routes from 'routes';
import moment from 'moment';
import { showNormal } from 'helpers/notification.helper';

const ReservationCreate = ({ redirectTo }) => {
    const { t } = useTranslation();

    const createReservation = async values => {
        const data = await api.post(APP_URLS.COUNSELOR_RESERVATIONS_SUBMIT.replace(':id', values['client_id']), values);
        if (data) {
            history.push(redirectTo ? redirectTo : Routes.private.COUNSELOR_CALENDAR.path);
            showNormal('', t('message:I made a reservation'), 3);
        }
    };

    const location = useLocation();
    const urlSearchParams = new URLSearchParams(location.search);
    const date = urlSearchParams.get('date');
    const time = urlSearchParams.get('time');

    let initialValues = date
        ? {
              date: moment(date),
              time: time ? `${time}〜` : null,
              timeStrValue: time,
          }
        : {};

    return (
        <div className="self-wrapper d-block mb-32 ml--4 px-48">
            <BackBtn className="back-btn-counselor" label={t('Consultation reservation creation')} />
            <ReservationForm
                onSubmit={values => createReservation(values)}
                initialValues={initialValues}
                createFromBlock={!!date}
            />
        </div>
    );
};

export default ReservationCreate;
