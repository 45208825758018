import React from 'react';
import QuestionTypeEnum from 'enums/question_type.enum';
import FreeInputQuestion from 'components/questions/FreeInputQuestion';
import SingleSelectQuestion from 'components/questions/SingleSelectQuestion';
import MultipleSelectQuestion from 'components/questions/MultipleSelectQuestion';
import DateTimeQuestion from 'components/questions/DateTimeQuestion';
import DailyLifeQuestion from 'components/questions/DailyLifeQuestion';
import { Typography } from 'antd';

const QuestionnairePreview = ({ data, state: byState }) => {
    const convertDataToState = data => {
        let state = {};
        const {
            name,
            input_condition: inputCondition,
            description,
            questions,
            in_used,
            json_categories: jsonCategories,
        } = data;
        state.name = name;
        state.inputCondition = inputCondition;
        state.description = description;
        let stateQuestions = {
            byId: {},
            order: [],
        };
        let stateOptions = {
            byId: {},
        };
        let stateQuestionOptions = {};
        for (const question of questions) {
            let { id, type, order, value, addition, is_required } = question;
            stateQuestions.byId[id] = { id, type, order, value, addition, isRequired: is_required };
            stateQuestions.order.push(id);
            if (question.options && question.options.length > 0) {
                stateQuestionOptions[id] = [];
            }
            for (const option of question.options) {
                let { id: optId, order, value, type, is_range } = option;
                stateOptions.byId[optId] = { value, type, order, isRange: is_range };
                stateQuestionOptions[id].push(optId);
            }
        }
        state.questions = stateQuestions;
        state.options = stateOptions;
        state.questionOptions = stateQuestionOptions;

        state.inUsed = in_used;

        // parse categories
        if (jsonCategories) {
            const arrCategories = JSON.parse(jsonCategories);
            if (arrCategories.length > 0) {
                const questionsOrder = stateQuestions.order;
                let stateCategories = {
                    byId: {},
                    order: [],
                };

                // +1 because bug !0 is true
                for (let i = 1; i <= arrCategories.length; i++) {
                    const { title, start, end } = arrCategories[i - 1];

                    stateCategories.byId[i] = { id: i, title, children: questionsOrder.slice(start, end + 1) };
                    stateCategories.order.push(i);
                }
                state.categories = stateCategories;
            }
        }

        return state;
    };

    const state = byState ? byState : data && data.id ? convertDataToState(data) : {};

    const renderPreview = () => {
        return (
            <div className="interview-sheet-preview border-top-none p-0">
                <h2 className="mb-8">{state.name}</h2>
                <p className="mb-24">{state.inputCondition}</p>
                <p className="description mb-16">{state.description}</p>
                <p className="note mb-0">*必須項目</p>

                <div className="pointer-events-none">
                    {state.categories && state.categories.order.length > 0 ? (
                        <>
                            {state.categories.order.map(categoryId => {
                                const category = state.categories.byId[categoryId];
                                return (
                                    <div key={`rct-${categoryId}`} className="mt-24">
                                        <Typography
                                            className="fs-16 fw-700 mb--24 bg-gray-5"
                                            style={{ paddingLeft: 16, paddingTop: 8, paddingBottom: 8 }}
                                        >
                                            {category.title}
                                        </Typography>
                                        {category.children.map(questionId => {
                                            const info = state.questions.byId[questionId];
                                            return (
                                                <SingleSelectQuestion
                                                    key={`ssq_${questionId}`}
                                                    info={info}
                                                    optionsOrder={state.questionOptions[questionId]}
                                                    options={state.options.byId}
                                                />
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </>
                    ) : (
                        <>
                            {state.questions.order.map(id => {
                                const info = state.questions.byId[id];
                                if (!info) return null;
                                let Component = null;
                                switch (info.type) {
                                    case QuestionTypeEnum.FREE_INPUT.value:
                                    case QuestionTypeEnum.FREE_INPUT_PARAGRAPH.value:
                                    case QuestionTypeEnum.FREE_INPUT_NUMBER.value:
                                        Component = FreeInputQuestion;
                                        break;
                                    case QuestionTypeEnum.SINGLE_SELECTION.value:
                                        Component = SingleSelectQuestion;
                                        break;
                                    case QuestionTypeEnum.MULTIPLE_SELECTION.value:
                                        Component = MultipleSelectQuestion;
                                        break;
                                    case QuestionTypeEnum.DATE_TIME.value:
                                        Component = DateTimeQuestion;
                                        break;
                                    case QuestionTypeEnum.DAILY_LIFE.value:
                                        Component = DailyLifeQuestion;
                                        break;
                                }
                                if (Component) {
                                    return (
                                        <Component
                                            key={id}
                                            info={info}
                                            optionsOrder={state.questionOptions[id]}
                                            options={state.options.byId}
                                        />
                                    );
                                }
                                return <div key={id}>{id}</div>;
                            })}
                        </>
                    )}
                </div>
            </div>
        );
    };

    return <>{(data && data.id) || byState ? renderPreview() : <></>}</>;
};

export default QuestionnairePreview;
