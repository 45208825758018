import React from 'react';
import Axis from 'components/chart/Axis';
import { defaultConfig, getMinMax, getYAxisInfo } from 'helpers/chart.helper';

const RangeChart = ({
    width = 1000,
    height = 320,
    data,
    config,
    yMin,
    yStepSize,
    yCount,
    yLabelRender,
    xLabelRender,
    tooltipRender,
    className,
}) => {
    let chartConfig;
    if (config) {
        chartConfig = { ...defaultConfig, ...config };
    } else {
        chartConfig = defaultConfig;
    }
    const { min: minValue, max: maxValue } = getMinMax(data.values);

    // Y Axis
    const yInfo = getYAxisInfo(minValue, maxValue, yMin, yStepSize, yCount);
    const yStepHeight = (height - chartConfig.xLabelHeight) / yInfo.count;

    // X Axis
    const xStepWidth = (width - chartConfig.yLabelWidth - chartConfig.xPaddingRight) / data.names.length;

    // Rects
    let rects = [];
    for (let i = 0; i < data.values.length && i < data.names.length; i++) {
        const value = data.values[i];
        if (value === null || typeof value === 'undefined') {
            continue;
        }
        const x = (i + 0.5) * xStepWidth + chartConfig.yLabelWidth;
        const name = data.names[i];
        const y = height - chartConfig.xLabelHeight - ((value[1] - yInfo.min) / yInfo.size) * yStepHeight;
        const rectHeight = ((value[1] - value[0]) / yInfo.size) * yStepHeight;
        rects.push({
            x,
            y,
            name,
            height: rectHeight,
            value,
        });
    }

    return (
        <svg viewBox={`0 0 ${width} ${height}`} className={className}>
            <Axis
                width={width - chartConfig.xPaddingRight}
                height={height}
                names={data.names}
                config={chartConfig}
                yInfo={yInfo}
                yStepHeight={yStepHeight}
                xStepWidth={xStepWidth}
                yLabelRender={yLabelRender}
                xLabelRender={xLabelRender}
            />
            {rects.map((rect, index) => {
                if (rect.height === 0) {
                    return (
                        <circle
                            key={index}
                            cx={rect.x}
                            cy={rect.y}
                            r={chartConfig.rectWidth / 2}
                            fill={data.colors[0]}
                        />
                    );
                }
                return (
                    <rect
                        key={index}
                        x={rect.x - chartConfig.rectWidth / 2}
                        y={rect.y}
                        width={chartConfig.rectWidth}
                        height={rect.height}
                        rx={chartConfig.rectWidth / 2}
                        ry={chartConfig.rectWidth / 2}
                        fill={data.colors[0]}
                    />
                );
            })}
            {rects.map((rect, index) => {
                let lineTopEnd = rect.y;
                let lineBottomStart = rect.y + rect.height;
                if (rect.height === 0) {
                    lineTopEnd = rect.y - chartConfig.rectWidth / 2;
                    lineBottomStart = rect.y + chartConfig.rectWidth / 2;
                }

                return (
                    <g key={index} className="g-hover">
                        <rect
                            x={rect.x - xStepWidth / 2}
                            y={0}
                            width={xStepWidth}
                            height={height - chartConfig.xLabelHeight}
                            fillOpacity={0}
                        />
                        <line
                            className="hover-line"
                            x1={rect.x}
                            y1={yStepHeight / 2}
                            x2={rect.x}
                            y2={lineTopEnd}
                            strokeWidth={1}
                            stroke="#666666"
                        />
                        <line
                            className="hover-line"
                            x1={rect.x}
                            y1={lineBottomStart}
                            x2={rect.x}
                            y2={height - chartConfig.xLabelHeight}
                            strokeWidth={1}
                            stroke="#666666"
                        />
                        {tooltipRender && <g className="tooltip">{tooltipRender(rect)}</g>}
                    </g>
                );
            })}
        </svg>
    );
};

export default React.memo(RangeChart);
