import { createSelector, createSlice } from '@reduxjs/toolkit';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import { baseChartInitState, baseChartReducer } from 'helpers/slice.helper';
import moment from 'moment';
import ResponsePeriodType from 'enums/response_period_type.enum';
import { periodToDateTime } from 'helpers/date.helper';

const name = 'interviewSheetDetail';

const interviewSheetDetailSlice = createSlice({
    name,
    initialState: {
        ...baseChartInitState,
        interviewSheet: null,
        periodFilter: {
            companyId: null,
            dateRange: null,
            displayPeriodType: ResponsePeriodType.ANSWER_PERIOD.value,

            //case: ANSWER_PERIOD
            answerPeriodId: 'whole_period',
        },
    },
    reducers: {
        ...baseChartReducer,
        setInterviewSheet(state, action) {
            state.interviewSheet = action.payload;
        },
        setFilterByCompany(state, action) {
            state.periodFilter.companyId = action.payload;
        },
        setFilterByDateRange(state, action) {
            state.periodFilter.dateRange = action.payload;
        },
        setDisplayPeriodType(state, action) {
            state.periodFilter.displayPeriodType = action.payload;
        },
        setFilterByAnswerPeriodId(state, action) {
            state.periodFilter.answerPeriodId = action.payload;
        },
        resetFilter(state) {
            state.periodFilter = {
                companyId: null,
                dateRange: null,
                displayPeriodType: ResponsePeriodType.ANSWER_PERIOD.value,
                answerPeriodId: 'whole_period',
            };
        },
    },
});

export const {
    setDisplayType,
    setInterviewSheet,
    setFilterByCompany,
    setFilterByDateRange,
    setDisplayPeriodType,
    setFilterByAnswerPeriodId,
    resetFilter,
} = interviewSheetDetailSlice.actions;

export const getInterviewSheet = id => async dispatch => {
    const data = await api.get(APP_URLS.ADMIN_INTERVIEW_SHEETS_DETAIL.replace(':id', id) + '?overview');
    if (data) {
        dispatch(setDisplayPeriodType(data.is_radar_questionnaire ? ResponsePeriodType.ANSWER_PERIOD.value : ResponsePeriodType.FREE_CHOICE_OF_PERIOD.value));
        dispatch(setInterviewSheet(data));
    }
};

export default interviewSheetDetailSlice.reducer;

export const overviewSelector = state => {
    const { interviewSheet, periodFilter } = state;

    let companies = {};
    let answers = [];
    for (const answer of interviewSheet.filled_in_answers) {
        const company = answer.user.employee_company[0];
        if (!companies[company.id]) {
            companies[company.id] = company;
        }
        if (!periodFilter.companyId || periodFilter.companyId === company.id) {

            // type select period
            if (periodFilter.displayPeriodType === ResponsePeriodType.ANSWER_PERIOD.value) {

                // whole_period
                if (periodFilter.answerPeriodId === 'whole_period') {
                    answers.push(answer);
                } else {
                    // with answer period id
                    const period = interviewSheet.periods.find(o => o.id === periodFilter.answerPeriodId);
                    if (period) {
                        const periodStart = periodToDateTime(period.start_date, period.start_hour, period.start_minute);
                        const periodEnd = periodToDateTime(period.end_date, period.end_hour, period.end_minute);
                        if (moment(answer.updated_at).isBetween(periodStart, periodEnd)) {
                            answers.push(answer);
                        }
                    }
                }

                continue;
            }

            // ResponsePeriodType.FREE_CHOICE_OF_PERIOD: free select range
            // filter by dateRange
            if (
                !periodFilter.dateRange ||
                moment(answer.updated_at).isBetween(
                    periodFilter.dateRange[0].startOf('day'),
                    periodFilter.dateRange[1].endOf('day')
                )
            ) {
                answers.push(answer);
            }
        }
    }
    companies = Object.values(companies);

    return {
        companies,
        answers,
        lastAnswer: answers.length > 0 ? answers[0] : null,
        totalAnswers: answers.length,
        questions: interviewSheet.questions,
    };
};

const startDateSelector = state => state.startDate;
const endDateSelector = state => state.endDate;
const displayTypeSelector = state => state.displayType;
export const chartDataSelector = createSelector(
    startDateSelector,
    endDateSelector,
    displayTypeSelector,
    overviewSelector,
    (startDate, endDate, displayType, overview) => {
        let diff,
            duration,
            names = [],
            tmpObj = {};

        if (displayType === 'year') {
            duration = 'months';
        } else {
            duration = 'days';
        }

        diff = endDate.diff(startDate, duration);
        for (let i = 0; i <= diff; i++) {
            const tmpDate = startDate.clone().add(i, duration).format('YYYY-MM-DD');
            names.push(tmpDate);
            tmpObj[tmpDate] = null;
        }

        const { answers } = overview;

        for (const answer of answers) {
            let date;
            if (displayType === 'year') {
                date = `${answer.created_at.substr(0, 7)}-01`;
            } else {
                date = answer.created_at.substr(0, 10);
            }
            if (!tmpObj[date]) {
                tmpObj[date] = [1];
            } else {
                tmpObj[date][0] += 1;
            }
        }
        return { names, values: Object.values(tmpObj), colors: ['#7046F1'] };
    }
);
