import React from 'react';
import { Spin } from 'antd';
import PropTypes from 'prop-types';

const Loader = ({ className = '' }) => (
    <div className={`loader ${className ? className : ''}`}>
        <Spin />
    </div>
);

export default Loader;

Loader.prototype = {
    className: PropTypes.string,
};
