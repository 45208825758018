import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DatePicker, Row, Col, Typography, Avatar, Image, Modal } from 'antd';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import { dateFormatForHealth, dateToStr } from 'helpers/date.helper';
import moment from 'moment';
import { CalendarIcon } from 'components/SvgIcon';
import NoData from 'components/NoData';
import { isAdminStaff } from 'helpers/role.helper';
import TdtButtonRestrict from 'components/TdtButtonRestrict';

const mapState = state => ({
    user: state.auth.user,
});

const PhotoTabContainer = ({ category, user }) => {
    const { RangePicker } = DatePicker;
    const dateFormat = 'YYYY/MM/DD';

    const [listPhoto, setListPhoto] = useState([]);
    const [date, setDate] = useState({});

    const { id } = useParams();

    const getListPhoto = async category => {
        const response = await api.get(
            APP_URLS.COUNSELOR_HEALTH_DATA_IMAGE_BY_CATEGORY.replace(':category', category),
            {
                user_id: id,
                ...date,
            }
        );
        if (response) {
            setListPhoto(response);
        }
    };

    useEffect(() => {
        getListPhoto(category);
    }, [category, date]);

    const groupImagesByDate = listImages => {
        let result = {};
        listImages.map(image => {
            const date = dateToStr(image.created_at, 'YYYY年M月DD日');
            result[date] = result[date] || [];
            result[date].push(image);
        });
        return result;
    };

    const imageGrouping = groupImagesByDate(listPhoto);

    const createdAtChangeHandler = value => {
        setDate({
            'created_at:gte': value[0] + ' 00:00:00',
            'created_at:lte': value[1] + ' 23:59:59',
        });
    };

    const [modalVisible, setModalVisible] = useState({
        image: false,
        deleteImage: false,
        createImage: false,
        data: {},
        id: null,
    });

    const showImageModal = data => setModalVisible({ image: true, deleteImage: false, data: data });

    const closeModal = () =>
        setModalVisible({ image: false, deleteImage: false, createImage: false, data: {}, id: null });
    return (
        <>
            <div className="mt-32 ml-32">
                {!isAdminStaff(user) && (
                    <Row>
                        <Col span={24}>
                            <div className="photo-range-picker-wrapper">
                                <RangePicker
                                    className="photo-range-picker"
                                    defaultValue={[moment.utc().startOf('month'), moment.utc()]}
                                    format={dateFormat}
                                    showToday={true}
                                    allowClear={false}
                                    suffixIcon={<CalendarIcon className="pl-8" size={20} />}
                                    onChange={(mm, value) => {
                                        createdAtChangeHandler(value);
                                    }}
                                    inputReadOnly={true}
                                />
                            </div>
                        </Col>
                    </Row>
                )}

                {isAdminStaff(user) ? (
                    <TdtButtonRestrict withImage={true} />
                ) : (
                    <>
                        {listPhoto.length === 0 ? (
                            <NoData />
                        ) : (
                            <Row className="mt-32">
                                <Col span={24}>
                                    {Object.keys(imageGrouping).map(k => {
                                        return (
                                            <>
                                                <Typography key={k} className="mt-24">
                                                    {k}
                                                </Typography>
                                                {imageGrouping[k].map(image => {
                                                    return (
                                                        <>
                                                            <a onClick={() => showImageModal(image)}>
                                                                <Avatar
                                                                    className="mr-16 mt-20"
                                                                    shape="square"
                                                                    size={64}
                                                                    key={k}
                                                                    src={`${APP_URLS.STATIC_BASE_URL}/${image.url}`}
                                                                />
                                                            </a>
                                                        </>
                                                    );
                                                })}
                                            </>
                                        );
                                    })}
                                    <Modal
                                        closable={true}
                                        onCancel={closeModal}
                                        cancelButtonProps={false}
                                        visible={modalVisible.image}
                                        width={600}
                                        bodyStyle={{ paddingTop: '64px' }}
                                        footer={
                                            <>
                                                <div className="footer-photo-modal">
                                                    <Typography className="photo-tab-modal none-pc">
                                                        {dateFormatForHealth(
                                                            modalVisible.data && modalVisible.data.created_at
                                                        )}
                                                    </Typography>
                                                </div>
                                            </>
                                        }
                                    >
                                        <div className="photo-tab-modal center">
                                            <Image
                                                shape="square"
                                                preview={false}
                                                size={64}
                                                style={{ maxHeight: '650px' }}
                                                src={`${APP_URLS.STATIC_BASE_URL}/${modalVisible.data.url}`}
                                            />
                                        </div>
                                    </Modal>
                                </Col>
                            </Row>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default connect(mapState, null)(PhotoTabContainer);

PhotoTabContainer.propTypes = {
    category: PropTypes.any,
};
