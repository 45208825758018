import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Select } from 'antd';
import Routes from 'routes';
import { BaseStatePropShape, CompanyPropTypes } from 'constants/proptypes.constant';
import CompanyStateEnum from 'enums/company_state.enum';
import { getEnumText } from 'helpers/enum.helper';
import history from 'helpers/history.helper';
import DataTable from 'components/DataTable';
import FloatBar from 'components/FloatBar';
import { AddIcon, EditIcon } from 'components/SvgIcon';
import { getData, setFilters, setSorter, setPagination } from 'pages/admin/company/company.slice';
import TdtButton from 'components/TdtButton';
import TdtSelect from 'components/TdtSelect';

const mapState = state => ({ companyState: state.admin.company });
const mapDispatch = { getData, setSorter, setFilters, setPagination };
const Company = ({ companyState, getData, setFilters, setPagination, setSorter }) => {
    const { t } = useTranslation();

    const columns = [
        {
            title: t('Company name'),
            dataIndex: 'name',
            // width: 412,
            render: (name, record) => (
                <>
                    {name}
                    {record.count_employees_has_unread_message > 0 && <span className="dot-unread-message" />}
                </>
            ),
            sorter: (a, b) => {
                return a.name.localeCompare(b.name);
            },
        },
        {
            title: t('Number of employees'),
            dataIndex: 'number_of_employees',
            className: 'text-align-right',
            // sorter: true,
            render: total => total || 0,
            width: 120,
            sorter: (a, b) => {
                let a0 = a.number_of_employees || 0;
                let b0 = b.number_of_employees || 0;
                return a0 > b0 ? 1 : a0 < b0 ? -1 : 0;
            },
        },
        {
            title: t('State'),
            dataIndex: 'state',
            // sorter: true,
            render: state => {
                return t(`enum:${getEnumText(CompanyStateEnum, state)}`);
            },
            width: 120,
            // sorter: (a, b) => {
            //     return a.state.localeCompare(b.state);
            // },
        },
        {
            title: t('Edit'),
            dataIndex: 'EDIT',
            render: (_, r) => {
                return (
                    <TdtButton
                        buttonStyle="plain"
                        icon={<EditIcon />}
                        onClick={event => {
                            event.stopPropagation();
                            history.push(Routes.private.ADMIN_COMPANIES_DETAIL_SETTING.path.replace(':id', r.id));
                        }}
                    />
                );
            },
            width: 60,
        },
    ];

    const getCompanies = (
        pagination = companyState.pagination,
        filters = companyState.filters,
        sorter = companyState.sorter,
    ) => {
        if (!sorter || !sorter.field) {
            sorter = companyState.sorter;
        }
        setSorter(sorter);
        getData({ pagination, filters, sorter });
    };

    useEffect(() => {
        getCompanies();
    }, []);

    return (
        <div className="self-wrapper">
            <h1 className="page-title mb-24">{t('Company')}</h1>
            <div>
                <TdtSelect
                    size="large"
                    className="fs-14"
                    defaultValue="all"
                    value={companyState.filters.state || 'all'}
                    onChange={value => {
                        const newFilters = Object.assign({}, companyState.filters, {
                            state: value,
                        });
                        const newPagination = { ...companyState.pagination, current: 1 };
                        setFilters(newFilters);
                        setPagination(newPagination);
                        getCompanies(newPagination, newFilters, companyState.sorter);
                    }}
                >
                    <Select.Option value="all">{'全てのステージ'}</Select.Option>
                    {Object.values(CompanyStateEnum).map(o => (
                        <Select.Option key={o.value} value={o.value}>
                            {t(`enum:${getEnumText(CompanyStateEnum, o.value)}`)}
                        </Select.Option>
                    ))}
                </TdtSelect>
            </div>
            <DataTable
                className="mt-16 collapse-table"
                columns={columns}
                hideColumns={companyState.hideColumns}
                data={companyState.data}
                loading={companyState.loading}
                pagination={companyState.pagination}
                filters={companyState.filters}
                sorter={companyState.sorter}
                onTableChange={(pagination, filter, sorter) => {
                    getCompanies(pagination, companyState.filters, sorter);
                }}
                onRow={record => {
                    return {
                        onClick: () => {
                            history.push(Routes.private.ADMIN_COMPANIES_DETAIL.path.replace(':id', record.id));
                        },
                    };
                }}
                noDataText={'登録されている企業はありません'}
            />
            <FloatBar>
                <Button
                    icon={<AddIcon />}
                    className="fw-b"
                    onClick={() => history.push(Routes.private.ADMIN_COMPANIES_CREATE.path)}
                >
                    {t('Register new')}
                </Button>
            </FloatBar>
        </div>
    );
};

export default connect(mapState, mapDispatch)(Company);

Company.propTypes = {
    companyState: PropTypes.shape(
        Object.assign({}, BaseStatePropShape, {
            data: PropTypes.arrayOf(CompanyPropTypes).isRequired,
        }),
    ),
    getData: PropTypes.func.isRequired,
    setSorter: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
};
