import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { CloseIcon } from 'components/SvgIcon';
import RenderPageElements from 'pages/admin/common/page-elements/RenderPageElements';
import RenderHTMLMail from 'pages/admin/common/page-elements/RenderHTMLMail';
import TabList from 'components/TabList';

const PreviewSeminarModal = ({ visible, onClose, info = {}, byId = {}, order = [] }) => {
    const { t } = useTranslation();
    const details = order.map(key => byId[key]);

    const tabList = [
        {
            key: 'page',
            title: t('Page'),
            container: (
                <>
                    <div className="render-page-container">
                        <RenderPageElements info={info} details={details} />
                    </div>
                </>
            ),
        },
        {
            key: 'news',
            title: t('News'),
            container: (
                <>
                    <div className="render-page-container html-mail_wrapper">
                        <RenderHTMLMail info={info} details={details} />
                    </div>
                </>
            ),
        },
    ];

    return (
        <Modal
            title={t('Preview')}
            visible={visible}
            width={823}
            footer={null}
            closeIcon={<CloseIcon />}
            onCancel={() => onClose()}
        >
            <TabList tabs={tabList} animated={false} defaultActiveKey="news" />
        </Modal>
    );
};

export default PreviewSeminarModal;

PreviewSeminarModal.propTypes = {
    info: PropTypes.object,
    byId: PropTypes.object,
    order: PropTypes.array,
};
