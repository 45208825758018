import { getEnumText } from 'helpers/enum.helper';
import DayOfWeekShortEnum from 'enums/day_of_week_short.enum';

export const processCounselorDetails = data => {
    if (data) {
        let registered = [];
        let programs = {};
        for (const value of Object.values(data)) {
            let record = {};
            let dayDetails = [];
            for (const tmp of value) {
                const curProgram = tmp.company_program.program;
                const curCompany = tmp.company_program.company;
                if (!programs[curProgram.id]) {
                    programs[curProgram.id] = curProgram.name;
                }
                if (!record.companyId || !record.programId) {
                    record.companyId = curCompany.id;
                    record.companyName = curCompany.name;
                    record.programId = curProgram.id;
                    record.programName = curProgram.name;
                    record.programNameMngt = curProgram.name_mngt;
                    record.companyProgramId = tmp.company_program_id;
                    record.allowSameDayBooking = curProgram.allow_same_day_booking;
                }
                const day = getEnumText(DayOfWeekShortEnum, tmp.day);
                if (!day) continue;
                const dayOfWeekAndTime = day + ' ' + tmp.hour_start + ' ~ ' + tmp.hour_end;
                dayDetails.push(dayOfWeekAndTime);
            }
            record.days = dayDetails;
            registered.push(record);
        }

        return {
            registered: registered,
            programs: programs,
        };
    }
};
