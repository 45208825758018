import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import { CloseIcon } from 'components/SvgIcon';
import DeleteBtnInModal from 'components/DeleteBtnInModal';

const DeleteStaffModal = ({ visible, onClose, onUpdate, data = {} }) => {
    return (
        <>
            <Modal
                title={'スタッフの削除'}
                visible={visible}
                width={400}
                footer={null}
                closeIcon={<CloseIcon />}
                onCancel={() => onClose()}
            >
                <p>
                    <span className="fw-b">“{`${data.full_name}`}”</span> を削除します。
                </p>
                <p>この操作は元に戻せません。削除してよろしいですか？</p>

                <DeleteBtnInModal onClose={onClose} onUpdate={onUpdate} />
            </Modal>
        </>
    );
};

DeleteStaffModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

export default DeleteStaffModal;
