import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import SeminarForm from 'pages/admin/company/tabs/seminar/form/SeminarForm';
import { getSeminar, setSeminar } from 'pages/admin/company/tabs/seminar/seminar_detail.slice';

const mapState = state => ({
    seminar: state.admin.companyDetailSeminarDetail.seminar,
});
const mapDispatch = { getSeminar, setSeminar };

const SeminarUpdate = ({ company, baseUrl, seminar, getSeminar, setSeminar }) => {
    const { id, seminarId } = useParams();

    useEffect(() => {
        getSeminar(seminarId);

        return () => {
            setSeminar(null);
        };
    }, [id, seminarId]);

    if (!seminar) {
        return <></>;
    }

    return <SeminarForm isUpdate={true} initialValues={seminar} company={company} baseUrl={baseUrl} />;
};

export default connect(mapState, mapDispatch)(SeminarUpdate);

SeminarUpdate.propTypes = {
    company: PropTypes.object,
    baseUrl: PropTypes.string,
};
