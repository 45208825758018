import React from 'react';
import { Dropdown } from 'antd';

const TdtCalendarContextMenu = ({ createMenu, date, time, isActive, isBlocked, body = null }) => {
    if (!isActive || !createMenu) return null;
    return (
        <Dropdown overlay={createMenu(date, time, isBlocked)} trigger={['click']}>
            {body ? body : <div className="dropdown-clickable" />}
        </Dropdown>
    );
};

export default TdtCalendarContextMenu;
