import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form } from 'antd';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import BackBtn from 'components/BackBtn';
import QuestionTypeEnum from 'enums/question_type.enum';
import FreeInputQuestion from 'components/questions/FreeInputQuestion';
import SingleSelectQuestion from 'components/questions/SingleSelectQuestion';
import MultipleSelectQuestion from 'components/questions/MultipleSelectQuestion';
import DateTimeQuestion from 'components/questions/DateTimeQuestion';

import { momentToStr, strToMoment } from 'helpers/date.helper';
import NoData from 'components/NoData';
import { connect } from 'react-redux';
import { getConsultation } from 'pages/counselor/calendar/consultation/consultation_detail.slice';
import DateTimeOptionTypeEnum from 'enums/date_time_option_type.enum';
import DailyLifeQuestion from 'components/questions/DailyLifeQuestion';

const mapDispatch = { getConsultation };

const DisplayInterviewSheet = ({ getConsultation }) => {
    const { id, reservationId } = useParams();
    const [reservationAnswer, setReservationAnswer] = useState();

    const [form] = Form.useForm();

    const getReservationAnswer = async () => {
        const response = await api.get(
            APP_URLS.COUNSELOR_CONSULTATIONS_RESERVATION_ANSWERS.replace(':id', id).replace(
                ':reservationId',
                reservationId
            )
        );
        if (response) {
            setReservationAnswer(response);
        }
    };

    useEffect(() => {
        if (!reservationAnswer) {
            getReservationAnswer().then(() => {});
            getConsultation(id, reservationId);
        }
    }, [reservationId]);

    if (!reservationAnswer) return <NoData />;

    const questions = JSON.parse(reservationAnswer.json_questions);
    const answers = JSON.parse(reservationAnswer.json_answers) || [];

    const valueMomentToStr = (current, format, reverse) => {
        const func = reverse ? strToMoment : momentToStr;
        return current.is_range
            ? [func(current.value[0], format), func(current.value[1], format)]
            : func(current.value, format);
    };

    // reserve case date-time
    const strNestedToMoment = values => {
        return allNestedMomentToStr(values, true);
    };

    // case: date-time nested
    const allNestedMomentToStr = (values = {}, reverse = false) => {
        let objValues = {};
        Object.keys(values).map(k => {
            let current = values[k];
            if (current.type === DateTimeOptionTypeEnum.DATE.value) {
                objValues[k] = {
                    ...current,
                    value: valueMomentToStr(current, 'YYYY-MM-DD', reverse),
                };
            } else if (current.type === DateTimeOptionTypeEnum.TIME.value) {
                objValues[k] = {
                    ...current,
                    value: valueMomentToStr(current, 'HH:mm', reverse),
                };
            } else if (current.type === DateTimeOptionTypeEnum.DATE_TIME.value) {
                objValues[k] = {
                    ...current,
                    value: valueMomentToStr(current, 'YYYY-MM-DD HH:mm', reverse),
                };
            } else if (current.type === DateTimeOptionTypeEnum.DURATION.value) {
                objValues[k] = current;
            }
        });

        return objValues;
    };

    const initialValues = answers.reduce(
        (rs = {}, answer) => ({
            ...rs,
            [`qid_${answer.qid}`]: answer.type === 'date-time' ? strNestedToMoment(answer.values) : answer.values,
        }),
        {}
    );

    // helper
    const parseArrOptions = opts => {
        let optionsOrder = [];
        let options = {};
        opts.map(opt => {
            optionsOrder.push(opt.id);
            options[opt.id] = opt;
        });
        return { optionsOrder, options };
    };

    return (
        <>
            <div className="pt-8 pb-40 pl-48">
                <BackBtn className="mb-0 back-btn-counselor back-at-interview" label={'質問票'} />
            </div>
            <div className="pl-48 pr-24">
                <div className="f-24-36 fw-b mb-8">{reservationAnswer.title}</div>
                <div className="mb-24 h6">{reservationAnswer.input_condition}</div>
                <div className="mb-16">{reservationAnswer.description}</div>
                <div className="pink-color mb-24">{'*必須項目'}</div>
            </div>

            <Form form={form} layout="vertical" initialValues={initialValues} onFinish={() => {}} requiredMark={false}>
                <div className="pl-48 pr-24 pointer-events-none">
                    {questions.map(q => {
                        let Component = null;
                        switch (q.type) {
                            case QuestionTypeEnum.FREE_INPUT.value:
                            case QuestionTypeEnum.FREE_INPUT_PARAGRAPH.value:
                            case QuestionTypeEnum.FREE_INPUT_NUMBER.value:
                                Component = FreeInputQuestion;
                                break;
                            case QuestionTypeEnum.SINGLE_SELECTION.value:
                            case QuestionTypeEnum.RADAR_CHART.value: // for preview with radio select, not chart
                                Component = SingleSelectQuestion;
                                break;
                            case QuestionTypeEnum.MULTIPLE_SELECTION.value:
                                Component = MultipleSelectQuestion;
                                break;
                            case QuestionTypeEnum.DATE_TIME.value:
                                Component = DateTimeQuestion;
                                break;
                            case QuestionTypeEnum.DAILY_LIFE.value:
                                Component = DailyLifeQuestion;
                                break;
                        }

                        const { optionsOrder, options } = parseArrOptions(q.options);

                        return (
                            <Form.Item key={q.id} name={`qid_${q.id}`} rules={[{ required: q.is_required || false }]}>
                                <Component info={q} optionsOrder={optionsOrder} options={options} />
                            </Form.Item>
                        );
                    })}
                </div>
            </Form>
        </>
    );
};

export default connect(null, mapDispatch)(DisplayInterviewSheet);
