import React from 'react';
import PropTypes from 'prop-types';
import TabList from 'components/TabList';
import history from 'helpers/history.helper';
import { isAdminStaff } from 'helpers/role.helper';

const CompanyTabList = ({ tab, url, company }) => {
    const tabList = [
        {
            key: 'employee', // ~path
            title: (
                <>
                    {'社員'}
                    {!!company.unread_message && (
                        <span className="number-unread-message">{company.unread_message}</span>
                    )}
                </>
            ), // t('Employee')
            container: <></>,
        },
        {
            key: 'program',
            title: 'プログラム', // t('Program')
            container: <></>,
        },
        // {
        //     key: 'seminar',
        //     title: 'セッション', // t('Seminar')
        //     container: <></>,
        //     //hidden: isAdminStaff(user),
        // },
        {
            key: 'staff',
            title: 'スタッフ', // t('Staff')
            container: <></>,
            hidden: true,
        },
        {
            key: 'message',
            title: 'お知らせ', // t('Message')
            container: <></>,
        },
        {
            key: 'setting',
            title: '設定', // t('Setting')
            container: <></>,
        },
    ];

    const handlerTabChange = key => {
        history.push(`${url}/${key}`);
    };

    return (
        <>
            <TabList
                tabs={tabList.filter(t => !t.hidden)}
                onChange={handlerTabChange}
                activeKey={tab}
                animated={false}
                indent
                className="mb-16"
            />
        </>
    );
};

export default CompanyTabList;

CompanyTabList.propTypes = {
    tab: PropTypes.string,
    url: PropTypes.string,
    company: PropTypes.any,
    user: PropTypes.any,
};
