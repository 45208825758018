import React from 'react';
import { Typography } from 'antd';
import PeriodicDiagnosisRow from 'pages/client/interview_sheet/PeriodicDiagnosisRow';

const PeriodicDiagnosis = ({ reservationAnswers, questionnaires, programsWithFirstInterview }) => {
    // TODO:
    if (reservationAnswers && programsWithFirstInterview) {
        console.log('');
    }

    const findPeriodicDiagnosis = () => {
        let itvs = {};

        // reservationAnswers.map(reservationAnswer => {
        //     const { interview_sheet: interviewSheet, ...rest } = reservationAnswer;
        //     if (interviewSheet && interviewSheet.periods && interviewSheet.periods.length > 0) {
        //         if (itvs[interviewSheet.id]) {
        //             itvs[interviewSheet.id]['rows'].push(interviewSheet);
        //         } else {
        //             itvs[interviewSheet.id] = { ...interviewSheet, rows: [{ ...rest }] };
        //         }
        //     }
        // });

        // only questionnaire, because reservationAnwser + first interviewSheet can not have period

        questionnaires.map(questionnaire => {
            const { interview_sheet: interviewSheet, ...rest } = questionnaire;
            if (interviewSheet && interviewSheet.periods && interviewSheet.periods.length > 0) {
                if (itvs[interviewSheet.id]) {
                    itvs[interviewSheet.id]['rows'].push(interviewSheet);
                } else {
                    itvs[interviewSheet.id] = { ...interviewSheet, rows: [{ ...rest }] };
                }
            }
        });
        return itvs;
    };
    const diagnosisRows = findPeriodicDiagnosis();
    const hasData = Object.keys(diagnosisRows).length > 0;

    return (
        <>
            {hasData && (
                <>
                    <div className="bg-gray-5">
                        <Typography
                            className="fs-18 mb-4 fw-700"
                            style={{ paddingLeft: 16, paddingTop: 8, paddingBottom: 8 }}
                        >
                            {'定期診断'}
                        </Typography>
                    </div>
                    {Object.values(diagnosisRows).map((diagnosisRow, index) => {
                        return (
                            <PeriodicDiagnosisRow
                                key={`pdr_${index}`}
                                diagnosisRow={diagnosisRow}
                                className={
                                    index === Object.keys(diagnosisRows).length - 1
                                        ? 'mb-40'
                                        : 'border-bottom-separator'
                                }
                            />
                        );
                    })}
                </>
            )}
        </>
    );
};

export default PeriodicDiagnosis;
