import ReservationAnswerStatus from 'enums/reservation_answer_status.enum';

const dontHasPeriod = item => {
    return !item.interview_sheet?.periods || item.interview_sheet.periods.length < 1;
}

export const filterInterviewSheets = (interviewSheets, isDone = false) => {
    if (!interviewSheets || interviewSheets.length < 1) {
        return [];
    }

    // filter items has no json_categories, periods

    // interview sheet
    if (interviewSheets[0].reservation_id) {
        return interviewSheets.filter(itv => dontHasPeriod(itv)).filter(itv =>
            isDone
                ? itv.status === ReservationAnswerStatus.FILLED_IN.value
                : itv.status === ReservationAnswerStatus.BLANK.value
        );
    } else if (interviewSheets[0].status) {
        // questionnaire
        return interviewSheets.filter(itv => dontHasPeriod(itv)).filter(itv =>
            isDone
                ? itv.status === ReservationAnswerStatus.FILLED_IN.value
                : itv.status === ReservationAnswerStatus.BLANK.value
        );
    } else {
        // first interview sheet
        return interviewSheets.filter(itv => dontHasPeriod(itv)).filter(itv =>
            isDone
                ? itv.first_interview_sheet.status === ReservationAnswerStatus.FILLED_IN.value
                : itv.first_interview_sheet.status === ReservationAnswerStatus.BLANK.value
        );
    }
};
