import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'antd';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { DeleteIcon, EditIcon } from 'components/SvgIcon';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import Loader from 'components/Loader';
import DeleteUserModal from 'pages/counselor/calendar/user_information/DeleteUserModal';
import PropTypes from 'prop-types';
import { hasAnyRole, hasRole, isAdminStaff } from 'helpers/role.helper';
import { connect } from 'react-redux';
import RoleEnum from 'enums/role.enum';
import { showNormal } from 'helpers/notification.helper';
import Routes from 'routes';
import history from 'helpers/history.helper';
import { emailDeletedShow } from 'helpers/string.helper';
import UpdateEmployeeProgram from 'pages/counselor/calendar/user_information/UpdateEmployeeProgram';

const mapState = state => ({
    user: state.auth.user,
    backUrl: state.counselor.calendar.backUrl,
    afterDeleted: state.admin.calendar.afterDeleted,
});

const UserInformation = ({ user, backUrl, afterDeleted }) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const [consultant, setConsultant] = useState();
    const [employeeProgram, setEmployeeProgram] = useState();
    const [loading, setLoading] = useState(true);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const isAdmin = hasAnyRole(user, [RoleEnum.ADMIN.value, RoleEnum.ADMIN_STAFF.value]);
    const defaultBackUrl = isAdmin && afterDeleted;
    const [modalVisible, setModalVisible] = useState({
        programs: false,
    });
    const showEmployeeProgramModal = () => setModalVisible({ programs: true });
    const closeModal = () => setModalVisible({ programs: false });

    const getConsultant = async id => {
        const response = await api.get(APP_URLS.COUNSELOR_CONSULTANT_INFO.replace(':id', id));
        if (response) {
            setConsultant(response);
            setLoading(false);
        }
    };

    const getEmployeeProgram = async id => {
        const response = await api.get(APP_URLS.COUNSELOR_EMPLOYEE_PROGRAM.replace(':id', id));
        if (response) {
            setEmployeeProgram(response);
            setLoading(false);
        }
    };

    const updateEmployeeProgram = async (id, program_ids) => {
        const response = await api.post(APP_URLS.COUNSELOR_EMPLOYEE_PROGRAM.replace(':id', id), { program_ids });
        if (response) {
            setEmployeeProgram(response);
            setLoading(false);
        }
    };

    const deleteUser = async id => {
        if (isAdmin) {
            const res = await api.delete(APP_URLS.ADMIN_COUNSELORS_DELETE_USER.replace(':id', id));
            if (res) {
                history.push(backUrl == null ? defaultBackUrl : backUrl);
                showNormal('', t('message:Client deletion completed'), 3);
            }
        }
    };

    useEffect(() => {
        getConsultant(id).then(() => {});
    }, [id]);

    useEffect(() => {
        getEmployeeProgram(id).then(() => {});
    }, [id]);

    return loading ? (
        <Loader className="loader-center" />
    ) : (
        <div className="user-information-detail p-24 pt-40">
            <Row className="user-information-row" justify="space-between" align="middle">
                <Col className="">
                    <p className="fs-12 text-gray-color mb-0">
                        {consultant && consultant.first_name_kana != null
                            ? `${consultant && consultant.first_name_kana} `
                            : ''}
                        {consultant && consultant.last_name_kana != null
                            ? `${consultant && consultant.last_name_kana}`
                            : ''}
                    </p>
                    <p className="fs-18 fw-b mb-0">{consultant && consultant.full_name}</p>
                    <p className="fs-16 fw-b mt-48 mb-8">{t('field:Affiliated company')}</p>
                    <p className="fs-16 mb-24">{consultant && consultant.employee_company[0].name}</p>
                </Col>
            </Row>

            {isAdminStaff(user) ? (
                <></>
            ) : (
                <>
                    <Row className="user-information-row" justify="space-between" align="middle">
                        <Col className="">
                            <p className="fs-16 fw-b mb-8 mt-24">{t('field:Email')}</p>
                            <p className="fs-16 mb-24">{consultant && emailDeletedShow(consultant.email)}</p>
                        </Col>
                    </Row>
                </>
            )}

            <Row className="user-information-row border-bottom-none" justify="space-between" align="middle">
                <Col>
                    <div className="program-action-panel">
                        <p className="fs-16 fw-b">{t('Program')}</p>
                        {isAdmin && consultant && !consultant.is_disabled && (
                            <Button
                                icon={<EditIcon />}
                                type="link"
                                className="pl-8"
                                onClick={() => {
                                    showEmployeeProgramModal();
                                }}
                            />
                        )}
                    </div>
                    <div className="mb-24">
                        {(employeeProgram || []).map(e => (
                            <p className="fs-16 mb-2" key={e.id}>
                                {e.assigned_programs_count > 0 && (isAdmin ? e.name_mngt : e.name)}
                            </p>
                        ))}
                    </div>
                </Col>
            </Row>

            <UpdateEmployeeProgram
                onUpdate={programIds => updateEmployeeProgram(id, programIds)}
                onClose={() => closeModal()}
                visible={modalVisible.programs}
                employeeProgram={employeeProgram}
                isAdmin={isAdmin}
            />

            {isAdmin && (
                <>
                    <Row className="user-information-row border-bottom-none" justify="space-between" align="middle">
                        <Col>
                            <div className="btn-hover">
                                <Button
                                    icon={<DeleteIcon />}
                                    onClick={() => setShowDeleteModal(true)}
                                    className={`fw-b ${consultant && consultant.is_disabled ? '' : 'btn-pink'}`}
                                    disabled={consultant && consultant.is_disabled}
                                >
                                    {t('Deletion of consultant')}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <DeleteUserModal
                        visible={showDeleteModal}
                        onClose={() => {
                            setShowDeleteModal(false);
                        }}
                        initialValue={{}}
                        existCreatedReservation={consultant && consultant.booked_reservations_count > 0}
                        onUpdate={() => {
                            deleteUser(id);
                        }}
                    />
                </>
            )}
        </div>
    );
};

export default connect(mapState)(UserInformation);

UserInformation.prototype = {
    user: PropTypes.any,
    backUrl: PropTypes.string,
    afterDeleted: PropTypes.any,
};
