import { createSlice } from '@reduxjs/toolkit';
import { APP_URLS } from 'constants/url.constant';
import api from 'helpers/api.helper';

const name = 'clientInterviewSheet';

const initialState = {
    reservationAnswers: [],
    questionnaires: [],
    programsWithFirstInterview: [],
    noData: false,
};

const interviewSheetSlice = createSlice({
    name,
    initialState: initialState,
    reducers: {
        setReservationAnswers(state, action) {
            state.reservationAnswers = action.payload;
        },
        setQuestionnaires(state, action) {
            state.questionnaires = action.payload;
        },
        setProgramsWithFirstInterview(state, action) {
            state.programsWithFirstInterview = action.payload;
        },
        setNoData(state, action) {
            state.noData = action.payload;
        },
    },
});

export const { setReservationAnswers, setQuestionnaires, setProgramsWithFirstInterview, setNoData } =
    interviewSheetSlice.actions;

export default interviewSheetSlice.reducer;

export const getReservationAnswers = () => async dispatch => {
    dispatch(setNoData(false));
    const data = await api.get(APP_URLS.CLIENT_INTERVIEW_SHEETS);
    if (data) {
        if (data.reservationAnswers) {
            dispatch(setReservationAnswers(data.reservationAnswers));
        }

        if (data.questionnaires) {
            dispatch(setQuestionnaires(data.questionnaires));
        }

        if (data.programsWithFirstInterview) {
            dispatch(setProgramsWithFirstInterview(data.programsWithFirstInterview));
        }

        if (
            data.reservationAnswers.length === 0 &&
            data.questionnaires.length === 0 &&
            data.programsWithFirstInterview.length === 0
        ) {
            dispatch(setNoData(true));
        }
    }
};
