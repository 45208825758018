import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getProgram } from 'pages/admin/program/program_create.slice';
import { useParams } from 'react-router';
import ProgramForm from './ProgramForm';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import { showNormal } from 'helpers/notification.helper';
import { useTranslation } from 'react-i18next';
import history from 'helpers/history.helper';
import Routes from 'routes';

const ProgramUpdate = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const [program, setProgram] = useState({});
    const [loading, setLoading] = useState(true);

    const canDelete = true;

    const updateProgram = async values => {
        const response = await api.post(APP_URLS.ADMIN_PROGRAM_UPDATE.replace(':id', id), values, {
            _method: 'PUT',
        });
        if (response) {
            showNormal('', t('message:Saved program changes'), 3);
            history.push(Routes.private.ADMIN_PROGRAM.path);
        }
    };

    const getProgram = async id => {
        const response = await api.get(APP_URLS.ADMIN_PROGRAM_DETAIL.replace(':id', id));
        if (response) {
            if (!response['first_interview_sheet_id']) {
                delete response['first_interview_sheet_id'];
            }
            if (!response['interview_sheet_id']) {
                delete response['interview_sheet_id'];
            }
            setProgram(response);
            setLoading(false);
        }
    };

    useEffect(() => {
        getProgram(id).then(() => {});
    }, [id]);
    if (loading) return <></>;

    return <ProgramForm mode="edit" isUpdate={true} canDelete={canDelete} initialValues={program} onFinish={updateProgram} />;
};

export default ProgramUpdate;

ProgramUpdate.propTypes = {
    getProgram: PropTypes.func,
};
