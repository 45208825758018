import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import TdtSelect from 'components/TdtSelect';
import { getTimeFromMinutes } from 'helpers/moment.helper';

const TdtSelectReservationFrameTime = ({ startTime, sessionTime, maxSession = 1, isEnd = false, ...rest }) => {
    let index = 0;
    return isEnd ? (
        <TdtSelect {...rest} style={{ width: 160 }} size="large" className="fs-14 select-gray">
            {[...Array(maxSession)].map((_, i) => {
                index++;
                let minute = startTime + sessionTime * index;
                let sessionMinute = getTimeFromMinutes(minute, true);
                return (
                    <Select.Option key={`h_s_${i}`} value={i}>
                        {sessionMinute}
                    </Select.Option>
                );
            })}
        </TdtSelect>
    ) : (
        <TdtSelect {...rest} style={{ width: 160 }} size="large" className="fs-14 select-gray">
            {[...Array(48)].map((_, i) => {
                let minute = i * 30;
                if (i < 0 || i > 47) {
                    return null;
                }
                let sessionStartMinute = getTimeFromMinutes(minute, true);
                return (
                    <Select.Option key={`h_s_${i}`} value={i}>
                        {sessionStartMinute}
                    </Select.Option>
                );
            })}
        </TdtSelect>
    );
};

export default TdtSelectReservationFrameTime;

TdtSelectReservationFrameTime.propTypes = {
    startTime: PropTypes.number,
    sessionTime: PropTypes.number,
    maxSession: PropTypes.number,
    isEnd: PropTypes.bool,
};
