import React from 'react';
import { floor } from 'helpers/number_format.helper';
import { COLORS } from 'helpers/color.helper';

const pie = (cx, cy, radius, data) => {
    let decimals = 4;
    let total = 0;
    let offset = 0;
    let offset2;
    let arr = [];
    let x;
    let y;
    let la;
    let radians;

    for (let i = 0; i < data.length; i++) total += data[i].value;

    for (let i = 0; i < data.length; i++) {
        let item = data[i];
        let tmp = {};

        tmp.index = i;
        tmp.value = item.text;
        radians = floor(((item.value / total) * 360 * Math.PI) / 180, decimals);
        offset2 = floor((offset / total) * 360, decimals);
        tmp.data = item;

        x = floor(cx + Math.sin(radians) * radius, decimals);
        y = floor(cy - Math.cos(radians) * radius, decimals);
        la = radians > Math.PI ? 1 : 0;

        // arc
        tmp.d = `M${cx} ${cy},L${cx} ${cy - radius},A${radius} ${radius},0 ${la} 1,${x} ${y}Z`;
        tmp.transform = `rotate(${offset2}, ${cx}, ${cy})`;

        // text
        x = floor(cx + (Math.sin(radians / 2) * radius) / 1.5, decimals);
        y = floor(cy - (Math.cos(radians / 2) * radius) / 1.35, decimals);
        tmp.text = {
            x,
            y,
            transform: `rotate(${-offset2},${x},${y})`,
        };

        offset += item.value;
        arr.push(tmp);
    }

    return arr;
};

const PieChart = ({ data }) => {
    // let data = [{ value: 33 }, { value: 20 }, { value: 80 }, { value: 48 }];
    let centerX = 90;
    let centerY = 90;
    let radius = 90;

    const arr = pie(centerX, centerY, radius, data);

    const renderItem = ({ transform, d, text, value, color }) => {
        return (
            <>
                <g transform={transform}>
                    <path d={d} fill={color} />
                    <text
                        textAnchor="middle"
                        fill="white"
                        fontSize="14"
                        fontFamily="Noto Sans JP"
                        fontWeight="700"
                        {...text}
                    >
                        {value}
                        <tspan fontSize={12} fontWeight={400} dx={1} dy={0.5}>
                            {'%'}
                        </tspan>
                    </text>
                </g>
            </>
        );
    };

    return (
        <>
            <svg height={centerX * 2} width={centerY * 2}>
                <circle r={radius} cx={centerX} cy={centerY} fill="#E8E8EB" />
                {arr.map((item, index) => (
                    <React.Fragment key={index}>{renderItem({ ...item, color: item.data.color })}</React.Fragment>
                ))}
                <circle r={radius / 2.5} cx={centerX} cy={centerY} fill="white" />
            </svg>
        </>
    );
};

export default PieChart;
