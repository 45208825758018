import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Radio } from 'antd';
import { CloseIcon } from 'components/SvgIcon';
import CompanyStateEnum from 'enums/company_state.enum';
import { showNormal } from 'helpers/notification.helper';

const UpdateStatusModal = ({ visible, onClose, initialValue, onUpdate, existCreatedReservation = false }) => {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);
    const disableState = stateEnum => {
        let stateValue = stateEnum.value;
        if (
            (CompanyStateEnum.PREPARING.value === stateValue || CompanyStateEnum.END.value === stateValue) &&
            existCreatedReservation
        ) {
            return true;
        }
        return false;
    };

    const { t } = useTranslation();
    return (
        <Modal
            title={'ステージ変更'}
            visible={visible}
            width={400}
            footer={null}
            closeIcon={<CloseIcon />}
            onCancel={() => onClose()}
        >
            <Radio.Group
                className="vertical-group"
                value={value.state}
                onChange={e => {
                    setValue({ state: e.target.value });
                }}
            >
                {Object.values(CompanyStateEnum).map(stateEnum => (
                    <Radio
                        className="company-status-setting"
                        key={stateEnum.value}
                        disabled={disableState(stateEnum)}
                        value={stateEnum.value}
                    >
                        <span>{t(`enum:${stateEnum.text}`)}</span>
                        {disableState(stateEnum) && <p className="note">相談予約がある場合には変更できません</p>}
                    </Radio>
                ))}
            </Radio.Group>
            <Button
                type="primary"
                block
                className="mt-16 fw-b"
                onClick={() => {
                    onUpdate && onUpdate(value);
                    showNormal('', t('message:Saved stage changes'), 5);
                    onClose();
                }}
            >
                {t('Save')}
            </Button>
        </Modal>
    );
};

UpdateStatusModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    initialValue: PropTypes.any,
    onUpdate: PropTypes.func.isRequired,
    existCreatedReservation: PropTypes.bool,
};

export default UpdateStatusModal;
