import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Col, Modal, Row, Input, Typography } from 'antd';
import { CloseIcon, HeartIcon } from 'components/SvgIcon';
import DateChangeInModalComponent from 'pages/client/health_data/tabs/date_time/DateChangeInModalComponent';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import { showNormal } from 'helpers/notification.helper';
import HealthTypeEnum from 'enums/health_type.enum';

const CreateHeartBeatModal = ({ value = {}, setValue, visible, onClose, onUpdate, getHealthPerType, isHealthList }) => {
    const { t } = useTranslation();

    const createHeartBeatData = async (values, type) => {
        const response = await api.post(APP_URLS.CLIENT_HEALTH_DATA_CREATE, {
            value: value.heart_beat,
            ext_value: value.ext_heart_beat,
            created_at: value.created_at,
            health_images: [{ images: value.images, category: value.category }],
            // category: values.category,
            type,
        });
        if (response) {
            showNormal('', t('message:Data has been saved'), 3);
            isHealthList ? getHealthPerType() : null;
        }
    };
    return (
        <Modal
            title={
                <>
                    <Row align={'middle'}>
                        <HeartIcon size={39} className="pr-8" />
                        {t('Heart beat')}
                    </Row>
                </>
            }
            visible={visible}
            width={320}
            footer={null}
            closeIcon={<CloseIcon />}
            onCancel={() => onClose()}
            destroyOnClose={true}
        >
            <DateChangeInModalComponent value={value} setValue={setValue} />
            <Row>
                <Col>
                    <Input
                        className="heath-data-modal-input heart-beat mt-16 mr-8"
                        value={value.heart_beat || null}
                        type="number"
                        min={0}
                        autoFocus={true}
                        onChange={e =>
                            setValue({
                                ...value,
                                heart_beat:
                                    Number(e.target.value) < 0
                                        ? Number(e.target.value) * -1
                                        : Number(e.target.value) <= 500
                                        ? Number(e.target.value)
                                        : null,
                            })
                        }
                    />
                    <span>拍/分</span>
                </Col>
            </Row>
            <Button
                type="primary"
                block
                className="mt-32 disable-gray-btn"
                disabled={!value.heart_beat || value.heart_beat.length < 1}
                onClick={values => {
                    isHealthList
                        ? createHeartBeatData(values, HealthTypeEnum.HEART_BEAT.value).then(() => {})
                        : onUpdate && onUpdate(value);
                    setValue({ ...value, heart_beat: [] });
                    onClose();
                }}
            >
                {t('Save')}
            </Button>
        </Modal>
    );
};

CreateHeartBeatModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func,
    value: PropTypes.any,
    setValue: PropTypes.func,
    getHealthPerType: PropTypes.func,
    isHealthList: PropTypes.any,
};

export default CreateHeartBeatModal;
