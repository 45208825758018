import { combineReducers } from 'redux';
import coreReducer from 'core.slice';
import authReducer from 'pages/auth/auth.slice';

import calendarReducer from 'pages/admin/calendar/calendar.slice';
import counselorReducer from 'pages/admin/counselor/counselor.slice';
import counselorDetailReducer from 'pages/admin/counselor/counselor_detail.slice';
import counselorCompanyProgramReducer from 'pages/admin/counselor/company_program/counselor_company_program.slice';
import reservableFrameProgramsReducer from 'pages/admin/counselor/company_program/reservable_frame_programs.slice';
import programReducer from 'pages/admin/program/program.slice';
import programCreateReducer from 'pages/admin/program/program_create.slice';
import programTempReducer from 'pages/admin/program/program_temp.slice';
import common_sessionReducer from './pages/admin/feature/common_session.slice';
import commonSessionCreateReducer from './pages/admin/feature/common_session_create.slice';
import commonSessionDetailSliceReducer from './pages/admin/feature/common_session_detail.slice';
import emailReducer from 'pages/admin/email/email.slice';
import emailCreateReducer from 'pages/admin/email/email_create.slice';
import emailDetailReducer from 'pages/admin/email/detail_screen/email_detail.slice';
import distributionListReducer from 'pages/admin/email/distribution/distribution_list.slice';
import distributionCreateReducer from 'pages/admin/email/distribution/distribution_create.slice';
import companyReducer from 'pages/admin/company/company.slice';
import companyDetailReducer from 'pages/admin/company/company_detail.slice';
import companyDetailEmployeeReducer from 'pages/admin/company/tabs/employee/employee.slice';
import companyDetailProgramReducer from 'pages/admin/company/tabs/program/program.slice';
import companyDetailProgramReservationReducer from 'pages/admin/company/tabs/program/reservation_detail.slice';
import companyDetailStaffReducer from 'pages/admin/company/tabs/staff/staff.slice';
import companyDetailSeminarReducer from 'pages/admin/company/tabs/seminar/seminar.slice';
import companyDetailSeminarDetailReducer from 'pages/admin/company/tabs/seminar/seminar_detail.slice';
import companyDetailMessageReducer from 'pages/admin/company/tabs/message/message.slice';
import companyDetailMessageDetailReducer from 'pages/admin/company/tabs/message/message_detail.slice';
import interviewSheetReducer from 'pages/admin/interview_sheet/interview_sheet.slice';
import interviewSheetCreateReducer from 'pages/admin/interview_sheet/interview_sheet_create.slice';
import interviewSheetDetailReducer from 'pages/admin/interview_sheet/detail_screen/interview_sheet_detail.slice';
import logReducer from 'pages/admin/log/log.slice';
import pageElementsReducer from 'pages/admin/common/page-elements/page_elements.slice';
import healthDataReducer from 'pages/client/health_data/health_data.slice';
import healthDataWeightReducer from 'pages/client/health_data/charts/weight.slice';
import healthDataPressureReducer from 'pages/client/health_data/charts/pressure.slice';
import healthDataHeartRateReducer from 'pages/client/health_data/charts/heart_rate.slice';
import messageReducer from 'pages/client/message/message.slice';
import seminarReducer from 'pages/client/seminar/seminar.slice';
import clientSeminarDetailReducer from 'pages/client/seminar/seminar_detail.slice';
import clientProgramDetailReducer from 'pages/client/reservation/program_detail.slice';
import messageDetailReducer from 'pages/client/message/message_detail.slice';
import clientInterviewSheetReducer from 'pages/client/interview_sheet/interview_sheet.slice';
import clientReservationReducer from 'pages/client/reservation/reservation.slice';
import clientPastReservationReducer from 'pages/client/reservation/past_reservation.slice';

import counselorDashboardReducer from 'pages/counselor/dashboard/dashboard.slice';
import counselorCalendarReducer from 'pages/counselor/calendar/calendar.slice';
import counselorCalendarReservationFormReducer from 'pages/counselor/calendar/reservation-create/reservation_form.slice';
import counselorConsultationReducer from 'pages/counselor/calendar/consultation/consultation.slice';
import counselorConsultationDetailReducer from 'pages/counselor/calendar/consultation/consultation_detail.slice';
import counselorMessageReducer from 'pages/counselor/calendar/message/message.slice';
import counselorMessageDetailReducer from 'pages/counselor/calendar/message/message_detail.slice';
import counselorAccountReducer from 'pages/counselor/account/account.slice';
import counselorBulkMessageReducer from 'pages/counselor/message/message.slice';
import counselorConsultationReservationReducer from 'pages/counselor/consultation-reservation/reservation.slice';
import counselorConsultantReducer from 'pages/counselor/consultant-list/consultant.slice';
import counselorReservationForm from 'pages/counselor/calendar/consultation/reservation_form.slice';
import counselorHealthDataWeightReducer from 'pages/counselor/calendar/health_data/weight.slice';
import counselorHealthDataPressureReducer from 'pages/counselor/calendar/health_data/pressure.slice';
import counselorHealthDataHeartRateReducer from 'pages/counselor/calendar/health_data/heart_rate.slice';

import counselorQuestionnaireReducer from 'pages/counselor/calendar/questionnaire/questionnaire.slice';
import counselorQuestionnaireDetailReducer from 'pages/counselor/calendar/questionnaire/questionnaire_detail.slice';

import masterDataReducer from 'pages/admin/common/master-data/master_data.slice';

const reducer = combineReducers({
    core: coreReducer,
    auth: authReducer,
    admin: combineReducers({
        calendar: calendarReducer,
        counselor: counselorReducer,
        counselorDetail: counselorDetailReducer,
        counselorCompanyProgram: counselorCompanyProgramReducer,
        reservableFramePrograms: reservableFrameProgramsReducer,
        program: programReducer,
        programCreate: programCreateReducer,
        programTemp: programTempReducer,

        commonSession: common_sessionReducer,
        commonSessionCreate: commonSessionCreateReducer,
        commonSessionDetail: commonSessionDetailSliceReducer,

        email: emailReducer,
        emailCreate: emailCreateReducer,
        emailDetail: emailDetailReducer,
        distributionList: distributionListReducer,
        distributionCreate: distributionCreateReducer,

        masterData: masterDataReducer,

        company: companyReducer,
        companyDetail: companyDetailReducer,
        companyDetailEmployee: companyDetailEmployeeReducer,
        companyDetailProgram: companyDetailProgramReducer,
        companyDetailProgramReservation: companyDetailProgramReservationReducer,
        companyDetailStaff: companyDetailStaffReducer,
        companyDetailSeminar: companyDetailSeminarReducer,
        companyDetailSeminarDetail: companyDetailSeminarDetailReducer,
        companyDetailMessage: companyDetailMessageReducer,
        companyDetailMessageDetail: companyDetailMessageDetailReducer,
        interviewSheet: interviewSheetReducer,
        interviewSheetCreate: interviewSheetCreateReducer,
        interviewSheetDetail: interviewSheetDetailReducer,
        log: logReducer,
        pageElements: pageElementsReducer,
    }),
    client: combineReducers({
        health_data: healthDataReducer,
        healthDataWeight: healthDataWeightReducer,
        healthDataPressure: healthDataPressureReducer,
        healthDataHeartRate: healthDataHeartRateReducer,
        message: messageReducer,
        messageDetail: messageDetailReducer,
        reservation: clientReservationReducer,
        interviewSheet: clientInterviewSheetReducer,
        seminar: seminarReducer,
        seminarDetail: clientSeminarDetailReducer,
        programDetail: clientProgramDetailReducer,
        pastReservation: clientPastReservationReducer,
    }),
    counselor: combineReducers({
        dashboard: counselorDashboardReducer,
        calendar: counselorCalendarReducer,
        calendarReservationForm: counselorCalendarReservationFormReducer,
        consultation: counselorConsultationReducer,
        consultationDetail: counselorConsultationDetailReducer,
        message: counselorMessageReducer, // calendar -> message
        messageDetail: counselorMessageDetailReducer,
        account: counselorAccountReducer,
        bulkMessage: counselorBulkMessageReducer,
        consultationReservation: counselorConsultationReservationReducer,
        consultant: counselorConsultantReducer,
        reservationForm: counselorReservationForm,
        healthDataWeight: counselorHealthDataWeightReducer,
        healthDataPressure: counselorHealthDataPressureReducer,
        healthDataHeartRate: counselorHealthDataHeartRateReducer,
        questionnaire: counselorQuestionnaireReducer,
        questionnaireDetail: counselorQuestionnaireDetailReducer,
    }),
});

const appReducer = (state, action) => {
    if (action.type === 'RESET') {
        state = undefined;
    }
    return reducer(state, action);
};

export default appReducer;
