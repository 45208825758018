import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Checkbox, Input } from 'antd';
import ElementActions from 'pages/admin/common/page-elements/types/ElementActions';

const HTMLCodeElement = React.memo(({ id, pageElement, setPageElement, setRefs }) => {
    const { t } = useTranslation();
    return (
        <div className="page-element-block">
            <div className="header pb-24">
                <div className="element-common-heading">{'HTML'}</div>
                <ElementActions id={id} />
            </div>
            <div className="element-item">
                <Input.TextArea
                    style={{ maxWidth: 480, height: 256 }}
                    value={pageElement.content}
                    onChange={e => {
                        setPageElement({ id, changes: { content: e.target.value } });
                    }}
                    ref={el => setRefs({ id, el: el })}
                />
            </div>
            <div>
                <Checkbox
                    className="mt-24"
                    checked={pageElement.is_displayed}
                    onChange={e => {
                        setPageElement({ id, changes: { is_displayed: e.target.checked ? 1 : 0 }});
                    }}
                >
                    {t('Posted in the news')}
                </Checkbox>
            </div>
        </div>
    );
});

export default HTMLCodeElement;

HTMLCodeElement.propTypes = {
    id: PropTypes.any.isRequired,
    pageElement: PropTypes.object,
    setPageElement: PropTypes.func,
};
