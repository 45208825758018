import React from 'react';
import ReactDOM from 'react-dom';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import { ConfigProvider } from 'antd';
import jaJP from 'antd/lib/locale-provider/ja_JP';
import { APP_URLS } from 'constants/url.constant';
import { PUSHER_CONFIG } from 'constants/pusher.constant';
import api from 'helpers/api.helper';
import App from './App';
import appReducer from 'app.reducer';
import 'antd/dist/antd.less';
import 'styles/app.scss';

window.Pusher = Pusher;
api.accessToken = localStorage.getItem('access_token');

const options = {
    broadcaster: 'pusher',
    key: PUSHER_CONFIG.APP_KEY,
    cluster: PUSHER_CONFIG.CLUSTER,
    wsHost: PUSHER_CONFIG.WS_HOST,
    wsPort: PUSHER_CONFIG.WS_PORT,
    forceTLS: PUSHER_CONFIG.FORCE_SSL,
    authEndpoint: `${APP_URLS.BASE_URL}/broadcasting/auth`,
    auth: {
        headers: {
            Authorization: `Bearer ${api.accessToken}`,
            Accept: 'application/json',
        },
    },
};
window.echo = new Echo(options);

const store = configureStore({
    reducer: appReducer,
    middleware: [thunk],
});

ReactDOM.render(
    <Provider store={store}>
        <ConfigProvider locale={jaJP} autoInsertSpaceInButton={false}>
            <App />
        </ConfigProvider>
    </Provider>,
    document.getElementById('root')
);
