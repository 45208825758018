import React from 'react';
import { Select } from 'antd';
import TdtSelect from 'components/TdtSelect';
import TdtPureSelect from 'components/TdtPureSelect';

const createTimeBlock = () => {
    let blocks = [];
    for (let i = 0; i < 24; i++) {
        const h = `${i > 9 ? i : '0' + i}`;
        for (let j = 0; j < 6; j++) {
            const m = `${j}0`;
            blocks.push(`${h}:${m}`);
        }
    }
    return blocks;
};

const blockTimes = createTimeBlock();

const TdtSelectTime = ({ className = '', minTime, ...rest }) => {
    return (
        <TdtSelect {...rest} size="large" className={`fs-14 select-gray ${className}`}>
            {blockTimes.map((v, i) => {
                if (minTime && v <= minTime) {
                    return null;
                }

                return (
                    <Select.Option key={`h_s_${i}`} value={v}>
                        {v}
                    </Select.Option>
                );
            })}
        </TdtSelect>
    );
};

export default TdtSelectTime;

const createTimeBlockSize = size => {
    let blocks = [];
    for (let i = 0; i < 24; i++) {
        for (let j = 0; j < 60 / size; j++) {
            const m = j === 0 ? '00' : `${j * size}`;
            blocks.push(`${i}:${m}`);
        }
    }
    return blocks;
};

const blockTimes15m = createTimeBlockSize(15);
export const timeToMinute = time => {
    if (!time) {
        return 0;
    }

    const arr = time.split(':');
    return Number(arr[0]) * 60 + Number(arr[1]);
};

export const TdtSelectTimeWithBlock = ({
    className = '',
    minTime,
    maxTime,
    hasEndOfDay = false,
    checkNotAvailable,
    ...rest
}) => {
    return (
        <TdtSelect {...rest} size="large" className={`fs-14 select-gray ${className}`}>
            {blockTimes15m.map((v, i) => {
                if (minTime && timeToMinute(v) <= timeToMinute(minTime)) {
                    return null;
                }

                if (maxTime && timeToMinute(v) >= timeToMinute(maxTime)) {
                    return null;
                }

                if (checkNotAvailable && checkNotAvailable(v)) {
                    return null;
                }

                return (
                    <Select.Option key={`h_s_${i}`} value={v}>
                        {v}
                    </Select.Option>
                );
            })}
            {hasEndOfDay && !(checkNotAvailable && checkNotAvailable('24:00')) && (
                <Select.Option key={`h_s_end`} value={'24:00'}>
                    {'24:00'}
                </Select.Option>
            )}
        </TdtSelect>
    );
};

export const TdtPureSelectTimeWithBlock = ({
    value,
    onSelect,
    minTime,
    maxTime,
    hasEndOfDay = false,
    checkNotAvailable,
    ...props
}) => {
    const options = blockTimes15m
        .map(v => {
            if (minTime && timeToMinute(v) <= timeToMinute(minTime)) {
                return null;
            }

            if (maxTime && timeToMinute(v) >= timeToMinute(maxTime)) {
                return null;
            }

            if (checkNotAvailable && checkNotAvailable(v)) {
                return null;
            }
            return v;
        })
        .filter(v => v);

    if (hasEndOfDay && !(checkNotAvailable && checkNotAvailable('24:00'))) {
        options.push('24:00');
    }

    return (
        <TdtPureSelect
            value={value}
            onSelect={onSelect}
            options={options.map(o => ({
                value: o,
                label: o,
            }))}
            {...props}
        />
    );
};
