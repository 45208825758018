import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

const DeleteBtnInModal = ({ onClose, onUpdate }) => {
    const { t } = useTranslation();
    return (
        <>
            <div className="delete-action-panel">
                <Button
                    type=""
                    className="delete-button fw-b"
                    onClick={() => {
                        onUpdate && onUpdate();
                        onClose();
                    }}
                >
                    <span className={'title-button'}>{t('Delete')}</span>
                </Button>
                <Button
                    type=""
                    className="cancel-button fw-b"
                    onClick={() => {
                        onClose();
                    }}
                >
                    <span className={'title-button'}>{t('Cancel')}</span>
                </Button>
            </div>
        </>
    );
};

export default DeleteBtnInModal;
