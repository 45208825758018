const DayOfWeekEnum = Object.freeze({
    SUNDAY: { value: 'sunday', text: '日曜日' },
    MONDAY: { value: 'monday', text: '月曜日' },
    TUESDAY: { value: 'tuesday', text: '火曜日' },
    WEDNESDAY: { value: 'wednesday', text: '水曜日' },
    THURSDAY: { value: 'thursday', text: '木曜日' },
    FRIDAY: { value: 'friday', text: '金曜日' },
    SATURDAY: { value: 'saturday', text: '土曜日' },
});

export default DayOfWeekEnum;
