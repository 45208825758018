import { createSlice } from '@reduxjs/toolkit';
import { APP_URLS } from 'constants/url.constant';
import { baseGetData, baseInitialState } from 'helpers/slice.helper';

const name = 'clientPastReservation';

const initialState = {
    pastReservation: { ...baseInitialState },
    loading: true,
};

const pastReservationSlice = createSlice({
    name,
    initialState: initialState,
    reducers: {
        setPastReservation(state, action) {
            state.pastReservation = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
    },
});

export const { setPastReservation, setLoading } = pastReservationSlice.actions;

export default pastReservationSlice.reducer;

export const getPastReservations =
    ({ ...props }) =>
    async dispatch => {
        dispatch(setLoading(true));
        const data = await baseGetData({ url: APP_URLS.CLIENT_PAST_RESERVATION, ...props });
        if (data) {
            dispatch(setPastReservation(data));
        }
        dispatch(setLoading(false));
    };
