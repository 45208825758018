import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Image, Row, Typography } from 'antd';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import QuestionTypeEnum from 'enums/question_type.enum';
import FreeInputQuestion from 'components/questions/FreeInputQuestion';
import SingleSelectQuestion from 'components/questions/SingleSelectQuestion';
import MultipleSelectQuestion from 'components/questions/MultipleSelectQuestion';
import DateTimeQuestion from 'components/questions/DateTimeQuestion';
import FloatBar from 'components/FloatBar';
import history from 'helpers/history.helper';
import { checkNowMatchPeriod, momentToStr, periodToDateTime, strToMoment } from 'helpers/date.helper';
import RouterPrompt from 'components/RouterPrompt';
import BackBtnWithTitle from 'components/BackBtnWithTitle';
import { showError, showNormal } from 'helpers/notification.helper';
import { isNullOrUndefined } from 'helpers/common.helper';
import DateTimeOptionTypeEnum from 'enums/date_time_option_type.enum';
import { handleScrollOnFocus } from 'helpers/form_event.helper';
import Routes from 'routes';
import ReservationAnswerStatus from 'enums/reservation_answer_status.enum';
import Loader from 'components/Loader';
import DailyLifeQuestion from 'components/questions/DailyLifeQuestion';
import { timeToMinute } from 'components/TdtSelectTime';
import noData from 'assets/images/noData.png';
import moment from 'moment';

const mapState = state => ({ reservationAnswers: state.client.interviewSheet.reservationAnswers });

const isLastAnsweredPeriod = (lastAnsweredTime, periods = []) => {
    const lastAnsweredDateTime = moment(lastAnsweredTime);
    let answeredPeriod = false;
    periods.forEach((period, index) => {
        const periodStart = periodToDateTime(period.start_date, period.start_hour, period.start_minute);
        const periodEnd = periodToDateTime(period.end_date, period.end_hour, period.end_minute);
        if (lastAnsweredDateTime.isBetween(periodStart, periodEnd) && index === 0) {
            answeredPeriod = true;
        }
    });

    return answeredPeriod;
};

const InterviewSheetDetail = ({ reservationAnswers }) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const searchParams = new URLSearchParams(location.search);
    const isFirstInterviewSheet = searchParams.get('first');
    const isAnswerPeriod = searchParams.has('period');

    const [reservationAnswer, setReservationAnswer] = useState(
        !reservationAnswers && reservationAnswers.find(r => r.id === Number(id))
    ); // always false
    const [errorsDailyLife, setErrorsDailyLife] = useState({});

    const routerPromptRef = useRef();
    const [isValueChange, setIsValueChange] = useState(false);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [selectedSingleOptIds, setSelectedSingleOptIds] = useState([]);
    // if not in redux state => get from api
    const getReservationAnswer = async id => {
        const response = await api.get(
            APP_URLS.CLIENT_INTERVIEW_SHEETS_DETAIL.replace(':id', id) +
                (isNullOrUndefined(isFirstInterviewSheet) ? '' : '?first=true')
        );
        if (response) {
            // period answers
            if (isAnswerPeriod) {
                setReservationAnswer({ ...response, json_answers: null, status: ReservationAnswerStatus.BLANK.value });
            } else {
                setReservationAnswer(response);
            }
        }
    };

    useEffect(() => {
        if (!reservationAnswer) {
            getReservationAnswer(id).then(() => {});
        }

        return () => {
            setSelectedSingleOptIds([]);
        };
    }, [id]);

    useEffect(() => {
        if (reservationAnswer && reservationAnswer.json_answers) {
            const answers = JSON.parse(reservationAnswer.json_answers);
            setSelectedSingleOptIds(
                answers.filter(o => o.type === QuestionTypeEnum.SINGLE_SELECTION.value).map(o => o.values)
            );
        }
    }, [reservationAnswer]);

    if (!reservationAnswer) return <Loader className="loader-center" />;

    // helper
    const parseArrOptions = (opts = []) => {
        let optionsOrder = [];
        let options = {};
        opts.map(opt => {
            optionsOrder.push(opt.id);
            options[opt.id] = opt;
        });
        return { optionsOrder, options };
    };

    const valueMomentToStr = (current, format, reverse) => {
        const func = reverse ? strToMoment : momentToStr;
        return current.is_range
            ? [func(current.value[0], format), func(current.value[1], format)]
            : func(current.value, format);
    };

    // reserve case date-time
    const strNestedToMoment = values => {
        return allNestedMomentToStr(values, true);
    };

    // case: date-time nested
    const allNestedMomentToStr = (values = {}, reverse = false) => {
        let objValues = {};
        Object.keys(values).map(k => {
            let current = values[k];
            if (current.type === DateTimeOptionTypeEnum.DATE.value) {
                objValues[k] = {
                    ...current,
                    value: valueMomentToStr(current, 'YYYY-MM-DD', reverse),
                };
            } else if (current.type === DateTimeOptionTypeEnum.TIME.value) {
                objValues[k] = {
                    ...current,
                    value: valueMomentToStr(current, 'HH:mm', reverse),
                };
            } else if (current.type === DateTimeOptionTypeEnum.DATE_TIME.value) {
                objValues[k] = {
                    ...current,
                    value: valueMomentToStr(current, 'YYYY-MM-DD HH:mm', reverse),
                };
            } else if (current.type === DateTimeOptionTypeEnum.DURATION.value) {
                objValues[k] = current;
            }
        });

        return objValues;
    };

    const questions = JSON.parse(reservationAnswer.json_questions);
    let answers = JSON.parse(reservationAnswer.json_answers) || [];
    const lastAnsweredTime = reservationAnswer.updated_at;
    const isAnsweredPeriod = isLastAnsweredPeriod(lastAnsweredTime, reservationAnswer?.interview_sheet?.periods);
    if (!isAnsweredPeriod && reservationAnswer?.interview_sheet?.is_radar_questionnaire) {
        answers = [];
    }
    const parseCategories = jsonCategories => {
        if (!jsonCategories) {
            return [];
        }
        const arrCategories = JSON.parse(jsonCategories);
        return arrCategories.map(category => {
            let tmp = { ...category, children: [] };

            for (let i = category.start; i <= category.end; i++) {
                if (i < questions.length) {
                    tmp.children.push(questions[i].id);
                }
            }

            return tmp;
        });
    };
    const categories = reservationAnswer.interview_sheet
        ? parseCategories(reservationAnswer.interview_sheet.json_categories)
        : [];

    const isEdit = answers && answers.length > 0;

    const initialValues = answers.reduce(
        (rs = {}, answer) => ({
            ...rs,
            [`qid_${answer.qid}`]: answer.type === 'date-time' ? strNestedToMoment(answer.values) : answer.values,
        }),
        {}
    );

    // submit
    const submitForm = async (values, redirectTo) => {
        setLoading(true);
        let answers = [];
        Object.keys(values)
            .filter(k => values[k])
            .map(k => {
                const question = questions.find(q => k === `qid_${q.id}`);
                if (question) {
                    answers.push({
                        qid: question.id,
                        values: question.type === 'date-time' ? allNestedMomentToStr(values[k]) : values[k],
                        type: question.type,
                    });
                }
            });

        // submit
        try {
            const response = await api.patch(
                APP_URLS.CLIENT_INTERVIEW_SHEETS_SUBMIT_ANSWERS.replace(':id', id),
                {
                    json_answers: JSON.stringify(answers),
                    is_first_interview_sheet: reservationAnswer.is_first_interview_sheet,
                    program_id: reservationAnswer.program_id,
                    ...(isAnswerPeriod ||
                    (!isAnsweredPeriod && reservationAnswer?.interview_sheet?.periods?.length > 0)
                        ? { period: true }
                        : {}),
                },
                null,
                null,
                true
            );
            if (response) {
                setLoading(false);
                showNormal(
                    '',
                    t('message:{{interviewSheetName}}saved the answer', {
                        interviewSheetName: reservationAnswer.title,
                    }),
                    5,
                    { width: 'max-content' },
                    'custom-notification-mobile mt-32'
                );
                redirectTo
                    ? history.push(redirectTo)
                    : history.push(
                          Routes.private.CLIENT_INTERVIEW_SHEET_DISPLAY.path.replace(
                              ':id',
                              response.program_id ? response.program_id : response.id
                          ) + (response.program_id ? '?first=true' : '')
                      );
            }
        } catch (e) {
            if (e.response && e.response.data && e.response.data.error === 'Got the answer for this period') {
                showError(
                    '',
                    '本期間のアンケートは回答済みのため回答できません',
                    5,
                    { width: 'max-content' },
                    'custom-notification-mobile mt-32'
                );
            } else {
                showError(
                    '',
                    '回答期間を設定したので、期間外は回答できません。',
                    5,
                    { width: 'max-content' },
                    'custom-notification-mobile mt-32'
                );
            }
        }

        setLoading(false);
    };

    const getSkipQuestionRules = questions => {
        let rule = {};

        for (let i = 1; i <= questions.length; i++) {
            const q = questions[i - 1];
            if (q.type !== QuestionTypeEnum.SINGLE_SELECTION.value) {
                continue;
            }
            const { optionsOrder, options } = parseArrOptions(q.options);
            if (optionsOrder != null) {
                for (const optId of optionsOrder) {
                    const option = options[optId];
                    const { next_question } = option;
                    if (next_question) {
                        rule[optId] = {
                            from: i + 1,
                            to: next_question === 'finish' ? questions.length + 99 : Number(next_question) - 1,
                        };
                        if (rule[optId].from > rule[optId].to) {
                            delete rule[optId];
                        }
                    }
                }
            }
        }
        return rule;
    };

    const skipRules = getSkipQuestionRules(questions);
    const handleSkipQuestions = values => {
        let selectedOptIds = [];
        Object.keys(values)
            .filter(k => values[k])
            .map(k => {
                const question = questions.find(q => k === `qid_${q.id}`);
                if (question && question.type === QuestionTypeEnum.SINGLE_SELECTION.value) {
                    selectedOptIds.push(values[k]);
                }
            });
        setSelectedSingleOptIds(selectedOptIds);
    };
    const skipRuleArr = Object.keys(skipRules)
        .filter(
            key =>
                selectedSingleOptIds.includes(Number(key)) ||
                selectedSingleOptIds.find(s => s && s.ids && s.ids.includes(Number(key)))
        )
        .map(key => skipRules[key]);

    const check24hValue = value => {
        const sortedValues = value.sort((a1, b1) => {
            const a = timeToMinute(a1.start);
            const b = timeToMinute(b1.start);
            return a > b ? 1 : a < b ? -1 : 0;
        });
        const result = {
            start: '0:00',
            missing: [],
            missingStr: '',
        };
        sortedValues.map(v => {
            if (timeToMinute(v.start) > timeToMinute(result.start)) {
                result.missing.push({ start: result.start, end: v.start });
                if (result.missingStr !== '') {
                    result.missingStr += '、';
                }
                result.missingStr += `${result.start}-${v.start}`;
            }
            result.start = v.end;
        });
        if (result.start !== '24:00') {
            result.missing.push({ start: result.start, end: '24:00' });
            if (result.missingStr !== '') {
                result.missingStr += '、';
            }
            result.missingStr += `${result.start}-${'24:00'}`;
        }
        return result;
    };

    const isFilledIn =
        reservationAnswer && reservationAnswer.status === ReservationAnswerStatus.FILLED_IN.value && isAnsweredPeriod;
    const checkIsOutOfPeriodRange = (periods = []) => {
        if (periods && periods.length > 0) {
            const matchPeriod = checkNowMatchPeriod(periods, true);
            return !matchPeriod;
        }

        return false;
    };
    const isOutOfPeriodRangeFromPeriodSet = checkIsOutOfPeriodRange(reservationAnswer?.interview_sheet?.periods);

    if (isOutOfPeriodRangeFromPeriodSet) {
        return (
            <>
                <BackBtnWithTitle
                    title={''}
                    onClick={() => history.push(Routes.private.CLIENT_INTERVIEW_SHEET.path)}
                    className="mb-32"
                />
                <div>
                    <Row justify="center">
                        <Col>
                            <Image className="mt-40" src={noData} preview={false} />
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col className="f-16-24">
                            <div className="mt-32">{'設定されている回答可能期間外のため、'}</div>
                            <div>{'質問票に回答することができません。'}</div>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }

    return (
        <>
            <BackBtnWithTitle
                title={reservationAnswer.title}
                onClick={() => history.push(Routes.private.CLIENT_INTERVIEW_SHEET.path)}
            />
            <div>
                <div className="mt-8 mb-32">{reservationAnswer.input_condition}</div>
                <div className="">
                    <div className="mb-16">{reservationAnswer.description}</div>
                    <div className="pink-color mb-24">{'*必須項目'}</div>
                </div>

                <RouterPrompt
                    childRef={ref => (routerPromptRef.current = ref)}
                    handleSave={async path => {
                        let values = form.getFieldsValue();
                        let rs = 'unset';
                        await form
                            .validateFields()
                            .then(() => {
                                submitForm(values, path).then(() => {});
                                rs = 'pass';
                            })
                            .catch(() => {
                                rs = 'field_error';
                            });

                        return rs;
                    }}
                    isValueChange={isValueChange}
                    title="問診の中止"
                    body={
                        isEdit
                            ? '問診の回答を中止します。編集された内容は破棄されますがよろしいですか？'
                            : '問診の回答を中止します。記入された内容は破棄されますがよろしいですか？'
                    }
                    leaveMode={true}
                />

                <Form
                    form={form}
                    layout="vertical"
                    initialValues={initialValues}
                    onFinish={async values => {
                        setIsValueChange(false);
                        await submitForm(values);
                    }}
                    onFinishFailed={errorInfo => {
                        const questionValueEntries = Object.entries(errorInfo.values);
                        const dailyErrors = {};
                        let index = 0;
                        for (const entry of questionValueEntries) {
                            const questionId = entry[0];
                            let questionValue = entry[1];
                            const question = questions.find(q => questionId === `qid_${q.id}`);
                            if (question.type !== QuestionTypeEnum.DAILY_LIFE.value) continue;
                            index++;
                            if (questionValue === undefined) {
                                dailyErrors[questionId] = {
                                    order: index,
                                    num: 0,
                                    error: t('validation:Please enter a required field'),
                                };
                                continue;
                            }
                            const result = check24hValue(questionValue);
                            if (result.missing.length === 0) {
                                index = 0;
                                continue;
                            }
                            const missingHour = result.missing[0].start.split(':')[0];
                            dailyErrors[questionId] = {
                                order: index,
                                num: parseInt(missingHour),
                                error:
                                    t('validation:Enter a 24 hour value') +
                                    ' (' +
                                    result.missingStr +
                                    'の時間帯に入力してください)',
                            };
                        }
                        setErrorsDailyLife(dailyErrors);
                        if (Object.keys(dailyErrors).length > 0) {
                            showNormal(t('validation:Enter a 24 hour value'), '', 3);
                        }
                    }}
                    requiredMark={false}
                    onValuesChange={() => {
                        if (!isValueChange) {
                            setIsValueChange(true);
                        }
                        handleSkipQuestions(form.getFieldsValue());
                    }}
                    style={{ marginBottom: 63 }}
                    onFocus={handleScrollOnFocus}
                    onChange={handleScrollOnFocus}
                    onKeyPress={e => {
                        e.key === 'Enter' && e.target.nodeName === 'INPUT' && e.preventDefault();
                    }}
                    className={isFilledIn ? `pointer-events-none` : ''}
                >
                    {categories.length > 0 ? (
                        <>
                            {categories.map((category, index) => {
                                return (
                                    <div key={`rct-${index}`} className="mt-24">
                                        <Typography
                                            className="fs-16 fw-700 mb--24 mb-mobile-0 bg-gray-5"
                                            style={{ paddingLeft: 16, paddingTop: 8, paddingBottom: 8 }}
                                        >
                                            {category.title}
                                        </Typography>
                                        {category.children.map(questionId => {
                                            const q = questions.find(q => q.id === questionId);
                                            const { optionsOrder, options } = parseArrOptions(q.options);
                                            return (
                                                <Form.Item
                                                    key={q.id}
                                                    className="white-space-pre-line detail-has-validation-msg"
                                                    name={`qid_${q.id}`}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t('validation:Please enter a required field'),
                                                        },
                                                    ]}
                                                >
                                                    <SingleSelectQuestion
                                                        key={`ssq_${questionId}`}
                                                        info={{ ...q, isRequired: true }}
                                                        optionsOrder={optionsOrder}
                                                        options={options}
                                                        isFilledIn={isFilledIn}
                                                    />
                                                </Form.Item>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </>
                    ) : (
                        <div>
                            {questions.map((q, index) => {
                                let Component = null;
                                switch (q.type) {
                                    case QuestionTypeEnum.FREE_INPUT.value:
                                    case QuestionTypeEnum.FREE_INPUT_PARAGRAPH.value:
                                    case QuestionTypeEnum.FREE_INPUT_NUMBER.value:
                                        Component = FreeInputQuestion;
                                        break;
                                    case QuestionTypeEnum.SINGLE_SELECTION.value:
                                        Component = SingleSelectQuestion;
                                        break;
                                    case QuestionTypeEnum.MULTIPLE_SELECTION.value:
                                        Component = MultipleSelectQuestion;
                                        break;
                                    case QuestionTypeEnum.DATE_TIME.value:
                                        Component = DateTimeQuestion;
                                        break;
                                    case QuestionTypeEnum.DAILY_LIFE.value:
                                        Component = DailyLifeQuestion;
                                        break;
                                }

                                const { optionsOrder, options } = parseArrOptions(q.options);

                                if (skipRuleArr.find(r => r.from <= index + 1 && r.to >= index + 1)) {
                                    return null;
                                }

                                return (
                                    <Form.Item
                                        key={q.id}
                                        className="white-space-pre-line detail-has-validation-msg"
                                        name={`qid_${q.id}`}
                                        rules={[
                                            {
                                                required:
                                                    (q.is_required && q.type !== QuestionTypeEnum.DAILY_LIFE.value) ||
                                                    false,
                                                message: t('validation:Please enter a required field'),
                                            },
                                            () => ({
                                                validator(_, value) {
                                                    if (q.is_required) {
                                                        if (
                                                            q.type === QuestionTypeEnum.MULTIPLE_SELECTION.value &&
                                                            (!value || !value.ids || value.ids.length < 1)
                                                        ) {
                                                            return Promise.reject();
                                                        }
                                                    }
                                                    if (q.type === QuestionTypeEnum.DAILY_LIFE.value) {
                                                        if (!value) {
                                                            return Promise.reject();
                                                        }
                                                        const result = check24hValue(value);
                                                        if (result.missing.length > 0) {
                                                            return Promise.reject();
                                                        }
                                                    }

                                                    const regHalfWidth = /^[0-9]+$/;

                                                    if (
                                                        value &&
                                                        q.type === QuestionTypeEnum.FREE_INPUT_NUMBER.value &&
                                                        !regHalfWidth.test(value)
                                                    ) {
                                                        return Promise.reject(
                                                            new Error(t('validation:Please enter half-width numbers'))
                                                        );
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <Component
                                            info={q}
                                            optionsOrder={optionsOrder}
                                            options={options}
                                            errorsDailyLife={errorsDailyLife}
                                            isFilledIn={isFilledIn}
                                        />
                                    </Form.Item>
                                );
                            })}
                        </div>
                    )}

                    <FloatBar className={isFilledIn ? 'd-none' : ''}>
                        <Button
                            type="primary"
                            loading={loading}
                            className="mr-8 fw-b"
                            htmlType="submit"
                            disabled={!isValueChange}
                        >
                            {t('Send')}
                        </Button>
                    </FloatBar>
                </Form>
            </div>
        </>
    );
};

export default connect(mapState)(InterviewSheetDetail);

InterviewSheetDetail.propTypes = {
    reservationAnswers: PropTypes.array,
};
