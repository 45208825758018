import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BackBtn from 'components/BackBtn';
import ReservationForm from 'pages/counselor/calendar/consultation/ReservationForm';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import history from 'helpers/history.helper';
import ConsultationRoutes from 'pages/counselor/calendar/consultation/consultation_routes';
import moment from 'moment';
import Routes from 'routes';
import { showNormal } from 'helpers/notification.helper';
import { useTranslation } from 'react-i18next';

const ReservationUpdate = () => {
    const { id, reservationId } = useParams();
    const { t } = useTranslation();
    const [consultant, setConsultant] = useState();

    const getConsultant = async id => {
        const response = await api.get(APP_URLS.COUNSELOR_CONSULTANT_INFO.replace(':id', id));
        if (response) {
            setConsultant(response);
        }
    };

    const updateReservation = async values => {
        const data = await api.post(
            APP_URLS.COUNSELOR_RESERVATIONS_UPDATE.replace(':id', id).replace(':reservationId', reservationId),
            values
        );
        if (data) {
            history.push(
                `${Routes.private.COUNSELOR_DETAIL_CONSULTATION.path.replace(
                    ':id',
                    id
                )}${ConsultationRoutes.DETAIL.path.replace(':reservationId', reservationId)}`
            );
            showNormal('', t('message:Saved reservation changes'), 3);
        }
    };

    const [reservation, setReservation] = useState(null);
    const getReservation = async () => {
        const data = await api.get(APP_URLS.COUNSELOR_RESERVATIONS_DETAIL.replace(':id', reservationId));
        if (data) {
            setReservation(data);
        }
    };

    useEffect(() => {
        getConsultant(id).then(() => {});
    }, [id]);

    useEffect(() => {
        getReservation().then(() => {});
    }, [reservationId]);

    if (!consultant || !reservation) {
        return <></>;
    }

    let initialValues = { ...reservation };
    initialValues.date = moment(reservation.date);
    initialValues.time = `${reservation.start_time}〜${reservation.end_time}`;

    return (
        <div className="pl-44 pr-24 mb-32">
            <BackBtn className="back-btn-counselor" label={'相談編集'} />
            <ReservationForm
                consultant={consultant}
                isUpdate={true}
                onSubmit={values => updateReservation(values)}
                initialValues={initialValues}
            />
        </div>
    );
};

export default ReservationUpdate;
