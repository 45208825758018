import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, DatePicker, Modal } from 'antd';
import { CloseIcon, DateRangeIcon } from 'components/SvgIcon';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import TdtSelectTime from 'components/TdtSelectTime';
import { dateTimeToMoment, momentToStr, strToMoment } from 'helpers/date.helper';

const SettingTransmissionDateModal = ({ visible, onClose, onUpdate, isUpdate = false, seminar }) => {
    const { t } = useTranslation();
    const titleContent = (
        <span>
            {!isUpdate
                ? t('Notification automatic transmission setting')
                : t('Edit the date and time when notifications are automatically sent')}
        </span>
    );

    const [value, setValue] = useState({
        date: seminar.transmission_date
            ? strToMoment(seminar.transmission_date)
            : strToMoment(seminar.date).subtract(1, 'day'),
        time: seminar.transmission_time || '12:00',
    });

    const checkWarningTime = () => {
        return moment().isAfter(dateTimeToMoment(value.date, value.time));
    };

    const checkTimeInPast = () => moment().isAfter(dateTimeToMoment(value.date, value.time));

    const checkTimeAfterSessionDate = () => {
        return dateTimeToMoment(value.date, value.time).isAfter(
            dateTimeToMoment(strToMoment(seminar.date), seminar.end_time)
        );
    };

    const descriptionContent = (
        <>
            <span className="d-block mb-16">
                {checkWarningTime() ? (
                    <>
                        <span className="pink-color d-block">
                            {t('The transmission date and time has passed the day before the session date and time.')}
                        </span>
                        <span>{t('Please set a date when sending notifications.')}</span>
                    </>
                ) : (
                    <>
                        {checkTimeAfterSessionDate() ? (
                            <span className="pink-color d-block">
                                {t(
                                    'The transmission date and time has passed the day before the session date and time.'
                                )}
                            </span>
                        ) : (
                            <></>
                        )}
                        <span>
                            {!isUpdate
                                ? t(
                                      'After the session is published, we will send you a notification at the next date and time.'
                                  )
                                : ''}
                        </span>
                    </>
                )}
            </span>

            <div className="d-flex align-items-center mb-16">
                <DatePicker
                    placeholder={'日時を選択'}
                    className="mr-24 bg-gray tdt-datepicker reservation-date-picker"
                    dropdownClassName="tdt-datepicker-panel z-index-above-modal"
                    disabledDate={date => {
                        if (
                            date.isBefore(moment().startOf('day')) ||
                            date.isAfter(strToMoment(seminar.date).endOf('day'))
                        ) {
                            return true;
                        }
                    }}
                    style={{ width: 160 }}
                    suffixIcon={<DateRangeIcon />}
                    format="YYYY年M月D日(dd)"
                    value={value.date}
                    allowClear={false}
                    showToday={false}
                    onChange={date => setValue({ ...value, date: date })}
                    inputReadOnly={true}
                />
                <TdtSelectTime
                    style={{ width: 86 }}
                    className={`mr-16 invalid-time-style ${checkTimeInPast() ? 'invalid-time' : ''}`}
                    onChange={v => setValue({ ...value, time: v })}
                    value={value.time}
                />
            </div>
        </>
    );

    const isDisableSaveButton =
        !value || !value.date || !value.time || checkTimeInPast() || checkTimeAfterSessionDate();

    return (
        <Modal
            title={titleContent}
            visible={visible}
            width={400}
            footer={null}
            closeIcon={<CloseIcon />}
            onCancel={() => onClose()}
        >
            {descriptionContent}
            {
                <>
                    <div className="delete-action-panel">
                        {!isUpdate ? (
                            <>
                                <Button
                                    type=""
                                    className="cancel-button fw-b disable-color"
                                    onClick={() => {
                                        onUpdate && onUpdate(null, true);
                                        onClose();
                                    }}
                                >
                                    <span className={'title-button'}>{t('Published without setting')}</span>
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button
                                    type=""
                                    className="cancel-button fw-b disable-color"
                                    onClick={() => {
                                        onClose();
                                    }}
                                >
                                    <span className={'title-button'}>{t('Cancel')}</span>
                                </Button>
                            </>
                        )}

                        <Button
                            type=""
                            className={`delete-button fw-b bg-transparent ${
                                isDisableSaveButton ? 'disable-color' : 'color-blue-100'
                            }`}
                            onClick={() => {
                                onUpdate && onUpdate({ ...value, date: momentToStr(value.date) });
                                onClose();
                            }}
                            disabled={isDisableSaveButton}
                        >
                            <span className={'title-button'}>{t('Save')}</span>
                        </Button>
                    </div>
                </>
            }
        </Modal>
    );
};

SettingTransmissionDateModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

export default SettingTransmissionDateModal;
