import { createSlice } from '@reduxjs/toolkit';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import SendEmailStatus from 'enums/send_email_status';

const name = 'emailDetail';

const emailDetailSlice = createSlice({
    name,
    initialState: {
        emailHtml: null,
        filters: {
            companyId: null,
            deliveryStatus: null,
        },
    },
    reducers: {
        setEmailHtml(state, action) {
            state.emailHtml = action.payload;
        },
        setFilterByCompany(state, action) {
            state.filters.companyId = action.payload;
        },
        setFilterByDeliveryStatus(state, action) {
            state.filters.deliveryStatus = action.payload;
        },
        resetFilter(state) {
            state.filters = {
                companyId: null,
                deliveryStatus: null,
            };
        },
    },
});

export const { setEmailHtml, setFilterByCompany, setFilterByDeliveryStatus, resetFilter } = emailDetailSlice.actions;

export const getEmailHtml = id => async dispatch => {
    const data = await api.get(APP_URLS.ADMIN_EMAILS_DETAIL.replace(':id', id));
    if (data) {
        dispatch(setEmailHtml(data));
    }
};

export default emailDetailSlice.reducer;

export const deliveryResultSelector = state => {
    const { emailHtml, filters } = state;
    const deliveryResults = emailHtml.target_audiences;
    const filteredDeliveryResults = [];
    let companies = {};
    for (const deliveryResult of deliveryResults) {
        if (deliveryResult.company_id) {
            companies[deliveryResult.company_id] = {
                id: deliveryResult.company_id,
                name: deliveryResult.company_name,
            };
        }

        if (!filters.companyId || Number(filters.companyId) === Number(deliveryResult.company_id)) {
            if (!filters.deliveryStatus || filters.deliveryStatus === deliveryResult.status) {
                filteredDeliveryResults.push(deliveryResult);
            }
        }
    }

    companies = Object.values(companies);

    const countSuccessDelivery = deliveryResults.filter(
        d => d.status === SendEmailStatus.SUCCESSFUL_DELIVERY.value
    ).length;

    const countPermanentError = deliveryResults.filter(d => d.status === SendEmailStatus.PERMANENT_ERROR.value).length;
    const countTemporaryError = deliveryResults.filter(d => d.status === SendEmailStatus.TEMPORARY_ERROR.value).length;
    const countUnknownError = deliveryResults.filter(d => d.status === SendEmailStatus.UNKNOWN_ERROR.value).length;

    return { companies, deliveryResults, filteredDeliveryResults, countSuccessDelivery, countPermanentError, countTemporaryError, countUnknownError };
};
