const PageElementTypeEnum = Object.freeze({
    HEADING: { value: 'heading', text: 'Heading' },
    TEXT: { value: 'text', text: 'Text' },
    IMAGE: { value: 'image', text: 'Image' },
    PERSON_PROFILE: { value: 'person_profile', text: 'Person profile' },
    HTML_CODE: { value: 'html_code', text: 'HTML code' },
    BUTTON: { value: 'button', text: 'Button', is_extend: true },
});

export default PageElementTypeEnum;
