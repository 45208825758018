import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Col, DatePicker, Row, Typography } from 'antd';
import { dateFormatForHealth } from 'helpers/date.helper';
import LineChart from 'components/chart/LineChart';
import DateChangeComponent from 'pages/client/health_data/tabs/date_time/DateChangeComponent';
import HealthDataSidebar from 'pages/counselor/calendar/health_data/HealthDataSidebar';
import {
    chartDataSelector,
    getPressures,
    goNext,
    goPrev,
    goToday,
    pressuresFilterSelector,
    setDisplayType,
    setMonthFilter,
} from 'pages/counselor/calendar/health_data/pressure.slice';
import { CyanCircleIcon, PurpleCircleIcon } from 'components/SvgIcon';
import NoData from 'components/NoData';
import { isAdminStaff } from 'helpers/role.helper';
import TdtButtonRestrict from 'components/TdtButtonRestrict';

const mapState = state => ({
    startDate: state.counselor.healthDataPressure.startDate,
    endDate: state.counselor.healthDataPressure.endDate,
    displayType: state.counselor.healthDataPressure.displayType,
    monthFilter: state.counselor.healthDataPressure.monthFilter,
    hasData: state.counselor.healthDataPressure.pressures.length > 0,
    pressures: pressuresFilterSelector(state.counselor.healthDataPressure),
    chartData: chartDataSelector(state.counselor.healthDataPressure),
    user: state.auth.user,
});

const mapDispatch = { goPrev, goNext, goToday, setDisplayType, setMonthFilter, getPressures };

const xLabelRenderer = date => moment(date).format('MM/DD');

const PressureChart = ({
    startDate,
    endDate,
    displayType,
    monthFilter,
    pressures,
    chartData,
    goPrev,
    goNext,
    goToday,
    setDisplayType,
    setMonthFilter,
    getPressures,
    user,
}) => {
    const { t } = useTranslation();
    const weekFormat = 'YYYY年MM月';

    const { id } = useParams();

    useEffect(() => {
        getPressures(id, startDate, endDate);
    }, [id, startDate, endDate]);

    const tooltipRenderer = useCallback(
        points => {
            const width = 150;
            const x = points[0].x - width / 2;
            const y = points[0].y;
            return (
                <>
                    <rect x={x} y={y + 8} width={width} height={52} rx={4} ry={4} fill="#ffffff" stroke="#D0D0D0" />
                    <text x={x + 8} y={y + 32} fill="#222126" fontSize={12} alignmentBaseline="top">
                        {chartData.datesHasAverage.includes(points[0].name) && <tspan>{t('Average')}</tspan>}
                        <tspan fontSize={16} fontWeight={500} dx={4}>
                            {points[1].value.toFixed(0)}/{points[0].value.toFixed(0)} mmHg
                        </tspan>
                    </text>
                    <text x={x + 8} y={y + 50} fill="#222126" fontSize={12} alignmentBaseline="top">
                        {moment(points[0].name).format(displayType === 'year' ? 'M月' : 'M月D日(dd)')}
                    </text>
                </>
            );
        },
        [t, displayType, chartData.datesHasAverage]
    );

    return (
        <>
            <div className="counselor-aside">
                <div className="consultation-aside">
                    <div className="p-16 pb-0">
                        <div className="h7 mt-16 mb-24">{t('Health data')}</div>
                    </div>
                </div>
                <HealthDataSidebar activeKey="blood_pressure" />
            </div>
            <div className="counselor-main pl-24 pr-24 mt-40">
                <div className="f-24-36 fw-b">{t('Blood Pressure')}</div>
                {!isAdminStaff(user) && (
                    <DateChangeComponent
                        startDate={startDate}
                        endDate={endDate}
                        displayType={displayType}
                        goPrev={goPrev}
                        goNext={goNext}
                        goToday={goToday}
                        setDisplayType={setDisplayType}
                    />
                )}

                {isAdminStaff(user) ? (
                    <TdtButtonRestrict withImage={true} />
                ) : (
                    <>
                        <div className="mt-16">
                            <span>
                                <PurpleCircleIcon className="pr-8" size={11} />
                                {t('field:Maximum blood pressure')}
                            </span>
                            <span className="pl-14">
                                <CyanCircleIcon className="pr-8" size={11} />
                                {t('field:Minimum blood pressure')}
                            </span>
                        </div>

                        <LineChart
                            data={chartData}
                            width={1000}
                            height={320}
                            yStepSize={20}
                            yMin={50}
                            yCount={6}
                            baseLines={[80, 130]}
                            xLabelRender={xLabelRenderer}
                            circleOnHoverOnly={displayType !== 'week'}
                            tooltipRender={tooltipRenderer}
                        />

                        <Row justify="space-between" align="bottom">
                            <Col className="mt-48">
                                <Typography className="fw-b">{t('Data details')}</Typography>
                            </Col>
                            <Col>
                                {displayType === 'year' && (
                                    <DatePicker
                                        value={monthFilter}
                                        picker="month"
                                        format={weekFormat}
                                        allowClear={false}
                                        onChange={value => {
                                            setMonthFilter(value);
                                        }}
                                        inputReadOnly={true}
                                    />
                                )}
                            </Col>
                        </Row>
                        {pressures.length === 0 ? (
                            <NoData />
                        ) : (
                            pressures.map(p => {
                                return (
                                    <Row
                                        key={p.id}
                                        className="health-data-row data-details"
                                        justify="space-between"
                                        align="middle"
                                    >
                                        <Col className="health-data-col-center" span={4}>
                                            <Typography>{dateFormatForHealth(p.created_at)}</Typography>
                                        </Col>
                                        <Col span={4} className="flex">
                                            <Typography>
                                                {p.value || '0'}/{p.ext_value || '0'}
                                            </Typography>
                                        </Col>
                                        <Col className="health-data-col-flex-end" span={14} />
                                    </Row>
                                );
                            })
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default connect(mapState, mapDispatch)(PressureChart);

PressureChart.propTypes = {
    tab: PropTypes.string,
    url: PropTypes.string,
};
