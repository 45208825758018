import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from 'components/SvgIcon';
import RenderHTMLMail from 'pages/admin/common/page-elements/RenderHTMLMail';

const EmailPreviewModal = ({ visible, onClose, record, isProgram = false }) => {
    const { t } = useTranslation();

    if (!record) {
        return <></>;
    }

    return (
        <Modal
            title={record.name || t('Preview')}
            visible={visible}
            width={823}
            closeIcon={<CloseIcon />}
            onCancel={() => onClose()}
            footer={null}
        >
            <div className="render-page-container html-mail_wrapper">
                {record.additional_notes ? (
                    <div className="preview-html-note-wrapper">
                        <div className="preview-html-note">{record.additional_notes}</div>
                    </div>
                ) : (
                    <></>
                )}
                <RenderHTMLMail
                    info={record}
                    isProgram={isProgram}
                    details={isProgram ? record.program_details : record.seminar_details}
                />
            </div>
        </Modal>
    );
};

export default EmailPreviewModal;
