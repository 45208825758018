export const rdStr = (len = 4) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < len; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export const truncateStr = (source, size = 32) => {
    if (!source) return '';
    return source.length > size ? source.slice(0, size - 1) + '...' : source;
};

export const emailDeletedShow = str => {
    if (!str) return '';

    const pos = str.indexOf('_deleted_');
    if (pos > 0) {
        return str.substr(0, pos);
    }

    return str;
};
