import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import history from 'helpers/history.helper';

const GoHomeButton = () => {
    const { t } = useTranslation();
    return (
        <Button type="primary" onClick={() => history.push('/')}>
            {t('Go to home')}
        </Button>
    );
};

export default GoHomeButton;
