import React from 'react';
import PropTypes from 'prop-types';
import QuestionTypeEnum from 'enums/question_type.enum';
import { useTranslation } from 'react-i18next';
import { DatePicker, Input, Space, TimePicker } from 'antd';
import QuestionCommon from 'components/questions/QuestionCommon';
import { DateRangeIcon } from 'components/SvgIcon';
import DateTimeOptionTypeEnum from 'enums/date_time_option_type.enum';

const { RangePicker } = DatePicker;

const DateTimeQuestion = ({ info, optionsOrder, options, ...rest }) => {
    const { t } = useTranslation();

    //const other = { id: rest.id, value: rest.value, onChange: rest.onChange };

    const customValue = rest.value || {};

    const handleValueChange = (option, value) => {
        rest.onChange &&
            rest.onChange({ ...customValue, [option.id]: { type: option.type, is_range: option.is_range, value } });
    };

    const renderOption = option => {
        const isRange = option.is_range;

        switch (option.type) {
            case DateTimeOptionTypeEnum.DATE.value:
                return (
                    <>
                        {isRange ? (
                            <RangePicker
                                value={customValue[option.id] ? customValue[option.id].value : null}
                                onChange={v => handleValueChange(option, v)}
                                style={{ minWidth: 240 }}
                                suffixIcon={<DateRangeIcon />}
                                format="YYYY年M月D日(dd)"
                                inputReadOnly={true}
                            />
                        ) : (
                            <DatePicker
                                value={customValue[option.id] ? customValue[option.id].value : null}
                                onChange={v => handleValueChange(option, v)}
                                style={{ minWidth: 240 }}
                                suffixIcon={<DateRangeIcon />}
                                format="YYYY年M月D日(dd)"
                                inputReadOnly={true}
                            />
                        )}
                    </>
                );
            case DateTimeOptionTypeEnum.TIME.value:
                return (
                    <>
                        {isRange ? (
                            <RangePicker
                                value={customValue[option.id] ? customValue[option.id].value : null}
                                onChange={v => handleValueChange(option, v)}
                                picker="time"
                                inputReadOnly={true}
                            />
                        ) : (
                            <TimePicker
                                value={customValue[option.id] ? customValue[option.id].value : null}
                                onChange={v => handleValueChange(option, v)}
                                inputReadOnly={true}
                            />
                        )}
                    </>
                );
            case DateTimeOptionTypeEnum.DATE_TIME.value:
                return (
                    <>
                        {isRange ? (
                            <RangePicker
                                value={customValue[option.id] ? customValue[option.id].value : null}
                                onChange={v => handleValueChange(option, v)}
                                style={{ minWidth: 240 }}
                                suffixIcon={<DateRangeIcon />}
                                format="YYYY年M月D日(dd) HH:mm"
                                showTime={{ format: 'HH:mm' }}
                                inputReadOnly={true}
                            />
                        ) : (
                            <DatePicker
                                value={customValue[option.id] ? customValue[option.id].value : null}
                                onChange={v => handleValueChange(option, v)}
                                style={{ minWidth: 240 }}
                                suffixIcon={<DateRangeIcon />}
                                format="YYYY年M月D日(dd) HH:mm"
                                showTime={{ format: 'HH:mm' }}
                                inputReadOnly={true}
                            />
                        )}
                    </>
                );
            case DateTimeOptionTypeEnum.DURATION.value:
                return (
                    <span className="d-flex">
                        {isRange ? (
                            <>
                                <Input
                                    value={
                                        customValue[option.id] &&
                                        customValue[option.id].value &&
                                        customValue[option.id].value.length > 0
                                            ? customValue[option.id].value[0]
                                            : ''
                                    }
                                    onChange={evt => {
                                        let currentValue = customValue[option.id] ? customValue[option.id].value : [];
                                        let value = evt.target.value;
                                        if (Array.isArray(currentValue) && currentValue.length > 0) {
                                            currentValue[0] = value;
                                            handleValueChange(option, currentValue);
                                        } else {
                                            handleValueChange(option, [value, '']);
                                        }
                                    }}
                                    className="mt-8 mr-16"
                                />
                                <Input
                                    value={
                                        customValue[option.id] &&
                                        customValue[option.id].value &&
                                        customValue[option.id].value.length > 1
                                            ? customValue[option.id].value[1]
                                            : ''
                                    }
                                    onChange={evt => {
                                        let currentValue = customValue[option.id] ? customValue[option.id].value : [];
                                        let value = evt.target.value;
                                        if (Array.isArray(currentValue) && currentValue.length > 1) {
                                            currentValue[1] = value;
                                            handleValueChange(option, currentValue);
                                        } else {
                                            handleValueChange(option, ['', value]);
                                        }
                                    }}
                                    className="mt-8"
                                />
                            </>
                        ) : (
                            <Input
                                value={customValue[option.id] ? customValue[option.id].value : ''}
                                onChange={evt => handleValueChange(option, evt.target.value)}
                                className="mt-8"
                            />
                        )}
                    </span>
                );
        }
    };

    return (
        <div className="question multiple-select">
            <QuestionCommon
                title={info.value}
                addition={info.addition}
                type={t(`enum:${QuestionTypeEnum.DATE_TIME.text}`)}
                isRequired={info.isRequired || info.is_required}
            />
            <Space direction="vertical" className="mt-16">
                {optionsOrder != null &&
                    optionsOrder.map(optId => {
                        const option = options[optId];
                        return (
                            <div key={optId} className="mb-12 d-flex align-items-center flex-wrap">
                                <label className="mr-12">{option.value}</label>
                                {renderOption(option)}
                            </div>
                        );
                    })}
            </Space>
        </div>
    );
};

export default DateTimeQuestion;

DateTimeQuestion.propTypes = {
    info: PropTypes.shape({
        value: PropTypes.string,
        isRequired: PropTypes.bool,
    }),
    optionsOrder: PropTypes.array,
    options: PropTypes.object,
};
