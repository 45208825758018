import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Checkbox, Col, Form, Row, Select } from 'antd';
import BackBtn from 'components/BackBtn';
import FloatBar from 'components/FloatBar';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import history from 'helpers/history.helper';
import TdtSelect from 'components/TdtSelect';
import { showNormal } from 'helpers/notification.helper';
import TdtButtonSubmit from 'components/TdtButtonSubmit';
import Loader from 'components/Loader';
import { sessionTimeToHourAndMinute, sessionTimeToPoint } from 'helpers/session_time_calculation.helper';

const ProgramUpdate = ({ initialValues }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { id, programId } = useParams();
    const [automaticApply, setAutomaticApply] = useState(true);

    const updateProgram = async values => {
        const response = await api.put(
            APP_URLS.ADMIN_COMPANIES_DETAIL_PROGRAMS_UPDATE.replace(':id', id).replace(':programCompanyId', programId),
            values
        );
        if (response) {
            history.goBack();
            showNormal('', t('message:Reservation slot editing completed'), 3);
        }
    };

    const [companyProgram, setCompanyProgram] = useState({});
    const getCompanyProgram = async () => {
        const response = await api.get(
            APP_URLS.ADMIN_COMPANIES_DETAIL_PROGRAMS_DETAIL.replace(':id', id).replace(':programCompanyId', programId)
        );
        if (response) {
            setCompanyProgram(response);
            setCommentary(sessionTimeToHourAndMinute(response));
        }
    };

    const [isValid, setIsValid] = useState(false);
    const [commentary, setCommentary] = useState({});

    useEffect(() => {
        getCompanyProgram().then(() => {});
    }, [id, programId]);

    if (!companyProgram || !companyProgram.program) {
        return <Loader className="loader-center" />;
    }

    initialValues = Object.assign(
        {
            session_length_hour: 0,
            session_length_minute: 0,
            buffer_time: 0,
            is_automatic_apply: true,
        },
        { ...companyProgram }
    );

    return (
        <div className="px-24">
            <BackBtn label={'予約枠編集'} />
            <Form
                className="mt-32"
                layout="vertical"
                form={form}
                initialValues={initialValues}
                onFinish={async values => {
                    await updateProgram(values);
                }}
                requiredMark={false}
                onValuesChange={() => {
                    setIsValid(sessionTimeToPoint(form.getFieldsValue()) > 0);
                    setCommentary(
                        sessionTimeToHourAndMinute(
                            form.getFieldsValue(['session_length_hour', 'session_length_minute', 'buffer_time'])
                        )
                    );
                }}
            >
                <Row gutter={16}>
                    <Col span={24} className="mb-8">
                        <Form.Item
                            name="program_id"
                            label={'プログラム'}
                            validateTrigger={false}
                            rules={[
                                {
                                    required: true,
                                    message: t('validation:Please enter {{field}}', {
                                        field: t('field:program name'),
                                    }),
                                },
                            ]}
                        >
                            {/*<TdtSelect style={{ width: 240 }} size="large" className="fs-14 select-gray" disabled>*/}
                            {/*    <Select.Option value={companyProgram.program_id}>*/}
                            {/*        {companyProgram.program.name}*/}
                            {/*    </Select.Option>*/}
                            {/*</TdtSelect>*/}
                            <p>{companyProgram.program.name_mngt}</p>
                        </Form.Item>
                    </Col>
                    <Col span={24} style={{ maxWidth: 272 }}>
                        <Form.Item label={'予約枠の長さ'} className="mb-16">
                            <Form.Item name="session_length_hour" noStyle>
                                <TdtSelect style={{ width: 120 }} size="large" className="fs-14 mr-8 select-gray">
                                    {[...Array(24)].map((_, i) => (
                                        <Select.Option key={`s_l_h_${i}`} value={i}>
                                            {i}
                                            {'時間'}
                                        </Select.Option>
                                    ))}
                                </TdtSelect>
                            </Form.Item>
                            <Form.Item name="session_length_minute" noStyle>
                                <TdtSelect style={{ width: 120 }} size="large" className="fs-14 select-gray">
                                    {[...Array(60)].map((_, i) => (
                                        <Select.Option key={`s_l_m_${i}`} value={i}>
                                            {i}
                                            {'分'}
                                        </Select.Option>
                                    ))}
                                </TdtSelect>
                            </Form.Item>
                        </Form.Item>
                    </Col>
                    <Col span={24} style={{ maxWidth: 240 }}>
                        <Form.Item label={'バッファタイム'} name="buffer_time" className="mb-16">
                            <TdtSelect style={{ width: 120 }} size="large" className="fs-14 select-gray">
                                {[...Array(60)].map((_, i) => (
                                    <Select.Option key={`b_t_${i}`} value={i}>
                                        {i}
                                        {'分'}
                                    </Select.Option>
                                ))}
                            </TdtSelect>
                        </Form.Item>
                    </Col>
                    <Col span={24} className="text-gray-color">
                        {commentary.hour + commentary.minute > 0 &&
                            t('Reservation frame will be for each {{time}}', {
                                time:
                                    (commentary.hour > 0 ? `${commentary.hour}時間` : '') +
                                    (commentary.minute > 0 ? `${commentary.minute}分` : ''),
                            })}
                    </Col>
                    <Col span={24}>
                        <Form.Item name="is_automatic_apply" className="mt-32" valuePropName="checked">
                            <Checkbox
                                className="fs-14"
                                value={automaticApply}
                                onChange={e => {
                                    setAutomaticApply(e.target.checked);
                                }}
                            >
                                {t('Automatically apply to new consultants')}
                            </Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <FloatBar>
                    <TdtButtonSubmit type="primary" htmlType="submit" className="fw-b" disabled={!isValid}>
                        {t('Save')}
                    </TdtButtonSubmit>
                </FloatBar>
            </Form>
        </div>
    );
};

export default ProgramUpdate;

ProgramUpdate.propTypes = {
    initialValues: PropTypes.object,
};
