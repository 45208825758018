import React from 'react';
import PieChart from 'components/chart/PieChart';
import DataTable from 'components/DataTable';
import { useTranslation } from 'react-i18next';
import { numberToFixed } from 'helpers/number_format.helper';

const PieWithTableChart = ({ data }) => {
    const { t } = useTranslation();

    // const data = [
    //     {
    //         text: '良い',
    //         percent: 42.3,
    //         count: 80,
    //     },
    //     {
    //         text: '悪い',
    //         percent: 31.1,
    //         count: 30,
    //     },
    //     {
    //         text: 'どちらともいえない',
    //         percent: 26.6,
    //         count: 10,
    //     },
    // ];

    const columns = [
        {
            title: t('スコア'),
            dataIndex: 'text',
            width: 182,
            render: (text, r) => (
                <>
                    <div className="d-flex align-items-center">
                        <div className="gray-symbol" style={{ backgroundColor: r.color }} />
                        <div className="ml-4">{text}</div>
                    </div>
                </>
            ),
        },
        {
            title: t('回答数'),
            dataIndex: 'count',
            width: 120,
        },
        {
            title: t('割合(%)'),
            dataIndex: 'percent',
            width: 120,
            render: (_, r) => (r.count / totalMatchCount ? numberToFixed((r.count / totalMatchCount) * 100, 1) : 0),
        },
    ];

    const totalMatchCount = data.map(d => d.count).reduce((prev = 0, next) => prev + next, 0);

    const parseForPieChart = () => {
        return data
            .filter(d => d.count)
            .map(d => ({ value: d.count, text: numberToFixed((d.count / totalMatchCount) * 100, 1), color: d.color }));
    };

    return (
        <div className="pie-with-table-chart d-flex align-items-center">
            <div style={{ width: 180, marginRight: 40 }}>
                <PieChart data={parseForPieChart(data)} />
            </div>
            <div className="flex-1">
                <DataTable
                    className="collapse-table no-clickable-row-table"
                    columns={columns}
                    noDataText={' '}
                    data={data}
                />
            </div>
        </div>
    );
};

export default PieWithTableChart;
