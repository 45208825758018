import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { CloseIcon } from 'components/SvgIcon';
import { useTranslation } from 'react-i18next';
import ActionBtnInModal from 'components/ActionBtnInModal';

const ChangeSeminarStateModal = ({ visible, onClose, onUpdate, isPrivate }) => {
    const { t } = useTranslation();
    const titleContent = isPrivate ? <span>{t('Session release')}</span> : <span>{t('Session unpublished')}</span>;

    const descriptionContent = isPrivate ? (
        <span>{t('Make the session open to the public. Is it OK?')}</span>
    ) : (
        <span>
            {t(
                'Stop publishing the session and make it private. The automatic transmission of notifications will also stop. Is it OK?'
            )}
        </span>
    );

    return (
        <Modal
            title={titleContent}
            visible={visible}
            width={400}
            footer={null}
            closeIcon={<CloseIcon />}
            onCancel={() => onClose()}
        >
            <div className="mb-16">{descriptionContent}</div>
            {isPrivate ? (
                <ActionBtnInModal
                    onClose={onClose}
                    onUpdate={onUpdate}
                    confirmText={'公開'}
                    isNotDelete={true}
                    manualClose={true}
                />
            ) : (
                <ActionBtnInModal onClose={onClose} onUpdate={onUpdate} confirmText={'公開停止'} />
            )}
        </Modal>
    );
};

ChangeSeminarStateModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

export default ChangeSeminarStateModal;
