import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Checkbox, Input, Select } from 'antd';
import QuestionTypeEnum from 'enums/question_type.enum';
import { getEnumText } from 'helpers/enum.helper';
import TdtSelect from 'components/TdtSelect';

const QuestionCommonFields = ({
    id,
    question,
    addition,
    setQuestion,
    setAddition,
    type,
    setType,
    displayColumns,
    setDisplayColumns,
}) => {
    const { t } = useTranslation();
    const { TextArea } = Input;
    return (
        <>
            <div className="item">
                <div className="label mb-8 mt-24 fw-700 fs-14 text-gray-color ">{t('field:質問')}</div>
                <TextArea
                    className="interview-sheet-input-create"
                    value={question}
                    onChange={e => {
                        setQuestion({
                            id,
                            question: e.target.value,
                        });
                    }}
                    placeholder="質問を入力"
                    autoSize
                />
            </div>
            <div className="item">
                <div className="label mb-8 mt-16 fw-700 fs-14 text-gray-color">{t('field:補足説明')}</div>
                <TextArea
                    className="interview-sheet-input-create"
                    value={addition}
                    onChange={e => {
                        setAddition({
                            id,
                            addition: e.target.value,
                        });
                    }}
                    placeholder="質問を入力"
                    autoSize
                />
            </div>
            <div className="d-flex mt-32 mb-16 align-items-center ant-select-arrow-interview">
                <TdtSelect
                    size="large"
                    className="fs-16 fw-500 bg-white"
                    style={{ minWidth: 88, height: 40 }}
                    value={type}
                    onChange={v => {
                        setType({ id, type: v });
                    }}
                >
                    {Object.values(QuestionTypeEnum).filter(o => o.value !== QuestionTypeEnum.RADAR_CHART.value).map(o => (
                        <Select.Option key={o.value} value={o.value} className="fs-16 fw-400">
                            {t(`enum:${getEnumText(QuestionTypeEnum, o.value)}`)}
                        </Select.Option>
                    ))}
                </TdtSelect>

                <div className="d-flex align-items-center ant-select-arrow-interview">
                    {(type === QuestionTypeEnum.SINGLE_SELECTION.value ||
                        type === QuestionTypeEnum.MULTIPLE_SELECTION.value) && (
                        <Checkbox
                            className="fw-400 fs-14 ml-18"
                            checked={displayColumns.indexOf('set_score') > -1}
                            onChange={e => {
                                const checked = e.target.checked;
                                const tmpDisplayColumns = displayColumns.includes('set_next_question')
                                    ? checked
                                        ? 'set_score,set_next_question'
                                        : 'set_next_question'
                                    : checked
                                    ? 'set_score'
                                    : 'unset';

                                setDisplayColumns({
                                    id,
                                    display_columns: tmpDisplayColumns,
                                    clearScore: true,
                                });
                            }}
                        >
                            {t('スコアを設定する')}
                        </Checkbox>
                    )}

                    {type === QuestionTypeEnum.SINGLE_SELECTION.value && (
                        <Checkbox
                            className="fw-400 fs-14 ml-18"
                            checked={displayColumns.indexOf('set_next_question') > -1}
                            onChange={e => {
                                const checked = e.target.checked;
                                const tmpDisplayColumns = displayColumns.includes('set_score')
                                    ? checked
                                        ? 'set_score,set_next_question'
                                        : 'set_score'
                                    : checked
                                    ? 'set_next_question'
                                    : 'unset';

                                setDisplayColumns({
                                    id,
                                    display_columns: tmpDisplayColumns,
                                    clearNextQuestion: true,
                                });
                            }}
                        >
                            {t('回答に応じて次の質問を変更')}
                        </Checkbox>
                    )}
                </div>
            </div>
        </>
    );
};

export default QuestionCommonFields;

QuestionCommonFields.propTypes = {
    isMultiple: PropTypes.bool,
    id: PropTypes.any.isRequired,
    question: PropTypes.string,
    addition: PropTypes.string,
    setQuestion: PropTypes.func.isRequired,
    setAddition: PropTypes.func.isRequired,
    type: PropTypes.any,
    setType: PropTypes.func,
};
