import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Input, Row } from 'antd';
import FloatBar from 'components/FloatBar';
import TdtButtonSubmit from 'components/TdtButtonSubmit';
import CompanyStateEnum from '../../../enums/company_state.enum';
import { DeleteIcon } from '../../../components/SvgIcon';
import DeleteCompanyModal from './tabs/setting/DeleteCompanyModal';
import api from '../../../helpers/api.helper';
import { APP_URLS } from '../../../constants/url.constant';
import history from '../../../helpers/history.helper';
import Routes from '../../../routes';
import { showNormal } from '../../../helpers/notification.helper';
import { useParams } from 'react-router-dom';

const CompanyFormEdit = ({ isEdit = false, initialValues = {}, onFinish }) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [company, setCompany] = useState({});
    const [modalVisible, setModalVisible] = useState({
        deleteCompany: false,
    });

    const [tempCompanyState] = useState(company.state);

    const showDeleteModal = () => setModalVisible({ deleteCompany: true });
    const closeModal = () => setModalVisible({ deleteCompany: false });

    const deleteCompany = async () => {
        const res = await api.delete(APP_URLS.ADMIN_COMPANIES_DELETE.replace(':id', company.id));
        if (res) {
            history.push(Routes.private.ADMIN_COMPANY.path);
            showNormal('', t('message:Company deletion completed'), 3);
        }
    };
    const getCompany = async id => {
        const response = await api.get(APP_URLS.ADMIN_COMPANIES_DETAIL.replace(':id', id));
        if (response) {
            setCompany(response);
            setLoading(false);
        }
    };

    useEffect(() => {
        getCompany(id).then(() => {
        });
    }, [id]);

    if (loading) return <></>;

    return (
        <Form
            form={form}
            layout='vertical'
            initialValues={initialValues}
            onFinish={async values => {
                await onFinish(values);
            }}
            requiredMark={false}
            className='mt-32 mb--60'
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name='name'
                        label={t('field:Company name')}
                        extra={
                            <span className='company-name-extra'>
                                {/*<InfoIcon className="mr-6" />*/}
                                {
                                    '登録後、企業コードを同社社員に周知する必要があります。企業コードは各企業の詳細画面の「設定」タブで発行できます。'
                                }
                            </span>
                        }
                        validateTrigger={false}
                        rules={[
                            {
                                required: true,
                                message: t('validation:Please enter a valid {{field}}', {
                                    field: t('field:Company name'),
                                }),
                            },
                        ]}
                        style={{ maxWidth: 480 }}
                    >
                        <Input maxLength={255} />
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item shouldUpdate>
                {() => (
                    <FloatBar className='d-flex justify-content-space-between'>
                        <TdtButtonSubmit
                            type='primary'
                            disabled={!form.isFieldsTouched(true)}
                            htmlType='submit'
                            className='fw-b disable-gray-btn'
                        >
                            {isEdit ? t('Save') : t('Register')}
                        </TdtButtonSubmit>
                        <Button
                            disabled={
                                tempCompanyState == CompanyStateEnum.PROVIDING.value ||
                                tempCompanyState == CompanyStateEnum.STOPPED_TEMPORARILY.value
                            }
                            icon={<DeleteIcon />}
                            className='fw-b btn-pink'
                            onClick={() => showDeleteModal()}
                        >
                            {'企業の削除'}
                        </Button>

                        <DeleteCompanyModal
                            visible={modalVisible.deleteCompany}
                            onClose={() => closeModal()}
                            initialValue={{}}
                            onUpdate={() => deleteCompany()}
                            existCreatedReservation={company.existCreatedReservation}
                        />
                    </FloatBar>
                )}
            </Form.Item>
        </Form>
    );
};

export default CompanyFormEdit;

CompanyFormEdit.propTypes = {
    isEdit: PropTypes.bool,
    initialValues: PropTypes.object,
    onFinish: PropTypes.func,
};
