import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import history from 'helpers/history.helper';
import BackBtnWithTitle from 'components/BackBtnWithTitle';
import { DeleteIcon, InfoIcon } from 'components/SvgIcon';
import { Button } from 'antd';
import Routes from 'routes';
import AccountDeletionModal from 'pages/client/account/AccountDeletionModal';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import { useDispatch } from 'react-redux';
import { setLoading } from 'core.slice';
import { isMobileActive } from 'helpers/mobile.helper';

const AccountDeletion = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [modalVisible, setModalVisible] = useState({
        delete: false,
    });
    const showDeleteModal = () => setModalVisible({ delete: true });
    const closeModal = () => setModalVisible({ delete: false });

    const submitAccountDeletion = async () => {
        const res = await api.delete(APP_URLS.CLIENT_DELETE_ACCOUNT);
        if (res) {
            localStorage.removeItem('access_token');
            api.accessToken = null;
            if (!isMobileActive()) {
                dispatch({ type: 'RESET' });
                dispatch(setLoading(false));
            }

            localStorage.removeItem('device_token');
            history.replace(Routes.public.ACCOUNT_DELETED.path);
        }
    };

    return (
        <>
            <BackBtnWithTitle title={t('Account deletion')} onClick={() => history.goBack()} />
            <div className="body1 mt-16">{t('Please check before deleting your account.')}</div>
            <div className="inquiry-note mt-16 account-deleted-mobile">
                <div className="mr-8 account-deleted-mobile-info-icon">
                    <InfoIcon className="td-info-icon" />
                </div>
                <div>
                    <span className="inquiry-note-title">{'ご注意ください'}</span>
                    <div className="mt-8">
                        <p className="inquiry-note-content mb-0 account-deleted-mobile-indent">
                            {'・一度アカウントを削除すると、同じユーザー情報でログインできなくなります。'}
                        </p>
                        <p className="inquiry-note-content mb-8 account-deleted-mobile-indent">
                            {'・予約中の相談はキャンセルとなります。'}
                        </p>
                        <span className="inquiry-note-content">
                            {'ご不明点、ご意見がある場合は'}
                            <Button
                                type="link"
                                className="faq-link"
                                onClick={() => history.push(Routes.private.CLIENT_INQUIRY.path)}
                            >
                                {'お問い合わせ'}
                            </Button>
                            {'ください。'}
                        </span>
                    </div>
                </div>
            </div>
            <div>
                <Button
                    className="mt-56 fw-b pink-color delete-btn-mobile"
                    icon={<DeleteIcon />}
                    onClick={() => showDeleteModal()}
                >
                    {t('Account deletion')}
                </Button>
                <AccountDeletionModal
                    onClose={closeModal}
                    onUpdate={submitAccountDeletion}
                    visible={modalVisible.delete}
                />
            </div>
        </>
    );
};

export default AccountDeletion;
