import React, { useEffect, useMemo, useState } from 'react';
import { Modal, Tree } from 'antd';
import { CloseIcon, DomainIcon } from 'components/SvgIcon';
import { useTranslation } from 'react-i18next';
import InputSearch from 'components/InputSearch';
import ActionBtnInDistributionModal from 'components/ActionBtnInDistributionModal';
import { getMasterCompanyEmployees } from 'pages/admin/common/master-data/master_data.slice';
import { connect } from 'react-redux';
import DataTable from 'components/DataTable';
import EmailType from 'enums/email_type.enum';
import { randomId } from 'helpers/common.helper';

const mapState = state => ({
    companyEmployeesData: state.admin.masterData.companyEmployeesData,
});
const mapDispatch = { getMasterCompanyEmployees };

const ProvideCompanyEmployeeModal = ({
    visible,
    onClose,
    onUpdate,
    companyEmployeesData,
    getMasterCompanyEmployees,
    selectedRecord,
}) => {
    const { t } = useTranslation();
    const [keyword, setKeyword] = useState('');
    const [sorterDir, setSorterDir] = useState();
    const [checkedKeys, setCheckedKeys] = useState([]);

    useEffect(() => {
        if (!companyEmployeesData.fetched) {
            getMasterCompanyEmployees();
        }

        return () => {
            setKeyword('');
            setSorterDir(null);
            setCheckedKeys([]);
        };
    }, []);

    const checkDisabledTreeRow = rowData => {
        if (selectedRecord) {
            return !selectedRecord.companies.find(c => c.id === rowData.id);
        }
        return false;
    }


    const stateDataToTree = useMemo(() => {
        const data = companyEmployeesData.data;
        return data.map(d => ({
            key: `${d.id}`,
            title: (
                <span className="d-flex align-items-center">
                    <DomainIcon className="color-blg-60 mr-4" size={20} />
                    {d.name}
                    <span className="label-count ml-2">{`(${d.employees.length})`}</span>
                </span>
            ),
            name: d.name,
            children: d.employees.map(child => ({
                key: `${d.id}_${child.id}`,
                title: (
                    <span className="d-flex align-items-center">
                        <span>{child.full_name}</span>
                        <span className="label-count ml-24">{child.email}</span>
                    </span>
                ),
                full_name: child.full_name,
                email: child.email,
                disabled: checkDisabledTreeRow(d),
            })),
            disabled: checkDisabledTreeRow(d),
        }));
    }, [companyEmployeesData.fetched, selectedRecord]);

    const onCheck = checkedKeysValue => {
        setCheckedKeys(checkedKeysValue);
    };

    const filterByKeyword = (data, keyword) => {
        const hasKeyword = keyword !== '';
        return data
            .map(company => {
                if (!hasKeyword || company.name.indexOf(keyword) > -1) {
                    return company;
                }

                const filteredChildren = company.children.filter(child =>
                    hasKeyword ? child.full_name.indexOf(keyword) > -1 || child.email.indexOf(keyword) > -1 : true
                );
                if (filteredChildren.length > 0) {
                    return { ...company, children: filteredChildren };
                }
                return null;
            })
            .filter(c => c)
            .sort((a, b) => {
                if (sorterDir && sorterDir === 'ascend') {
                    return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
                } else if (sorterDir && sorterDir === 'descend') {
                    return b.name > a.name ? 1 : b.name < a.name ? -1 : 0;
                }
            });
    };

    const getCheckedItems = () => {
        let result = [];
        const flatCompanies = {};
        const flatEmployees = {};
        const allData = companyEmployeesData.data;

        allData.map(d => {
            flatCompanies[d.id] = d;
            d.employees.map(e => {
                flatEmployees[e.id] = e;
            });
        });
        for (const key of checkedKeys) {
            if (key.indexOf('_') > -1) {
                const arr = key.split('_');
                const curCompany = flatCompanies[arr[0]];
                const curEmployee = flatEmployees[arr[1]];
                result.push({
                    //...curEmployee,
                    email: curEmployee.email,
                    company_id: curCompany.id,
                    company_name: curCompany.name,
                    user_id: curEmployee.id,
                    full_name: curEmployee.full_name,
                    id: randomId(),
                });
            }
        }
        setCheckedKeys([]);
        return result;
    };

    const countSubKey = checkedKeys => checkedKeys.filter(k => k.indexOf('_') > -1).length;

    return (
        <Modal
            title={t('field:Target registration')}
            visible={visible}
            width={640}
            footer={
                <div className=" d-flex align-items-center justify-content-space-between pt-6 pb-6 fw-400 fs-16 space">
                    <div>
                        <span>{'選択人数：'}</span>
                        <span className="fw-700 fs-20">{countSubKey(checkedKeys)}</span>
                        <span className="ml-4">{'人'}</span>
                    </div>
                    <ActionBtnInDistributionModal onClose={onClose} onUpdate={() => onUpdate(getCheckedItems())} />
                </div>
            }
            closeIcon={<CloseIcon />}
            onCancel={() => onClose()}
        >
            <div className="tree-searchable tree-searchable-as-table">
                <div className="bg-gray-5 p-8 with-search-box">
                    <InputSearch
                        placeholder={'会社名, 氏名で検索'}
                        value={keyword}
                        onChange={e => setKeyword(e.target.value)}
                    />
                </div>
                <DataTable
                    className="collapse-table"
                    columns={[
                        {
                            title: t('会社名'),
                            dataIndex: 'name',
                            // width: 544,
                            render: r => (
                                <span>
                                    <DomainIcon className="color-blg-60" />
                                    {r.name}
                                </span>
                            ),
                            sorter: (a, b) => {
                                return (a.name || '').localeCompare(b.name || '');
                            },
                            className: 'pl-20',
                        },
                    ]}
                    data={filterByKeyword(stateDataToTree, keyword)}
                    onTableChange={(pagination, filter, sorter) => {
                        setSorterDir(sorter.order);
                    }}
                    customRowSelection={{
                        selectedRowKeys: [],
                        onChange: () => {},
                        type: 'checkbox',
                    }}
                />
                <div className="mt-4 tree-list-container">
                    <Tree
                        checkable
                        treeData={filterByKeyword(stateDataToTree, keyword)}
                        checkedKeys={checkedKeys}
                        onCheck={onCheck}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default connect(mapState, mapDispatch)(ProvideCompanyEmployeeModal);
