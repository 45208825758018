import React from 'react';
import { Button } from 'antd';

const AggregateDailyLife = ({ setActiveKey }) => {
    return (
        <Button
            type="text"
            className="fw-400 fs-14-21 color-blue-100 btn-underline p-0 h-unset"
            onClick={() => setActiveKey('individual')}
        >
            {'個人のデータを確認する'}
        </Button>
    );
};

export default AggregateDailyLife;
