import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reset } from 'pages/admin/interview_sheet/interview_sheet_create.slice';
import { useLocation } from 'react-router-dom';
import { InterviewSheetPropTypes } from 'constants/proptypes.constant';
import InterviewSheetForm from './InterviewSheetForm';

const mapDispatch = {
    reset,
};
const InterviewSheetCreate = ({ reset }) => {
    const location = useLocation();
    const isReset = new URLSearchParams(location.search).get('new');

    useEffect(() => {
        if (isReset) {
            reset();
        }
    }, []);

    return <InterviewSheetForm />;
};

export default connect(null, mapDispatch)(InterviewSheetCreate);

InterviewSheetCreate.propTypes = {
    interviewSheet: InterviewSheetPropTypes,
    reset: PropTypes.func.isRequired,
};
