import ConsultationDetail from 'pages/counselor/calendar/consultation/ConsultationDetail';
import DisplayInterviewSheet from 'pages/counselor/calendar/consultation/DisplayInterviewSheet';
import CreateFeedbackMessage from 'pages/counselor/calendar/consultation/CreateFeedbackMessage';

const ConsultationRoutes = {
    INDEX: { path: '/', component: ConsultationDetail },
    DETAIL: { path: '/:reservationId', component: ConsultationDetail }, // id ref
    DISPLAY_INTERVIEW_SHEET: { path: '/:reservationId/interview-sheet', component: DisplayInterviewSheet },
    CREATE_FEEDBACK: { path: '/:reservationId/create-feedback', component: CreateFeedbackMessage },
};

export default ConsultationRoutes;
