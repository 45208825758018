import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import BackBtn from 'components/BackBtn';
import FloatBar from 'components/FloatBar';
import TdtSelect from 'components/TdtSelect';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import history from 'helpers/history.helper';
import { InfoIcon } from 'components/SvgIcon';
import { showNormal } from 'helpers/notification.helper';
import ErrorMessageEnum from 'enums/error_message.enum';
import TdtButtonSubmit from 'components/TdtButtonSubmit';

const MessageCreate = ({ initialValues, company }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    initialValues = Object.assign(
        {
            template_id: 1,
        },
        initialValues
    );
    const { id } = useParams();

    const createMessage = async values => {
        values.company_id = Number(id);
        const res = await api.post(APP_URLS.MESSAGE_THREADS_CREATE, values);
        if (res) {
            history.goBack();
            if (res !== ErrorMessageEnum.SEND_MSG_FAIL.value) {
                showNormal('', t('message:I sent a message in bulk'), 3);
            } else {
                showNormal('', t('message:Can not send messages to companies that do not have employees'), 3);
            }
        }
    };

    const [templates, setTemplates] = useState([]);
    const getTemplates = async () => {
        const res = await api.get(APP_URLS.MESSAGE_TEMPLATES);
        if (res && res.data && res.data.length > 0) {
            setTemplates(res.data);
        }
    };

    useEffect(() => {
        getTemplates().then(() => {});
    }, []);

    return (
        <div className="px-24">
            <BackBtn className="mb-32" label={t('Manually send notifications')} />

            <p className="fw-b mt-32">{t('field:Company name')}</p>
            <div className="mb-16 h7">{company.name}</div>
            <div className="mb-32 body2 d-flex">
                <span className="medium-color">
                    <InfoIcon size={20} className="mr-4" />
                </span>
                {'送信者はTeam Doctorsカスタマーサポートで、社員から返信はできない形式で送信されます。'}
            </div>

            <Form
                layout="vertical"
                form={form}
                initialValues={initialValues}
                onFinish={async values => {
                    await createMessage(values);
                }}
                requiredMark={false}
            >
                <Row gutter={16}>
                    <Col span={24} className="mb-8 d-none">
                        <Form.Item
                            // name="message_template_id"
                            label={'メッセージテンプレート'}
                            validateTrigger={false}
                            rules={[
                                {
                                    required: true,
                                    message: t('validation:Please enter {{field}}', {
                                        field: t('field:template id'),
                                    }),
                                },
                            ]}
                        >
                            <TdtSelect
                                style={{ width: 320 }}
                                size="large"
                                className="fs-14 select-gray"
                                onChange={value => {
                                    const objSelected = templates.find(t => t.id === value);
                                    form.setFieldsValue({
                                        title: objSelected.title,
                                        content: objSelected.content,
                                    });
                                }}
                            >
                                {templates.map(t => (
                                    <Select.Option key={t.id} value={t.id}>
                                        {t.name}
                                    </Select.Option>
                                ))}
                            </TdtSelect>
                        </Form.Item>
                    </Col>
                    <Col span={24} className="mb-8">
                        <Form.Item
                            name="title"
                            label={'タイトル'}
                            validateTrigger={false}
                            rules={[
                                {
                                    required: true,
                                    message: t('validation:Please enter a valid {{field}}', {
                                        field: t('field:Title'),
                                    }),
                                },
                            ]}
                            style={{ maxWidth: 320 }}
                        >
                            <Input maxLength={255} />
                        </Form.Item>
                    </Col>
                    <Col span={24} className="mb-8">
                        <Form.Item
                            name="content"
                            label={'メッセージ'}
                            validateTrigger={false}
                            rules={[
                                {
                                    required: true,
                                    message: t('validation:Please enter a valid {{field}}', {
                                        field: t('field:Message'),
                                    }),
                                },
                            ]}
                            style={{ maxWidth: 480 }}
                        >
                            <Input.TextArea className="outline-explanation-input" />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item shouldUpdate>
                    {() => (
                        <FloatBar>
                            <div className="float-bar-company">
                                <div className="title-total-employee">{'該当人数'}</div>
                                <div className="number-total-employee">{`${company.total_employee} 人`}</div>
                                <TdtButtonSubmit
                                    type="primary"
                                    disabled={!form.isFieldsTouched(true)}
                                    className="fw-b"
                                    htmlType="submit"
                                    style={{ minWidth: 120 }}
                                >
                                    {t('Send')}
                                </TdtButtonSubmit>
                            </div>
                        </FloatBar>
                    )}
                </Form.Item>
            </Form>
        </div>
    );
};

export default MessageCreate;

MessageCreate.propTypes = {
    initialValues: PropTypes.object,
    company: PropTypes.object,
};
