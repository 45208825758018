import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TabList from 'components/TabList';
import PhotoTabContainer from 'pages/client/health_data/tabs/photos/PhotoTabContainer';
import BackBtnWithTitle from 'components/BackBtnWithTitle';
import HealthImageEnum from 'enums/health_image.enum';

const PhotoTabList = () => {
    const [tab, setTab] = useState(`${HealthImageEnum.MEAL_RECORD.value}`);
    const tabList = [
        {
            key: `${HealthImageEnum.MEAL_RECORD.value}`, // ~path
            title: '食事履歴',
            container: (
                <>
                    <PhotoTabContainer category={HealthImageEnum.MEAL_RECORD.value} tab={tab} setTab={setTab} />
                </>
            ),
        },
        {
            key: `${HealthImageEnum.EXAMINATION_DATA.value}`,
            title: '検診データ',
            container: (
                <>
                    <PhotoTabContainer category={HealthImageEnum.EXAMINATION_DATA.value} tab={tab} setTab={setTab} />
                </>
            ),
        },
        {
            key: `${HealthImageEnum.OTHER.value}`,
            title: 'その他',
            container: (
                <>
                    <PhotoTabContainer category={HealthImageEnum.OTHER.value} tab={tab} setTab={setTab} />
                </>
            ),
        },
    ];

    const handlerTabChange = key => {
        setTab(key);
    };
    return (
        <>
            <BackBtnWithTitle title="写真" />
            <TabList
                tabs={tabList}
                onChange={handlerTabChange}
                activeKey={tab}
                animated={false}
                indent
                className="mb-24"
            />
        </>
    );
};

export default PhotoTabList;

PhotoTabList.propTypes = {
    tab: PropTypes.string,
};
