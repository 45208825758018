import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Checkbox, Input, Radio } from 'antd';
import PageElementTypeEnum from 'enums/page_element_type.enum';
import ElementActions from 'pages/admin/common/page-elements/types/ElementActions';

const HeadingElement = React.memo(({ id, pageElement, setPageElement, setRefs }) => {
    const { t } = useTranslation();
    return (
        <div className="page-element-block">
            <div className="header pb-24">
                <div className="element-common-heading">{t(`enum:${PageElementTypeEnum.HEADING.text}`)}</div>
                <Radio.Group
                    buttonStyle="solid"
                    className="page-element-heading-size"
                    value={pageElement.heading_size || 'l'}
                    onChange={e => setPageElement({ id, changes: { heading_size: e.target.value } })}
                >
                    <Radio.Button value="l">{'L'}</Radio.Button>
                    <Radio.Button value="m">{'M'}</Radio.Button>
                    <Radio.Button value="s">{'S'}</Radio.Button>
                </Radio.Group>
                <ElementActions id={id} />
            </div>
            <div className="element-item">
                <Input
                    value={pageElement.content}
                    onChange={e => {
                        setPageElement({ id, changes: { content: e.target.value } });
                    }}
                    ref={el => setRefs({ id, el: el })}
                />
            </div>
            <div>
                <Checkbox
                    className="mt-24"
                    checked={pageElement.is_displayed}
                    onChange={e => {
                        setPageElement({ id, changes: { is_displayed: e.target.checked ? 1 : 0 }});
                    }}
                >
                    {t('Posted in the news')}
                </Checkbox>
            </div>
        </div>
    );
});

export default HeadingElement;

HeadingElement.propTypes = {
    id: PropTypes.any.isRequired,
    pageElement: PropTypes.object,
    setPageElement: PropTypes.func,
};
