import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Col, Form, Input, Row, Radio, Select } from 'antd';
import FloatBar from 'components/FloatBar';
import { useTranslation } from 'react-i18next';
import TdtSelect from 'components/TdtSelect';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import history from 'helpers/history.helper';
import BackBtnWithTitle from 'components/BackBtnWithTitle';
import TdtButtonSubmit from 'components/TdtButtonSubmit';
import MessageRoutes from 'pages/counselor/calendar/message/message_routes';
import Routes from 'routes';
import { showNormal } from 'helpers/notification.helper';

const MessageThreadCreate = ({ getMessageThreads }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const initialValues = { allow_reply_flag: true };

    const { id } = useParams();

    const createMessage = async values => {
        values.user_id = Number(id);
        values.module = 'counselor_thread';
        const response = await api.post(APP_URLS.MESSAGE_THREADS_CREATE, values);
        if (response) {
            showNormal('', t('message:Your message has been sent'), 3);
            history.push(
                `${Routes.private.COUNSELOR_DETAIL_MESSAGE.path.replace(':id', id)}${MessageRoutes.DETAIL.path.replace(
                    ':messageThreadId',
                    response.message_thread_id
                )}`
            );
            getMessageThreads();
        }
    };

    const [templates, setTemplates] = useState([]);
    const getTemplates = async () => {
        const res = await api.get(APP_URLS.MESSAGE_TEMPLATES);
        if (res && res.data && res.data.length > 0) {
            setTemplates(res.data);
        }
    };

    useEffect(() => {
        getTemplates().then(() => {});
    }, []);

    return (
        <div className="pl-44 pr-24 counselor-message-create-container">
            <BackBtnWithTitle title={'メッセージの新規作成'} className="back-btn-counselor" />
            <p className="pink-color fs-14 mt-16 mb-24">{'*必須項目'}</p>

            <Form
                layout="vertical"
                form={form}
                initialValues={initialValues}
                onFinish={async values => {
                    await createMessage(values);
                }}
                requiredMark={false}
            >
                <Row gutter={16}>
                    <Col span={24} className="mb-8">
                        <Form.Item
                            className={'d-none'}
                            name="message_template_id"
                            label={
                                <>
                                    メッセージテンプレート<span className="pink-color">*</span>
                                </>
                            }
                        >
                            <TdtSelect
                                style={{ width: 480 }}
                                size="large"
                                className="fs-14 select-gray"
                                onChange={value => {
                                    const objSelected = templates.find(t => t.id === value);
                                    form.setFieldsValue({
                                        title: objSelected.title,
                                        content: objSelected.content,
                                    });
                                }}
                            >
                                {templates.map(t => (
                                    <Select.Option key={t.id} value={t.id} style={{ height: 34 }}>
                                        <span className="fs-16 absolute-select">{t.name}</span>
                                        <span className="fs-10 text-gray-color absolute-select-description">
                                            医療相談の終了後に毎回送信するフィードバックの雛形です
                                        </span>
                                        {/*<div className="body1 mb-4">{t.name}</div>*/}
                                        {/*<div className="caption">{t.description}</div>*/}
                                    </Select.Option>
                                ))}
                            </TdtSelect>
                        </Form.Item>
                    </Col>
                    <Col span={24} className="mb-8">
                        <Form.Item
                            name="title"
                            label={
                                <>
                                    タイトル<span className="pink-color">*</span>
                                </>
                            }
                            validateTrigger={false}
                            rules={[
                                {
                                    required: true,
                                    message: t('validation:Please enter a valid {{field}}', {
                                        field: t('field:Title'),
                                    }),
                                },
                            ]}
                            style={{ maxWidth: 320 }}
                        >
                            <Input maxLength={255} />
                        </Form.Item>
                    </Col>
                    <Col span={24} className="mb-8">
                        <Form.Item
                            name="content"
                            label={
                                <>
                                    メッセージ<span className="pink-color">*</span>
                                </>
                            }
                            validateTrigger={false}
                            rules={[
                                {
                                    required: true,
                                    message: t('validation:Please enter a valid {{field}}', {
                                        field: t('field:Message'),
                                    }),
                                },
                            ]}
                            style={{ maxWidth: 480 }}
                        >
                            <Input.TextArea className="outline-explanation-input" />
                        </Form.Item>
                    </Col>
                    <Col span={24} className="mb-8">
                        <Form.Item
                            name="allow_reply_flag"
                            label={
                                <>
                                    相談者の返信を可能にするか<span className="pink-color">*</span>
                                </>
                            }
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Radio.Group>
                                <Radio value={true}>{'はい'}</Radio>
                                <Radio value={false}>{'いいえ'}</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <FloatBar className="left-2side">
                    <TdtButtonSubmit type="primary" htmlType="submit" className="fw-b">
                        {t('Send')}
                    </TdtButtonSubmit>
                </FloatBar>
            </Form>
        </div>
    );
};

export default MessageThreadCreate;

MessageThreadCreate.propTypes = {
    getMessageThreads: PropTypes.func.isRequired,
};
