import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Route, Switch, useRouteMatch, useParams } from 'react-router-dom';
import { AddIcon } from 'components/SvgIcon';
import TdtButton from 'components/TdtButton';
import DataTable from 'components/DataTable';
import { getData, setFilters } from 'pages/counselor/calendar/consultation/consultation.slice';
import { setConsultation } from 'pages/counselor/calendar/consultation/consultation_detail.slice';
import { dateToStr } from 'helpers/date.helper';
import { getEnumText } from 'helpers/enum.helper';
import ReservationStatus from 'enums/reservation_status.enum';
import ConsultationRoutes from 'pages/counselor/calendar/consultation/consultation_routes';
import history from 'helpers/history.helper';
import Routes from 'routes';
import TdtFilter from 'components/TdtFilter';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import { hasAnyRole, hasRole } from 'helpers/role.helper';
import RoleEnum from 'enums/role.enum';

const mapState = state => ({
    state: state.counselor.consultation,
    user: state.auth.user,
    consultation: state.counselor.consultationDetail.consultation,
});
const mapDispatch = { getData, setFilters, setConsultation };

const Consultation = ({ state, getData, consultation, user, setFilters, setConsultation }) => {
    const { t } = useTranslation();
    const { id } = useParams();
    let { path, url } = useRouteMatch();
    const isAdmin = hasAnyRole(user, [RoleEnum.ADMIN.value, RoleEnum.ADMIN_STAFF.value]);

    const columns = [
        {
            title: '日付',
            dataIndex: 'date',
            sorter: true,
            render: (_, record) => {
                return (
                    <>
                        <div className="mb-4">{`${dateToStr(record.date, 'M月DD日(ddd)')} ${record.start_time}〜${
                            record.session_end_time
                        }`}</div>
                        <div>{isAdmin ? record.program.name_mngt : record.program.name}</div>
                    </>
                );
            },
        },
        {
            title: 'ステータス',
            dataIndex: 'status',
            width: 92,
            className: 'text-align-right',
            render: v => t(`enum:${getEnumText(ReservationStatus, v)}`),
        },
    ];

    const getConsultations = (
        pagination = state.pagination,
        filters = state.filters,
        sorter = state.sorter,
        callback
    ) => {
        getData({ id, pagination, filters, sorter }).then(o => {
            const data = o.payload ? o.payload.data : [];
            return callback && callback(data);
        });
    };

    const [metaData, setMetaData] = useState({});
    const getMetaDataConsultations = async () => {
        const response = await api.get(APP_URLS.COUNSELOR_META_DATA_CONSULTATIONS.replace(':id', id));
        if (response) {
            setMetaData(response);
        }
    };

    useEffect(() => {
        getMetaDataConsultations().then(() => {});
    }, []);

    // process for same status label
    const processLabelStatus = () => {
        const pair = {
            CREATED_FILLED: {
                value: 'created,filled',
                text: 'Reservation Created',
                valueEnum: 'created',
            },
            START: {
                value: 'start',
                text: 'Reservation Start',
            },
            END: {
                value: 'end,cancelled_timeout',
                text: 'Reservation End',
                valueEnum: 'end',
            },
            COMPLETED: {
                value: 'completed',
                text: 'Reservation Completed',
            },
            PENDING: {
                value: 'pending',
                text: 'Reservation Pending',
            },
        };
        return Object.values(pair).map(o => ({
            key: `status_${o.value}`,
            text: t(`enum:${getEnumText(ReservationStatus, o.valueEnum || o.value)}`),
            value: o.value,
        }));
    };

    // filters
    const filterCond = [
        {
            key: 'status',
            label: 'ステータス',
            options: processLabelStatus(),
        },
        {
            key: 'program',
            label: 'プログラム',
            options: metaData.programs
                ? metaData.programs.map(p => ({
                      key: `program_${p.id}`,
                      text: isAdmin ? p.name_mngt : p.name,
                      value: p.id,
                  }))
                : [],
        },
        {
            key: 'counselor',
            label: 'カウンセラー',
            options: metaData.counselors
                ? metaData.counselors.map(c => ({
                      key: `counselor_${c.id}`,
                      text: `${c.full_name}`,
                      value: c.id,
                  }))
                : [],
        },
        {
            key: 'feedback',
            label: 'カルテ',
            options: [
                { key: `feedback_null`, text: '作成済み', value: 'null', tooltip: 'カルテ' },
                { key: `feedback_not_null`, text: '未作成', value: 'not_null', tooltip: 'カルテ' },
            ],
        },
        {
            key: 'with-message',
            label: 'フィードバック',
            options: [
                { key: 'with-message_created', text: '作成済み', value: 'created', tooltip: 'フィードバック' },
                { key: 'with-message_not_created', text: '未作成', value: 'not_created', tooltip: 'フィードバック' },
            ],
        },
    ];

    // merge helper
    const mergeFilter = (filters, keyStartWith) => {
        let tmp = [];
        filters.map(f => {
            if (f.key.indexOf(keyStartWith) === 0) {
                tmp.push(f.value);
            }
        });
        return tmp.join(',');
    };

    const getListCallback = data => {
        const reservationId = consultation && consultation.id;
        if (!reservationId) {
            return;
        }

        if (data.length > 0) {
            if (data.find(d => d.id === reservationId)) {
                return;
            }
            history.push(`${url}${ConsultationRoutes.DETAIL.path.replace(':reservationId', data[0].id)}`);
        } else {
            history.push(`${url}`);
            setConsultation(null);
        }
    };

    const getConsultationsWithFilter = filters => {
        const mergeFilters = {
            ...state.filters,
            status: mergeFilter(filters, 'status_'),
            feedback: mergeFilter(filters, 'feedback_'),
            programs: mergeFilter(filters, 'program_'),
            counselors: mergeFilter(filters, 'counselor_'),
            has_message: mergeFilter(filters, 'with-message_'),
        };
        setFilters(mergeFilters);
        getConsultations(state.pagination, mergeFilters, state.sorter, getListCallback);
    };

    return (
        <>
            <div className="counselor-aside">
                <div className="consultation-aside ant-layout h-100p">
                    <div className="p-16 pb-0">
                        <div className="h7 mt-16 mb-24">{t('Consultation')}</div>
                        <TdtButton
                            icon={<AddIcon />}
                            className="w-100p f-14-700"
                            onClick={() =>
                                history.push(Routes.private.COUNSELOR_DETAIL_CREATE_RESERVATION.path.replace(':id', id))
                            }
                        >
                            {'新規予約作成'}
                        </TdtButton>
                        <div className="text-align-right mt-16">
                            <TdtFilter rows={filterCond} applyFilters={getConsultationsWithFilter} />
                        </div>
                    </div>

                    <div className="ant-layout">
                        <DataTable
                            className="collapse-table aside-table overflow-y h-100p"
                            columns={columns}
                            data={state.data}
                            loading={state.loading}
                            pagination={state.pagination}
                            filters={state.filters}
                            sorter={state.sorter}
                            onTableChange={(pagination, filter, sorter) => {
                                getConsultations(pagination, state.filters, sorter);
                            }}
                            renderNoData={<div className="pt-24 pb-24">データがありません</div>}
                            onRow={record => {
                                return {
                                    onClick: () => {
                                        history.push(
                                            `${url}${ConsultationRoutes.DETAIL.path.replace(
                                                ':reservationId',
                                                record.id
                                            )}`
                                        );
                                    },
                                };
                            }}
                            rowClassName={record => (record.id === consultation?.id ? 'tdt-row-selected' : '')}
                        />
                    </div>
                </div>
            </div>
            <div className="counselor-main">
                <Switch>
                    {Object.keys(ConsultationRoutes).map(k => {
                        const r = ConsultationRoutes[k];
                        return (
                            <Route
                                key={`consultation_${r.path}`}
                                path={`${path}${r.path}`}
                                exact
                                render={props => (
                                    <r.component
                                        {...props}
                                        baseUrl={url}
                                        getConsultations={() => getConsultations()}
                                        firstId={state.data.length > 0 ? state.data[0].id : undefined}
                                        isAdmin={hasRole(user, 'admin')}
                                        isAdminStaff={hasRole(user, RoleEnum.ADMIN_STAFF.value)}
                                    />
                                )}
                            />
                        );
                    })}
                </Switch>
            </div>
        </>
    );
};

export default connect(mapState, mapDispatch)(Consultation);

Consultation.propTypes = {
    state: PropTypes.any,
    getData: PropTypes.func.isRequired,
    consultation: PropTypes.any,
};
