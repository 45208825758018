import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

const DragList = React.memo(({ data, order, rowComponent: Component }) => {
    return order.map((id, index) => {
        return (
            <Draggable key={id} draggableId={id} index={index}>
                {provided => (
                    <div
                        className="program-draggable-row p-8 mb-8 d-flex align-items-center"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                    >
                        <Component id={id} data={data} {...provided.dragHandleProps} />
                    </div>
                )}
            </Draggable>
        );
    });
});

export default DragList;
