import React from 'react';
import HorizontalBarChart from 'pages/admin/interview_sheet/detail_screen/common/aggregate_results/HorizontalBarChart';
import { numberToFixed } from 'helpers/number_format.helper';
import AnswerQuestionsWithCount
    from 'pages/admin/interview_sheet/detail_screen/common/aggregate_results/AnswerQuestionsWithCount';

const AggregateMultipleSelect = ({ question, answersDataList }) => {

    const parseData = () => {
        let data = [];
        let dataOthers = [];

        const { options } = question;
        for (const opt of options) {
            const matchCount = answersDataList.filter(r => {
                const byId = r.questionById[opt.interview_question_id];
                const answer = byId && byId.answers.find(d => d.id === opt.id);
                if (answer && answer.type === 'other') {
                    dataOthers.push(answer);
                }
                return answer;
            }).length;

            data.push({
                text: opt.value,
                percent:
                    matchCount / answersDataList.length
                        ? numberToFixed((matchCount / answersDataList.length) * 100, 1)
                        : 0,
                count: matchCount,
                isOther: true,
            });

        }
        return { data, dataOthers: parseOtherValues(dataOthers) };
    }

    const parseOtherValues = dataOthers => {
        let group = {};
        for (const dataOther of dataOthers) {
            const v = dataOther['otherValue'];
            if (!group[v]) {
                group[v] = { text: v, count: 0 };
            }
            group[v].count += 1;
        }

        return Object.values(group);
    }

    const parseData2 = () => {
        let data = [];

        const { options } = question;
        for (const opt of options) {
            const matchCount = answersDataList.filter(r => {
                const byId = r.questionById[opt.interview_question_id];
                return byId && byId.answers.find(d => d.id === opt.id);
            }).length;

            opt.type !== 'other' &&
                data.push({
                    text: opt.value,
                    percent:
                        matchCount / answersDataList.length
                            ? numberToFixed((matchCount / answersDataList.length) * 100, 1)
                            : 0,
                    count: matchCount,
                });

            if (opt.type === 'other') {
                let totalOther = {};
                answersDataList.map(answer => {
                    const byId = answer.questionById[opt.interview_question_id];
                    if (byId) {
                        const optionAnswer = byId.answers.find(a => a.id === opt.id);
                        if (optionAnswer) {
                            totalOther[optionAnswer.otherValue]
                                ? (totalOther[optionAnswer.otherValue] += 1)
                                : (totalOther[optionAnswer.otherValue] = 1);
                        }
                    }
                });
                Object.keys(totalOther).map(key => {
                    data.push({
                        text: key,
                        percent:
                            totalOther[key] / answersDataList.length
                                ? numberToFixed((totalOther[key] / answersDataList.length) * 100, 1)
                                : 0,
                        count: totalOther[key],
                        isOther: true,
                    });
                });
            }
        }
        return data;
    };

    const parsedData = parseData();

    return (
        <>
            <HorizontalBarChart data={parsedData.data} />
            {parsedData.dataOthers.length > 0 && (
                <>
                    <div className="fw-b mt-32 color-blg-80">{'その他の回答'}</div>
                    <AnswerQuestionsWithCount data={parsedData.dataOthers} />
                </>
            )}
        </>
    );
};

export default AggregateMultipleSelect;
