import { createSlice } from '@reduxjs/toolkit';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import { showNormal } from 'helpers/notification.helper';

const name = 'clientMessageDetail';

const clientMessageDetailSlice = createSlice({
    name,
    initialState: {
        messageThread: null,
        loading: null,
    },
    reducers: {
        setMessageThread(state, action) {
            state.messageThread = action.payload;
        },
        setMessages(state, action) {
            state.messageThread.messages = [...state.messageThread.messages, action.payload];
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
    },
});

export const { setMessageThread, setMessages, setLoading } = clientMessageDetailSlice.actions;

export const getMessageThread = messageThreadId => async dispatch => {
    const data = await api.get(APP_URLS.MESSAGE_THREADS_DETAIL.replace(':id', messageThreadId), { module: 'client' });
    if (data) {
        dispatch(setMessageThread(data));
    }
    dispatch(setLoading(new Date().getTime()));
};

export const createMessage = (messageThreadId, content, completeMessage) => async dispatch => {
    const data = await api.post(APP_URLS.MESSAGE_THREADS_CREATE, {
        content,
        message_thread_id: messageThreadId,
        module: 'client',
    });
    if (data) {
        dispatch(setMessages(data));
        showNormal('', completeMessage, 3);
    }
};

export default clientMessageDetailSlice.reducer;
