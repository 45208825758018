import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from 'antd';
import DataTable from 'components/DataTable';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import { imageUrlAdapter } from 'helpers/image_url.helper';
import { CheckmarkCheckedIcon, CheckmarkEmptyIcon } from 'components/SvgIcon';
import NoImage from 'components/NoImage';
import ProvideCompaniesModal from 'pages/admin/feature/modal/ProvideCompaniesModal';

const NewProgramTable = ({ selectedRecord, setSelectedRecord, selectFromId }) => {
    const { t } = useTranslation();

    const [data, setData] = useState([]);
    const getNewPrograms = async sorter => {
        const res = await api.get(APP_URLS.ADMIN_EMAILS_LIST_NEW_PROGRAM, {
            order_by:
                sorter && sorter.field === 'name'
                    ? sorter.order === 'ascend'
                        ? 'name'
                        : sorter.order === 'descend'
                        ? '-name'
                        : '-created_at'
                    : '-created_at',
        });
        if (res && res.data && res.data.length > 0) {
            setData(res.data);
            if (selectFromId) {
                const found = res.data.find(d => d.id === Number(selectFromId));
                setSelectedRecord(found);
            }
        }
    };

    const [modalVisible, setModalVisible] = useState({
        record: null,
        provide: false,
    });
    const closeModal = () =>
        setModalVisible({
            record: null,
            provide: false,
        });
    const showProvideModal = r => setModalVisible({ ...modalVisible, provide: true, record: r });

    const columns = [
        {
            title: t('Name'),
            dataIndex: 'name',
            sorter: true,
            render: (v, r) => {
                const mediaUrl = `${APP_URLS.STATIC_BASE_URL}/${r.cover_img}`;
                return (
                    <span className="d-flex align-items-center">
                        {isSelectedRow(r) ? (
                            <CheckmarkCheckedIcon className="mr-10 color-blue-100" />
                        ) : (
                            <CheckmarkEmptyIcon className="mr-10 color-blg-30" />
                        )}
                        <span className="mr-2 pr-8 pt-8 pb-8 d-flex">
                            {r.cover_img ? (
                                <Image width={46} height={46} preview={false} src={imageUrlAdapter(mediaUrl, 64)} />
                            ) : (
                                <NoImage />
                            )}
                        </span>
                        <span>{r.name}</span>
                    </span>
                );
            },
        },
        {
            title: t('Provide companies'),
            dataIndex: 'companies',
            width: 120,
            render: (companies, record) => {
                const total = companies.length;
                const show = 5;
                return (
                    <>
                        <div
                            className="circle-listed"
                            onClick={e => {
                                e.stopPropagation();
                                showProvideModal(record);
                            }}
                        >
                            {companies.map((company, index) => {
                                if (index > show - 1) {
                                    return null;
                                }
                                return (
                                    <span
                                        key={`seminar${record.id}_${company.id}_${index}`}
                                        className={`circle-list-item item-${index + 1}`}
                                    >
                                        {index === show - 1 && total > show
                                            ? `+${total - show + 1}`
                                            : company.name.substr(0, 2)}
                                    </span>
                                );
                            })}
                        </div>
                    </>
                );
            },
        },
        {
            title: t('Send status'),
            dataIndex: 'send_status',
            width: 214,
            render: (v, r) => {
                const sent_at = r.program_companies[0].sent_at;
                return sent_at ? `${sent_at}に送信済み` : '未送信';
            },
        },
    ];

    useEffect(() => {
        getNewPrograms().then(() => {});
    }, []);

    const isSelectedRow = record => selectedRecord && selectedRecord.id === record.id;

    return (
        <>
            <DataTable
                className="collapse-table mt-8 target-program-table"
                columns={columns}
                data={data}
                onRow={record => ({
                    onClick: () => {
                        setSelectedRecord(isSelectedRow(record) ? null : record);
                    },
                })}
                rowClassName={record => (isSelectedRow(record) ? 'checked-row' : '')}
                onTableChange={(pagination, filter, sorter) => {
                    getNewPrograms(sorter);
                }}
            />
            <ProvideCompaniesModal
                onClose={() => closeModal()}
                visible={modalVisible.provide}
                record={modalVisible.record}
            />
        </>
    );
};

export default NewProgramTable;
