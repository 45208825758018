import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import SidebarTopClient from 'layouts/SidebarTopClient';
import { Button, Col, Row } from 'antd';
import { InfoIcon } from 'components/SvgIcon';
import MessageWithIcon from 'components/MessageWithIcon';
import Routes from 'routes';
import { useTranslation } from 'react-i18next';
import history from 'helpers/history.helper';
import registrationCompleteImage from 'assets/images/icons/colorful/registration_complete.png';

const mapState = state => ({
    registerAccount: state.auth.registerAccount,
});

const mapDispatch = {};

const RegisterComplete = ({ registerAccount }) => {
    const { t } = useTranslation();

    if (registerAccount.email == null) {
        return <Redirect to={'/'} />;
    }

    return (
        <>
            <SidebarTopClient />
            <div className="mt-96">
                <Row justify="center" align="middle">
                    <Col xs={24} sm={24} md={12} lg={16} xl={16}>
                        <MessageWithIcon
                            imageUrl={registrationCompleteImage}
                            title={t('translation:Account registration is complete')}
                        />

                        <div className="registration-complete">
                            <div className="mr-8">
                                <InfoIcon className="td-info-icon" />
                            </div>
                            <div>
                                <span className="title-fill-interview">
                                    {t(
                                        'translation:A verification email has been sent to your registered email account'
                                    )}
                                </span>
                                <div className="title-confirm-email">
                                    {t(
                                        'translation:Please click on the link has just been sent to verify your email and continue the reservation process'
                                    )}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row justify="center" align="middle" className="mt-40">
                    <Button
                        type="primary"
                        onClick={() => {
                            history.push(Routes.public.LOGIN.path);
                        }}
                        className="td-btn to-login-screen"
                    >
                        {t('translation:Login') + t('translation:screen')}
                    </Button>
                </Row>
            </div>
        </>
    );
};

export default connect(mapState, mapDispatch)(RegisterComplete);

RegisterComplete.propTypes = {
    registerAccount: PropTypes.any.isRequired,
};
