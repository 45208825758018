import React, { useCallback, useEffect } from 'react';
import TdtCalendar from 'components/calendar/TdtCalendar';
import { connect } from 'react-redux';
import {
    programIdsSelector,
    filteredReservationsSelector,
    getMasterData,
    getReservations,
    goNext,
    goPrev,
    goToday,
    setCompany,
    setDisplayType,
    setLayoutType,
    setProgram,
    getData,
    setFilters,
    setPagination,
    setAfterDeleted,
    filteredBusySlotsSelector,
} from 'pages/admin/calendar/calendar.slice';

import { filteredRegisteredSelector } from 'pages/counselor/calendar/calendar.slice';
import { useTranslation } from 'react-i18next';
import TdtSelect from 'components/TdtSelect';
import { Button, Menu, Select } from 'antd';
import { AddIcon, ArrowDropDownIcon, DateRangeIcon, ListsIcon } from 'components/SvgIcon';
import CustomDropdown from 'components/CustomDropdown';
import TdtCalendarControl from 'components/calendar/TdtCalendarControl';
import DataTable from 'components/DataTable';
import { dateToStr, fullDateFormatExceptHour } from 'helpers/date.helper';
import TdtButton from 'components/TdtButton';
import history from 'helpers/history.helper';
import Routes from 'routes';
import moment from 'moment';
import TdtCalendarDefaultDetailModal from 'components/calendar/TdtCalendarDefaultDetailModal';
import { setBackUrl } from 'core.slice';
import FloatBar from 'components/FloatBar';

const mapState = state => ({
    state: state.admin.calendar,
    companies: state.admin.calendar.master.company,
    programs: state.admin.calendar.master.program,
    selectableProgramIds: programIdsSelector(state.admin.calendar),
    programCompany: state.admin.calendar.master.programCompany,
    busySlots: filteredBusySlotsSelector(state.admin.calendar),
    registeredFiltered: filteredRegisteredSelector(state.counselor.calendar),
    company: state.admin.calendar.filters.company_id,
    program: state.admin.calendar.filters.program_id,
    startDate: state.admin.calendar.filters.start_date,
    endDate: state.admin.calendar.filters.end_date,
    displayType: state.admin.calendar.displayType,
    layoutType: state.admin.calendar.layoutType,
    reservations: filteredReservationsSelector(state.admin.calendar),
});

const mapDispatch = {
    getMasterData,
    getReservations,
    setDisplayType,
    setLayoutType,
    getData,
    setCompany,
    setProgram,
    goPrev,
    goNext,
    goToday,
    setFilters,
    setPagination,
    setBackUrl,
    setAfterDeleted,
};

const Calendar = ({
    state,
    companies,
    programs,
    selectableProgramIds,
    company,
    program,
    busySlots,
    registeredFiltered,
    startDate,
    endDate,
    displayType,
    layoutType,
    reservations,
    getMasterData,
    getReservations,
    setDisplayType,
    setLayoutType,
    getData,
    setCompany,
    setProgram,
    goPrev,
    goNext,
    goToday,
    setFilters,
    setPagination,
    setBackUrl,
    setAfterDeleted,
}) => {
    const { t } = useTranslation();

    const columns = [
        {
            title: '予約日時',
            dataIndex: 'date',
            key: 'date',
            width: 160,
            render: (value, record) =>
                `${dateToStr(value, 'DD日(ddd)')} ${record.start_time}-${record.session_end_time}`,
            sorter: true,
        },
        {
            title: '企業名',
            dataIndex: 'company|name',
            width: 242,
            render: (_, record) => (record.client.employee_company[0] || {}).name,
            sorter: true,
        },
        {
            title: 'プログラム名',
            dataIndex: 'program_name',
            width: 134,
            render: (_, record) => (
                <>
                    <span className={`eclipse-status eclipse-status-${record.program.color} mr-10`} />
                    {record.program.name_mngt || record.program.name}
                </>
            ),
        },
        {
            title: '相談者',
            dataIndex: 'client|first_name',
            width: 108,
            render: (_, record) => {
                const client = record.client || {};
                return (
                    <TdtButton
                        buttonStyle="plain"
                        onClick={event => {
                            event.stopPropagation();
                            setAfterDeleted(Routes.private.ADMIN_CALENDAR.path);
                            setBackUrl(Routes.private.ADMIN_CALENDAR.path);
                            history.push(Routes.private.COUNSELOR_DETAIL_CONSULTATION.path.replace(':id', client.id));
                        }}
                        disabled={!!client.deleted_by_id}
                        className={client.deleted_by_id ? 'btn-plain-disable' : ''}
                    >{`${client.full_name}`}</TdtButton>
                );
            },
            sorter: true,
        },
        {
            title: 'カウンセラー',
            dataIndex: 'counselor|first_name',
            width: 134,
            render: (_, record) => {
                const counselor = record.counselor || {};
                return (
                    <TdtButton
                        buttonStyle="plain"
                        onClick={event => {
                            event.stopPropagation();
                            history.push(Routes.private.ADMIN_COUNSELORS_DETAIL.path.replace(':id', counselor.id));
                        }}
                    >{`${counselor.full_name}`}</TdtButton>
                );
            },
            sorter: true,
        },
    ];

    const getCalendars = (pagination = state.pagination, filters = state.filters, sorter = state.sorter) => {
        getData({ pagination, filters, sorter });
    };

    useEffect(() => {
        getMasterData();
    }, []);
    useEffect(() => {
        if (layoutType === 'table') {
            getCalendars();
        } else {
            getReservations(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
        }
    }, [layoutType, startDate, endDate]);

    const handleFilterByCompany = key => {
        const newFilters = { ...state.filters, company_id: key };
        const newPagination = { ...state.pagination, current: 1 };
        setFilters(newFilters);
        setPagination(newPagination);
        getCalendars(newPagination, newFilters, state.sorter);
    };

    const handleFilterByProgram = key => {
        const newFilters = { ...state.filters, program_id: key };
        const newPagination = { ...state.pagination, current: 1 };
        setFilters(newFilters);
        setPagination(newPagination);
        getCalendars(newPagination, newFilters, state.sorter);
    };

    const eventModalRenderer = useCallback(event => {
        const mEvent = { ...event, program_name: event.program_name_mngt };
        return (
            <TdtCalendarDefaultDetailModal
                event={mEvent}
                setBackUrl={() => setBackUrl(Routes.private.ADMIN_CALENDAR.path)}
            />
        );
    }, []);

    const createMenu = useCallback((date, time) => {
        let items = [];
        items.push(<Menu.Item key="create">{t('Create new reservation')}</Menu.Item>);
        return (
            <Menu
                onClick={item => {
                    let path;
                    switch (item.key) {
                        case 'create':
                            path = `${Routes.private.ADMIN_CALENDAR_RESERVATION_CREATE.path}?date=${date}`;
                            if (time) {
                                path += `&time=${time}`;
                            }
                            history.push(path);
                            break;
                    }
                }}
            >
                {items}
            </Menu>
        );
    }, []);

    return (
        <div className="self-wrapper d-block">
            <h1 className="page-title mb-24">{t('Calendar')}</h1>
            <div className="controls">
                <div>
                    <TdtSelect
                        value={company}
                        size="large"
                        className="fs-14 mr-8"
                        style={{ width: 120 }}
                        onChange={value => {
                            setCompany(value);
                            handleFilterByCompany(value);
                        }}
                    >
                        <Select.Option value={null}>{t('All companies')}</Select.Option>
                        {companies.order.map(id => {
                            return (
                                <Select.Option key={id} value={id}>
                                    {companies.byId[id].name}
                                </Select.Option>
                            );
                        })}
                    </TdtSelect>
                    <TdtSelect
                        value={program}
                        size="large"
                        className="fs-14 mr-8"
                        style={{ width: 160 }}
                        onChange={value => {
                            setProgram(value);
                            handleFilterByProgram(value);
                        }}
                    >
                        <Select.Option value={null}>{t('All programs')}</Select.Option>
                        {selectableProgramIds.map(id => {
                            if (!programs.byId[id]) {
                                return null;
                            }
                            return (
                                <Select.Option key={id} value={id}>
                                    {programs.byId[id].name_mngt || programs.byId[id].name}
                                </Select.Option>
                            );
                        })}
                    </TdtSelect>
                </div>
                <div className="right-controls">
                    <TdtCalendarControl
                        displayType={displayType}
                        startDate={startDate}
                        endDate={endDate}
                        goNext={goNext}
                        goToday={goToday}
                        goPrev={goPrev}
                    />
                    <Button
                        type={layoutType === 'table' ? 'primary' : ''}
                        className="layout-btn mr-8"
                        onClick={() => {
                            setDisplayType('month');
                            setLayoutType('table');
                        }}
                    >
                        <ListsIcon class="m-0" />
                    </Button>
                    <CustomDropdown
                        trigger={['click']}
                        options={[
                            {
                                key: 'month',
                                text: t('Month display'),
                            },
                            {
                                key: 'week',
                                text: t('Week display'),
                            },
                            {
                                key: 'day',
                                text: t('Day display'),
                            },
                        ]}
                        onSelect={opt => {
                            setDisplayType(opt.key);
                            setLayoutType('calendar');
                        }}
                        separator
                        ovlClassName="calendar-dropdown-range-type"
                    >
                        <Button type={layoutType === 'calendar' ? 'primary' : ''} className="type-dropdown">
                            <DateRangeIcon className="ml-0" />
                            <ArrowDropDownIcon className="ml-0" />
                        </Button>
                    </CustomDropdown>
                </div>
            </div>
            {layoutType === 'table' && (
                <DataTable
                    className="collapse-table mt-16"
                    columns={columns}
                    hideColumns={state.hideColumns}
                    data={state.data}
                    loading={state.loading}
                    pagination={state.pagination}
                    filters={state.filters}
                    sorter={state.sorter}
                    onTableChange={(pagination, filter, sorter) => {
                        getCalendars(pagination, state.filters, sorter);
                    }}
                    noDataText={'データがありません'}
                    onRow={record => {
                        return {
                            onClick: () => {
                                if (record.client.deleted_by_id) return;
                                history.push(
                                    Routes.private.COUNSELOR_DETAIL_CONSULTATION.path.replace(':id', record.client_id) +
                                        `/${record.id}`
                                );
                            },
                        };
                    }}
                />
            )}
            {layoutType === 'calendar' && (
                <TdtCalendar
                    type={displayType}
                    events={reservations}
                    startDate={startDate}
                    active={registeredFiltered}
                    disableDate={date => {
                        return moment(date).isBefore(moment().startOf('day'));
                    }}
                    createMenu={createMenu}
                    eventModalRenderer={eventModalRenderer}
                    busySlots={busySlots}
                />
            )}
            {layoutType === 'table' && (
                <FloatBar>
                    <Button
                        icon={<AddIcon />}
                        className="fw-b"
                        onClick={() => history.push(Routes.private.ADMIN_CALENDAR_RESERVATION_CREATE.path)}
                        type="primary"
                    >
                        {t('Create new reservation')}
                    </Button>
                </FloatBar>
            )}
        </div>
    );
};

export default connect(mapState, mapDispatch)(Calendar);
