import React from 'react';
import AnswerQuestionsWithCount from 'pages/admin/interview_sheet/detail_screen/common/aggregate_results/AnswerQuestionsWithCount';

const AggregateDateTime = ({ question, answersDataList }) => {
    const parseData = () => {
        let values = [];
        for (const r of answersDataList) {
            const cur = r.questionById[question.id];
            if (cur && cur.answers.length > 0) {
                values = [...values, ...cur.answers.map(o => o.value)];
            }
        }
        let group = {};
        for (const v of values) {
            if (!group[v]) {
                group[v] = { text: v, count: 0 };
            }
            group[v].count += 1;
        }

        return Object.values(group);
    };
    return (
        <>
            <AnswerQuestionsWithCount data={parseData()} />
        </>
    );
};

export default AggregateDateTime;
