import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Routes from 'routes';
import history from 'helpers/history.helper';
import DataTable from 'components/DataTable';
import { DeleteIcon, DotsHorizontalRoundedIcon, DuplicateIcon, EditIcon } from 'components/SvgIcon';
import CustomDropdown from 'components/CustomDropdown';
import { UserPropTypes } from 'constants/proptypes.constant';
import { setCloneFromId } from 'pages/admin/counselor/company_program/reservable_frame_programs.slice';

const mapState = state => ({
    counselor: state.admin.counselorDetail.counselor,
});

const mapDispatch = { setCloneFromId };

const CounselorProgramTable = ({ counselor, registeredLoading, registered, setModalVisible, setCloneFromId }) => {
    const { t } = useTranslation();

    const programColumns = [
        {
            title: t('Company name'),
            dataIndex: 'companyName',
            sorter: false,
        },
        {
            title: t('Program'),
            dataIndex: 'programNameMngt',
            sorter: false,
        },
        {
            title: t('Same-day reservation'),
            dataIndex: 'allowSameDayBooking',
            sorter: false,
            render: value => {
                return value ? t('Possible') : t('Impossible');
            },
        },
        {
            title: t('Reservation frame (day of the week)'),
            dataIndex: 'days',
            sorter: false,
            render: days => {
                const daysMap = days.map((day, i) => (
                    <React.Fragment key={`days_${i}`}>
                        <span>{day}</span>
                        {i !== days.length - 1 ? <br /> : <></>}
                    </React.Fragment>
                ));
                return <>{daysMap}</>;
            },
        },
    ];

    const showDeleteProgramModal = (programId, id) =>
        setModalVisible({ role: false, email: false, delete: false, deleteProgram: true, programId, id });
    return (
        <DataTable
            className="mt-24 collapse-table"
            columns={programColumns}
            loading={registeredLoading}
            data={registered}
            noDataText={'データがありません'}
            onRow={record => {
                return {
                    onClick: () => {
                        let editPath = Routes.private.ADMIN_COMPANY_PROGRAM__ADD_COUNSELOR.path
                            .replace(':company_id', record.companyId)
                            .replace(':company_program_id', record.companyProgramId)
                            .replace(':counselor_id', counselor.id);
                        history.push(editPath);
                    },
                };
            }}
            actionColumn={{
                title: '',
                className: 'action-with-icon',
                width: 120,
                render: r => {
                    return (
                        <CustomDropdown
                            ovlClassName={'z-index-above-modal'}
                            trigger={['click']}
                            onClick={e => {
                                e.stopPropagation();
                                e.preventDefault();
                            }}
                            options={[
                                {
                                    key: 'duplicate',
                                    text: (
                                        <>
                                            <DuplicateIcon size={24} /> <span>{t('Duplicate')}</span>
                                        </>
                                    ),
                                    className: 'primary-color fw-b',
                                },
                                {
                                    key: 'delete',
                                    text: (
                                        <>
                                            <DeleteIcon /> <span>{t('Delete')}</span>
                                        </>
                                    ),
                                    className: 'pink-color fw-b',
                                },
                            ]}
                            onSelect={opt => {
                                opt.domEvent.stopPropagation();
                                switch (opt.key) {
                                    case 'delete':
                                        showDeleteProgramModal(r.companyProgramId, counselor.id);
                                        break;
                                    case 'duplicate':
                                        setCloneFromId(r.companyProgramId);
                                        history.push(
                                            Routes.private.ADMIN_COUNSELORS_ADD_COMPANY_PROGRAM_INDIVIDUAL.path.replace(
                                                ':id',
                                                counselor.id
                                            )
                                        );
                                        break;
                                }
                            }}
                            separator
                        >
                            <DotsHorizontalRoundedIcon className=" dots-icon c-pointer" />
                        </CustomDropdown>
                    );
                },
            }}
        />
    );
};

export default connect(mapState, mapDispatch)(CounselorProgramTable);

CounselorProgramTable.propTypes = {
    counselor: UserPropTypes,
    registeredLoading: PropTypes.bool,
    registered: PropTypes.array,
    setModalVisible: PropTypes.func,
};
