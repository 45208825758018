import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import BackBtn from 'components/BackBtn';
import { getProgram } from 'pages/client/reservation/program_detail.slice';
import Loader from 'components/Loader';
import RenderPageElements from 'pages/admin/common/page-elements/RenderPageElements';
import FloatBar from 'components/FloatBar';
import { Button, Col } from 'antd';
import { DateRangeIcon } from 'components/SvgIcon';
import { useTranslation } from 'react-i18next';
import TdtAvatar from 'components/TdtAvatar';
import history from 'helpers/history.helper';
import Routes from 'routes';
import NotFound from 'pages/error/NotFound';
import { realSessionTime } from 'helpers/session_time_calculation.helper';
const mapState = state => ({
    program: state.client.programDetail.program,
    loading: state.client.programDetail.loading,
    verified: state.auth.isVerified,
});
const mapDispatch = { getProgram };
const ProgramDetail = ({ program, loading, getProgram, verified }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const id = new URLSearchParams(location.search).get('companyProgram');

    useEffect(() => {
        program = null;
        getProgram(id);
    }, [id]);

    const detailCompanyProgram = program && program.program;

    const isVerified = verified && !(program && program.company.is_stage_stopped_end);

    if (loading) {
        return <Loader className="loader-center" />;
    }

    if (!program) {
        return (
            <>
                <NotFound />
            </>
        );
    }

    return (
        <>
            <BackBtn
                label={program.name}
                noBackText={true}
                action={() => history.push(Routes.private.CLIENT_RESERVATION.path)}
            />
            <div className="client-render-page-container mb-80">
                <RenderPageElements
                    info={detailCompanyProgram}
                    details={detailCompanyProgram.program_details}
                    isClient={true}
                />
            </div>
            <FloatBar className="program-detail-float-bar">
                <Button
                    className="make-rsv-btn fw-b"
                    type="primary"
                    disabled={!isVerified}
                    block={true}
                    onClick={() =>
                        history.push(`${Routes.private.CLIENT_RESERVATION_CREATE.path}?companyProgram=${program.id}`)
                    }
                >
                    {t('Click here to make a reservation')}
                </Button>

                <div className="info-column">
                    <div className="program-name-panel">
                        <span className="fs-20">{detailCompanyProgram.name}</span>
                        <span className="description">{`オンラインカウンセンリング（1回 ${realSessionTime(
                            program
                        )}分）`}</span>
                    </div>
                    <TdtAvatar avatarIcon={<DateRangeIcon size={28} />} avatarSize={48} avatarBgColor={'#13CBCB'} />
                </div>
            </FloatBar>
        </>
    );
};

export default connect(mapState, mapDispatch)(ProgramDetail);

ProgramDetail.propTypes = {
    program: PropTypes.any,
    getProgram: PropTypes.func.isRequired,
};
