import { createSlice } from '@reduxjs/toolkit';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';

const name = 'counselorConsultationDetail';

const counselorConsultationDetailSlice = createSlice({
    name,
    initialState: {
        consultation: null,
        loading: true,
    },
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setConsultation(state, action) {
            state.consultation = action.payload;
            state.loading = false;
        },
    },
});

export const { setLoading, setConsultation } = counselorConsultationDetailSlice.actions;

export const getConsultation = (id, reservationId) => async dispatch => {
    dispatch(setLoading(true));
    const data = await api.get(
        APP_URLS.COUNSELOR_CONSULTATION_DETAIL.replace(':id', id).replace(':reservationId', reservationId)
    );
    if (data) {
        dispatch(setConsultation(data));
        return data;
    }
};

export default counselorConsultationDetailSlice.reducer;
