import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeliveryContent from 'pages/admin/email/form/delivery_content/DeliveryContent';
import DeliverySettings from 'pages/admin/email/form/delivery_settings/DeliverySettings';
import TabList from 'components/TabList';
import BackBtnWithTitle from 'components/BackBtnWithTitle';
import history from 'helpers/history.helper';
import FloatBar from 'components/FloatBar';
import { Button } from 'antd';
import { connect } from 'react-redux';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import Routes from 'routes';
import DeliveryStatus from 'enums/delivery_status.enum';
import { checkTargetAudiencesSelector, reset, setInfo } from 'pages/admin/email/email_create.slice';
import EmailPreviewModal from 'pages/admin/email/modal/EmailPreviewModal';
import CustomDropdown from 'components/CustomDropdown';
import { DeleteIcon, DotsHorizontalRoundedIcon, DuplicateIcon } from 'components/SvgIcon';
import { getEnumText } from 'helpers/enum.helper';
import EmailType from 'enums/email_type.enum';
import { valuesToFormData } from 'helpers/form.helper';
import { convertDataToState, reset as resetPageElements } from 'pages/admin/common/page-elements/page_elements.slice';
import { showNormal } from 'helpers/notification.helper';
import DeleteEmailModal from 'pages/admin/email/modal/DeleteEmailModal';
import CloneEmailModal from 'pages/admin/email/modal/CloneEmailModal';
import TransmissionAndSendModal from 'pages/admin/email/modal/TransmissionAndSendModal';

const mapState = state => ({
    info: state.admin.emailCreate.info,
    byId: state.admin.pageElements.elements.byId,
    order: state.admin.pageElements.elements.order,
    checkTargetAudiences: checkTargetAudiencesSelector(state.admin.emailCreate),
});
const mapDispatch = { reset, setInfo, convertDataToState, resetPageElements };

const EmailForm = ({
                       initialValues = {},
                       isUpdate = false,
                       info,
                       setInfo,
                       reset,
                       byId,
                       order,
                       convertDataToState,
                       resetPageElements,
                       checkTargetAudiences,
                   }) => {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [modalVisible, setModalVisible] = useState({
        preview: false,
        clone: false,
        delete: false,
        record: null,
        transmission: false,
    });
    const showPreviewModal = () => setModalVisible({ ...modalVisible, preview: true });
    const showDeleteModal = r => setModalVisible({ ...modalVisible, delete: true, record: r });
    const showCloneModal = r => setModalVisible({ ...modalVisible, clone: true, record: r });
    const showTransmissionAndSendModal = () => setModalVisible({ ...modalVisible, transmission: true });
    const closeModal = () =>
        setModalVisible({ preview: false, clone: false, delete: false, record: null, transmission: false });

    const deleteEmail = async emailId => {
        const response = await api.delete(APP_URLS.ADMIN_EMAILS_DELETE.replace(':id', emailId));
        if (response) {
            history.push(Routes.private.ADMIN_EMAIL.path);
            showNormal('', t('message:Data has been deleted'), 3);
        }
    };

    initialValues = Object.assign(
        {
            status: DeliveryStatus.DRAFT.value,
        },
        initialValues,
    );

    const isRefSeminar = isUpdate && initialValues.ref_seminar_id;

    const tabList = [
        {
            key: 'delivery_content',
            title: '配信内容',
            container: (
                <DeliveryContent
                    initialValues={initialValues}
                    selectedRecord={selectedRecord}
                    setSelectedRecord={setSelectedRecord}
                    isRefSeminar={isRefSeminar}
                />
            ),
        },
        {
            key: 'delivery_settings',
            title: '配信設定',
            container: <DeliverySettings initialValues={initialValues} selectedRecord={selectedRecord}
                                         isRefSeminar={isRefSeminar} />,
            forceRender: true,
        },
    ];

    const renderTabList = () => {
        return (
            <TabList
                tabs={tabList}
                animated={false}
                className='interview-sheet-tab-list bg-white tab-with-less-padding'
            />
        );
    };

    const submitForm = async (saveAsDraft = false) => {
        const formData = new FormData();
        const params = {
            ...info,
            status: saveAsDraft ? DeliveryStatus.DRAFT.value : (isRefSeminar ? DeliveryStatus.SCHEDULED.value : DeliveryStatus.RESERVED.value),
        };

        if (params.type === EmailType.HTML_EMAIL.value) {
            // set details
            params.details = [];
            order.map(key => params.details.push(byId[key]));
        }

        if (selectedRecord && selectedRecord.id) {
            if (info.type === EmailType.NEW_PROGRAM.value) {
                params.program_id = selectedRecord.id;
            } else if (info.type === EmailType.NEW_SESSION.value) {
                params.seminar_id = selectedRecord.id;
            }
        }

        // target_audiences
        params.target_audiences = info.target_audiences.map(entry => {
            const { id, ...others } = entry;
            return isNaN(id) ? { ...others } : { ...entry };
        });
        params.number_of_deliveries = info.target_audiences.length;

        valuesToFormData(formData, params);

        const res = isUpdate
            ? await api.post(APP_URLS.ADMIN_EMAILS_DETAIL.replace(':id', initialValues.id), formData, {
                _method: 'PUT',
            })
            : await api.post(APP_URLS.ADMIN_EMAILS, formData);
        if (res) {
            history.push(Routes.private.ADMIN_EMAIL.path);
            setIsSending(false);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (isUpdate) {
            setInfo({ ...initialValues });
            if (initialValues.type === EmailType.HTML_EMAIL.value) {
                convertDataToState({ elements: initialValues.email_html_details });
            }
        }

        return () => {
            reset();
            resetPageElements();
        };
    }, []);

    useEffect(() => {
        if (initialValues.type !== info.type) {
            setSelectedRecord(null);
        }
    }, [info.type]);

    const setAsSelectedRecord = () => {
        const details = order.map(key => byId[key]);
        return { ...info, name: info.title, note: info.additional_notes, program_details: details };
    };

    const totalItems = checkTargetAudiences.targetAudience;

    const isDisabledSendBtn =
        !info.title ||
        (info.delivery_type !== 'send_now' && (!info.delivery_date || !info.delivery_time)) ||
        !totalItems;

    const hasSetDate = info.delivery_type !== 'send_now';
    const isDraft = initialValues.status === DeliveryStatus.DRAFT.value;
    const isReserved = initialValues.status === DeliveryStatus.RESERVED.value;

    const clickSaveAsDraftBtn = () => {
        setIsLoading(true);
        submitForm(true);
    };

    const clickSendBtn = () => {
        setIsSending(true);
        submitForm();
        showNormal('', t('message:I have reserved the mail delivery'), 5);
    };
    const clickApplyChangesBtn = () => {
        setIsSending(true);
        submitForm();
    };

    return (
        <div className='self-wrapper px-0 pb-80'>
            <div className='px-48'>
                <div className='d-flex justify-content-space-between align-items-center'>
                    <BackBtnWithTitle
                        title={isUpdate ? initialValues.title : '新規メール作成'}
                        onClick={() => history.goBack()}
                    />

                    {isUpdate && (
                        <div className='pt-48 change-state-container'>
                            <div className='mr-16 white-space-nowrap ml-12'>
                                <span>{'ステータス：'}</span>
                                <span className=' fw-700 fs-16'>
                                    {t(`enum:${getEnumText(DeliveryStatus, initialValues.status)}`)}
                                </span>
                            </div>
                            <CustomDropdown
                                ovlClassName='menu-less-py'
                                className='d-flex'
                                trigger={['click']}
                                options={[
                                    {
                                        key: 'duplicate',
                                        text: (
                                            <>
                                                <DuplicateIcon /> <span>{'複製'}</span>
                                            </>
                                        ),
                                        className: 'primary-color fw-b',
                                    },
                                    {
                                        key: 'delete',
                                        text: (
                                            <>
                                                <DeleteIcon /> <span>{'削除'}</span>
                                            </>
                                        ),
                                        className: 'pink-color fw-b',
                                    },
                                ].filter(o => o.key !== 'duplicate' || !initialValues.ref_seminar_id)}
                                onSelect={opt => {
                                    opt.domEvent.stopPropagation();
                                    if (opt.key === 'delete') {
                                        showDeleteModal(initialValues);
                                    } else {
                                        showCloneModal(initialValues);
                                    }
                                }}
                                separator
                            >
                                <DotsHorizontalRoundedIcon className=' primary-color c-pointer' />
                            </CustomDropdown>
                        </div>
                    )}
                </div>
            </div>
            {renderTabList()}

            <EmailPreviewModal
                record={info.type !== EmailType.HTML_EMAIL.value ? {
                    ...selectedRecord,
                    additional_notes: info.additional_notes,
                } : setAsSelectedRecord()}
                visible={modalVisible.preview}
                onClose={() => closeModal()}
                isProgram={info.type !== EmailType.NEW_SESSION.value}
            />

            <DeleteEmailModal
                onClose={() => closeModal()}
                onUpdate={() => deleteEmail(modalVisible.record.id)}
                visible={modalVisible.delete}
                record={modalVisible.record}
            />
            <CloneEmailModal onClose={() => closeModal()} visible={modalVisible.clone} record={modalVisible.record} />
            <TransmissionAndSendModal
                onClose={() => closeModal()}
                visible={modalVisible.transmission}
                record={selectedRecord}
                hasSetDate={hasSetDate}
                onUpdate={() => clickSendBtn()}
            />
            <FloatBar className='ant-row-space-between flex-direction-row'>
                <div>
                    <div>
                        <span>{'配信対象者数：'}</span>
                        <span
                            className='fw-700 fs-20'>{isRefSeminar ? initialValues.number_of_deliveries : checkTargetAudiences.targetAudience}</span>
                        <span className='ml-4'>{'人'}</span>
                    </div>
                    {/*<div className=" text-gray-color fw-400 fs-14 d-flex align-items-center">*/}
                    {/*    <div>*/}
                    {/*        <span>{'登録者数：'}</span>*/}
                    {/*        <span>{checkTargetAudiences.numberOfRegistrants}</span>*/}
                    {/*        <span className="ml-4">{'人'}</span>*/}
                    {/*    </div>*/}
                    {/*    <div className="ml-16">*/}
                    {/*        <span>{'重複しているメールアドレス：'}</span>*/}
                    {/*        <span>{checkTargetAudiences.duplicateEmailAddress}</span>*/}
                    {/*        <span className="ml-4">{'人'}</span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                <div>
                    {isDraft && (
                        <Button
                            className='ml-8 fw-b'
                            disabled={!info.title}
                            onClick={() => clickSaveAsDraftBtn()}
                            loading={isLoading}
                            type='link'
                        >
                            {'下書き保存'}
                        </Button>
                    )}

                    <Button
                        className='ml-8 fw-b'
                        onClick={() => showPreviewModal()}
                        disabled={!selectedRecord && order.length < 1}
                    >
                        {t('Preview')}
                    </Button>
                    {(!isRefSeminar && isDraft) && (
                        <>
                            <Button
                                className='ml-8 fw-b'
                                type='primary'
                                onClick={() => {
                                    if (info.type === EmailType.NEW_SESSION.value && !selectedRecord.sent_at && selectedRecord.transmission_date && selectedRecord.transmission_time) {
                                        showTransmissionAndSendModal();
                                    } else {
                                        clickSendBtn();
                                    }
                                }}
                                disabled={isDisabledSendBtn}
                                loading={isSending}
                            >
                                {hasSetDate ? t('Reservation') : t('Send')}
                            </Button>
                        </>
                    )}
                    {(initialValues.status !== DeliveryStatus.DISCONTINUED.value) && (isRefSeminar || isReserved) && (
                        <>
                            <Button
                                className='ml-8 fw-b'
                                type='primary'
                                onClick={() => clickApplyChangesBtn()}
                                disabled={!isRefSeminar && isDisabledSendBtn}
                            >
                                {'変更内容を反映'}
                            </Button>
                        </>
                    )}
                </div>
            </FloatBar>
        </div>
    );
};

export default connect(mapState, mapDispatch)(EmailForm);
