import React from 'react';
import PropType from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { InfoIcon, ReportProblemIcon, RestrictIcon } from 'components/SvgIcon';

const TdtButtonRestrict = ({ className = '', children, withImage = false, ...rest }) => {
    const { t } = useTranslation();
    const classNames = `btn-restrict ${className}`;

    if (withImage) {
        return (
            <div className="text-align-center pt-64 mt-24">
                <div className="mb-24">
                    <RestrictIcon size={240} />
                </div>
                <div className="d-inline-flex">
                    <ReportProblemIcon size={24} className="mr-8 color-red-100" />
                    {t('You need permission to view')}
                </div>
            </div>
        );
    }

    return (
        <Button {...rest} className={classNames}>
            {children ? (
                children
            ) : (
                <>
                    <InfoIcon size={16} className="mr-8" />
                    {t('You need permission to view')}
                </>
            )}
        </Button>
    );
};

export default TdtButtonRestrict;

TdtButtonRestrict.propTypes = {
    className: PropType.string,
    children: PropType.any,
    withImage: PropType.bool,
};
