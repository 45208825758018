import { createSlice } from '@reduxjs/toolkit';
import { randomId } from 'helpers/common.helper';
import { APP_URLS } from 'constants/url.constant';

const name = 'pageElements';
const initialState = {
    elements: {
        byId: {},
        order: [],
    },
    operationTargetId: null,
    refs: {},
};

const pageElementsSlice = createSlice({
    name,
    initialState: initialState,
    reducers: {
        reset(state) {
            state.elements = initialState.elements;
        },

        addPageElement(state, action) {
            const id = action.payload.id;
            state.elements.byId[id] = { ...action.payload.pageElement, id };
            state.elements.order = [...state.elements.order, id];

            state.operationTargetId = id;
        },

        movePageElementUp(state, action) {
            const id = action.payload.id;
            if (!id || !state.elements.byId[id]) return;
            let order = state.elements.order.slice();
            const index = order.indexOf(id);
            if (index < 1) return;
            const tmpId = order[index - 1];
            order[index - 1] = id;
            order[index] = tmpId;
            state.elements.order = order;

            state.operationTargetId = id;
        },

        movePageElementDown(state, action) {
            const id = action.payload.id;
            if (!id || !state.elements.byId[id]) return;
            let order = state.elements.order.slice();
            const index = order.indexOf(id);
            if (index === -1 || index === order.length - 1) return;
            const tmpId = order[index + 1];
            order[index + 1] = id;
            order[index] = tmpId;
            state.elements.order = order;

            state.operationTargetId = id;
        },

        duplicatePageElement(state, action) {
            const id = action.payload.id;
            if (!id || !state.elements.byId[id]) return;
            const newId = `${randomId()}_${id}`;
            state.elements.byId[newId] = { ...state.elements.byId[id], id: newId };
            state.elements.order.push(newId);

            state.operationTargetId = newId;
        },

        removePageElement(state, action) {
            const id = action.payload.id;
            if (!id || !state.elements.byId[id]) return;
            delete state.elements.byId[id];
            state.elements.order = state.elements.order.filter(tmpId => tmpId !== id);

            state.operationTargetId = null;
        },

        setPageElement(state, action) {
            const id = action.payload.id;
            if (!id || !state.elements.byId[id]) return;
            state.elements.byId[id] = { ...state.elements.byId[id], ...action.payload.changes };
        },

        convertDataToState(state, action) {
            const { elements } = action.payload;
            let stateElements = {
                byId: {},
                order: [],
            };

            for (const element of elements) {
                let { id, type, heading_size, content, profile_name, media_url, is_displayed } = element;
                stateElements.byId[id] = {
                    id,
                    type,
                    heading_size,
                    content,
                    profile_name,
                    is_displayed,
                    media_url,
                    ...(media_url ? { image_media_url: { url: `${APP_URLS.STATIC_BASE_URL}/${media_url}` } } : {}),
                };
                stateElements.order.push(id);
            }

            state.elements = stateElements;
        },

        restoreFromTemp(state, action) {
            state.elements = action.payload;
        },

        setRefs(state, action) {
            const { id, el } = action.payload;
            state.refs = { ...state.refs, [id]: el };
        },
    },
});

export const {
    reset,
    addPageElement,
    movePageElementUp,
    movePageElementDown,
    duplicatePageElement,
    removePageElement,
    setPageElement,
    convertDataToState,
    restoreFromTemp,
    setRefs,
} = pageElementsSlice.actions;

export default pageElementsSlice.reducer;
