import { createSlice } from '@reduxjs/toolkit';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';

const name = 'clientProgramDetail';

const clientProgramDetailSlice = createSlice({
    name,
    initialState: {
        program: null,
        loading: true,
    },
    reducers: {
        setProgram(state, action) {
            state.program = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
    },
});

export const { setProgram, setLoading } = clientProgramDetailSlice.actions;

export const getProgram = id => async dispatch => {
    dispatch(setLoading(true));
    const data = await api.get(APP_URLS.CLIENT_PROGRAMS_DETAIL.replace(':id', id));
    if (data) {
        dispatch(setProgram(data));
    }
    dispatch(setLoading(false));
};

export default clientProgramDetailSlice.reducer;
