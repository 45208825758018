import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Select } from 'antd';
import DataTable from 'components/DataTable';
import InputSearch from 'components/InputSearch';
import BackBtn from 'components/BackBtn';
import {
    setData,
    getData,
    setFilters,
    setSorter,
    getMetaData,
    setPagination,
} from 'pages/admin/company/tabs/employee/employee.slice';
import { setAfterDeleted } from 'pages/admin/calendar/calendar.slice';
import { getEnumText } from 'helpers/enum.helper';
import AuthenticationStatusEnum from 'enums/authentication_status.enum';
import TdtSelect from 'components/TdtSelect';
import history from 'helpers/history.helper';
import Routes from 'routes';
import FloatBar from 'components/FloatBar';
import SelectPagination from 'components/SelectPagination';
import { setBackUrl } from 'core.slice';
import { dateToStr } from 'helpers/date.helper';

const mapState = state => ({
    state: state.admin.companyDetailEmployee,
});
const mapDispatch = {
    setData,
    getData,
    setSorter,
    setFilters,
    getMetaData,
    setPagination,
    setBackUrl,
    setAfterDeleted,
};

const EmployeeContainer = ({
    company,
    renderTabList,
    state,
    getData,
    getMetaData,
    setPagination,
    setBackUrl,
    setAfterDeleted,
    setSorter,
    setFilters,
}) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const columns = [
        {
            title: t('Full name'),
            dataIndex: 'first_name_kana',
            sorter: true,
            width: 180,
            render: (firstNameKana, r) => {
                return (
                    <>
                        <div className="fw-b">
                            {`${r.full_name}`}
                            {r.unread_message > 0 && <span className="dot-unread-message" />}
                        </div>
                        <span className="text-small">{r.email}</span>
                    </>
                );
            },
        },
        {
            title: t('Program'),
            dataIndex: 'assigned_programs',
            width: 342,
            render: assigned_programs => {
                if (assigned_programs && assigned_programs.length > 0) {
                    let tmp = [];
                    const names = assigned_programs
                        .filter(p => {
                            if (tmp.includes(p.id)) return false;
                            else tmp.push(p.id);
                            return true;
                        })
                        .map(p => p.name_mngt);
                    return names.join('，');
                }
                return '';
            },
        },
        {
            title: t('Register date'),
            dataIndex: 'created_at',
            width: 128,
            render: createdAt => dateToStr(createdAt, 'YYYY/MM/DD'),
        },
        {
            title: '認証日',
            dataIndex: 'email_verified_at',
            sorter: true,
            width: 128,
            render: verifiedAt => verifiedAt ? dateToStr(verifiedAt, 'YYYY/MM/DD') : t(`enum:${getEnumText(AuthenticationStatusEnum, !!verifiedAt)}`),
        }
    ];

    const getEmployees = (pagination = state.pagination, filters = state.filters, sorter = state.sorter) => {
        setSorter(sorter);
        getData({ id: id, pagination, filters, sorter });
    };

    useEffect(() => {
        setData([]);
        getMetaData(id);
        getEmployees(state.pagination, {});
    }, [id]);

    const metaData = state.metaData;

    const handlerFilterByStatus = key => {
        const newFilters = { ...state.filters, status: key };
        const newPagination = { ...state.pagination, current: 1 };
        setFilters(newFilters);
        setPagination(newPagination);
        getEmployees(newPagination, newFilters, state.sorter);
    };

    const handleFilterByProgram = key => {
        const newFilters = { ...state.filters, program_id: key };
        const newPagination = { ...state.pagination, current: 1 };
        setFilters(newFilters);
        setPagination(newPagination);
        getEmployees(newPagination, newFilters, state.sorter);
    };

    const keywordChangeHandler = value => {
        const newFilters = Object.assign({}, state.filters, {
            keyword: value,
        });
        setFilters(newFilters);
        getEmployees(state.pagination, newFilters, state.sorter);
    };

    return (
        <>
            <div className="px-24 pb-12">
                <BackBtn label={company.name} action={() => history.push(Routes.private.ADMIN_COMPANY.path)} />
            </div>

            {renderTabList('employee')}

            <div className="p-0">
                <Row gutter={16}>
                    <Col span={24} className="d-flex">
                        <InputSearch
                            style={{ maxWidth: 240 }}
                            //value={state.filters['keyword']}
                            //onChange={value => setFilters({ ...state.filters, keyword: value })}
                            onSearch={value => keywordChangeHandler(value)}
                            placeholder={'氏名・プログラムで検索'}
                        />
                        <TdtSelect
                            value={state.filters.status}
                            size="large"
                            className="fs-14 ml-8"
                            onChange={handlerFilterByStatus}
                        >
                            <Select.Option value={null}>{'全ての認証状態'}</Select.Option>
                            {Object.values(AuthenticationStatusEnum).map(o => (
                                <Select.Option key={o.value} value={o.value}>
                                    {t(`enum:${getEnumText(AuthenticationStatusEnum, o.value)}`)}
                                </Select.Option>
                            ))}
                        </TdtSelect>
                        <TdtSelect
                            value={state.filters.program_id}
                            size="large"
                            className="fs-14 ml-8"
                            onChange={handleFilterByProgram}
                        >
                            <Select.Option value={null}>{'全てのプログラム'}</Select.Option>
                            {metaData.programs.map(p => (
                                <Select.Option key={p.id} value={p.id}>
                                    {p.name_mngt}
                                </Select.Option>
                            ))}
                        </TdtSelect>
                        <SelectPagination
                            onChange={pagination => {
                                setPagination(pagination);
                                getEmployees(pagination);
                            }}
                            pageSizeOptions={[100, 200, 300]}
                        />
                    </Col>
                </Row>

                <div className="mt-16">
                    <DataTable
                        className="collapse-table"
                        columns={columns}
                        hideColumns={state.hideColumns}
                        data={state.data}
                        loading={state.loading}
                        pagination={state.pagination}
                        filters={state.filters}
                        sorter={state.sorter}
                        onTableChange={(pagination, filter, sorter) => {
                            getEmployees(pagination, state.filters, sorter);
                        }}
                        onRow={record => {
                            return {
                                onClick: () => {
                                    setAfterDeleted(Routes.private.ADMIN_COMPANY.path + '/' + id);
                                    setBackUrl(Routes.private.ADMIN_COMPANY.path + '/' + id);
                                    if (record.unread_message > 0) {
                                        history.push(
                                            Routes.private.COUNSELOR_DETAIL_MESSAGE.path.replace(':id', record.id) +
                                                '?=from-company'
                                        );
                                    } else {
                                        history.push(
                                            Routes.private.COUNSELOR_DETAIL_USER_INFORMATION.path.replace(
                                                ':id',
                                                record.id
                                            ) + '?=from-company'
                                        );
                                    }
                                },
                            };
                        }}
                        noDataText={'登録されている社員はいません'}
                    />
                </div>
            </div>

            <FloatBar>
                <div className="body1">{`${company.total_employee}人`}</div>
            </FloatBar>
        </>
    );
};

export default connect(mapState, mapDispatch)(EmployeeContainer);

EmployeeContainer.propTypes = {
    company: PropTypes.any,
    renderTabList: PropTypes.func,
    state: PropTypes.any,
    setData: PropTypes.func,
    getData: PropTypes.func.isRequired,
    setSorter: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
    getMetaData: PropTypes.func.isRequired,
    setPagination: PropTypes.func.isRequired,
    setBackUrl: PropTypes.any,
    setAfterDeleted: PropTypes.any,
};
