import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Checkbox } from 'antd';
import { CloseIcon } from 'components/SvgIcon';

const UpdateEmployeeProgram = ({ visible, onClose, onUpdate, employeeProgram, isAdmin }) => {
    const assignedProgramIds = () => {
        return (employeeProgram || []).filter(p => p.assigned_programs_count > 0).map(p => p.id);
    };

    const [programIds, setProgramIds] = useState(assignedProgramIds());
    const { t } = useTranslation();

    useEffect(() => {
        setProgramIds(assignedProgramIds());
    }, [employeeProgram]);

    return (
        <Modal
            title={t('Target program editing')}
            visible={visible}
            width={320}
            footer={null}
            closeIcon={<CloseIcon />}
            onCancel={() => onClose()}
        >
            <div className="vertical-group ">
                <Checkbox.Group
                    value={programIds}
                    onChange={value => {
                        setProgramIds(value);
                    }}
                >
                    {employeeProgram &&
                        employeeProgram.map(p => {
                            return (
                                <React.Fragment key={p.id}>
                                    <Checkbox
                                        value={p.id}
                                        disabled={p && p.reservations_count > 0}
                                        className={p && p.reservations_count > 0 ? 'fs-16 mb-0' : 'fs-16 mb-16'}
                                    >
                                        {isAdmin ? p.name_mngt : p.name}
                                    </Checkbox>
                                    {p && p.reservations_count > 0 ? (
                                        <p className="fs-12 pl-28 pb-4 text-gray-color">
                                            相談がある場合には変更できません
                                        </p>
                                    ) : null}
                                </React.Fragment>
                            );
                        })}
                </Checkbox.Group>
            </div>
            <Button
                type="primary"
                block
                className="fw-b mt-16"
                onClick={() => {
                    onUpdate && onUpdate(programIds);
                    onClose();
                }}
            >
                {t('Save')}
            </Button>
        </Modal>
    );
};

UpdateEmployeeProgram.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    consultant: PropTypes.any,
};

export default UpdateEmployeeProgram;
