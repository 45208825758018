import React, { useEffect, useState } from 'react';
import { APP_URLS } from 'constants/url.constant';
import api from 'helpers/api.helper';
import history from 'helpers/history.helper';
import ResponsePeriodForm from 'pages/admin/interview_sheet/detail_screen/tabs/response_period/ResponsePeriodForm';
import { useParams } from 'react-router-dom';
import { dateToStr } from 'helpers/date.helper';

const ResponsePeriodUpdate = () => {
    const { id, periodId } = useParams();
    const [interviewSheet, setInterviewSheet] = useState({});
    const [loading, setLoading] = useState(true);

    const getInterviewSheet = async id => {
        setLoading(true);
        const response = await api.get(APP_URLS.ADMIN_INTERVIEW_SHEETS_DETAIL.replace(':id', id));
        if (response) {
            setInterviewSheet(response);
            setLoading(false);
        }
    };

    const onFinish = async values => {
        values.start_date = dateToStr(values.start_date, 'YYYY-MM-DD');
        values.end_date = dateToStr(values.end_date, 'YYYY-MM-DD');

        const response = await api.put(
            APP_URLS.ADMIN_INTERVIEW_SHEETS_DETAIL_PERIOD_UPDATE.replace(':id', id).replace(':periodId', periodId),
            values
        );
        if (response) {
            history.goBack();
        }
    };

    useEffect(() => {
        getInterviewSheet(id).then(() => {});

        return () => {
            setInterviewSheet({});
            setLoading(false);
        };
    }, [id]);
    if (loading) return <></>;

    return (
        <ResponsePeriodForm
            isEdit={true}
            initialValues={interviewSheet.periods.find(o => o.id === Number(periodId))}
            periods={interviewSheet.periods}
            onFinish={onFinish}
        />
    );
};

export default ResponsePeriodUpdate;
