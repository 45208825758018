import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from 'antd';
import CsvDownloadTemplate from 'pages/admin/email/distribution/modal/csv/CSVDownloadTemplate';
import { useCSVReader } from 'react-papaparse';

const DropCsvFileInput = ({ callbackData }) => {
    const { CSVReader } = useCSVReader();

    return (
        <>
            <Typography className="fw-700 fs-14">{'CSVアップロード'}</Typography>
            <CSVReader onUploadAccepted={results => callbackData(results)}>
                {({ getRootProps }) => (
                    <div
                        className="mt-8 primary-color bg-blue-purple-10 text-align-center drop-file-input"
                        style={{ width: 608, height: 118, border: '1px dashed #4C18ED', borderRadius: 8 }}
                        {...getRootProps()}
                    >
                        <div>
                            <Button
                                className="fw-700 fs-16 mt-24"
                                style={{ width: 174, height: 45, border: '1px solid #d1d0d6', paddingLeft: 16 }}
                            >
                                {'CSVファイルを選択'}
                            </Button>
                            <Typography className="mt-8 mb-24 fw-400 fs-12">{'ドラッグ&ドロップ'}</Typography>
                        </div>
                    </div>
                )}
            </CSVReader>
            <div className="mt-8 d-flex fw-400 fs-14 align-items-center">
                <Typography>{'テンプレートダウンロードは'}</Typography>
                <CsvDownloadTemplate />
            </div>
        </>
    );
};

DropCsvFileInput.propTypes = {
    onFileChange: PropTypes.func,
};

export default DropCsvFileInput;
