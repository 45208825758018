import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import Routes from 'routes';
import { BaseStatePropShape, UserPropTypes } from 'constants/proptypes.constant';
import history from 'helpers/history.helper';
import DataTable from 'components/DataTable';
import FloatBar from 'components/FloatBar';
import { AddIcon, EditIcon } from 'components/SvgIcon';
import { getData, setSorter } from 'pages/admin/program/program.slice';
import TdtButton from 'components/TdtButton';
import ProgramCategoryEnum from 'enums/program_category.enum';

const mapState = state => ({ programState: state.admin.program });
const mapDispatch = { getData, setSorter };
const Program = ({ programState, getData, setSorter }) => {
    const { t } = useTranslation();

    const columns = [
        {
            title: t('Name'),
            dataIndex: 'name_mngt',
            sorter: true,
        },
        {
            title: t('Category'),
            dataIndex: 'category',
            width: 400,
            render: (_, r) => (
                <span>
                    {r.category === ProgramCategoryEnum.COMPREHENSIVE_PROGRAM.value
                        ? '総合プログラム'
                        : 'テーマ別プログラム'}
                </span>
            ),
        },
        {
            title: t('Edit'),
            dataIndex: 'EDIT',
            render: (_, r) => {
                return (
                    <TdtButton
                        buttonStyle="plain"
                        icon={<EditIcon />}
                        onClick={() => {
                            history.push(Routes.private.ADMIN_PROGRAM_UPDATE.path.replace(':id', r.id));
                        }}
                    />
                );
            },
            width: 80,
        },
    ];

    const getProgram = (
        pagination = programState.pagination,
        filters = programState.filters,
        sorter = programState.sorter
    ) => {
        if (!sorter || !sorter.field) {
            sorter = programState.sorter;
        }
        setSorter(sorter);
        getData({ pagination, filters, sorter });
    };

    useEffect(() => {
        getProgram();
    }, []);

    return (
        <div className="self-wrapper d-block">
            <h1 className="page-title mb-32">{t('Program')}</h1>

            <DataTable
                className="mt-24 collapse-table"
                columns={columns}
                noDataText={'データがありません'}
                hideColumns={programState.hideColumns}
                data={programState.data}
                loading={programState.loading}
                pagination={programState.pagination}
                filters={programState.filters}
                sorter={programState.sorter}
                onTableChange={(pagination, filter, sorter) => {
                    getProgram(pagination, filter, sorter);
                }}
                onRow={record => {
                    return {
                        onClick: () => {
                            history.push(Routes.private.ADMIN_PROGRAM_UPDATE.path.replace(':id', record.id));
                        },
                    };
                }}
            />
            <FloatBar>
                <Button
                    icon={<AddIcon />}
                    className="fw-b"
                    onClick={() => history.push(Routes.private.ADMIN_PROGRAM_CREATE.path)}
                >
                    {t('Register new')}
                </Button>
                <Button
                    className="fw-b mr-16"
                    onClick={() => history.push(Routes.private.ADMIN_PROGRAM_DISPLAY_ORDER_SETTING.path)}
                >
                    {t('Display order setting')}
                </Button>
            </FloatBar>
        </div>
    );
};

export default connect(mapState, mapDispatch)(Program);

Program.propTypes = {
    programState: PropTypes.shape(
        Object.assign({}, BaseStatePropShape, {
            data: PropTypes.arrayOf(UserPropTypes).isRequired,
        })
    ),
    getData: PropTypes.func.isRequired,
    setSorter: PropTypes.func.isRequired,
};
