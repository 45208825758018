import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import TdtButton from 'components/TdtButton';
import { CloseIcon } from 'components/SvgIcon';
import { getEnumText } from 'helpers/enum.helper';
import DayOfWeekShortEnum from 'enums/day_of_week_short.enum';
import { boundaryAfterMerging } from 'helpers/common.helper';

const DuplicateReservationSlotsModal = ({ visible, onClose, onSave, overlapData }) => {
    const { t } = useTranslation();

    const overlapInfo = overlapData.overlapInfo || [];

    const renderInfoByType = (arrInfo, type) => {
        if (!type) {
            const merging = boundaryAfterMerging(arrInfo);
            return (
                <p className="mb-0 body1">
                    <span>{`[${t('After merging')}]`}</span>
                    {'　'}
                    {`${getEnumText(DayOfWeekShortEnum, merging.day)} ${merging.hour_start} ~ ${merging.hour_end}`}
                </p>
            );
        }

        return arrInfo
            .filter(o => o.source === type)
            .map((o, i) => {
                return (
                    <p className="mb-0 body1" key={`${o.day}_${o.hour_start}_${o.hour_end}_${i}`}>
                        <span className={i > 0 ? 'opacity-0' : ''}>{`[${
                            type === 'targets' ? t('Additions') : t('Existing minutes')
                        }]`}</span>
                        {'　　'}
                        {`${getEnumText(DayOfWeekShortEnum, o.day)} ${o.hour_start} ~ ${o.hour_end}`}
                    </p>
                );
            });
    };

    return (
        <Modal
            title={t('Duplicate reservation slots')}
            visible={visible}
            width={360}
            footer={[
                <TdtButton
                    key="stay"
                    buttonSize="big"
                    buttonStyle="plain"
                    className="fw-b disable-color text-decoration-none"
                    onClick={() => onClose()}
                >
                    {t('Cancel')}
                </TdtButton>,
                <TdtButton
                    key="do_not_save"
                    buttonSize="big"
                    buttonStyle="plain"
                    className="fw-b text-decoration-none"
                    onClick={() => onSave()}
                >
                    {t('Save')}
                </TdtButton>,
            ]}
            closeIcon={<CloseIcon />}
            onCancel={() => onClose()}
            className="modal-footer-no-border"
            //wrapClassName="preview-modal-container"
        >
            <div className="body1 render-page-container pl-0 pr-0 overflow-y-auto">
                {t(
                    'The reservation slot you are trying to save additionally overlaps with the existing reservation slot. When you save, the reservation slots will be merged. Is it OK?'
                )}
                <div className="mt-24">
                    {overlapInfo.map((info, index) => (
                        <div className="mt-24" key={`overlap_info_${index}`}>
                            <p className="mb-4 fw-b">{`マージされる予約枠(${index + 1})`}</p>
                            {renderInfoByType(info, 'targets')}
                            {renderInfoByType(info, 'exists')}
                            {renderInfoByType(info)}
                        </div>
                    ))}
                </div>
            </div>
        </Modal>
    );
};

export default DuplicateReservationSlotsModal;
