const isRetina = window.devicePixelRatio >= 2;
const sizes = [480, 768, 1200, 1460, 1920];

export const imageUrlAdapter = (orgUrl, pxContainer) => {
    if (!orgUrl) {
        return '';
    }
    const matches = orgUrl.match(/_org(.*?)\./);

    if (!matches || matches.length < 2) {
        return orgUrl;
    }

    const willChange = matches[0]; // "_org720."
    const orgSize = matches[1];

    const screenWidth = window.screen.width;
    const availableSizes = [...sizes.filter(s => s < orgSize), orgSize];

    if (availableSizes.length <= 1) {
        return orgUrl;
    }

    let adaptationSize = 0;
    for (const i of availableSizes.keys()) {
        const size = availableSizes[i];
        if (
            size >= screenWidth ||
            (pxContainer && size >= pxContainer) ||
            (size < screenWidth && i === availableSizes.length - 1)
        ) {
            adaptationSize = size;

            // if retina, +1 > +2 level
            if (isRetina) {
                // + 1 if can
                if (i < availableSizes.length - 2) {
                    adaptationSize = availableSizes[i + 1];
                }
                // +2 if can
                if (i < availableSizes.length - 3) {
                    adaptationSize = availableSizes[i + 2];
                }
            }

            break;
        }
    }
    if (adaptationSize === orgSize) {
        return orgUrl;
    }

    return orgUrl.replace(willChange, '_w' + adaptationSize + '.');
};
