import React, { useEffect, useRef, useState } from 'react';
import { AddIcon, DeleteIcon, DotsHorizontalRoundedIcon, EditIcon } from 'components/SvgIcon';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import AddEvent from 'components/questions/input-event/AddEvent';
import { getEnumText } from 'helpers/enum.helper';
import DailyLifeEventEnum from 'enums/daily_life_event.enum';
import CustomDropdown from 'components/CustomDropdown';
import { isElementInViewport, randomId } from 'helpers/common.helper';
import { timeToMinute } from 'components/TdtSelectTime';

const times = [...Array(25).keys()];

const DailyLifeEvents = ({ id, value, onChange, isPreview = false, currentDailyLifeQuestion, isFilledIn }) => {
    const { t } = useTranslation();
    const ref = useRef(null);
    const [slots, setSlots] = useState(value || []);
    const [event, setEvent] = useState({});
    const [visible, setVisible] = useState(false);
    const onClose = () => {
        setVisible(false);
        setEvent({});
    };

    const renderAxisTime = () => {
        return (
            <>
                {times.map((num, index) => {
                    return (
                        <div key={`${num}_${index}`} className="dle-time">
                            <span>{`${index < times.length - 1 ? num : 0}:00`}</span>
                        </div>
                    );
                })}
            </>
        );
    };

    const onTouchSlot = num => {
        if (isPreview) return;
        const start = `${num}:00`;
        const startToMinutes = timeToMinutes(start);
        const endToMinutes = startToMinutes + 60;

        const closestUpperSlot = slots.reduce(
            (prev, curr) =>
                timeToMinute(curr.start) <= startToMinutes && timeToMinute(curr.start) >= timeToMinute(prev.start)
                    ? curr
                    : prev,
            { start: '0:00' }
        );
        const closestLowerSlot = slots.reduce(
            (prev, curr) =>
                timeToMinute(curr.end) >= endToMinutes && timeToMinute(curr.end) <= timeToMinute(prev.end)
                    ? curr
                    : prev,
            { end: '24:00' }
        );

        setEvent({
            start:
                closestUpperSlot.end && timeToMinute(closestUpperSlot.end) >= startToMinutes
                    ? closestUpperSlot.end
                    : start,
            end:
                closestLowerSlot.start && timeToMinute(closestLowerSlot.start) <= startToMinutes + 60
                    ? closestLowerSlot.start
                    : minutesToStr(startToMinutes + 60),
        });

        setVisible(true);
    };

    const renderAxisLine = () => {
        return (
            <div>
                {times.map((num, index) => {
                    return (
                        <div
                            key={`${num}_${index}`}
                            className="dle-hor-line dle-touch-slot"
                            onClick={() => onTouchSlot(num)}
                        >
                            <span />
                        </div>
                    );
                })}
            </div>
        );
    };

    const timeToMinutes = time => {
        if (!time) {
            return 0;
        }

        const arr = time.split(':');
        return Number(arr[0]) * 60 + Number(arr[1]);
    };

    const minutesToStr = minutes => {
        const hour = Math.floor(minutes / 60);
        const minute = minutes - hour * 60;
        return `${hour}:${minute ? minute : '00'}`;
    };

    const timeToPx = time => {
        if (!time) {
            return 0;
        }

        const arr = time.split(':');
        const m = Number(arr[0]) * 60 + Number(arr[1]);
        return (m * 72) / 60;
    };

    const handleMobileClickSlot = slot => {
        if (isPreview) return;
        setEvent({ ...slot });
        setVisible(true);
    };

    const renderSlots = () => {
        return (
            <div className="dle-slots">
                {slots.map((slot, index) => {
                    return (
                        <div
                            key={index}
                            className={`dle-event-slot slot-filled p-8 dle-color-${slot.type}`}
                            style={{ top: timeToPx(slot.start), height: timeToPx(slot.end) - timeToPx(slot.start) }}
                            onClick={() => {
                                if (isPreview) return;
                                setEvent({ ...slot });
                                setVisible(true);
                            }}
                        >
                            <span className="dle-type-label">
                                {timeToMinute(slot.end) - timeToMinute(slot.start) > 15 &&
                                    t(`enum:${getEnumText(DailyLifeEventEnum, slot.type)}`)}
                            </span>
                            {!isPreview && !isFilledIn && (
                                <CustomDropdown
                                    className={`dle-three-dots ${
                                        timeToMinute(slot.end) - timeToMinute(slot.start) > 15
                                            ? ''
                                            : 'dle-three-dot-higher'
                                    }`}
                                    ovlClassName={'dots-dropdown z-index-above-modal'}
                                    getPopupContainer={node => node}
                                    onClick={e => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }}
                                    trigger={['click']}
                                    options={[
                                        {
                                            key: 'edit',
                                            text: (
                                                <>
                                                    <EditIcon /> <span>{'編集'}</span>
                                                </>
                                            ),
                                            className: 'primary-color fw-b',
                                        },
                                        {
                                            key: 'delete',
                                            text: (
                                                <>
                                                    <DeleteIcon /> <span>{'削除'}</span>
                                                </>
                                            ),
                                            className: 'pink-color fw-b',
                                        },
                                    ]}
                                    onSelect={opt => {
                                        opt.domEvent.stopPropagation();
                                        if (opt.key === 'delete') {
                                            onChangeSlots(slots.filter(s => s.id !== slot.id));
                                        } else {
                                            setEvent({ ...slot });
                                            setVisible(true);
                                        }
                                    }}
                                    separator
                                >
                                    <DotsHorizontalRoundedIcon className=" dots-icon c-pointer" />
                                </CustomDropdown>
                            )}
                            <span
                                className="dle-event-slot-mobile-overlay"
                                onClick={() => handleMobileClickSlot(slot)}
                            />
                        </div>
                    );
                })}

                {!!event.start && (
                    <div
                        className={`dle-event-slot slot-creating dle-color-${event.type}-border`}
                        style={{
                            top: timeToPx(event.start),
                            height: event.end ? timeToPx(event.end) - timeToPx(event.start) : 0,
                        }}
                        ref={ref}
                    />
                )}
            </div>
        );
    };

    const onChangeSlots = slots => {
        setSlots(slots);
        onChange(slots);
    };

    const autoSlotSelectRange = () => {
        if (slots.length < 1) {
            return { start: '0:00', end: '1:00' };
        }

        const ends = slots
            .map(slot => timeToMinutes(slot.end))
            .sort((a, b) => {
                return a > b ? -1 : a < b ? 1 : 0;
            });
        const maxEnd = ends[0];
        if (maxEnd > 23 * 60) {
            return null;
        }
        return { start: minutesToStr(maxEnd), end: minutesToStr(maxEnd + 60) };
    };

    const renderSlotSelect = () => {
        const auto = autoSlotSelectRange();

        if (isPreview || visible || !auto || !auto.start || !auto.end) {
            return <></>;
        }

        return (
            <div
                className={`dle-slots ${slots.length > 0 ? 'dle-slots-select-has-slot' : ''}`}
                onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    setVisible(true);
                    setEvent({ start: auto.start, end: minutesToStr(timeToMinutes(auto.start) + 60) });
                }}
            >
                <div
                    className={`dle-event-slot slot-select p-8`}
                    style={{
                        top: timeToPx(auto.start),
                        height: timeToPx(auto.end) - timeToPx(auto.start),
                        minHeight: 73,
                    }}
                >
                    <div className="slot-select-container">
                        <AddIcon size={40} className="color-blg-40" />
                        <div className="h7 fw-700 color-blg-40">{'予定の追加'}</div>
                    </div>
                </div>
            </div>
        );
    };

    useEffect(() => {
        if (ref && event.start) {
            const el = ref.current;
            if (el && !isElementInViewport(el)) {
                const refRectTop = el.getBoundingClientRect().top;
                setTimeout(() => {
                    const absoluteElementTop = refRectTop + window.pageYOffset;
                    const middle = absoluteElementTop - window.innerHeight / 2.4;
                    window.scrollTo({ top: middle, left: 0, behavior: 'smooth' });
                }, 10);
            }
        }
    }, [event.start]);

    useEffect(() => {
        if (!id) {
            return;
        }
        if (!currentDailyLifeQuestion) return;
        setEvent({});
        setTimeout(() => {
            if (currentDailyLifeQuestion.order === 1) {
                onTouchSlot(currentDailyLifeQuestion.num);
            }
        }, 10);
    }, [currentDailyLifeQuestion]);

    return (
        <div className="daily-life-events daily-life-events-mobile">
            <div className="daily-life-wrapper">
                <div className="daily-life-times">{renderAxisTime()}</div>
                <div className="daily-life-calendar">
                    {renderAxisLine()}
                    {renderSlots()}
                    {renderSlotSelect()}
                </div>
                <div className={`daily-life-actions ${isFilledIn ? 'd-none' : ''}`}>
                    {!isPreview && (
                        <>
                            <Button icon={<AddIcon />} className="fw-b ml-16" onClick={() => setVisible(true)}>
                                {t('Register new')}
                            </Button>
                            <div
                                className="dle-add-event-wrapper"
                                style={{
                                    top:
                                        timeToPx(event.start) > 52
                                            ? timeToPx(event.start) > 1540
                                                ? 1540
                                                : timeToPx(event.start)
                                            : 52,
                                }}
                                ref={ref}
                            >
                                <AddEvent
                                    record={event}
                                    setRecord={setEvent}
                                    visible={visible}
                                    onClose={onClose}
                                    onOk={values => {
                                        if (values.id) {
                                            onChangeSlots(slots.map(s => (s.id === values.id ? values : s)));
                                        } else {
                                            onChangeSlots([...slots, { ...values, id: randomId(4) }]);
                                        }

                                        setEvent({});
                                    }}
                                    onDelete={slot => {
                                        onChangeSlots(slots.filter(s => s.id !== slot.id));
                                    }}
                                    slots={slots}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DailyLifeEvents;
