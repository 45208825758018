import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Col, Radio, Row } from 'antd';
import { fullDateFormatExceptHour, monthFormatExceptDay } from 'helpers/date.helper';
import { isEmptyAllowObject } from 'helpers/common.helper';
import { ArrowNextIcon, ArrowPreviousIcon } from 'components/SvgIcon';

const DateChangeComponent = ({ startDate, endDate, displayType, goPrev, goNext, goToday, setDisplayType }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="mt-32 chart-container">
                <Row justify="space-between">
                    <Col>
                        <Radio.Group
                            className="select-date-group-btn"
                            buttonStyle="solid"
                            defaultValue="week"
                            value={displayType}
                        >
                            <Radio.Button
                                className="select-date-btn"
                                value="week"
                                onChange={() => {
                                    setDisplayType('week');
                                }}
                            >
                                {t('Week')}
                            </Radio.Button>
                            <Radio.Button
                                className="select-date-btn"
                                value="month"
                                onChange={() => {
                                    setDisplayType('month');
                                }}
                            >
                                {t('Month')}
                            </Radio.Button>
                            <Radio.Button
                                className="select-date-btn"
                                value="year"
                                onChange={() => {
                                    setDisplayType('year');
                                }}
                            >
                                {t('Year')}
                            </Radio.Button>
                        </Radio.Group>
                    </Col>
                    <Col>
                        <span className="chart-date-text">
                            {!isEmptyAllowObject(startDate) && displayType === 'year'
                                ? monthFormatExceptDay(startDate)
                                : fullDateFormatExceptHour(startDate)}
                        </span>
                        <span className="chart-date-text">〜</span>
                        <span className="chart-date-text">
                            {!isEmptyAllowObject(endDate) && displayType === 'year'
                                ? monthFormatExceptDay(endDate)
                                : fullDateFormatExceptHour(endDate)}
                        </span>

                        <Button
                            className="pl-4"
                            type="link"
                            icon={<ArrowPreviousIcon size={12} />}
                            onClick={() => goPrev()}
                        />
                        <Button className="chart-today-button" onClick={() => goToday()}>
                            {t('today')}
                        </Button>
                        <Button
                            className="pl-14"
                            type="link"
                            icon={<ArrowNextIcon size={12} />}
                            onClick={() => goNext()}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default DateChangeComponent;

DateChangeComponent.propTypes = {
    startDate: PropTypes.any,
    endDate: PropTypes.any,
    displayType: PropTypes.any,
    goPrev: PropTypes.func,
    goNext: PropTypes.func,
    goToday: PropTypes.func,
    setDisplayType: PropTypes.func,
};
