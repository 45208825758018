import { createSelector, createSlice, current } from '@reduxjs/toolkit';
import EmailType from 'enums/email_type.enum';
import { randomId } from 'helpers/common.helper';

const name = 'emailCreate';
const initialState = {
    info: {
        type: EmailType.HTML_EMAIL.value,
        delivery_type: 'send_now',
        target_audiences: [],
    },
};

const emailCreateSlice = createSlice({
    name,
    initialState: initialState,
    reducers: {
        reset(state) {
            state.info = initialState.info;
        },
        setInfo(state, action) {
            state.info = { ...state.info, ...action.payload };
        },

        addByEmailInput(state, action) {
            const { email, companyEmployeesData } = action.payload;
            if (!state.info.target_audiences.find(r => r.email === email)) {
                state.info.target_audiences = [...state.info.target_audiences, { ...autoFillEmailInfo(email, companyEmployeesData), id: randomId() }];
            }
        },
        removeById(state, action) {
            const id = action.payload;
            state.info.target_audiences = state.info.target_audiences.filter(d => d.id !== id);
        },
        addByEmailList(state, action) {
            const { emails: items, companyEmployeesData } = action.payload;
            const oldItems = state.info.target_audiences;
            const mergedItems = oldItems.map(item => {
                const newItem = items.find(r => r.email === item.email);
                return (newItem && newItem.user_id) ? newItem : item;
            });

            state.info.target_audiences = [...mergedItems, ...items.filter(item => !mergedItems.find(r => r.email === item.email)).map(o => o.user_id ? o : ({...o, ...autoFillEmailInfo(o.email, companyEmployeesData)}))];
        },
    },
});

export const { reset, setInfo, addByEmailInput, removeById, addByEmailList } = emailCreateSlice.actions;
export default emailCreateSlice.reducer;

const targetAudiencesSelector = state => state.info.target_audiences;
export const checkTargetAudiencesSelector = createSelector(targetAudiencesSelector, data => {
    const tmp = {};
    for (const obj of data) {
        !tmp[obj.email] && (tmp[obj.email] = true);
    }
    let targetAudience = Object.keys(tmp).length;
    let numberOfRegistrants = data.length;
    let duplicateEmailAddress = numberOfRegistrants - targetAudience;
    return { targetAudience, numberOfRegistrants, duplicateEmailAddress };
});

const autoFillEmailInfo = (email, companyEmployeesData = []) => {
    for (const company of companyEmployeesData.data) {
        for (const employee of company.employees) {
            if (employee.email === email) {
                return {
                    email,
                    company_id: company.id,
                    company_name: company.name,
                    user_id: employee.id,
                    full_name: employee.full_name,
                }
            }
        }
    }
    return  {
         email
    }
};