import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col, Row, Form, Input, Select, Button } from 'antd';
import { InfoIcon } from 'components/SvgIcon';
import FloatBar from 'components/FloatBar';
import TdtButtonSubmit from 'components/TdtButtonSubmit';
import TdtSelect from 'components/TdtSelect';
import InquiryItemEnum from 'enums/inquiry_item.enum';
import { isMobileActive } from 'helpers/mobile.helper';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import { showNormal } from 'helpers/notification.helper';
import history from 'helpers/history.helper';

const Inquiry = () => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const createInquiry = async values => {
        const response = await api.post(APP_URLS.CLIENT_INQUIRY_CREATE, values);
        if (response) {
            showNormal('', t('message:We have sent your inquiry. Thank you very much.'), 3);
            form.resetFields();
        }
    };
    return (
        <>
            <Row justify="space-between" align="middle">
                <Col>
                    <h1 className="page-title mb-16 mt-48">{t('Inquiry')}</h1>
                </Col>
            </Row>
            <div className="inquiry-description">
                {t(
                    'Please send us your questions about Team Doctors. Customer support will respond to your account email address within 5 business days.'
                )}
            </div>
            <div className="inquiry-note">
                <div className="mr-8">
                    <InfoIcon className="td-info-icon" />
                </div>
                <div>
                    <span className="inquiry-note-title">{t('translation:Before contacting us')}</span>
                    <div className="mt-8">
                        <p className="inquiry-note-content mb-8">
                            {
                                '予約した相談の日時変更・キャンセルをおこなう場合は、相談の詳細画面からの予約編集または削除をおこなってください。'
                            }
                            <br />
                            {'※本ページからご連絡いただく必要はありません。'}
                        </p>
                        <span className="inquiry-note-content">
                            {'その他、よくある質問については'}
                            <Button
                                type="link"
                                className="faq-link"
                                href={'https://teamdoctors.jp/faq'}
                                target={isMobileActive() ? '_top' : '_blank'}
                            >
                                {'FAQ'}
                            </Button>
                            {'にも情報があります。'}
                        </span>
                    </div>
                </div>
            </div>

            <Form
                className="mt-32"
                layout="vertical"
                form={form}
                onFinish={async values => {
                    await createInquiry(values);
                }}
                requiredMark={false}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="category"
                            label={t('Category')}
                            validateTrigger={'onChange'}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <TdtSelect size="large" className="fs-14 tdt-select-40 inquiry-select">
                                {Object.values(InquiryItemEnum).map(i => (
                                    <Select.Option key={i.value} value={i.text}>
                                        {i.text}
                                    </Select.Option>
                                ))}
                            </TdtSelect>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="content"
                            label={t('Contents of inquiry')}
                            validateTrigger={'onChange'}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input.TextArea className="inquiry-text-area" maxLength={10000} />
                        </Form.Item>
                    </Col>
                </Row>

                <FloatBar className="ant-row-space-between">
                    <TdtButtonSubmit type="primary" htmlType="submit" className="fw-b btn-float-bar inquiry-btn">
                        {t('Send')}
                    </TdtButtonSubmit>
                    <Button
                        className="fw-b btn-float-bar inquiry-btn"
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        {t('Cancel')}
                    </Button>
                </FloatBar>
            </Form>
        </>
    );
};

export default Inquiry;

Inquiry.propTypes = {};
