import React from 'react';
import { Input, InputNumber, Typography } from 'antd';
import TdtButton from 'components/TdtButton';
import { CheckIcon, CloseIcon, DeleteIcon, EditIcon } from 'components/SvgIcon';
import { connect } from 'react-redux';
import { removeInfoByScore, updateInfoByScore } from 'pages/admin/interview_sheet/interview_sheet_create.slice';
import { isEmpty } from 'helpers/common.helper';

const mapState = (state, props) => ({
    data: state.admin.interviewSheetCreate.infoByScores.byId[props.id],
});
const mapDispatch = { remove: removeInfoByScore, update: updateInfoByScore };

const MessageByScoreRow = React.memo(({ colStyles, id, data, update, remove }) => {
    const { score_from, score_to, title, description, isEditing, rev } = data;

    const isValid =
        score_from < score_to &&
        title &&
        !isNaN(score_from) &&
        !isNaN(score_to) &&
        score_from !== null &&
        score_to !== null;
    const isBigger = score_from < score_to || (score_from !== 0 && !score_from) || (score_to !== 0 && !score_to);

    return (
        <div className="by-score-row d-flex align-items-center">
            <div style={colStyles[0]}>
                <div className="d-flex align-items-center">
                    <div>
                        {isEditing ? (
                            <InputNumber
                                className={`mw-58 h-40 bg-white input-number-score ${!isBigger ? 'border-red' : ''}`}
                                type="text"
                                min={0}
                                precision={2}
                                placeholder="00.00"
                                value={score_from || (score_from === 0 ? 0 : '')}
                                onChange={v => update({ id, changes: { score_from: v } })}
                            />
                        ) : (
                            <Typography className="">{parseFloat(rev.score_from || 0).toFixed(2)}</Typography>
                        )}
                    </div>
                    <Typography className="ml-6 mr-6">{'~'}</Typography>
                    <div>
                        {isEditing ? (
                            <InputNumber
                                className={`mw-58 h-40 bg-white input-number-score ${!isBigger ? 'border-red' : ''}`}
                                type="text"
                                min={0}
                                precision={2}
                                placeholder="00.00"
                                value={score_to || (score_to === 0 ? 0 : '')}
                                onChange={v => update({ id, changes: { score_to: v } })}
                            />
                        ) : (
                            <Typography className="">{parseFloat(rev.score_to || 0).toFixed(2)}</Typography>
                        )}
                    </div>
                </div>
            </div>
            <div style={colStyles[1]}>
                {isEditing ? (
                    <div>
                        <Input
                            className="h-40 bg-white interview-sheet-input-create"
                            placeholder="タイトルを入力"
                            value={title}
                            onChange={e => update({ id, changes: { title: e.target.value } })}
                        />
                    </div>
                ) : (
                    <Typography className="color-blg-100 fw-700 f-16-24">{rev.title || ''}</Typography>
                )}

                {isEditing ? (
                    <div className="mt-8">
                        <Input.TextArea
                            className="h-88 bg-white interview-sheet-input-create"
                            placeholder="説明テキストを入力"
                            value={description}
                            onChange={e => update({ id, changes: { description: e.target.value } })}
                        />
                    </div>
                ) : (
                    <Typography className="color-blg-100 fs-14-21">{rev.description || ''}</Typography>
                )}
            </div>
            <div style={colStyles[2]}>
                <div className="d-flex align-items-center pt-4 pb-4">
                    {isEditing ? (
                        <>
                            <div>
                                <TdtButton
                                    buttonStyle="outline"
                                    buttonSize="big"
                                    className="color-blue-100 btn-radius-4"
                                    onClick={() => {
                                        update({
                                            id,
                                            changes: {
                                                isEditing: false,
                                                rev: { score_from, score_to, title, description },
                                            },
                                        });
                                    }}
                                    disabled={!isValid}
                                    style={{ width: 64 }}
                                >
                                    <span className={`fw-700 fs-16 white-space-nowrap ${!isValid ? 'color-blg-60' : 'color-blue-100'}`}>{'確定'}</span>
                                    {/*<CheckIcon className={`${!isValid ? 'color-blg-60' : ''}`} />*/}
                                </TdtButton>
                            </div>

                            <div className="pl-10">
                                <TdtButton
                                    buttonStyle="outline"
                                    buttonSize="big"
                                    className="pink-color btn-radius-4"
                                    onClick={() =>
                                        rev ? update({ id, changes: { isEditing: false, ...rev } }) : remove({ id })
                                    }
                                >
                                    <CloseIcon />
                                </TdtButton>
                            </div>
                        </>
                    ) : (
                        <>
                            <div>
                                <TdtButton
                                    buttonStyle="outline"
                                    buttonSize="big"
                                    className="color-blue-100 btn-radius-4"
                                    onClick={() => update({ id, changes: { isEditing: true } })}
                                >
                                    <EditIcon />
                                </TdtButton>
                            </div>

                            <div className="pl-10">
                                <TdtButton
                                    buttonStyle="outline"
                                    buttonSize="big"
                                    className="pink-color btn-radius-4"
                                    onClick={() => remove({ id })}
                                >
                                    <DeleteIcon />
                                </TdtButton>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
});

export default connect(mapState, mapDispatch)(MessageByScoreRow);
