import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Input, Typography } from 'antd';
import {
    infoSelector,
    setDescription,
    setInputCondition,
    setName,
} from 'pages/admin/interview_sheet/interview_sheet_create.slice';

const mapState = state => infoSelector(state.admin.interviewSheetCreate);
const mapDispatch = { setName, setInputCondition, setDescription };

const InterviewSheetInfoCreate = ({
    description,
    inputCondition,
    name,
    setDescription,
    setInputCondition,
    setName,
}) => {
    const { t } = useTranslation();
    const { TextArea } = Input;
    const [form] = Form.useForm();

    return (
        <Form layout="vertical" form={form} className="bg-gray-5 flex-1" component="div">
            <div className="session-edit-wrapper session-detail-cards pt-24 pb-0 border-top-none">
                <div className="session-detail-card mb-24">
                    <div className="session-detail-card--header">
                        <Typography className="fw-700 fs-16 color-blg-100">{t('基本情報')}</Typography>
                    </div>
                    <div className="session-detail-card--body">
                        <div className="fw-700 fs-16 color-blg-100">
                            <label>{t('field:Name')}</label>
                            <div className="mt-8">
                                <Input
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                    className="w-50p bg-white interview-sheet-input-create"
                                />
                            </div>
                        </div>
                        <div className="fw-700 fs-16 color-blg-100 mt-16">
                            <label>{t('field:Input condition')}</label>
                            <div className="mt-8">
                                <Input
                                    value={inputCondition}
                                    onChange={e => setInputCondition(e.target.value)}
                                    className="bg-white interview-sheet-input-create"
                                />
                            </div>
                            <div className="body2 text-gray-color mt-8">
                                {t('Please enter input condition, such as once a month or when you are feeling tired')}
                            </div>
                        </div>
                        <div className="fw-700 fs-16 color-blg-100 mt-16">
                            <label>{t('field:Overview explanation')}</label>
                            <div className="mt-8">
                                <TextArea
                                    className="bg-white interview-sheet-input-create"
                                    value={description}
                                    onChange={e => setDescription(e.target.value)}
                                    autoSize
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    );
};

export default connect(mapState, mapDispatch)(InterviewSheetInfoCreate);

InterviewSheetInfoCreate.propTypes = {
    name: PropTypes.string,
    inputCondition: PropTypes.string,
    description: PropTypes.string,
    setName: PropTypes.func.isRequired,
    setInputCondition: PropTypes.func.isRequired,
    setDescription: PropTypes.func.isRequired,
};
