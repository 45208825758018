import React, { useState } from 'react';
import { Button, Select, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PeriodFilter from 'pages/admin/interview_sheet/detail_screen/common/PeriodFilter';
import QuestionTypeEnum from 'enums/question_type.enum';
import AggregateFreeInput from 'pages/admin/interview_sheet/detail_screen/tabs/total/AggregateFreeInput';
import AggregateSingleSelect from 'pages/admin/interview_sheet/detail_screen/tabs/total/AggregateSingleSelect';
import AggregateMultipleSelect from 'pages/admin/interview_sheet/detail_screen/tabs/total/AggregateMultipleSelect';
import AggregateDateTime from 'pages/admin/interview_sheet/detail_screen/tabs/total/AggregateDateTime';
import {
    answersToRadarChartData,
    combineCategoriesWithMultipleScore, parseCategoriesWithQuestions,
    parseQuestionnaire,
} from 'helpers/questionnaire.helper';
import { overviewSelector } from 'pages/admin/interview_sheet/detail_screen/interview_sheet_detail.slice';
import AggregateDailyLife from 'pages/admin/interview_sheet/detail_screen/tabs/total/AggregateDailyLife';
import RadarChart, { TransitionGraphChart } from 'components/chart/radar/RadarChart';
import { EllipseIcon } from 'components/SvgIcon';
import { getGraphPeriods, getMatchGraphPeriod } from 'helpers/date.helper';
import TdtSelect from 'components/TdtSelect';
import moment from 'moment';

const mapState = state => overviewSelector(state.admin.interviewSheetDetail);
const mapDispatch = {};

const TotalContainer = ({ answers, questions, setActiveKey, interviewSheet }) => {
    const { t } = useTranslation();

    const [displayType, setDisplayType] = useState(9999);

    const getParsedAnswersDataList = () => {
        let results = [];
        for (const answer of answers) {
            results.push(
                parseQuestionnaire({
                    questions: questions,
                    listAnswers: JSON.parse(answer.json_answers),
                })
            );
        }
        return results;
    };

    const parsedData = getParsedAnswersDataList();
    const renderQuestion = (question, no) => {
        let Component = null;
        switch (question.type) {
            case QuestionTypeEnum.FREE_INPUT.value:
            case QuestionTypeEnum.FREE_INPUT_PARAGRAPH.value:
            case QuestionTypeEnum.FREE_INPUT_NUMBER.value:
                Component = AggregateFreeInput;
                break;
            case QuestionTypeEnum.SINGLE_SELECTION.value:
                Component = AggregateSingleSelect;
                break;
            case QuestionTypeEnum.MULTIPLE_SELECTION.value:
                Component = AggregateMultipleSelect;
                break;
            case QuestionTypeEnum.DATE_TIME.value:
                Component = AggregateDateTime;
                break;
            case QuestionTypeEnum.DAILY_LIFE.value:
                Component = AggregateDailyLife;
                break;
        }

        if (!Component) {
            return <></>;
        }

        //const parsedData = getParsedAnswersDataList();
        const countAnswers = parsedData.filter(r => r.questionById[question.id]).length;

        return (
            <div className="session-detail-card--body">
                <Typography className="fs-12 fw-500 color-blg-80">{`Q${no}.`}</Typography>
                <div className="d-inline-flex fs-16 fw-500 white-space-pre-line">
                    {question.value}
                    {!!question.is_required && <span className="pink-color">*</span>}
                </div>
                <div className="d-flex">
                    <Typography className="fs-12 fw-700 color-blg-80">{countAnswers}</Typography>
                    <Typography className="ml-2 fs-12 fw-400 color-blg-80">{'件の回答'}</Typography>
                </div>
                <div className="mt-8">
                    <Component question={question} answersDataList={parsedData} setActiveKey={setActiveKey} />
                </div>
            </div>
        );
    };

    const jsonCategories = interviewSheet.json_categories;
    const categories = jsonCategories ? JSON.parse(jsonCategories) : [];

    const renderRadarChart = () => {
        const combineAllResults = answersToRadarChartData(
            answers.map(o => ({ ...o, interview_sheet: interviewSheet }))
                .sort((a, b) => {
                    return a.updated_at.localeCompare(b.updated_at);
                }),
            'YYYY/MM/DD HH:mm:ss'
        );

        let sumAllToResult = {};
        combineAllResults.map(combineAResult => {
            (combineAResult || []).map(o => {
                 if (!sumAllToResult[o.title]) {
                     sumAllToResult[o.title] = {
                        title: o.title,
                        score: 0,
                        scorePercent: 0,
                     };
                 }

                sumAllToResult[o.title].score += (o.score / combineAllResults.length);
                sumAllToResult[o.title].scorePercent += (o.scorePercent / combineAllResults.length);
            });
        });
        sumAllToResult = Object.values(sumAllToResult);

        const chartData = {
            labels: sumAllToResult.length > 0 ? sumAllToResult.map(o => o.title) : categories.map(c => c.title),
            datasets: [{
                label: '',
                data: sumAllToResult.map(o => o.scorePercent),
                dataScore: sumAllToResult.map(o => o.score),
            }],
        };

        const graphPeriods = getGraphPeriods(interviewSheet?.periods).sort((a, b) => {

            return a.displayPeriodStart.localeCompare(b.displayPeriodStart);
        });

        const resultToGraph = results => {
            const labels = [];
            const datasets = {};

            const limitedGraphPeriods = graphPeriods.slice(-displayType);

            const tempResults = {};
            let indexPeriodHasAnswer = -1;
            for (let i = 0; i < limitedGraphPeriods.length; i++) {
                const graphPeriod = limitedGraphPeriods[i];

                for (const result of results) {
                    const resultDataSetLabel = result[0].dataSetLabel;
                    const mm = moment(resultDataSetLabel);
                    if (!mm.isBetween(graphPeriod.periodStart, graphPeriod.periodEnd)) {
                        continue;
                    }

                    indexPeriodHasAnswer = i;
                    if (!tempResults[i]) {
                        tempResults[i] = [...result.map(cate => ({...cate, averageCount: 1}))];
                    } else {
                        for (const [index, refTempCategory] of tempResults[i].entries()) {
                            refTempCategory.score += result[index].score;
                            refTempCategory.scorePercent += result[index].scorePercent;
                            refTempCategory.averageCount += 1;
                        }
                    }
                }
            }

            for (let i = 0; i < limitedGraphPeriods.length; i++) {
                if (!tempResults[i] && indexPeriodHasAnswer > -1) {
                    tempResults[i] = tempResults[indexPeriodHasAnswer].map(o => ({ title: o.title, score: null, scorePercent: null, averageCount: null, isNoAnswer: true, dataSetLabel: limitedGraphPeriods[i].displayPeriodStart + ':59' }));
                }
            }

            const averageResults = Object.values(tempResults);

            for (const result of averageResults) {
                labels.push(result[0].dataSetLabel);
                for (const category of result) {
                    if (!datasets[category.title]) {
                        datasets[category.title] = {
                            title: category.title,
                            data: [],
                            dataScore: [],
                        };
                    }
                    datasets[category.title].data.push(category.averageCount ? category.scorePercent / category.averageCount : null);
                    datasets[category.title].dataScore.push(category.averageCount ? category.score / category.averageCount : null);
                }
            }

            return { labels, datasets: Object.values(datasets) };
        };
        const graphData = resultToGraph(combineAllResults);
        const chartDataGraph = {
            labels: graphData.labels,
            datasets: graphData.datasets.map(dataset => ({
                label: dataset.title,
                data: dataset.data,
                dataScore: dataset.dataScore,
            })),
        };

        return (
            <div className="session-detail-card--body">
                <div className="d-inline-flex fs-16 fw-500 white-space-pre-line">{`${interviewSheet.name}(平均)`}</div>
                <div className="d-flex">
                    <Typography className="fs-12 fw-700 color-blg-80">{answers.length}</Typography>
                    <Typography className="ml-2 fs-12 fw-400 color-blg-80">{'件の回答'}</Typography>
                </div>
                <div className="mt-8">
                    <Button
                        type="text"
                        className="fw-400 fs-14-21 color-blue-100 btn-underline p-0 h-unset"
                        onClick={() => setActiveKey('individual')}
                    >
                        {'個人のデータを確認する'}
                    </Button>

                    <div className="mt-32 d-flex align-items-center justify-content-space-between">
                        <RadarChart chartData={chartData} hideLegend={true} showScore={true} />
                    </div>

                    {graphData && graphData.labels.length > 0 && (
                        <>
                            <div className="mt-32 d-flex align-items-center justify-content-space-between ant-select-arrow-interview">
                                <Typography className="fw-700 fs-16">{t('推移グラフ')}</Typography>
                                <TdtSelect
                                    size="large"
                                    className=" mt-4 fw-400 fs-16 bg-white interview-sheet-select"
                                    style={{ width: 140, height: 44 }}
                                    value={displayType}
                                    onChange={v => setDisplayType(v)}
                                >
                                    <Select.Option value={3}>{'3回分'}</Select.Option>
                                    <Select.Option value={6}>{'6回分'}</Select.Option>
                                    <Select.Option value={12}>{'12回分'}</Select.Option>
                                    <Select.Option value={9999}>{'全部'}</Select.Option>
                                </TdtSelect>
                            </div>
                            <div className="mt-32 mb-24">
                                <TransitionGraphChart chartData={chartDataGraph} showScore={true} graphPeriods={graphPeriods} />
                            </div>
                        </>
                    )}

                </div>
            </div>
        );
    };

    return (
        <div className="bg-gray-5 flex-1">
            <div className="session-edit-wrapper session-detail-cards">
                <div className="session-detail-card">
                    <div className="session-detail-card--header">
                        <Typography className="fs-16 fw-700">{t('集計結果')}</Typography>
                    </div>
                    <div className="session-detail-card--body">
                        <div className="d-flex align-items-center ant-select-arrow-interview">
                            <PeriodFilter setActiveKey={setActiveKey} interviewSheet={interviewSheet} />
                        </div>
                    </div>

                    {categories.length > 0 ? (
                        <>{renderRadarChart()}</>
                    ) : (
                        <>
                            {questions.map((question, index) => (
                                <React.Fragment key={question.id || index}>
                                    {renderQuestion(question, index + 1)}
                                </React.Fragment>
                            ))}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
export default connect(mapState, mapDispatch)(TotalContainer);
